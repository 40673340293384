/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mineral/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { connect } from "react-redux";
import MetricListItem from "./MetricListItem";
import { selectMetricTypeGroup } from "../../api/metricTypes/actions";

class MetricsSelection extends Component {
  selectGroup(metricTypeGroup, selected) {
    let selectedStatus =
      selected && !metricTypeGroup.partialSelected ? true : false;
    this.props.onCheckMetricTypeGroup(metricTypeGroup, selectedStatus);
  }

  renderListItem(metricType) {
    let style = {
      color: "#fff",
      paddingLeft: "72px",
      backgroundColor: metricType.selected ? "#4D4D4D" : null,
    };
    if (metricType.metrics.length === 1) {
      return (
        <MetricListItem
          key={metricType.metrics[0]}
          metricId={metricType.metrics[0]}
        />
      );
    } else {
      return (
        <div>
          <ListItem
            style={style}
            key={metricType.metricType}
            /*  primaryText={`${metricType.name} (${metricType.metrics.length})`}
          secondaryText={`${metricType.description}, ${metricType.unit}`}
          secondaryTextLines={2}          
          nestedItems={metricType.metrics.map((metricId) => (
            <MetricListItem key={metricId} metricId={metricId} child={true} />
          ))} */
          >
            <ListItemIcon
              style={!metricType.selected ? { fill: "#C9C9C9" } : null}
            >
              <Checkbox
                style={{ left: "24px" }}
                checkedIcon={
                  //CheckBoxIcon
                  metricType.partialSelected ? (
                    <IndeterminateCheckBoxIcon
                      style={{ marginRight: "24px" }}
                    />
                  ) : (
                    <CheckBoxIcon style={{ marginRight: "24px" }} />
                  )
                }
                // iconStyle={!metricType.selected ? { fill: "#C9C9C9" } : null}
                defaultChecked={metricType.selected}
                onChange={(event) =>
                  this.selectGroup(metricType, event.target.checked)
                }
                aria-labelledby={`${metricType.name} (${metricType.metrics.length})`}
              />
            </ListItemIcon>
            <ListItemText
              primary={`${metricType.name} (${metricType.metrics.length})`}
              id={`${metricType.name} (${metricType.metrics.length})`}
              secondary={`${metricType.description}, ${metricType.unit}`}
            />
            {/* {open ? <ExpandLess /> : <ExpandMore />} */}
          </ListItem>

          {/* Need to implement for nestedItems
<Collapse in={open} timeout="auto" unmountOnExit>
<List component="div" disablePadding>
{metricType.metrics.map((metricId) => (
            <MetricListItem key={metricId} metricId={metricId} child={true} />
          ))}
</List>
</Collapse> */}
        </div>
      );
    }
  }

  render() {
    const { metricTypes } = this.props;

    let nestedItems;

    if (metricTypes.length === 0) {
      nestedItems = [
        <ListItem key="no-metrics" children="No metrics for this device" />,
      ];
    }

    nestedItems = metricTypes.map((metricType) =>
      this.renderListItem(metricType)
    );

    return <List>{nestedItems}</List>;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckMetricTypeGroup: (metricTypeGroup, selected) =>
      dispatch(selectMetricTypeGroup(metricTypeGroup, selected)),
  };
};

export default connect(null, mapDispatchToProps)(MetricsSelection);
//export default MetricsSelection
