/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  REQUEST_CONTAINER,
  REQUEST_CONTAINERS,
  FETCH_CONTAINER_SUCCESS,
  FETCH_CONTAINERS_SUCCESS,
  FETCH_CONTAINER_FAILURE,
  FETCH_CONTAINERS_FAILURE,
  INVENTORY_TREE_SELECTION,
  SET_CONTAINERS_TOTAL_ITEMS,
  SET_CONTAINERS_SORT,
  SET_CONTAINERS_FILTER,
  SET_CONTAINERS_COLUMN_FILTER,
  SET_CONTAINERS_QUICK_FILTER,
  SET_CONTAINERS_SHOW_SUMMARY,
  SET_CONTAINERS_REFRESH_GRID_DATA,
  SET_CONTAINERS_TREE,
  FETCH_LIST_SUCCESS,
  SET_CONTAINERS_TYPE,RESET_CONTAINER_ENTITIES,RESET_CONTAINER
} from "./actionTypes";
import entityTypes from "./../entityTypes";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { cleanItems } from "../../components/entities/setEntitiesReducer";

let updatedState = {};
const CONTAINERS_INIT_STATE= {   
       
      
     
  isFetching: false,
  didInvalidate: false,
  items: [],
  item:{},
  monitoringProbes: [],
  columnNameSums:{},
  columnNameTop5List:{}
 


};

const containers = (
  state = CONTAINERS_INIT_STATE,
  action
) => {
  switch (action.type) {
    case RESET_CONTAINER:
      state=CONTAINERS_INIT_STATE;  return state;        
    case REQUEST_CONTAINER:
    case REQUEST_CONTAINERS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case FETCH_CONTAINER_SUCCESS:
      const item = Object.assign({}, action.data, {
        entityType: entityTypes.CONTAINER,
      });
      updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        item: item,
      });
      // updatedState = Object.assign({}, state, {
      //   isFetching: false,
      //   didInvalidate: false,
      //   item: {
      //     ...action.data,
      //     entityType: entityTypes.CONTAINER,
      //     _items: action.data._items.map(item => {
      //       return {...cloneDeep(item), entityType: entityTypes.CONTAINER }
      //     })
      //   },
      // })
      return isEqual(updatedState, state) ? state : updatedState;
    case FETCH_CONTAINERS_SUCCESS:
      // const items = action.data._items
      // updatedState = Object.assign({}, state, {
      //   isFetching: false,
      //   didInvalidate: false,
      //   items: items,
      // })
      const items = action?.data?._items && cleanItems(
        action?.data?._items?.map((item) => {
          

        
            /*if(item && !item.alarmCountSummary){
              
            }
            const alarmCountSummary = Object.keys(item.alarmCountSummary).map(function(key) {
              let name = key === 'information' ? 'info' : key
              let cnt = item.alarmCountSummary[key]
              max = max >= cnt ? max : cnt
              return { name: capitalize(name), cnt: cnt }
            })
            let maxAlarmSeverity = 0
            if (alarmCountSummary[0].cnt > 0) {
              maxAlarmSeverity = {
                //CRITICAL
                label: Resource.get('CRITICAL'),
                value: 5,
                cnt: alarmCountSummary[0].cnt,
              }
            } else if (alarmCountSummary[1].cnt > 0) {
              maxAlarmSeverity = {
                //MAJOR
                label: Resource.get('MAJOR'),
                value: 4,
                cnt: alarmCountSummary[1].cnt,
              }
            } else if (alarmCountSummary[2].cnt > 0) {
              maxAlarmSeverity = {
                //MINOR
                label:Resource.get('MINOR'),
                value: 3,
                cnt: alarmCountSummary[2].cnt,
              }
            } else if (alarmCountSummary[3].cnt > 0) {
              maxAlarmSeverity = {
                //WARNING
                label: Resource.get('WARNING'),
                value: 2,
                cnt: alarmCountSummary[3].cnt,
              }
            } else if (alarmCountSummary[4].cnt > 0) {
              maxAlarmSeverity = {
                // INFO
                label: Resource.get('INFO'),
                value: 1,
                cnt: alarmCountSummary[4].cnt,
              }
            } else {
              maxAlarmSeverity = {
                // NONE
                label: Resource.get('NONE'),
                value: 0,
                cnt: 0,
              }
            }
            var totalAlarmCount = alarmCountSummary.reduce(function(total, alarm) {
              return alarm.cnt + total
            }, 0)
            item= Object.assign({}, item, {
              alarmCountSummary: alarmCountSummary,
              maxAlarmSeverity: maxAlarmSeverity,
              totalAlarmCount: totalAlarmCount,
            })*/
          return {
            ...cloneDeep(item),
            entityType: entityTypes.CONTAINER,
          };
        })
      );
      updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        columnNameSums:cloneDeep(action.data.columnNameSums),
          columnNameTop5List:cloneDeep(action.data.columnNameTop5List),
          totalCount:action.data.totalCount,
        items: {
          ...action.data,
          entityType: entityTypes.CONTAINER,
          items,
          

        },
      });
      return isEqual(updatedState, state) ? state : updatedState;
    case FETCH_CONTAINER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        item: [],
      });
    case FETCH_CONTAINERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: [],
        columnNameSums:{},
          columnNameTop5List:{}
      });
    case INVENTORY_TREE_SELECTION:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        errorMessage: action.errorMessage,
        items: [],
      });
    case FETCH_LIST_SUCCESS:
      return Object.assign({}, state, {
        monitoringProbes: action.data,
      });

      case SET_CONTAINERS_TYPE:
        return  Object.assign({}, state, {
          containerType: action.containerType,
        });

    default:
      return state;
  }
};

const CONTAINERENTITIESFILTERSORTCONFIG_INIT_STATE= {   
       
      
     
  totalItems: 0,
    suppressGridUpdate: false,
    config: {
      scopeId: null,
      probeName: null,
      daId: null,
      pageSize: 100,
      sortColumn: null,
      sortOrder: null,
      quickFilter: null,
      colFilters: [],
      showSummary:true,
    },
 


};
export function containerEntitiesFilterSortConfig(
  state = CONTAINERENTITIESFILTERSORTCONFIG_INIT_STATE,
  action
) {
  const newState = cloneDeep(state);
  switch (action.type) {
    case RESET_CONTAINER_ENTITIES:
      state=CONTAINERENTITIESFILTERSORTCONFIG_INIT_STATE; 
      return state;    
    case SET_CONTAINERS_TOTAL_ITEMS:
      return state.totalItems === action.totalItems
        ? state
        : { ...state, totalItems: action.totalItems };
    case SET_CONTAINERS_SORT:
      newState.config.sortColumn = action.sort.sortColumn;
      newState.config.sortOrder = action.sort.sortDirection;
      return newState;
    case SET_CONTAINERS_FILTER:
      newState.config.filters = action.filters;
      return newState;
    case SET_CONTAINERS_COLUMN_FILTER:
      newState.config.colFilters = action.colFilters;
      return newState;
    case SET_CONTAINERS_QUICK_FILTER:
      newState.config.quickFilter = action.quickFilter;
      return newState;
    case SET_CONTAINERS_SHOW_SUMMARY:
        newState.config.showSummary = action.showSummary;
        return newState;
    case SET_CONTAINERS_TREE:
      newState.config.scopeId = action.config.scopeId;
      newState.config.daId = action.config.daId;
      return newState;
    case SET_CONTAINERS_REFRESH_GRID_DATA:
      newState.refreshGridData = action.refreshGridData;
      return newState;
    default:
      return state;
  }
}

export default containers;
