// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Monitored Devices
  content["Monitored Devices"] =
  "Monitored Devices";

  //Last 30 minutes
  content["Last 30 minutes"] =
  "Last 30 minutes";

  //Discovered
  content["Discovered"] =
  "Discovered";

  //Device Count
  content["Device Count"] =
  "Device Count";

  //No Roles found
  content["No Roles found"] =
  "No Roles found";

  //Devices By Roles
  content["Devices By Roles"] =
  "Devices By Roles";

  //View All
  content["View All"] =
  "View All";

  //Technologies
  content["Technologies"] =
  "Technologies";

  //No Technologies Found
  content["No Technologies Found"] =
  "No Technologies Found";

  //Alarm Count
  content["Alarm Count"] =
  "Alarm Count";

  //Open Alarms
  content["Open Alarms"] =
  "Open Alarms";

  //No Alarms Found
  content["No Alarms Found"] =
  "No Alarms Found";

  //Devices
  content["Devices"] =
  "Devices";

  //No Devices Found
  content["No Devices Found"] =
  "No Devices Found";

  //No Groups Found
  content["No Groups Found"] =
  "No Groups Found";

  //Groups
  content["Groups"] =
  "Groups";

  // END OF LOCALIZATION

export default content;
