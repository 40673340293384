/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  REQUEST_ROBOTS,
  FETCH_ROBOTS_SUCCESS,
  FETCH_ROBOTS_FAILURE,
  CREATE_ROBOTS,
  CREATE_ROBOTS_SUCCESS,
  CREATE_ROBOTS_FAILURE,
  DEPLOY_ROBOTS,
  DEPLOY_ROBOTS_SUCCESS,
  DEPLOY_ROBOTS_FAILURE,
} from './actionTypes';
import { isEqual } from '../../utils/lodash-utils';

const robots = (state = {
  isFetching: false,
  didInvalidate: false,
  isCreating: false,
  didInvalidateCreate: false,
  items: [],
  isDeploying: false,
  didInvalidateDeploy: false,
  itemsDeployed: [],
  is_post_deployment_done:false,
}, action) => {
  switch (action.type) {
    case REQUEST_ROBOTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case FETCH_ROBOTS_SUCCESS:
      const items = action.robots._items
      const updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: items
      });
      return isEqual(updatedState, state) ? state : updatedState
    case FETCH_ROBOTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: []
      });
    case CREATE_ROBOTS:
      return Object.assign({}, state, {
        isCreating: true,
        didInvalidateCreate: false
      });
    case CREATE_ROBOTS_SUCCESS:
      const created = action.robots._items
      const updatedStateCreate = Object.assign({}, state, {
        isCreating: false,
        didInvalidateCreate: false,
        items: created
      });
      return isEqual(updatedStateCreate, state) ? state : updatedStateCreate
    case CREATE_ROBOTS_FAILURE:
      return Object.assign({}, state, {
        isCreating: false,
        didInvalidateCreate: true,
        errorMessage: action.errorMessage,
        items: []
      });
    case DEPLOY_ROBOTS:
      return Object.assign({}, state, {
        isDeploying: true,
        didInvalidateDeploy: false,
        is_post_deployment_done:false,
      });
    case DEPLOY_ROBOTS_SUCCESS:
      const deployed = action.robots._items
      const updatedStateDeploy = Object.assign({}, state, {
        isDeploying: false,
        didInvalidateDeploy: false,
        itemsDeployed: deployed,
        is_post_deployment_done:true,
      });
      return isEqual(updatedStateDeploy, state) ? state : updatedStateDeploy
    case DEPLOY_ROBOTS_FAILURE:
      return Object.assign({}, state, {
        isDeploying: false,
        didInvalidateDeploy: true,
        is_post_deployment_done:true,
        errorMessage: action.errorMessage,
        itemsDeployed: []
      });
    default:
      return state
  }
};

export default robots
