/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import FilterIcon from "./../icons/FilterForTable";
import UpDownIcon from "./../icons/UpDownIcon";
import { EventBus } from "../../../../utils/EventBus";
import { SortUp, SortDown } from "@mineral/icons";

class GridHeader extends Component {
  constructor(props) {
    super(props);
    this.props.column.addEventListener("sortChanged", this.onSortChanged);
    this.props.column.addEventListener("filterChanged", this.onFilterChanged);
    // document.addEventListener("backendSortChanged", this.onBackendSortChange);
    EventBus.$on("backendSortChanged", (params) => {
      this.onBackendSortChange(params, this.props);
    });
    this.state = {
      sorted: null,
      filterState: this.props.column.filterActive,
      isMouseEntered: false,
    };
    this.HeaderRef = null;
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  componentDidMount() {
    this.onSortChanged();
    if (this.HeaderRef) {
      this.HeaderRef.addEventListener("mouseenter", this.onMouseEnter);
      this.HeaderRef.addEventListener("mouseleave", this.onMouseLeave);
    }
    if (this.props.agGridReact?.props.backendSortConfig?.enable) {
      if (
        this.props.agGridReact.props.backendSortConfig.defaultSortColumnConfig
          .id === this.props.column.colId
      ) {
        this.setState({
          sorted:
            this.props.agGridReact.props.backendSortConfig
              .defaultSortColumnConfig.type,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.column.removeEventListener("sortChanged", this.onSortChanged);
    this.props.column.removeEventListener(
      "filterChanged",
      this.onFilterChanged
    );
    // document.removeEventListener(
    //   "backendSortChanged",
    //   this.onBackendSortChange
    // );
    if (this.HeaderRef) {
      this.HeaderRef.removeEventListener("mouseenter", this.onMouseEnter);
      this.HeaderRef.removeEventListener("mouseleave", this.onMouseLeave);
    }
  }

  onMouseEnter(e) {
    this.setState({
      isMouseEntered: true,
    });
  }
  onMouseLeave(e) {
    this.setState({
      isMouseEntered: false,
    });
  }
  onSortRequested = (event) => {
    var sortOrder;
    if (this.props.enableSorting) {
      if (
        this.props.column.colDef.defaultSort &&
        this.props.column.colDef.defaultSort === "desc"
      ) {
        sortOrder = "desc";
        if (this.state.sorted === "desc") {
          sortOrder = "asc";
        } else if (this.state.sorted === "asc") {
          sortOrder = null;
        }
      } else {
        sortOrder = "asc";
        if (this.state.sorted === "asc") {
          sortOrder = "desc";
        } else if (this.state.sorted === "desc") {
          sortOrder = null;
        }
      }

      if (this.props.columnSorting) {
        this.props.columnSorting(this.props.column.colId, sortOrder);
      }
      this.props.progressSort(false);
    }
  };
  onBackendSortRequested = (currentSort) => {
    let sortOrder;
    if (
      this.props.agGridReact.props.backendSortConfig.defaultSortColumnConfig
        ?.id === this.props.column.colId
    ) {
      sortOrder = "asc";
      if (currentSort === "asc") {
        sortOrder = "desc";
      } else if (this.state.sorted === "desc") {
        sortOrder = "asc";
      }
      } else {
      sortOrder = "asc";
      if (currentSort === "asc") {
        sortOrder = "desc";
      } else if (this.state.sorted === "desc") {
        sortOrder = null;
      }
    }
    this.props.agGridReact.props.backendSortConfig.callback({
      column: this.props.column.colId,
      sortOrder,
    });
  };
  onSortChanged = () => {
    let _sorted = null;
    if (this.props.column.isSortAscending()) {
      _sorted = "asc";
    } else if (this.props.column.isSortDescending()) {
      _sorted = "desc";
    }
    if (_sorted !== this.state.sorted) {
      this.setState({
        sorted: _sorted,
      });
    }
  };
  onFilterChanged = () => {
    if (this.state.filterState !== this.props.column.filterActive) {
      this.setState({
        filterState: this.props.column.filterActive,
      });
    }
  };

  onBackendSortChange = (event, props) => {
    if (
      !event.sortOrder &&
      this.props.agGridReact?.props.backendSortConfig.defaultSortColumnConfig
        .id === this.props.column.colId
    ) {
      this.setState({
        sorted:
          this.props.agGridReact.props.backendSortConfig.defaultSortColumnConfig
            .type,
      });
    } else if (event.column === this.props.column.colId) {
      this.setState({
        sorted: event.sortOrder,
      });
    } else {
      if (this.state.sorted) {
        this.setState({
          sorted: "",
        });
      }
    }
  };
  onMenuClicked(e) {
    this.props.showColumnMenu(this.menuButton);
    e.stopPropagation();
  }
  render() {
    const getSortedClass = (sorted) => {
      let classes = ["sortIcon"];
      if (
        sorted === "asc" ||
        sorted === "desc" ||
        (this.props.enableSorting && this.props.column.colDef.showSort)
      ) {
        classes.push("active");
      } else if (this.state.isMouseEntered) {
        classes.push("active");
      }
      return classes.join(" ");
    };
    let menu = null,
      menuClasses = ["customHeaderMenuButton"];
    if (this.state.filterState || this.state.isMouseEntered) {
      menuClasses.push("active");
    }
    if (this.props.enableMenu) {
      menu = (
        <div
          ref={(menuButton) => {
            this.menuButton = menuButton;
          }}
          className={menuClasses.join(" ")}
          style={{
            pointerEvents:
              menuClasses.indexOf("active") !== -1 ? "auto" : "none",
          }}
          onClick={this.onMenuClicked.bind(this)}
        >
          {this.state.filterState ? (
            <FilterIcon height={16} width={16} fill="#333840" />
          ) : (
            <FilterIcon
              className="icon filter__icon"
              height={16}
              width={16}
              fill={
                menuClasses.indexOf("active") !== -1 ? "#000000" : "#333840"
              }
              fillOpacity={menuClasses.indexOf("active") !== -1 ? "1" : "0"}
            />
          )}
        </div>
      );
    }
    let sortClasses = getSortedClass(this.state.sorted);

    return (
      <div
        style={{ width: "100%" }}
        ref={(input) => {
          this.HeaderRef = input;
        }}
        onClick={
          this.props.agGridReact?.props.backendSortConfig?.enable
            ? () => this.onBackendSortRequested(this.state.sorted)
            : this.onSortRequested.bind(this)
        }
      >
        <div
          className="customHeaderLabel"
          style={{ width: "100%", display: "flex" }}
        >
          {this.props.icon ? (
            this.props.icon
          ) : (
            <div className="displayName" style={{ pointerEvents: "none" }}>
              {this.props.displayName}
            </div>
          )}
          {this.props.enableSorting ? (
            <div
              style={{ width: "16px", marginLeft: "auto", marginRight: "24px" }}
              className={sortClasses}
            >
              {!this.state.sorted ? (
                <span>
                  <UpDownIcon aria-label={"Sortable"} width={16} height={16} />
                </span>
              ) : this.state.sorted === "asc" ? (
                <span>
                  <SortUp aria-label={"Sorted in Ascending Order"} style={{ height: "16px", width: "16px" }} />
                </span>
              ) : (
                <SortDown  aria-label={"Sorted in Descending Order"} style={{ height: "16px", width: "16px" }} />
              )}
            </div>
          ) : null}
          {menu}
        </div>
      </div>
    );
  }
}
export default GridHeader;
