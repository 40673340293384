/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  REQUEST_AGENTS,
  REQUEST_AGENTS_STATUS,
  FETCH_AGENTS_SUCCESS,
  FETCH_AGENTS_FAILURE,
  FETCH_AGENTS_STATUS_SUCCESS,
  FETCH_AGENTS_STATUS_FAILURE,
  FETCH_AGENTS,
  FETCH_AGENTS_STATUS,
  POLL_AGENTS_START,
  POLL_AGENTS_STOP,
  POLL_AGENTS_STATUS_START,
  POLL_AGENTS_STATUS_STOP,
  SELECTED_AGENT,
  GET_JOBS_RUNNING,
  FETCH_JOBS_RUNNING_SUCCESS,
  FETCH_JOBS_RUNNING_FAILURE,
  POLL_JOBS_RUNNING_START,
  POLL_JOBS_RUNNING_STOP,
  CUSTOM_SCOPE_DISCOVERY,
} from "./actionTypes";

export function getJobsRunning() {
  return {
    type: GET_JOBS_RUNNING,
  };
}

export function fetchJobsRunningSuccess(json) {
  return {
    type: FETCH_JOBS_RUNNING_SUCCESS,
    jobsCount: json,
  };
}

export function fetchJobsRunningFailure(error) {
  return {
    type: FETCH_JOBS_RUNNING_FAILURE,
    errorMessage: error.message,
  };
}

export function pollJobsRunningStart() {
  return {
    type: POLL_JOBS_RUNNING_START,
  };
}

export function pollJobsRunningStop() {
  return {
    type: POLL_JOBS_RUNNING_STOP,
  };
}

export function requestAgents() {
  return {
    type: REQUEST_AGENTS,
  };
}

export function requestAgentsStatus() {
  return {
    type: REQUEST_AGENTS_STATUS,
  };
}

export function fetchAgentsSuccess(json) {
  return {
    type: FETCH_AGENTS_SUCCESS,
    agents: json,
  };
}

export function fetchAgentsFailure(error) {
  return {
    type: FETCH_AGENTS_FAILURE,
    errorMessage: error.message,
  };
}

export function fetchAgentsStatusSuccess(json) {
  return {
    type: FETCH_AGENTS_STATUS_SUCCESS,
    status: json,
  };
}

export function fetchAgentsStatusFailure(error) {
  return {
    type: FETCH_AGENTS_STATUS_FAILURE,
    errorMessage: error.message,
  };
}

export function fetchAgents() {
  return {
    type: FETCH_AGENTS,
  };
}

export function fetchAgentsStatus() {
  return {
    type: FETCH_AGENTS_STATUS,
  };
}

export function pollAgentsStart() {
  return {
    type: POLL_AGENTS_START,
  };
}

export function pollAgentsStop() {
  return {
    type: POLL_AGENTS_STOP,
  };
}

export function pollAgentsStatusStart() {
  return {
    type: POLL_AGENTS_STATUS_START,
  };
}

export function pollAgentsStatusStop() {
  return {
    type: POLL_AGENTS_STATUS_STOP,
  };
}

export function setSelectedAgent(agent) {
  return {
    type: SELECTED_AGENT,
    selectedAgent: agent,
  };
}

export function setCustomScopeDiscovery(customScope) {
  return {
    type: CUSTOM_SCOPE_DISCOVERY,
    selectedAgentCustomScope: customScope
  }
}
