/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  REQUEST_OPEN_ALARMS,
  FETCH_OPEN_ALARMS_SUCCESS,
  FETCH_OPEN_ALARMS_FAILURE,
  REQUEST_ACTIVE_DEVICES,
  FETCH_ACTIVE_DEVICES_SUCCESS,
  FETCH_ACTIVE_DEVICES_FAILURE,
  REQUEST_ROLE_DEVICES,
  FETCH_ROLE_DEVICES_SUCCESS,
  FETCH_ROLE_DEVICES_FAILURE,
  REQUEST_TOP_TECHNOLOGIES,
  FETCH_TOP_TECHNOLOGIES_SUCCESS,
  FETCH_TOP_TECHNOLOGIES_FAILURE,
  REQUEST_TOP_GROUPS,
  FETCH_TOP_GROUPS_SUCCESS,
  FETCH_TOP_GROUPS_FAILURE,
  REQUEST_TOP_DEVICES,
  FETCH_TOP_DEVICES_SUCCESS,
  FETCH_TOP_DEVICES_FAILURE,
  HOME_SCREEN_COMPONENTS_CHANGE,
} from './actionTypes';
import { isEqual } from '../../utils/lodash-utils';

const homeScreen = (state = {
  isFetching: false,
  isOpenAlarmsFetching: false,
  isRoleDevicesFetching: false,
  didOpenAlarmsInvalidate: false,
  didActiveDevicesInvalidate: false,
  didRoleDevicesInvalidate: false,
  didTopTechnologiesInvalidate: false,
  didTopGroupsInvalidate: false,
  didTopDevicesInvalidate: false,
  openAlarms:[],
  activeDevices:[],
  roleDevices:[],
  topTechnologies:[],
  topGroups:[],
  topDevices:[],
  homeScreenComponents:{
    showOpenAlarms:true,
    showActiveDevices:true,
    showDevicesByRoles:true,
    showTopEntitiesContainer:true,
    showTopTechnologies:true,
    showTopGroups:true,
    showTopDevices:true,
  }

}, action) => {
  switch (action.type) {
    case REQUEST_OPEN_ALARMS:
    return Object.assign({}, state, {
      isOpenAlarmsFetching: true,
      didOpenAlarmsInvalidate: false
    });
    case FETCH_OPEN_ALARMS_SUCCESS:
    const openAlarms = action.data;
    const openAlarmsState = Object.assign({}, state, {
      isOpenAlarmsFetching: false,
      didOpenAlarmsInvalidate: false,
      openAlarms: openAlarms,

    });
    return isEqual(openAlarmsState, state) ? state : openAlarmsState
    case FETCH_OPEN_ALARMS_FAILURE:
    return Object.assign({}, state, {
      isOpenAlarmsFetching: false,
      didOpenAlarmsInvalidate: true,
      errorMessage: action.errorMessage,
      openAlarms: []

    });
    case REQUEST_ACTIVE_DEVICES:
    return Object.assign({}, state, {
      isFetching: true,
      didActiveDevicesInvalidate: false
    });
    case FETCH_ACTIVE_DEVICES_SUCCESS:
    const activeDevices = action.data;

    const activeDevicesState = Object.assign({}, state, {
      isFetching: false,
      didActiveDevicesInvalidate: false,
      activeDevices: activeDevices,

    });
    return isEqual(activeDevicesState, state) ? state : activeDevicesState
    case FETCH_ACTIVE_DEVICES_FAILURE:
    return Object.assign({}, state, {
      isFetching: false,
      didActiveDevicesInvalidate: true,
      errorMessage: action.errorMessage,
      activeDevices: []

    });
    case REQUEST_ROLE_DEVICES:
    return Object.assign({}, state, {
      isRoleDevicesFetching: true,
      didRoleDevicesInvalidate: false
    });
    case FETCH_ROLE_DEVICES_SUCCESS:
    const roleDevices = action.data;

    const roleDevicesState = Object.assign({}, state, {
      isRoleDevicesFetching: false,
      didRoleDevicesInvalidate: false,
      roleDevices: roleDevices,

    });
    return isEqual(roleDevicesState, state) ? state : roleDevicesState
    case FETCH_ROLE_DEVICES_FAILURE:
    return Object.assign({}, state, {
      isRoleDevicesFetching: false,
      didRoleDevicesInvalidate: true,
      errorMessage: action.errorMessage,
      roleDevices: []

    });
    case REQUEST_TOP_TECHNOLOGIES:
    return Object.assign({}, state, {
      isFetching: true,
      didTopTechnologiesInvalidate: false
    });
    case FETCH_TOP_TECHNOLOGIES_SUCCESS:
    const topTechnologies = action.data
    const topTechnologiesState = Object.assign({}, state, {
      isFetching: false,
      didTopTechnologiesInvalidate: false,
      topTechnologies: topTechnologies,

    });
    return isEqual(topTechnologiesState, state) ? state : topTechnologiesState
    case FETCH_TOP_TECHNOLOGIES_FAILURE:
    return Object.assign({}, state, {
      isFetching: false,
      didTopTechnologiesInvalidate: true,
      errorMessage: action.errorMessage,
      topTechnologies: []

    });
    case REQUEST_TOP_GROUPS:
    return Object.assign({}, state, {
      isFetching: true,
      didTopGroupsInvalidate: false
    });
    case FETCH_TOP_GROUPS_SUCCESS:
    const topGroups = action.data;
    const topGroupsState = Object.assign({}, state, {
      isFetching: false,
      didTopGroupsInvalidate: false,
      topGroups: topGroups,

    });
    return isEqual(topGroupsState, state) ? state : topGroupsState
    case FETCH_TOP_GROUPS_FAILURE:
    return Object.assign({}, state, {
      isFetching: false,
      didTopGroupsInvalidate: true,
      errorMessage: action.errorMessage,
      topGroups: []

    });
    case REQUEST_TOP_DEVICES:
    return Object.assign({}, state, {
      isFetching: true,
      didTopDevicesInvalidate: false
    });
    case FETCH_TOP_DEVICES_SUCCESS:
    const topDevices = action.data;
    const topDevicesState = Object.assign({}, state, {
      isFetching: false,
      didTopDevicesInvalidate: false,
      topDevices: topDevices,

    });
    return isEqual(topDevicesState, state) ? state : topDevicesState
    case FETCH_TOP_DEVICES_FAILURE:
    return Object.assign({}, state, {
      isFetching: false,
      didTopDevicesInvalidate: true,
      errorMessage: action.errorMessage,
      topDevices: []

    });
    case HOME_SCREEN_COMPONENTS_CHANGE:
    return Object.assign({}, state, {
      homeScreenComponents:action.currentState,

    });
    default:
    return state
  }
};

export default homeScreen
