/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Essentials subscription
  content["Essentials subscription"] =
  "Essentials の購読";

  //50 max
  content["50 max"] =
  "最大 50";

  //Total devices discovered
  content["Total devices discovered"] =
  "検出されたデバイスの合計";

  //VIEW INVENTORY
  content["VIEW INVENTORY"] =
  "インベントリの表示";

  //No Roles found
  content["No Roles found"] =
  "役割が見つかりませんでした";

  //Devices by role
  content["Devices by role"] =
  "ロール別デバイス";

  //Alarm Severity Message
  content["Highest Alarm Severity Level and Total Alarm Count"] =
  "最も高いアラーム重大度レベルおよび合計アラーム数";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "最大アラーム重大度";

  //Monitored technology
  content["Monitored technology"] =
  "モニタ対象テクノロジ";

  //Total Alarms
  content["Total Alarms"] =
  "合計アラーム数";

  //INFO
  content["INFO"] =
  "情報";

  //WARNING
  content["WARNING"] =
  "警告";

  //MINOR
  content["MINOR"] =
  "マイナー";

  //MAJOR
  content["MAJOR"] =
  "メジャー";

  //CRITICAL
  content["CRITICAL"] =
  "クリティカル";

  //Monitored teechnologies
  content["Monitored Technologies"] =
  "モニタ対象テクノロジ";

  //Name
  content["Name"] =
  "名前";

  //Role
  content["Role"] =
  "役割";

  //Top Devices by Alarm
  content["Top Devices by Alarm"] =
  "アラーム別の上位デバイス";

  //Group name
  content["Group name"] =
  "グループ名";

  //Total alarms
  content["Total alarms"] =
  "合計アラーム数";

  //Total alarms by group
  content["Total alarms by group"] =
  "合計アラーム (グループ別)";

  // END OF LOCALIZATION

export default content;
