/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React from 'react'
import {Link} from 'react-router-dom'
import '../../assets/css/index.less'
import './pageNotFound.less'
import Resource from './Resource'

const PageNotFound = (props) => {
    return (
      <div className='page-not-found' >
        <h2>{props.title}</h2>
        <div className='line-separator page-not-found__line-separator' ></div>
        <div className='page-not-found__message'>
          {props.message}
        </div>
        <div className='page-not-found__link-container'>
          <Link to='/' className='page-not-found__link'>{props.returnLink}</Link>
        </div>
      </div>
    )
}

PageNotFound.defaultProps ={
	//Page Not Found
  title : Resource.get('Page Not Found'),
  //The requested pag ecannot be found
  message: Resource.get('The requested page cannot be found'),
  //Return to Operator Console
  returnLink : Resource.get('Return to Operator Console'),
}
export default PageNotFound