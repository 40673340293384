/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React from 'react'


const Icon = (props) => {
  let classes = [
    'icon',
    props.className,
  ].join(' '),
  height = props.height || 24,
  width = props.width || 24,
  fillVal = props.fill ,
  fillOpacity =  props.fillOpacity || 1;
  return (
  
    <svg  className={classes} role={props.role || 'figure'} aria-label={props['aria-label']} width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M5.5 6.84692L3.67468 8.70717C3.29157 9.09761 2.67043 9.09761 2.28733 8.70717C1.90422 8.31674 1.90422 7.68372 2.28733 7.29328L6.5 3L10.7127 7.29328C11.0958 7.68372 11.0958 8.31674 10.7127 8.70717C10.3296 9.09761 9.70843 9.09761 9.32532 8.70717L7.5 6.84692V21H5.5V6.84692ZM16.5 17.1531V3H18.5V17.1531L20.3253 15.2928C20.7084 14.9024 21.3296 14.9024 21.7127 15.2928C22.0958 15.6833 22.0958 16.3163 21.7127 16.7067L17.5 21L13.2873 16.7067C12.9042 16.3163 12.9042 15.6833 13.2873 15.2928C13.6704 14.9024 14.2916 14.9024 14.6747 15.2928L16.5 17.1531Z" fill="#333840"/>
</svg>

  )
}

export default Icon
