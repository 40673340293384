/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { useEffect } from "react";
import { FormControlLabel, Checkbox, Box, Tooltip } from "@mineral/core";
import { GridToolbarContainer, GridToolbarDensitySelector } from "@mui/x-data-grid";
import CalendarPicker from './CalendarPicker';
import CustomPlotPoint from "./CustomPlotPoint";
import CustomDataGrid from "./CustomDataGrid";
import './customLegend.less';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarExport /> */}
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}
const CustomLegend = (props) => {
  const [isColumnVisible, setColumnVisibility] = React.useState(false)
  const columns = [
    {
      field: "color",
      headerName: "",
      // align: 'center',
      headerClassName: 'legend-header-color',
      renderCell: (cellValues) => {
        const i = cellValues.row.id;
        const width = props.selectedCombos[i].icon === 'filledCircle' ? 25 : 15;
        const height = props.selectedCombos[i].icon === 'filledCircle' ? 25 : 15;
        return (
          <Box>
            <CustomPlotPoint value={1} color={props.selectedCombos[i].color} shape={props.selectedCombos[i].icon} width={width} height={height} />
          </Box>
        )
      },
      editable: false,
      width: `${props.isChartExpanded ? 100 : 40}`,
    },
    {
      field: "source",
      headerName: "Source",
      // width: isColumnVisible ? 200 : 300,
      flex: 1,
      headerClassName: 'legend-header',
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div style={{ textOverflow: "ellipsis", width: `${props.isChartExpanded?"300px":"150px"}`, overflow: "hidden" }}>{cellValues.value}</div></Tooltip>
      }
    },
    {
      field: "qos",
      headerName: "QoS",
      headerClassName: 'legend-header',
      flex: 1,

      // width: isColumnVisible ? 200 : 300,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div style={{ textOverflow: "ellipsis", width: `${props.isChartExpanded?"300px":"150px"}`, overflow: "hidden" }}>{cellValues.value}</div></Tooltip>
      }
    },
    {
      field: "target",
      headerName: "Target",
      headerClassName: 'legend-header',
      flex: 1,

      // width: isColumnVisible ? 200 : 300,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div style={{ textOverflow: "ellipsis", width: `${props.isChartExpanded?"300px":"150px"}`, overflow: "hidden" }}>{cellValues.value}</div></Tooltip>
      }
    },
    {
      field: "dataMin",
      headerName: "Min.",
      headerClassName: 'legend-header',
      flex: 1,

      // width: 100,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div>{cellValues.value}</div></Tooltip>
      }
    },
    {
      field: "dataMax",
      headerName: "Max.",
      headerClassName: 'legend-header',
      flex: 1,

      // width: 100,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div>{cellValues.value}</div></Tooltip>
      }
    },

    {
      field: "dataStdDev",
      headerName: "Deviation",
      headerClassName: 'legend-header',
      flex: 1,

      // width: 150,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={Number(cellValues.value).toFixed(2)}><div>{(cellValues.value!=null && cellValues.value!=undefined)? Number(cellValues.value).toFixed(2):null}</div></Tooltip>
      }
    },
    {
      field: "dataAvg",
      headerName: "Average",
      headerClassName: 'legend-header',
      flex: 1,

      // width: 150,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={Number(cellValues.value).toFixed(2)}><div>{(cellValues.value!=null && cellValues.value!=undefined)? Number(cellValues.value).toFixed(2):null}</div></Tooltip>
      }
    },
    {
      field: "currentAvg",
      headerName: "Sample",
      headerClassName: 'legend-header',
      flex: 1,

      // width: 150,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div>{cellValues.value}</div></Tooltip>
      }
    },
    {
      field: "percentileValue",
      headerName: "Percentile",
      headerClassName: 'legend-header',
      flex: 1,

      // width: 150,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div>{cellValues.value}</div></Tooltip>
      }
    },
    {
      field: "baselineValue",
      headerName: "Baseline",
      headerClassName: 'legend-header',
      flex: 1,

      // width: 150,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div>{cellValues.value}</div></Tooltip>
      }
    },
    {
      field: "trendLineValue",
      headerName: "Trendline",
      headerClassName: 'legend-header',
      flex: 1,

      // width: 150,
      editable: false,
      renderCell: (cellValues) => {
        return <Tooltip title={cellValues.value}><div>{cellValues.value}</div></Tooltip>
      }
    },
  ];
  useEffect(() => {
    setColumnVisibility(props.showMaxMin);
  }, [props.showMaxMin])
  const actionHandler = () => {
    setColumnVisibility(!isColumnVisible);
    props.changeMaxMin(!isColumnVisible);
  }
  return (
    <Box display={'flex'} flexDirection={'column'} sx={{ height: '100%' }}>
      <Box display={'flex'} flexDirection={'row'} justifyContent="space-between">

        <Box width="60%"
          style={{
            width: `${window.screen.width > 1280 ? '60%' : '50%'}`
          }}
        >
          <CalendarPicker isChartExpanded={props.isChartExpanded} tooltipValue={props.label} referenceData={props.refLineData} setRefLineData={props.setRefLineData} startTime={props.startTime} endTime={props.endTime} />
        </Box>
        <FormControlLabel
          control={<Checkbox onChange={actionHandler} onFocusVisible={actionHandler} checked={props.showMaxMin} style={{margin:"0px",padding:"0px"}} />}
          label={<div style={{margin:"0px",padding:"0px",position:"relative",left:"-10px"}}>Show Aggregate values</div>}
          name="basic"
          sx={{ paddingTop: 0, paddingRight: "0px", margin:0 }}
        />
      </Box>
      <CustomDataGrid selectNode={props.selectNode} isColumnVisible={isColumnVisible}
      data={props.data} columns={columns} referenceData={props.referenceData}
      percentileValue={props.percentileValue} scaledValue={props.scaledValue}
      isBaseline={props.isBaseline} baselineValue={props.baselineValue}
      isTrendLine={props.isTrendLine}
      trendLineValue={props.trendLineValue}
      isBlank={false}
      />
    </Box>
  );
};

export default CustomLegend;
