/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Essentials subscription
  content["Essentials subscription"] =
  "Suscripción a Essentials";

  //50 max
  content["50 max"] =
  "50 máx.";

  //Total devices discovered
  content["Total devices discovered"] =
  "Número total de dispositivos detectados";

  //VIEW INVENTORY
  content["VIEW INVENTORY"] =
  "VER INVENTARIO";

  //No Roles found
  content["No Roles found"] =
  "No se ha encontrado roles.";

  //Devices by role
  content["Devices by role"] =
  "Dispositivos por rol";

  //Alarm Severity Message
  content["Highest Alarm Severity Level and Total Alarm Count"] =
  "Nivel de severidad de alarma más alto y recuento total de alarmas";

  //Max Alarm Severity
  content["Max Alarm Severity"] =
  "Nivel de severidad de alarma máximo";

  //Monitored technology
  content["Monitored technology"] =
  "Tecnología monitorizada";

  //Total Alarms
  content["Total Alarms"] =
  "Núm. total de alarmas";

  //INFO
  content["INFO"] =
  "INFORMACIÓN";

  //WARNING
  content["WARNING"] =
  "ADVERTENCIA";

  //MINOR
  content["MINOR"] =
  "LEVE";

  //MAJOR
  content["MAJOR"] =
  "GRAVE";

  //CRITICAL
  content["CRITICAL"] =
  "CRÍTICA";

  //Monitored teechnologies
  content["Monitored Technologies"] =
  "Tecnologías monitorizadas";

  //Name
  content["Name"] =
  "Nombre";

  //Role
  content["Role"] =
  "Rol";

  //Top Devices by Alarm
  content["Top Devices by Alarm"] =
  "Dispositivos principales por alarma";

  //Group name
  content["Group name"] =
  "Nombre del grupo";

  //Total alarms
  content["Total alarms"] =
  "Núm. total de alarmas";

  //Total alarms by group
  content["Total alarms by group"] =
  "Núm. total de alarmas por grupo";

  // END OF LOCALIZATION

export default content;
