/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import { isEqual, forOwn, values } from '../../../../utils/lodash-utils'

import {
  REQUEST_METRIC_TYPES,
  FETCH_METRIC_TYPES_SUCCESS,
  FETCH_METRIC_TYPES_FAILURE,
  SELECT_METRIC_TYPE,
  SELECT_ALL_METRIC_TYPES,
  SELECT_METRIC_TYPE_GROUP,
} from './actionTypes'

const initialState = {
  isFetching: false,
  items: {},
  groups: {},
  selectedMetricsGroup: [],
  selectedMetricsArrayChanged: false,
}

const metricTypes = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_METRIC_TYPES:
      return { ...state, isFetching: true }
    case FETCH_METRIC_TYPES_SUCCESS:
      let items = {}
      let groups = {}
      forOwn(action.data, (device, deviceId) => {
        let group = {}
        forOwn(device, (value, metricId) => {
          items[metricId] = value
          group[value.metricType] = group[value.metricType] || {
            metricType: value.metricType,
            name: (value.name && value.name !== "null")? value.name : value.qosName,
            description: value.description,
            unit: value.unit,
            unitShort: value.unitShort,
            selected: value.selected,
          }
          group[value.metricType].metrics = group[value.metricType].metrics || []
          if (group[value.metricType].metrics.length > 1) {
           group[value.metricType].name = (value.descriptionShort && value.descriptionShort !== "null")? value.descriptionShort : value.qosName
          }
          group[value.metricType].metrics.push(value.id)
        })
        groups = group
      })
      const updatedTypesState = Object.assign({}, state, {
        isFetching: false,
        items,
        groups,
      })
      return isEqual(updatedTypesState, state) ? state : updatedTypesState

    case FETCH_METRIC_TYPES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.errorMessage,
        groups: {},
        items: {},
      })

    case SELECT_METRIC_TYPE:
      const updatedMetric = Object.assign({}, state.items[action.metricId], { selected: action.selected })
      const updatedItems = Object.assign({}, state.items, { [action.metricId]: updatedMetric })
      let nestedMetricTypes = values(state.groups[action.metricTypeId].metrics).filter(
        metricType => updatedItems[metricType].selected,
      )
      let groupSelected = state.groups[action.metricTypeId].selected || false
      let updatedSelectedMetricsGroup = state.selectedMetricsGroup ? [...state.selectedMetricsGroup] : nestedMetricTypes
      let checkMetricsArrayChanged = true

      if (nestedMetricTypes.length >= 1) {
        groupSelected = true
        if (updatedSelectedMetricsGroup.indexOf(action.metricTypeId) === -1){
          updatedSelectedMetricsGroup = [...updatedSelectedMetricsGroup, action.metricTypeId]
        }else{
          checkMetricsArrayChanged = false
        }
      } else {
        groupSelected = action.selected
        updatedSelectedMetricsGroup = action.selected
          ? [...updatedSelectedMetricsGroup, action.metricTypeId]
          : updatedSelectedMetricsGroup.filter(a => a !== action.metricTypeId)
      }

      let partialSelected =
        groupSelected && nestedMetricTypes.length !== state.groups[action.metricTypeId].metrics.length ? true : false

      const updatedMetricType = Object.assign({}, state.groups[action.metricTypeId], {
        selected: groupSelected,
        partialSelected: partialSelected,
      })
      const updatedGroups = Object.assign({}, state.groups, { [action.metricTypeId]: updatedMetricType })
      return Object.assign({}, state, {
        items: updatedItems,
        groups: updatedGroups,
        selectedMetricsGroup: updatedSelectedMetricsGroup,
        selectedMetricsArrayChanged: checkMetricsArrayChanged,
      })

    case SELECT_ALL_METRIC_TYPES:
      let toggleAllMetrics = {}
      for (var key in state.items[action.deviceId]) {
        toggleAllMetrics[key] = Object.assign({}, state.items[action.deviceId][key], { selected: action.selected })
      }
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, { [action.deviceId]: toggleAllMetrics }),
      })

    case SELECT_METRIC_TYPE_GROUP:
      const updatedMetricGroupItem = Object.assign({}, state.groups[action.metricTypeGroup.metricType], {
        selected: action.selected,
        partialSelected: false,
      })
      const updatedMetricGroup = Object.assign({}, state.groups, {
        [action.metricTypeGroup.metricType]: updatedMetricGroupItem,
      })
      const updatedNestedMetrics = Object.assign({}, state.items)
      const nestedMetrics = updatedMetricGroupItem.metrics
      for (let i = 0; i < nestedMetrics.length; i++) {
        updatedNestedMetrics[nestedMetrics[i]] = Object.assign({}, state.items[nestedMetrics[i]], {
          selected: action.selected,
        })
      }
      let updatedMetricSelection = action.selected
        ? [...state.selectedMetricsGroup, action.metricTypeGroup.metricType]
        : state.selectedMetricsGroup.filter(a => a !== action.metricTypeGroup.metricType)

      return Object.assign({}, state, {
        groups: updatedMetricGroup,
        items: updatedNestedMetrics,
        selectedMetricsGroup: updatedMetricSelection,
        selectedMetricsArrayChanged: true,
      })

    default:
      return state
  }
}

export default metricTypes
