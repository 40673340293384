/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import { Home, Tools, Task, Gear, Reports, UnevenGrid, MobileDevice, Dashboard, Chat, QuestionMark } from "@mineral/icons";

import {
  AlarmsManagementIcon
} from "../ui-components/uim-components";

const communityLink =
  "https://community.broadcom.com/enterprisesoftware/communities/communityhomeblogs?CommunityKey=170eb4e5-a593-4af2-ad1d-f7655e31513b";

  const  helpLink =
    "https://techdocs.broadcom.com/bin/gethidpage?hid=OChome&space=UIM234&language=&format=rendered";

export const iconMapper = {
  "Home": Home,
  "Alarm Management": AlarmsManagementIcon,
  "Inventory Management": MobileDevice,
  "Service Level Management": Task,
  "Dashboards": Dashboard,
  Reports: Reports,
  "My Dashboards": UnevenGrid,
  Extensions: Tools,
  Administration: Gear,
  Community: Chat,
  Help: QuestionMark
};

export const hyperLinkMapper = {
  "Home": '/overview',
  "Alarm Console": "/uim-alarms",
  "Alarm Policies": "/settings/alarm-policies",
  Groups: "/groups/0/treeviewnew",
  Inventory: "/inventory",
  "Cloud Native":"/cloud",
  "Device Discovery": "/discover-devices-tiles",
  "Setup Wizard": "/getting-started",
  "Dashboard Designer": "/settings/dashboard",
  "List Designer": "/settings/listdesigner",
  "Performance Report Designer": "/settings/performancereports",
  "Observability Designer": "/mydashboards?redirect=%252Fcanvas",
  "Dashboard Viewer": "/technologies",
  "List Viewer": "/settings/listviewer",
  "Observability Viewer": "/mydashboardsOV",
  customPages: "/mydashboardscustompages",
  "Report Schedules": "/settings/reportscheduler",
  "CABI Reports": "/reports",
  "Service Level Management": "/slm",
  "Self Certification Portal": "/settings/self-cert",
  "RESTMon": "/settings/restmon",
  "Ecometer": "/settings/dcimadmin",
  "Account Administration": "/settings/acc-admin",
  "Admin Console": "/adminconsoleapp",
  "User Personalization": "/settings/personalize",
  "MCS Dashboards": "/mcsDeploymentStatus", // MCS Dashboards. Change to correct route
  "App Discovery": "/settings/administration",
  "Product Usage": "/settings/usagemetrics",
  Community: communityLink,
  Help: helpLink
};

export const hyperLinkInNewTab = {
  "Admin Console": true,
  "Community": true,
  "Help": true
};

export const notReactRouter = {
  "Admin Console": true,
  Community: true,
  Help: true
};

export const poll = async (fn, ms) => {
  while (true) {
    await wait(ms);
    await fn();
  }
};

export const wait = (ms = 5000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const menu = {
  ALARM_MANAGEMENT: 'Alarm Management',
  INVENTORY_MANAGEMENT: 'Inventory Management',
  DASHBOARDS: 'Dashboards',
  MYDASHBOARDS: 'My Dashboards'
}
