/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import axios from 'axios'
import config from '../config'

// we have to renew the sid for every request because the alarm api is not under the same namespace

export class HomeScreen {

  static getOpenAlarms(params) {
    let csId = params.csId, groupIds = params.groupIds, probeId = params.probeId;

    let url = [config.basename, 'api/dashboard/component/openAlarmsInformation'].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/openAlarms");
    } else {
      if (csId == null && groupIds == null && probeId == null) {
        return axios.get(url);
      } else {
        if (csId != null) {
          url = url.concat("?csId=" + csId)
        }
        if (groupIds != null) {
          url = url.includes("?") ? url.concat("&groupId=" + groupIds) : url.concat("?groupId=" + groupIds)
        }
        if (probeId != null) {
          url = url.includes("?") ? url.concat("&probeId=" + probeId) : url.concat("?probeId=" + probeId)
        }
        return axios.get(url);
      }

    }
  }

  static getTopDevicesByAlarms(params) {
    let groupIds = params.groupIds, probeId = params.probeId;

    let url = [config.basename, 'api/dashboard/component/topDevicesByAlarmInformation'].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/topDevices");
    } else {
      if (groupIds == null && probeId == null) {
        return axios.get(url);
      } else {

        if (groupIds != null) {
          url = url.concat("?groupId=" + groupIds)
        }
        if (probeId != null) {
          url = url.includes("?") ? url.concat("&probeId=" + probeId) : url.concat("?probeId=" + probeId)
        }
        return axios.get(url);
      }
    }
    // return axios.get([config.basename, 'api/topDevices'].join('/'));
  }

  static getTopGroupsByAlarms(params) {
    let csId = params.csId;

    let url = [config.basename, 'api/dashboard/component/topGroupsByAlarmInformation'].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/topGroups");
    } else {
      if (csId == null) {
        return axios.get(url);
      } else {

        if (csId != null) {
          url = url.concat("?csId=" + csId)
        }

        return axios.get(url);
      }
    }
    //  return axios.get([config.basename, 'api/topGroups'].join('/'));
  }

  static getActiveDevices() {
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/activeDevices");
    } else {
      return axios.get([config.basename, 'api/dashboard/component/activeDevicesInformation'].join('/'));
    }
    //return axios.get([config.basename, 'api/activeDevices'].join('/'));
  }

  static getMonitoredTechnologies(params) {
    let csId = params.csId, groupIds = params.groupIds;

    let url = [config.basename, 'api/dashboard/component/fetchMonitoredTechnologiesInformation'].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/technologies");
    } else {
      if (csId == null && groupIds == null) {
        return axios.get(url);
      } else {
        if (csId != null) {
          url = url.concat("?csId=" + csId)
        }
        if (groupIds != null) {
          url = url.includes("?") ? url.concat("&groupId=" + groupIds) : url.concat("?groupId=" + groupIds)
        }

        return axios.get(url);
      }
    }
    // return axios.get([config.basename, 'api/technologies'].join('/'));
  }

  static getDeviceByRole(params) {
    let groupIds = params.groupIds, probeId = params.probeId;

    let url = [config.basename, 'api/dashboard/component/deviceByRoleInformation'].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/roleDevices");
    } else {
      if (groupIds == null && probeId == null) {
        return axios.get(url);
      } else {

        if (groupIds != null) {
          url = url.concat("?groupId=" + groupIds)
        }
        if (probeId != null) {
          url = url.includes("?") ? url.concat("&probeId=" + probeId) : url.concat("?probeId=" + probeId)
        }
        return axios.get(url);
      }
    }
    //return axios.get([config.basename, 'api/roleDevices'].join('/'));
  }
}
