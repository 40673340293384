/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedHexagon = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
        <path d="M25.8592 30.1966L25.8799 30.1843L25.9009 30.1725L31.8422 26.8371L31.7601 20.0241L31.7599 20L31.7601 19.9759L31.8422 13.1629L25.9009 9.82747L25.8799 9.81567L25.8592 9.80337L20 6.32575L14.1408 9.80337L14.1201 9.81567L14.0991 9.82747L8.15775 13.1629L8.23986 19.9759L8.24015 20L8.23986 20.0241L8.15775 26.8371L14.0991 30.1725L14.1201 30.1843L14.1408 30.1966L20 33.6742L25.8592 30.1966Z" fill="#F0F5FC" stroke="#1D5BBF" stroke-width="4"/>
    </SvgIcon>
  )
);
