/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { useState, useCallback, useEffect } from 'react'
import QosChart from './QosChart'
import { useDispatch, useSelector } from 'react-redux';
import {
	updateChartListOrder,
} from "../../../../../../../api/performanceReports/actions";
import CustomSnackbar from '../../../../../../common/CustomSnackbar';

export function swapArrayLocs(arr, index1, index2) {
    var temp = arr[index1];
  
    arr[index1] = arr[index2];
    arr[index2] = temp;
}

const QosChartContainer =(props)=>{
    const [openedSnackbar, setOpenedSnackbar] = useState(false);
    const dispatch = useDispatch();
    const source = useSelector((state) =>state.performanceReports.selectedSource=='host'?state.performanceReports.byHost.host:undefined)
    const qosValue = useSelector((state) =>state.performanceReports.selectedSource=='host'?state.performanceReports.byHost.qos:state.performanceReports.byTarget.qos)
    const target = useSelector((state) =>state.performanceReports.selectedSource=='target'?state.performanceReports.byTarget.target:undefined)
    const [orderCharList, setOrderChartList] = useState([]);
    const handdleSnackbar = (status) => {
        setOpenedSnackbar(status);
    }

    function preparedChartList(newChartList) {
        const newOrderCharList = [];
        // orderCharList.forEach((idChart) => {
        //     if (newChartList.find(chart => idChart === chart.chartId)) {
        //         newOrderCharList.push(idChart);
        //     }
        // });
        newChartList.reverse().forEach(chart => {
            if (!newOrderCharList.includes(chart.chartId)) {
                newOrderCharList.unshift(chart.chartId);
            }
        })
        setOrderChartList(newOrderCharList);
    }

    function getCharts() {
        const preparedChartList = [];
        orderCharList.forEach(id => {
            const chart = props.chartList.find(newChart => newChart.chartId === id);
            if (chart) {
                preparedChartList.push(chart);
            }
        })
        return preparedChartList;
    }

    const moveChart = useCallback((dragIndex, hoverIndex) => {
        const prevOrderList = [...orderCharList]
        swapArrayLocs(prevOrderList, dragIndex, hoverIndex);
        setOrderChartList(prevOrderList);
        dispatch(updateChartListOrder(prevOrderList));
    }, [orderCharList]);

    useEffect(() => {
        // setChartList(props.chartList)
        preparedChartList(props.chartList)
    }, [props.chartList])

    return(
        <>
        <div className="chart-container">
        {
            getCharts().map((chart, index)=>
            <QosChart 
            key={`qos-chart-key-${chart.chartId}`} 
            chartData={chart} 
            data={{source,qosValue,target}}
            onQosChartDelete={()=>{props.onQosChartDelete(chart.chartId)}}
            moveChart={moveChart}
            index={index}
            chartList={props.chartList}
            changeLoading= {props.changeLoading}
            handdleSnackbar={handdleSnackbar}
            />
            )}
            
            </div>
            <CustomSnackbar
                    open={openedSnackbar}
                    autoHideDuration={5000}
                    close={() => setOpenedSnackbar(false)}
                    message="Chart series was successfully deleted."
                    severity="success"
            />
            </>
            )
        }
        
        export default QosChartContainer;