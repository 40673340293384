/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React from "react";

const Icon = (props) => {
  let classes = ["icon", props.className].join(" ");
  return (
    <svg
      className={classes}
      role={props.role || "figure"}
      aria-label={props.title}
      width="24px"
      height="16px"
      viewBox="0 0 24 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <g>
        <polygon points="0 3.81750011 6.54428591 3.81750011 6.54428591 0 0 0"></polygon>
        <polygon points="8.72571455 3.81750011 15.2700005 3.81750011 15.2700005 0 8.72571455 0"></polygon>
        <polygon points="17.4514291 3.81750011 23.995715 3.81750011 23.995715 0 17.4514291 0"></polygon>
        <polygon points="0 9.54375029 6.54428591 9.54375029 6.54428591 5.72625017 0 5.72625017"></polygon>
        <polygon points="8.72571455 9.54375029 15.2700005 9.54375029 15.2700005 5.72625017 8.72571455 5.72625017"></polygon>
        <polygon points="17.4514291 9.54375029 23.995715 9.54375029 23.995715 5.72625017 17.4514291 5.72625017"></polygon>
        <polygon points="0 15.2700005 6.54428591 15.2700005 6.54428591 11.4525003 0 11.4525003"></polygon>
        <polygon points="8.72571455 15.2700005 15.2700005 15.2700005 15.2700005 11.4525003 8.72571455 11.4525003"></polygon>
        <polygon points="17.4514291 15.2700005 23.995715 15.2700005 23.995715 11.4525003 17.4514291 11.4525003"></polygon>
      </g>
    </svg>
  );
};

export default Icon;
