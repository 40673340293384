/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //File Details:
  content["File Details:"] =
  "ファイルの詳細:";

  //File Name
  content["File Name:"] =
  "ファイル名:";

  //File Type:
  content["File Type:"] =
  "ファイル タイプ:";

  //File upload text
  content["Select file before Pressing the Upload button"] =
  "ファイルを選択してから [アップロード] ボタンを押してください";

  //Cancel
  content["Cancel"] =
  "キャンセル";

  //Upload
  content["Upload"] =
  "アップロード";

  //device import
  content["Device Import"] =
  "デバイスのインポート";

  //Inventory
  content["Inventory"] =
  "インベントリ";

  //Refreshed
  content["Refreshed"] =
  "更新時間";

  //Results
  content["Results"] =
  "結果数";

  //Import
  content["Import"] =
  "インポート";

  //Discovery Wizard
  content["Discovery Wizard"] =
  "ディスカバリ ウィザード";

  //Robot Deployment
  content["Robot Deployment"] =
  "ロボット展開";

  // Search computer systems
  content["Search computer systems"] =
  "コンピュータ システムの検索";

  // END OF LOCALIZATION

export default content;
