/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React from "react";
// import PropTypes from 'prop-types'

const DEFAULT_ICON_SIZE = "20px";

const LEVEL_CLEAR = 0;
const LEVEL_INFO = 1;
const LEVEL_WARNING = 2;
const LEVEL_MINOR = 3;
const LEVEL_MAJOR = 4;
const LEVEL_CRITICAL = 5;

//#0000ff

const Critical = props => (
  <svg 
    width="24" 
    height="24" 
    style={props.style}
    className={props.classes}
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_210_1512)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.70769 21C3.41442 21 3.12611 20.9251 2.87052 20.7825C2.04869 20.3241 1.75728 19.292 2.21963 18.4772L10.5121 3.86309C10.6654 3.59296 10.8905 3.36973 11.163 3.21776C11.9848 2.75935 13.0259 3.04827 13.4882 3.86309L21.7807 18.4772C21.9245 18.7306 22 19.0164 22 19.3072C22 20.2421 21.2356 21 20.2926 21H3.70769ZM12.0372 16.2919C12.6986 16.2919 13.2347 16.8235 13.2347 17.4792C13.2347 18.135 12.6986 18.6666 12.0372 18.6666C11.3758 18.6666 10.8396 18.135 10.8396 17.4792C10.8396 16.8235 11.3758 16.2919 12.0372 16.2919ZM13.1102 7.74941V14.0825H10.9546V7.74941H13.1102Z"
        fill="#DE1B1B"
      />
    </g>
  </svg>
)

const Major = (props) => (
  <svg
    width="24"
    height="24"
    style={props.style}
    className={props.classes}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_210_1518)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6168 21.4351L21.435 13.6169C22.3165 12.7354 22.3165 11.3062 21.435 10.4247L13.6168 2.60657C12.7353 1.72506 11.3061 1.72506 10.4246 2.60657L2.60645 10.4247C1.72493 11.3062 1.72493 12.7354 2.60645 13.6169L10.4246 21.4351C11.3061 22.3166 12.7353 22.3166 13.6168 21.4351Z"
        fill="#F78F4A"
      />
      <path
        d="M12 15.4932C12.6904 15.4932 13.25 16.0528 13.25 16.7432C13.25 17.4335 12.6904 17.9932 12 17.9932C11.3096 17.9932 10.75 17.4335 10.75 16.7432C10.75 16.0528 11.3096 15.4932 12 15.4932ZM13.12 6.5V13.1673H10.87V6.5H13.12Z"
        fill="#333840"
      />
    </g>
  </svg>
);

const Minor = (props) => (
  <svg
    width="24"
    height="24"
    style={props.style}
    className={props.classes}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_210_1524)">
      <path
        d="M10.9608 2.2765C11.6038 1.90783 12.3962 1.90783 13.0392 2.2765L19.9608 6.24458C20.6038 6.61325 21 7.29458 21 8.03193V15.9681C21 16.7054 20.6038 17.3868 19.9608 17.7554L13.0392 21.7235C12.3962 22.0922 11.6038 22.0922 10.9608 21.7235L4.03923 17.7554C3.39615 17.3868 3 16.7054 3 15.9681V8.03193C3 7.29458 3.39615 6.61325 4.03923 6.24458L10.9608 2.2765Z"
        fill="#F7CD3B"
      />
      <path
        d="M12 15.4932C12.6904 15.4932 13.25 16.0528 13.25 16.7432C13.25 17.4335 12.6904 17.9932 12 17.9932C11.3096 17.9932 10.75 17.4335 10.75 16.7432C10.75 16.0528 11.3096 15.4932 12 15.4932ZM13.12 6.5V13.1673H10.87V6.5H13.12Z"
        fill="#333840"
      />
    </g>
  </svg>
);

const Warning = (props) => (
  <svg
    width="24"
    height="24"
    style={props.style}
    className={props.classes}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.2681 8.66812C21.2704 8.40291 21.1673 8.14765 20.9814 7.95848L16.1975 3.09038C16.0116 2.90121 15.7582 2.79364 15.493 2.79132L8.668 2.73176C8.40279 2.72945 8.14753 2.83258 7.95836 3.01848L3.09026 7.80235C2.90109 7.98824 2.79352 8.24167 2.7912 8.50687L2.73164 15.3319C2.72933 15.5971 2.83246 15.8523 3.01835 16.0415L7.80223 20.9096C7.98812 21.0988 8.24155 21.2063 8.50675 21.2087L15.3317 21.2682C15.5969 21.2705 15.8522 21.1674 16.0414 20.9815L20.9095 16.1976C21.0986 16.0117 21.2062 15.7583 21.2085 15.4931L21.2681 8.66812Z"
      fill="#80BED9"
    />
    <path
      d="M12 15.4932C12.6904 15.4932 13.25 16.0528 13.25 16.7432C13.25 17.4335 12.6904 17.9932 12 17.9932C11.3096 17.9932 10.75 17.4335 10.75 16.7432C10.75 16.0528 11.3096 15.4932 12 15.4932ZM13.12 6.5V13.1673H10.87V6.5H13.12Z"
      fill="#333840"
    />
  </svg>
);

const Info = (props) => (
  <svg
    width="24"
    height="24"
    style={props.style}
    className={props.classes}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_210_1545)">
      <path
        d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 4C7.58457 4 4 7.58457 4 12C4 16.4154 7.58457 20 12 20C16.4154 20 20 16.4154 20 12C20 7.58457 16.4154 4 12 4ZM13.12 10.5V18.17H10.87V10.5H13.12ZM12 5.49317C12.8284 5.49317 13.5 6.16474 13.5 6.99317C13.5 7.82159 12.8284 8.49317 12 8.49317C11.1716 8.49317 10.5 7.82159 10.5 6.99317C10.5 6.16474 11.1716 5.49317 12 5.49317Z"
        fill="#3272D9"
      />
    </g>
  </svg>
);

const Clear = (props) => (
  <svg
    width="24"
    height="24"
    style={props.style}
    className={props.classes}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12ZM10.3058 16.5683L10.2768 16.5393L10.2716 16.5444L5.62113 11.894C5.23648 11.5093 5.23648 10.8856 5.62113 10.501C6.00579 10.1163 6.62944 10.1163 7.0141 10.501L10.3006 13.7875L16.2049 7.88322C16.5896 7.49856 17.2132 7.49856 17.5979 7.88322C17.9825 8.26788 17.9825 8.89153 17.5979 9.27619L10.3058 16.5683Z"
      fill="#2A854E"
    />
  </svg>
);

const SeverityIcon = (props) => {
  let view;
  let style = {};
  if (props.size) {
    style.width = props.size;
    style.height = props.size;
  } else {
    style.width = DEFAULT_ICON_SIZE;
    style.height = DEFAULT_ICON_SIZE;
  }
  switch (props.level) {
    case LEVEL_CRITICAL:
    case "CRITICAL":
      view = <Critical classes={props.class} style={style} />;
      break;
    case LEVEL_MAJOR:
    case "MAJOR":
      view = <Major classes={props.class} style={style} />;
      break;
    case LEVEL_MINOR:
    case "MINOR":
      view = <Minor classes={props.class} style={style} />;
      break;
    case LEVEL_WARNING:
    case "WARNING":
      view = <Warning classes={props.class} style={style} />;
      break;
    case LEVEL_INFO:
    case "INFO":
      view = <Info classes={props.class} style={style} />;
      break;
    case LEVEL_CLEAR:
    case "CLEAR":
    default:
      view = <Clear classes={props.class} style={style} />;
      break;
  }
  return view;
};

export default SeverityIcon;

export {
  LEVEL_CRITICAL,
  LEVEL_MAJOR,
  LEVEL_MINOR,
  LEVEL_WARNING,
  LEVEL_INFO,
  LEVEL_CLEAR,
};
