/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React from 'react'

const DatePickerIcon = ({backgroundColor}) => {
    return (
        <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z" 
                fill={backgroundColor ? "backgroundColor" : "#EBEFF5"}
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M4.66602 10V17.3333C4.66602 18.0697 5.26297 18.6667 5.99935 18.6667H17.9993C18.7357 18.6667 19.3327 18.0697 19.3327 17.3333V10H4.66602ZM16.3327 16.6667V14.6667H14.3327V16.6667H16.3327ZM12.9993 16.6667V14.6667H10.9993V16.6667H12.9993ZM9.66602 16.6667V14.6667H7.66602V16.6667H9.66602ZM16.3327 13.3333V11.3333H14.3327V13.3333H16.3327ZM12.9993 13.3333V11.3333H10.9993V13.3333H12.9993ZM9.66602 11.3333H7.66602V13.3333H9.66602V11.3333Z" 
                fill="#58606E"
            />
            <path 
                opacity="0.8" 
                d="M9.99935 6.66634V5.33301H7.99935V6.66634H5.99935C5.26297 6.66634 4.66602 7.26329 4.66602 7.99967V8.66634H19.3327V7.99967L19.329 7.90017C19.2781 7.21026 18.7023 6.66634 17.9993 6.66634H15.9993V5.33301H13.9993V6.66634H9.99935Z" 
                fill="#58606E"
            />
        </svg>
        </>
    )
}

export default DatePickerIcon
