/*
 * Copyright © 2024 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  REQUEST_GROUP,
  REQUEST_GROUPS,
  REQUEST_ADD_GROUP,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUP_FAILURE,
  FETCH_GROUPS_FAILURE,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_SUCCESS,
  RENAME_GROUP_FAILURE,
  ADD_TO_GROUP_FAILURE,
  ADD_TO_GROUP_SUCCESS,
  ADD_INTERFACES_TO_GROUP_FAILURE,
  ADD_INTERFACES_TO_GROUP_SUCCESS,
  SWITCH_TREE_VIEW_NAV,
  DISABLE_TREE_VIEW_NAV,
  GROUP_VIEW
} from './actionTypes'
import { isEqual } from '../../utils/lodash-utils'
import entityTypes from './../entityTypes'

let updatedState = {}

const groups = (
  state = {
    isFetching: false,
    isSaving: false,
    didInvalidate: false,
    groupDeleteFailed: false,
    addToGroupFailed: false,
    items: [],
    currentTreeViewNav:undefined,

    disabledTreeViewNavs:[],

  },
  action,
  ) => {
    switch (action.type) {
      case REQUEST_GROUP:
      case REQUEST_GROUPS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      })
      case SWITCH_TREE_VIEW_NAV:
      return {
        ...state,
        currentTreeViewNav:action.currentTreeViewNav
      }
      case DISABLE_TREE_VIEW_NAV:
        return {
          ...state,
          disabledTreeViewNavs:action.disabledTreeViewNavs
        }

      case REQUEST_ADD_GROUP:
      return Object.assign({}, state, {
        isSaving: true,
        didInvalidate: false,
      })
      case FETCH_GROUP_SUCCESS:
      const item = Object.assign({}, action.data, {
        entityType: entityTypes.GROUP,
      })
      item.groups = item.groups.map(group => {
        return Object.assign({}, group, {
          entityType: entityTypes.GROUP,
        })
      })
      item['computer-systems'] = item['computer-systems'].map(cs => {
        return Object.assign({}, cs, {
          entityType: entityTypes.COMPUTER_SYSTEM,
        })
      })
      updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        item: item,
      })
      return isEqual(updatedState, state) ? state : updatedState
      case FETCH_GROUPS_SUCCESS:
      const items = action.data._items
      updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: items,
      })
      return isEqual(updatedState, state) ? state : updatedState
      case FETCH_GROUP_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        item: [],
      })
      case FETCH_GROUPS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        error: action.error,
        items: [],
      })
      case ADD_GROUP_FAILURE:
      return Object.assign({}, state, {
        isSaving: false,
        errorMessage: action.errorMessage,
      })
      case DELETE_GROUP_FAILURE:
      return Object.assign({}, state, {
        isSaving: false,
        errorMessage: action.errorMessage,
        item: [],
      })
      case RENAME_GROUP_FAILURE:
      return Object.assign({}, state, {
        isSaving: false,
        errorMessage: action.errorMessage,
        groupRenameFailed: true,
      })
      case ADD_GROUP_SUCCESS:
      updatedState = Object.assign({}, state, {
        isSaving: false,
        didInvalidate: false,
      })
      return updatedState
      case DELETE_GROUP_SUCCESS:
      updatedState = Object.assign({}, state, {
        isSaving: false,
        didInvalidate: false,
      })
      return updatedState
      case ADD_TO_GROUP_FAILURE:
      return Object.assign({}, state, {
        isSaving: false,
        errorMessage: action.errorMessage,
        addToGroupFailed: true,
        item: [],
      })
      case ADD_TO_GROUP_SUCCESS:
      return Object.assign({}, state, {
        isSaving: false,
        didInvalidate: false,
      })
      case ADD_INTERFACES_TO_GROUP_FAILURE:
      return Object.assign({}, state, {
        isSaving: false,
        errorMessage: action.errorMessage,
        addToGroupFailed: true,
        item: [],
      })
      case ADD_INTERFACES_TO_GROUP_SUCCESS:
      return Object.assign({}, state, {
        isSaving: false,
        didInvalidate: false,
      })

      case GROUP_VIEW:
        const payload = action.data;
        updatedState = Object.assign({}, state, {
          currentView: payload?.view
        })
        return isEqual(updatedState, state) ? state : updatedState

      default:
      return state
    }
  }

  export default groups
