// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Critical
  content["Critical"] =
  "Critical";

  //Major
  content["Major"] =
  "Major";

  //Minor
  content["Minor"] =
  "Minor";

  //Warning
  content["Warning"] =
  "Warning";

  //Info
  content["Info"] =
  "Info";

  //Severity
  content["Severity"] =
  "Severity";

  //Clear
  content["Clear"] =
  "Clear";

  //LEVEL CLEAR
  content["LEVEL CLEAR"] =
  "LEVEL CLEAR";

  // END OF LOCALIZATION

export default content;
