// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Page Not Found
  content["Page Not Found"] =
  "Page Not Found";

  //The requested pag ecannot be found
  content["The requested page cannot be found"] =
  "The requested page cannot be found";

  //Return to Operator Console
  content["Return to Operator Console"] =
  "Return to Operator Console";

  // END OF LOCALIZATION

export default content;
