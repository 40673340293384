/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
export const FETCH_MAINTENANCE = 'FETCH_MAINTENANCE'
export const FETCH_MAINTENANCE_SUCCESS = 'FETCH_MAINTENANCE_SUCCESS'
export const FETCH_MAINTENANCE_FAILURE = 'FETCH_MAINTENANCE_SUCCESS'
export const POLL_MAINTENANCE_START = 'POLL_MAINTENANCE_START'
export const POLL_MAINTENANCE_STOP = 'POLL_MAINTENANCE_STOP'
export const FETCH_SCHEDULE_DEVICES='FETCH_SCHEDULE_DEVICES'
export const FETCH_SCHEDULE_DEVICES_SUCCESS='FETCH_SCHEDULE_DEVICES_SUCCESS'
export const FETCH_SCHEDULE_DEVICES_FAILURE='FETCH_SCHEDULE_DEVICES_FAILURE'
export const FETCH_GROUP_DEVICES='FETCH_GROUP_DEVICES'
export const FETCH_GROUP_DEVICES_SUCCESS='FETCH_GROUP_DEVICES_SUCCESS'
export const FETCH_GROUP_DEVICES_FAILURE='FETCH_GROUP_DEVICES_FAILURE'
export const FETCH_TIMEZONES='FETCH_TIMEZONES'
export const FETCH_TIMEZONES_SUCCESS='FETCH_TIMEZONES_SUCCESS'
export const FETCH_TIMEZONES_FAILURE='FETCH_TIMEZONES_FAILURE'
export const FETCH_CS_MAINTENANCE_LIST = 'FETCH_CS_MAINTENANCE_LIST'
export const FETCH_CS_MAINTENANCE_LIST_SUCCESS = 'FETCH_CS_MAINTENANCE_LIST_SUCCESS'
export const FETCH_CS_MAINTENANCE_LIST_FAILURE = 'FETCH_CS_MAINTENANCE_LIST_FAILURE'
export const REMOVE_CS_MAINTENANCE_LIST = 'REMOVE_CS_MAINTENANCE_LIST'
export const REMOVE_CS_MAINTENANCE_LIST_SUCCESS = 'REMOVE_CS_MAINTENANCE_LIST_SUCCESS'
export const REMOVE_CS_MAINTENANCE_LIST_FAILURE = 'REMOVE_CS_MAINTENANCE_LIST_FAILURE'
