/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
export const REQUEST_CONTAINER = "REQUEST_CONTAINER";
export const REQUEST_CONTAINERS = "REQUEST_CONTAINERS";
export const FETCH_CONTAINERS = "FETCH_CONTAINERS";
export const FETCH_CONTAINER_SUCCESS = "FETCH_CONTAINER_SUCCESS";
export const FETCH_CONTAINERS_SUCCESS = "FETCH_CONTAINERS_SUCCESS";
export const FETCH_CONTAINER_FAILURE = "FETCH_CONTAINER_FAILURE";
export const FETCH_CONTAINERS_FAILURE = "FETCH_CONTAINERS_FAILURE";
export const DELETE_DEVICES = "DELETE_DEVICES";
export const DELETE_DEVICES_SUCCESS = "DELETE_DEVICES_SUCCESS";
export const DELETE_DEVICES_FAILURE = "DELETE_DEVICES_FAILURE";
export const INVENTORY_TREE_SELECTION = "INVENTORY_TREE_SELECTION";
export const FETCH_INVENTORY_TREE = "FETCH_INVENTORY";
export const FETCH_INVENTORY_TREE_SUCCESS = "FETCH_INVENTORY_SUCCESS";
export const FETCH_INVENTORY_TREE_FAILURE = "FETCH_INVENTORY_FAILURE";
export const DELETE_DEVICES_FROM_INVENTORY = "DELETE_DEVICES_FROM_INVENTORY";
export const DELETE_DEVICES_FROM_INVENTORY_SUCCESS =
  "DELETE_DEVICES_FROM_INVENTORY_SUCCESS";
export const DELETE_DEVICES_FROM_INVENTORY_FAILURE =
  "DELETE_DEVICES_FROM_INVENTORY_FAILURE";

export const FILTERED_INVENTORY_ROWS = "FILTERED_INVENTORY_ROWS";

export const SET_CONTAINERS_TOTAL_ITEMS =
  "SET_CONTAINERS_TOTAL_ITEMS";
export const SET_CONTAINERS_SORT = "SET_CONTAINERS_SORT";
export const SET_CONTAINERS_FILTER = "SET_CONTAINERS_FILTER";
export const SET_CONTAINERS_COLUMN_FILTER =
  "SET_CONTAINERS_COLUMN_FILTER";
export const SET_CONTAINERS_QUICK_FILTER =
  "SET_CONTAINERS_QUICK_FILTER";
  export const SET_CONTAINERS_SHOW_SUMMARY =
  "SET_CONTAINERS_SHOW_SUMMARY";
export const SET_CONTAINERS_REFRESH_GRID_DATA =
  "SET_CONTAINERS_REFRESH_GRID_DATA";
export const SET_CONTAINERS_TREE = "SET_CONTAINERS_TREE";

export const FETCH_LIST = "FETCH_LIST";
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_FAILURE = "FETCH_LIST_FAILURE";
export const SET_CONTAINERS_TYPE = "SET_CONTAINERS_TYPE";
export const SET_QUICK_FILTER = "SET_QUICK_FILTER";


export const SET_SELECTED_CONTAINER_IDS="SET_SELECTED_CONTAINER_IDS";
export const RESET_CONTAINER_ENTITIES = "RESET_CONTAINER_ENTITIES";
export const RESET_CONTAINER = "RESET_CONTAINER";
