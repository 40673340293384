/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import Devices from "./panels/Devices";
import DevicesByRole from "./panels/DevicesByRole";
import TotalAlarms from "./panels/TotalAlarms";
import TotalAlarmsByGroup from "./panels/TotalAlarmsByGroup";
import TotalAlarmsByDevice from "./panels/TotalAlarmsByDevice";
import MonitoredTechnologies from "./panels/MonitoredTechnologies";
import "./overview.less";
import "./responsiveOverview.less";
import { isEqual } from "../../utils/lodash-utils";
import NoViewPanel from "./NoViewPanel";
import Resource from "./Resource";
import { ClearIcon as Clear } from "../ui-components/uim-components";
import connect from "./../../utils/connect";
import { setBreadCrumbs } from "./../breadcrumbs/actions";

import { Tooltip as MTooltip, IconButton } from "@mineral/core";

import { homeScreenComponentsChanged } from "./../../api/home-screen/actions";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOpenAlarms: true,
      showActiveDevices: true,
      showDevicesByRoles: true,
      showTopEntitiesContainer: true,
      showTopTechnologies: true,
      showTopGroups: true,
      showTopDevices: true,
    };
  }
  componentWillMount() {
    this.props.setBreadCrumbs();
  }
  componentDidMount() {
    if (!isEqual(this.props.homeScreenComponents, this.state)) {
      this.setState({ ...this.props.homeScreenComponents });
    }
    //document.title = "Infrastructure Manager - Dashboard";
  }
  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.homeScreenComponents, this.state)) {
      this.setState({ ...nextProps.homeScreenComponents });
    }
  }
  componentWillUpdate(prevState, currState) {
    if (!isEqual(this.state, currState)) {
      this.props.onHomeComponentChanged(currState);
    }
  }
  render() {
    let anyMenuSelected =
      this.state.showOpenAlarms ||
      this.state.showActiveDevices ||
      this.state.showDevicesByRoles ||
      this.state.showTopTechnologies ||
      this.state.showTopGroups ||
      this.state.showTopDevices;
    const handleCloseDialog = (propertyName) => {
      if (propertyName == "showTopEntitiesContainer") {
        this.setState({
          showTopEntitiesContainer: false,
          showTopTechnologies: false,
          showTopGroups: false,
          showTopDevices: false,
        });
      } else {
        this.setState({
          [propertyName]: false,
        });
      }
    };
    //Top 10 Entities
    let title = Resource.get("Top 10 Entities");
    //By Severity, By Alarms
    let sevTitle = Resource.get("By Severity, By Alarms");
    let cardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 className="overview_container_title" aria-label={title}>{title}</h2>
          <span
            style={{
              fontSize: "14px",
              alignSelf: "center",
              marginLeft: "10px",
              color: "#58606E",
            }} aria-label={sevTitle}
          >
            {sevTitle}
          </span>
        </div>
        <MTooltip title="Close">
          <IconButton
            style={{ padding: 0 }}
            onClick={() => {
              handleCloseDialog("showTopEntitiesContainer");
            }}
            aria-label="Close Top 10 Entities"
            role="button"
          >
            <Clear className="clearIcon" role="figure"
              title="Close" />
          </IconButton>
        </MTooltip>
      </div>
    );
    return (
      <main className="overview overview__main">
        {anyMenuSelected ? (
          <div>
            {this.state.showOpenAlarms ||
              this.state.showActiveDevices ||
              this.state.showDevicesByRoles ? (
              <div className="first-grid">
                {this.state.showOpenAlarms ? (
                  <div className="alarms-total-resp"
                    style={{
                      gridColumn: !this.state.showActiveDevices &&
                        !this.state.showDevicesByRoles && "1/ span 5"
                    }}>
                    {" "}
                    <TotalAlarms handleCloseDialog={handleCloseDialog} />
                  </div>
                ) : null}
                {this.state.showActiveDevices ||
                  this.state.showDevicesByRoles ? (
                  <div className="devices-resp" style={{ gridColumn: !this.state.showOpenAlarms && "1/ span 5" }}>
                    {this.state.showActiveDevices ? (
                      <Devices
                        handleCloseDialog={handleCloseDialog}
                        className="overview__monitored-tech"
                      />
                    ) : null}
                    {this.state.showDevicesByRoles ? (
                      <DevicesByRole
                        handleCloseDialog={handleCloseDialog}
                        className="overview__devices--by-role"
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null}
            {(this.state.showTopTechnologies ||
              this.state.showTopGroups ||
              this.state.showTopDevices) &&
              this.state.showTopEntitiesContainer ? (
              <div className="card-layout" style={{ marginBottom: 20 }}>
                {cardHeader}
                <div className="second-grid">
                  {this.state.showTopTechnologies ? (
                    <div className="overview-devices-resp"
                      style={{ gridColumn: (!this.state.showTopGroups && !this.state.showTopDevices) ? "1/span 6" : (!this.state.showTopGroups || !this.state.showTopDevices) && "1/span 3" }}>
                      <MonitoredTechnologies
                        handleCloseDialog={handleCloseDialog}
                        className="overview__devices"
                      />
                    </div>
                  ) : null}
                  {this.state.showTopGroups ? (
                    <div className="overview-alarms-resp"
                      style={{ gridColumn: (!this.state.showTopTechnologies && !this.state.showTopDevices) ? "1/span 6" : !this.state.showTopTechnologies ? "1/span 3" : !this.state.showTopDevices && "4/span 3" }}>
                      <TotalAlarmsByGroup
                        handleCloseDialog={handleCloseDialog}
                        className="overview__alarms--by-group "
                      />
                    </div>
                  ) : null}
                  {this.state.showTopDevices ? (
                    <div className="total-alarms-resp"
                      style={{ gridColumn: (!this.state.showTopGroups && !this.state.showTopTechnologies) ? "1/ span 6" : (!this.state.showTopGroups || !this.state.showTopTechnologies) && "4/span 3" }}>
                      <TotalAlarmsByDevice
                        handleCloseDialog={handleCloseDialog}
                        className="overview__alarms--by-device "
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <NoViewPanel />
        )}
      </main>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBreadCrumbs: (crumbs) => dispatch(setBreadCrumbs(crumbs)),
    onHomeComponentChanged: (data) =>
      dispatch(homeScreenComponentsChanged(data)),
  };
};
const mapStateToProps = (state) => {
  return {
    homeScreenComponents: state.homeScreen.homeScreenComponents,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Overview);
