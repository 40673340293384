// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //CRITICAL
  content["CRITICAL"] =
  "CRITICAL";

  //MAJOR
  content["MAJOR"] =
  "MAJOR";

  //MINOR
  content["MINOR"] =
  "MINOR";

  //WARNING
  content["WARNING"] =
  "WARNING";

  // INFO
  content["INFO"] =
  "INFO";

  // NONE
  content["NONE"] =
  "NONE";

  // END OF LOCALIZATION

export default content;
