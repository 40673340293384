/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import RequestTrials from "./RequestTrials";
import "./trialbar.less";
import { getWalkMeVariables } from "./../../../utils/walkMeLoad";
import Resource from "./Resource";
import { loadCommon } from "./../../../utils/common";

const TrialBar = ({
  trialsExpiry,
  extnRequested,
  handleExtendTrials,
  handleBuy,
  featureFlags,
  saas,
}) => {
  if (!saas) {
    return null;
  }
  if (
    document.getElementsByClassName("operator-console__application").length > 0
  ) {
    document
      .getElementsByClassName("operator-console__application")[0]
      .classList.add("trial");
  }
  let walkMeVariables = getWalkMeVariables();
  let trialbarClass = "trialbar";
  let trialBarMessage = "";
  let daysRemaining;
  if (trialsExpiry) {
    daysRemaining = Math.floor(
      (trialsExpiry - Date.now()) / (60 * 60 * 24 * 1000)
    );
    walkMeVariables.daysLeft = daysRemaining;
    const showExpiringClass = daysRemaining < 6;
    if (showExpiringClass) {
      trialbarClass += " trialbar--expiring";
    }
    //Expired
    trialBarMessage =
      daysRemaining > 0
        ? `${daysRemaining} days remaining`
        : Resource.get("Expired");
  }

  return (
    <div className={trialbarClass}>
      <div className="trialbar-label-container">
        <span className="trialbar-trial">
          {
            //Trial
            Resource.get("Trial")
          }
        </span>

        <span>{trialBarMessage}</span>
      </div>
      <div className="trialbar-separator" />
      <div className="trial-bar-status">
        <RequestTrials
          extnRequested={extnRequested}
          daysRemaining={daysRemaining}
          handleExtendTrials={handleExtendTrials}
        />
        {featureFlags.buy ? (
          <span className={"trialbar__button--buy"} onClick={handleBuy}>
            {
              //BUY
              Resource.get("BUY")
            }
          </span>
        ) : null}
      </div>
    </div>
  );
};
const TRIALS = "TRIALS";
class TrialBarContainer extends Component {
  state = {
    extnRequested: false,
    products: [],
    trialsExpiry: null,
    accountType: TRIALS,
    showTrialBar: false,
  };
  componentWillMount() {
    loadCommon().then((ca_cloud) => {
      ca_cloud.getProducts().then((response) => {
        const productList = response.data.products;
        const extnRequested = productList.some(
          (product) => product.extnRequested === true
        );
        const cloudManagement = productList.find(
          (product) => product.name === "CLOUD_MANAGEMENT"
        );
        const uim = productList.find((product) => product.name === "UIM");
        if (!uim.accountType) {
          uim.accountType = TRIALS;
        }
        let buyUrl;
        if (
          cloudManagement.axaTokenRedirectUrl.endsWith("#/") ||
          cloudManagement.axaTokenRedirectUrl.endsWith("#")
        ) {
          buyUrl = cloudManagement.axaTokenRedirectUrl + "/purchase/UIM";
        } else {
          buyUrl = cloudManagement.axaTokenRedirectUrl + "/#/purchase/UIM";
        }
        this.setState({
          extnRequested: extnRequested,
          products: productList,
          trialsExpiry: response.data.trialsExpiryInMillis,
          buyUrl: buyUrl,
          showTrialBar: uim.accountType === TRIALS ? true : false,
        });
      });
    });
  }
  handleExtendTrials = () => {
    loadCommon().then((ca_cloud) => {
      ca_cloud.extendTrials();
    });
    this.setState({ extnRequested: true });
    this.props.handleExtendTrials();
  };
  handleBuy = () => {
    if (this.state.buyUrl) {
      window.location.href = this.state.buyUrl;
    } else {
      console.error("The buy url is not defined correctly");
    }
  };
  render() {
    return this.state.showTrialBar ? (
      <TrialBar
        trialsExpiry={this.state.trialsExpiry}
        extnRequested={this.state.extnRequested}
        handleExtendTrials={this.handleExtendTrials}
        handleBuy={this.handleBuy}
        featureFlags={this.props.featureFlags || {}}
        saas={this.props.saas}
      />
    ) : null;
  }
}
export default TrialBarContainer;
