/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //File Details:
  content["File Details:"] =
  "Detalles del archivo:";

  //File Name
  content["File Name:"] =
  "Nombre del archivo:";

  //File Type:
  content["File Type:"] =
  "Tipo de archivo:";

  //File upload text
  content["Select file before Pressing the Upload button"] =
  "Seleccione el archivo antes de pulsar el botón Cargar.";

  //Cancel
  content["Cancel"] =
  "Cancelar";

  //Upload
  content["Upload"] =
  "Cargar";

  //device import
  content["Device Import"] =
  "Importación de dispositivos";

  //Inventory
  content["Inventory"] =
  "Inventario";

  //Refreshed
  content["Refreshed"] =
  "Actualizado";

  //Results
  content["Results"] =
  "Resultados";

  //Import
  content["Import"] =
  "Importar";

  //Discovery Wizard
  content["Discovery Wizard"] =
  "Asistente para la detección";

  //Robot Deployment
  content["Robot Deployment"] =
  "Implementación del robot";

  // Search computer systems
  content["Search computer systems"] =
  "Buscar sistemas informáticos";

  // END OF LOCALIZATION

export default content;
