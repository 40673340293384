/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedStar05 = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" {...props} ref={ref}>
        <path d="M24.2066 31.0161L24.4898 30.8393L24.815 30.764L29.6465 29.6465L30.764 24.815L30.8393 24.4898L31.0161 24.2066L33.6422 20L31.0161 15.7934L30.8393 15.5102L30.764 15.185L29.6465 10.3535L24.815 9.23598L24.4898 9.16074L24.2066 8.98394L20 6.35776L15.7934 8.98394L15.5102 9.16074L15.185 9.23598L10.3535 10.3535L9.23598 15.185L9.16074 15.5102L8.98394 15.7934L6.35776 20L8.98394 24.2066L9.16074 24.4898L9.23598 24.815L10.3535 29.6465L15.185 30.764L15.5102 30.8393L15.7934 31.0161L20 33.6422L24.2066 31.0161Z" fill={props.color} stroke="#1D5BBF" stroke-width="4"/>

    </SvgIcon>
  )
);
