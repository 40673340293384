/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  SAVE_WMI_CREDENTIALS,
  SAVE_WMI_CREDENTIALS_SUCCESS,
  SAVE_WMI_CREDENTIALS_FAILURE,
  EDIT_WMI_CREDENTIALS,
  EDIT_WMI_CREDENTIALS_SUCCESS,
  EDIT_WMI_CREDENTIALS_FAILURE,
  SAVE_UNIX_CREDENTIALS,
  SAVE_UNIX_CREDENTIALS_SUCCESS,
  SAVE_UNIX_CREDENTIALS_FAILURE,
  EDIT_UNIX_CREDENTIALS,
  EDIT_UNIX_CREDENTIALS_SUCCESS,
  EDIT_UNIX_CREDENTIALS_FAILURE,
  GET_UNIX_CREDENTIALS,
  FETCH_UNIX_CREDENTIALS_SUCCESS,
  FETCH_UNIX_CREDENTIALS_FAILURE,
  DELETE_UNIX_CRED,
  UPDATE_UNIX_CRED,
  ADDED_UNIX_CRED,
} from './actionTypes'
import { isEqual } from '../../../utils/lodash-utils'


const unixCredentials = (
  state = {
    isSaving: false,
    isUpdating:false,
    didInvalidate: false,
    isFetching: false,
    items:[],
    unixItems:[],
  },
  action,
) => {
  switch (action.type) {
      case GET_UNIX_CREDENTIALS:
          return Object.assign({}, state, {
        	    isFetching: true,
          	    didInvalidate: false,
         })
      case FETCH_UNIX_CREDENTIALS_SUCCESS:
          const itemsUnix = action.unixCredentials
          const updatedUnixState = Object.assign({}, state, {
            isFetching: false,
            didInvalidate: false,
            unixItems: itemsUnix
          });
          return isEqual(updatedUnixState, state) ? state : updatedUnixState
        case FETCH_UNIX_CREDENTIALS_FAILURE:
          return Object.assign({}, state, {
            isFetching: false,
            didInvalidate: true,
            errorMessage: action.errorMessage,
            unixItems: []
          })
    case SAVE_WMI_CREDENTIALS:
	       return Object.assign({}, state, {
	    	isSaving: true,
	        didInvalidate: false,
	      })
    case SAVE_WMI_CREDENTIALS_SUCCESS:
	      return Object.assign({}, state, {
	        isSaving: false,
	        didInvalidate: false,
	      })
    case SAVE_WMI_CREDENTIALS_FAILURE:
    	 return Object.assign({}, state, {
    	        isSaving: false,
    	        didInvalidate: true,
    	  })
    case EDIT_WMI_CREDENTIALS:
	       return Object.assign({}, state, {
	    	 isUpdating: true,
	        didInvalidate: false,
	      })
    case EDIT_WMI_CREDENTIALS_SUCCESS:
	      return Object.assign({}, state, {
	    	  isUpdating: false,
	        didInvalidate: false,
	      })
    case EDIT_WMI_CREDENTIALS_FAILURE:
	 	 return Object.assign({}, state, {
	 		isUpdating: false,
	 	        didInvalidate: true,
	 	  })
    case SAVE_UNIX_CREDENTIALS:
	       return Object.assign({}, state, {
	    	isSaving: true,
	        didInvalidate: false,
	      })
 case SAVE_UNIX_CREDENTIALS_SUCCESS:
	      return Object.assign({}, state, {
	        isSaving: false,
	        didInvalidate: false,
	      })
 case SAVE_UNIX_CREDENTIALS_FAILURE:
 	 return Object.assign({}, state, {
 	        isSaving: false,
 	        didInvalidate: true,
 	  })
 case EDIT_UNIX_CREDENTIALS:
	       return Object.assign({}, state, {
	    	 isUpdating: true,
	        didInvalidate: false,
	      })
 case EDIT_UNIX_CREDENTIALS_SUCCESS:
	      return Object.assign({}, state, {
	    	  isUpdating: false,
	        didInvalidate: false,
	      })
 case EDIT_UNIX_CREDENTIALS_FAILURE:
	 	 return Object.assign({}, state, {
	 		isUpdating: false,
	 	        didInvalidate: true,
	 	  })
case DELETE_UNIX_CRED:
    	const deletedCred = action.deletedCredential
    	let filteredList=[];
            filteredList=state.unixItems.filter(item => {
                return item.id !== deletedCred
            })
        const updatedItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          unixItems: filteredList
        })
        return isEqual(updatedItem, state) ? state : updatedItem
    case UPDATE_UNIX_CRED:
	const updatedCred = action.updatedCredential
    	let updatedCatalog = state.unixItems
    	for (let i = 0; i < updatedCatalog.length ; i++){
            if(updatedCred.id === updatedCatalog[i].id){
                updatedCatalog[i]=updatedCred;
                break;
            }
        }
        const updatedCatalogItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          unixItems: updatedCatalog
        })
        return isEqual(updatedCatalogItem, state) ? state : updatedCatalogItem
    case ADDED_UNIX_CRED:
	const addedCred = action.addedCredential
    	let addedCatalog = state.unixItems
    	let isNewCredential=true
        for (let i = 0; i < addedCatalog.length ; i++){
            if(addedCred.id === addedCatalog[i].id){
               	isNewCredential=false
				break;
            }
        }
        if(isNewCredential){
        	addedCatalog.push(addedCred);
		}
        const addCatalogItem =  Object.assign({}, state, {
          isFetching: false,
          didInvalidate: false,
          unixItems: addedCatalog
        })
        return isEqual(addCatalogItem, state) ? state : addCatalogItem
    default:
      return state
  }
}

export default unixCredentials
