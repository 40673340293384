/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import {
  ClearIcon as Clear,
  CenterError as Error,
  CenterInfo,
} from "../../ui-components/uim-components";
import { sortColumns } from "./../../../utils/tableUtils";
import Resource from "./Resource";
import connect from "./../../../utils/connect";
import {
  fetchTopTechnologiespoll,
  pollTopTechnologiesStop,
} from "./../../../api/home-screen/actions";

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Tooltip as MTooltip, IconButton, LinearProgress, Button, CircularProgress
} from "@mineral/core";
const chartColors = ["#D9E9EC", "#9EC6D0", "#FEE276", "#F7922F", "#A11D2E"];
const Panel = (props) => {
  const handleDrillDown = (rowNumber, colId) => {
    props.history.push(
      `/technologies/${props.data[rowNumber].probeName}/dashboard`
    );
  };
  const getImageForProbe = (probeName) => {
    try {
      return probeName.endsWith("(RESTMon)")
        ? require("./../../../assets/images/dashboard_restmon.png").default
        : require("./../../../assets/images/img_" + probeName + "-colorBkg.png")
            .default;
    } catch (e) {
      return require("./../../../assets/images/dashboard_generic_monitor.png")
        .default;
    }
  };
  const normalise = (value, min, max) => ((value - min) * 100) / (max - min);
  return (
    <div className="technologies_grid">
      <TableContainer
        style={{
          maxHeight: `${
            window.innerHeight < 800
              ? "25vh"
              : window.innerHeight == 800
              ? "29vh"
              : window.innerHeight < 1080
              ? "31vh"
              : "34vh"
          }`,
        }}
      >
        <Table aria-label="Listing top 10 technologies by severity and by alarms" style={{border:'none'}}>
          <TableBody stripedRows={false} displayRowCheckbox={false}>
            {props.data.map((row, index) => (
              <TableRow
                key={index}
                className="technologies-row"
                onClick={() => {
                  handleDrillDown(index, row.probeName);
                }}
                role="row"

              >
                <TableCell className="technologies_grid_col_img">
                  <img
                    width="95%"
                    height="60%"
                    className="home-page-tech-img"
                    src={getImageForProbe(row.probeName)}
                    alt={row.probeName}
                  />
                </TableCell>
                <TableCell colSpan="2" className="technologies_grid_col_name">
                  {/* <CustomTooltip message={row.probeName}> */}

                  <Button variant="text" style={{padding:0,display:'flex',justifyContent: 'flex-start',width: '100%',color:'#0361D9',
}} ><span style={{
    textOverflow: 'ellipsis',overflow:'hidden', whiteSpace: 'nowrap'}}>
                  {row.probeName}</span></Button>
                  {/* </CustomTooltip> */}
                </TableCell>
                <TableCell
                  colSpan="2"
                  className="technologies_grid_col_progress"
                >
                  {/* <CustomTooltip message={"Alarm Count"}> */}
                  <LinearProgress
                    variant="determinate"
                    color="primary"
                    aria-label={"Alarm count linear progress"}
                    value={normalise(row.alarmCount, 0, props.maxCount)}
                    classes={{
                      barColorPrimary: `${"chart-sev" + row.alarmLevel}`,
                    }}
                    className="technologies-progress-bar"
                  />
                  {/* </CustomTooltip> */}
                </TableCell>

                <TableCell className="technologies_grid_col_alarm">
                  {row.alarmCount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

class PanelContainer extends Component {
  state = {
    status: "loading",
    probes: [],
  };
  userExists = (probes, probeName) => {
    return probes.filter((probe) => {
      return probe.prid === probeName;
    });
  };

  apiError = () => {
    this.setState({
      status: "failure",
    });
  };
  componentWillUnmount() {
    this.props.pollTopTechnologiesStop();
  }

  componentDidMount() {
    this.props.fetchTopTechnologiespoll(
      this.props.csId ? this.props.csId : null,
      this.props.groupIds ? this.props.groupIds : null
    );
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      probes: nextProps.data,
      status: "complete",
      maxCount:
        nextProps.data.length > 0
          ? sortColumns("alarmCount", "desc", nextProps.data)[0].alarmCount
          : 0,
    });
  }
  render() {
    let view = (
      <Panel
        className={this.props.className}
        maxCount={this.state.maxCount}
        data={this.state.probes}
        history={this.props.history}
      />
    );
    //Technologies
    let title = Resource.get("Technologies");
    //No Technologies Found
    let noTechnologiesText = Resource.get("No Technologies Found");
    //View All
    let viewAllText = Resource.get("View All");
    let cardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span className="overview_container_subheader">{title}</span>
        <div style={{ display: "flex", alignItems: "center" }}>

<MTooltip title={viewAllText}>
          <Button variant="text" style={{height:'16px'}}
            aria-label="Click to view all technologies"

            onClick={() => {
              this.props.history.push("/technologies");
            }}
          >
            {viewAllText}
          </Button>
          </MTooltip>
          <span className="overview_header_seprator">|</span>

<MTooltip title={"Close"}>
          <IconButton
            style={{ padding: 0 }}
            aria-label={"Close Technologies"}
            onClick={() => {
              this.props.handleCloseDialog("showTopTechnologies");
            }}
          >

            <Clear className="clearIcon" role="figure"
            title="Close" />
          </IconButton>
          </MTooltip>
        </div>
      </div>
    );
    if (
      this.state.status === "loading" &&
      !this.props.didTopTechnologiesInvalidate
    ) {
      view = (
        <div
          className="no_data_panel"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (
      this.state.status === "failure" ||
      this.props.didTopTechnologiesInvalidate
    ) {
      view = (
        <div className="no_data_panel">
          <Error />
        </div>
      );
    } else if (
      this.state.status === "complete" &&
      this.state.probes.length == 0
    ) {
      view = (
        <div className="no_data_panel">
          <CenterInfo title={noTechnologiesText} />
        </div>
      );
    }
    return (
      <div
        className={this.props.className}
        // style={{
        //   height: null,
        //   width: null,
        //   margin: null,
        // }}
        style={{
         // height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {cardHeader}
        {view}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTopTechnologiespoll: (csId, groupIds) =>
      dispatch(fetchTopTechnologiespoll(csId, groupIds)),
    pollTopTechnologiesStop: () => dispatch(pollTopTechnologiesStop()),
  };
};
const mapStateToProps = (state) => {
  return {
    data: state.homeScreen.topTechnologies,
    isFetching: state.homeScreen.isFetching,
    didTopTechnologiesInvalidate: state.homeScreen.didTopTechnologiesInvalidate,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelContainer); //connect so that we can get a handle on history
