/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React from "react";
import "./icons.less";

const Icon = (props) => {
  let classes = ["icon", props.className].join(" ");
  return (
    <svg
      className={classes}
      role={props.role || "img"}
      aria-label={props.title}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <title>{props.title}</title>
        <path
          fill="#000000"
          fillRule="evenodd"
          d="M15,18.5732357 L15,20 L9,20 L9,18.5732357 C6.05430735,17.7056083 3.81246181,15.596316 3.18003261,13 L2,13 L2,9 L3.51211712,9 C4.74762635,6.08701907 8.08134352,4 12,4 C15.9186565,4 19.2523737,6.08701907 20.4878829,9 L22,9 L22,13 L20.8199674,13 C20.1875382,15.596316 17.9456926,17.7056083 15,18.5732357 Z M4.95081967,7.92123689 L4.95081967,12.813457 C4.95081967,13.3669733 5.40476682,13.7959184 5.964739,13.7959184 L18.035261,13.7959184 C18.5960703,13.7959184 19.0491803,13.3560554 19.0491803,12.813457 L19.0491803,7.92123689 C19.0491803,7.36772051 18.5952332,6.93877551 18.035261,6.93877551 L5.964739,6.93877551 C5.40392966,6.93877551 4.95081967,7.37863846 4.95081967,7.92123689 Z M9.86885246,16.0816327 L9.86885246,17.0612245 L13.8032787,17.0612245 L13.8032787,16.0816327 L9.86885246,16.0816327 Z M5.93442623,7.91836735 L18.0655738,7.91836735 L18.0655738,12.8163265 L5.93442623,12.8163265 L5.93442623,7.91836735 Z M15.6065574,11.8367347 C16.4214037,11.8367347 17.0819672,11.1788674 17.0819672,10.3673469 C17.0819672,9.55582649 16.4214037,8.89795918 15.6065574,8.89795918 C14.791711,8.89795918 14.1311475,9.55582649 14.1311475,10.3673469 C14.1311475,11.1788674 14.791711,11.8367347 15.6065574,11.8367347 Z M8.39344262,11.8367347 C9.20828897,11.8367347 9.86885246,11.1788674 9.86885246,10.3673469 C9.86885246,9.55582649 9.20828897,8.89795918 8.39344262,8.89795918 C7.57859628,8.89795918 6.91803279,9.55582649 6.91803279,10.3673469 C6.91803279,11.1788674 7.57859628,11.8367347 8.39344262,11.8367347 Z"
        />
      </g>
    </svg>
  );
};

export default Icon;
