// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // Unsupported browser message
  content["Infrastructure management works best on a select set of browsers. "] =
  "Infrastructure management works best on a select set of browsers. ";

  //text to link to documentation of unsupported browsers
  content["List of browsers"] =
  "List of browsers";

  // END OF LOCALIZATION

export default content;
