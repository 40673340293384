/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
	SAVE_WMI_CREDENTIALS,
	SAVE_WMI_CREDENTIALS_SUCCESS,
	SAVE_WMI_CREDENTIALS_FAILURE,
	EDIT_WMI_CREDENTIALS,
	EDIT_WMI_CREDENTIALS_SUCCESS,
	EDIT_WMI_CREDENTIALS_FAILURE,
	SAVE_UNIX_CREDENTIALS,
	SAVE_UNIX_CREDENTIALS_SUCCESS,
	SAVE_UNIX_CREDENTIALS_FAILURE,
	EDIT_UNIX_CREDENTIALS,
	EDIT_UNIX_CREDENTIALS_SUCCESS,
	EDIT_UNIX_CREDENTIALS_FAILURE,
	GET_UNIX_CREDENTIALS,
	FETCH_UNIX_CREDENTIALS_SUCCESS,
	FETCH_UNIX_CREDENTIALS_FAILURE,
	POLL_UNIX_START,
	POLL_UNIX_STOP,
	DELETE_UNIX_CRED,
	UPDATE_UNIX_CRED,
	ADDED_UNIX_CRED,
} from './actionTypes'
import { UnixCredentials } from './unixCredentials'

	//Get UNIX Credentials
export function getUNIX() {
		  return {
		    type: GET_UNIX_CREDENTIALS,
		  }
		}

export function fetchUNIXSuccess(json) {
		  return {
		    type: FETCH_UNIX_CREDENTIALS_SUCCESS,
		    unixCredentials: json
		  }
	}

export function fetchUNIXFailure(error) {
		  return {
		    type: FETCH_UNIX_CREDENTIALS_FAILURE,
		    errorMessage: error.message
		  }
	}
	 
 export function pollUnixCredStart() {
		  return {
		    type:POLL_UNIX_START
		  }
		}

		export function pollUnixCredStop() {
		  return {
		    type:POLL_UNIX_STOP
		  }
		}
//Save WMI credentials
export function saveWMICredentials(json) {
	  return {
	    type: SAVE_WMI_CREDENTIALS,
	    credentials: json
	  }
}
export function saveWMICredentialsSuccess(json) {
	  return {
	    type: SAVE_WMI_CREDENTIALS_SUCCESS,
	    credentials: json
	  }
}
export function saveWMICredentialsFailure(error) {
	  return {
	    type: SAVE_WMI_CREDENTIALS_FAILURE,
	    errorMessage: error.message
	  }
}

//edit WMI credentials
export function editWMICredentials(json) {
	  return {
	    type: EDIT_WMI_CREDENTIALS,
	    credentials: json
	  }
}
export function editWMICredentialsSuccess(json) {
	  return {
	    type: EDIT_WMI_CREDENTIALS_SUCCESS,
	    credentials: json
	  }
}
export function editWMICredentialsFailure(error) {
	  return {
	    type: EDIT_WMI_CREDENTIALS_FAILURE,
	    errorMessage: error.message
	  }
}
//save UNIX credentials
export function saveUnixCredentials(json) {
	  return {
	    type: SAVE_UNIX_CREDENTIALS,
	    credentials: json
	  }
}
export function saveUnixCredentialsSuccess(json) {
	  return {
	    type: SAVE_UNIX_CREDENTIALS_SUCCESS,
	    credentials: json
	  }
}
export function saveUnixCredentialsFailure(error) {
	  return {
	    type: SAVE_UNIX_CREDENTIALS_FAILURE,
	    errorMessage: error.message
	  }
}
export function deleteUnixCred(deletedItem) {
	  return {
	    type:DELETE_UNIX_CRED,
	    deletedCredential: deletedItem
	  }
	}
export function updateUnixCred(updatedItem) {
	  return {
	    type:UPDATE_UNIX_CRED,
	    updatedCredential: updatedItem
	  }
	}
export function addUnixCred(addedItem) {
	  return {
	    type:ADDED_UNIX_CRED,
	    addedCredential: addedItem
	  }
	}
//edit UNIX credentials
export function editUnixCredentials(json) {
	  return {
	    type: EDIT_UNIX_CREDENTIALS,
	    credentials: json
	  }
}
export function editUnixCredentialsSuccess(json) {
	  return {
	    type: EDIT_UNIX_CREDENTIALS_SUCCESS,
	    credentials: json
	  }
}
export function editUnixCredentialsFailure(error) {
	  return {
	    type: EDIT_UNIX_CREDENTIALS_FAILURE,
	    errorMessage: error.message
	  }
}

//save WMI credentials
export function saveWMI(params) {
	  return dispatch => {
	    dispatch(saveWMICredentials())
	    const promise = UnixCredentials.saveWMI(params)
	    promise
	      .then(response => response.json())
	      .then(json => dispatch(saveWMICredentialsSuccess(json)))
	      .catch(function(error) {
	        dispatch(saveWMICredentialsFailure(error))
	      })
	    return promise
	  }
	}

//edit WMI credentials
export function editWMI(params) {
	  return dispatch => {
	    dispatch(editWMICredentials())
	    const promise = UnixCredentials.editWMI(params)
	    promise
	      .then(response => response.json())
	      .then(json => dispatch(editWMICredentialsSuccess(json)))
	      .catch(function(error) {
	        dispatch(editWMICredentialsFailure(error))
	      })
	    return promise
	  }
	}

//save UNIX credentials
export function saveUNIX(params) {
	  return dispatch => {
	    dispatch(saveUnixCredentials())
	    const promise = UnixCredentials.saveUNIX(params)
	    promise
	      .then(response => response.json())
	      .then(json => dispatch(saveUnixCredentialsSuccess(json)))
	      .catch(function(error) {
	        dispatch(saveUnixCredentialsFailure(error))
	      })
	    return promise
	  }
	}

//edit UNIX credentials
export function editUNIX(params) {
	  return dispatch => {
	    dispatch(editUnixCredentials())
	    const promise = UnixCredentials.editUNIX(params)
	    promise
	      .then(response => response.json())
	      .then(json => dispatch(editUnixCredentialsSuccess(json)))
	      .catch(function(error) {
	        dispatch(editUnixCredentialsFailure(error))
	      })
	    return promise
	  }
	}
