/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import { timeFrameList } from "../../components/settings/portlets/performanceReports/PerformanceLayout/qosData/qos-chart-container/QosChartToolbar";
import {
  GET_PERFORMANCE_REPORTS_HOSTS_BY_TARGET,
  GET_PERFORMANCE_REPORTS_HOST_BY_HOST,
  GET_PERFORMANCE_REPORTS_QOS_BY_HOST,
  GET_PERFORMANCE_REPORTS_QOS_BY_TARGET,
  GET_PERFORMANCE_REPORTS_TARGETS_BY_HOST,
  GET_PERFORMANCE_REPORTS_TARGET_BY_TARGET,
  SET_PRD_SOURCE,
  SET_TIME_FRAME,
  CLEAR_QOS_SELECTION_DATA,
  ADD_NEW_CHART,
  EXPAND_CHART,
  ADD_NEW_SERIES,
  SET_CHART_TITLE,
  DELETE_CHART,
  SET_REPORT_TITLE,
  SET_TIME_INTERVAL,
  SET_AGGREGATION_LEVEL,
  SET_IS_INTERPOLATION,
  UPDATE_IMPORTED_XML_DATA,
  SET_NEW_PREPARED_CHARTS,
  SET_CHART_FILTER,
  SET_SAVE_STATUS,
  SET_MIN_MAX_YAXIS,
  SET_NEW_PREPARED_FILTER_CHARTS,
  SET_STATUS_MESSAGE,
  UPDATE_CHART_ORDER,
  SET_DATA_COUNT
} from "./actionTypes";
import moment from "moment";
import { isArray, isObject } from "../../utils/lodash-utils";
export const getHostValueByHost = (hostValue) => {
	return {
		type: GET_PERFORMANCE_REPORTS_HOST_BY_HOST,
		payload: hostValue,
	};
};
export const getQosValueByHost = (qosValue) => {
	return {
		type: GET_PERFORMANCE_REPORTS_QOS_BY_HOST,
		payload: qosValue,
	};
};
export const getTargetsByHost = (targets) => {
	return {
		type: GET_PERFORMANCE_REPORTS_TARGETS_BY_HOST,
		payload: targets,
	};
};

export const getQosValueByTarget = (qosValue) => {
	return {
		type: GET_PERFORMANCE_REPORTS_QOS_BY_TARGET,
		payload: qosValue,
	};
};

export const getTargetValueByTarget = (targetValue) => {
	return {
		type: GET_PERFORMANCE_REPORTS_TARGET_BY_TARGET,
		payload: targetValue,
	};
};

export const getHostsByTarget = (hosts) => {
	return {
		type: GET_PERFORMANCE_REPORTS_HOSTS_BY_TARGET,
		payload: hosts,
	};
};

export const setPrdSource = (source) => {
	return {
		type: SET_PRD_SOURCE,
		payload: source,
	};
};
export const setTimeFrameAction = (timeFrame, timeRangeValue) => {
	return {
		type: SET_TIME_FRAME,
		payload: {
			timeFrame: timeFrame,
			timeRange: handleTimeRangeChange(timeFrame, timeRangeValue),
		},
	};
};
const handleTimeRangeChange = (timeRange, value) => {
	switch (timeRange) {
		//Last Hour
		case `Last Hour`:
			return {
				startTime: (Math.round(new Date().getTime() / 1000) - 1 * 3600) * 1000,
				stopTime: Math.round(new Date().getTime() / 1000) * 1000,
			};

		//Last Day
		case `Last Day`:
			return {
				startTime: (Math.round(new Date().getTime() / 1000) - 24 * 3600) * 1000,
				stopTime: Math.round(new Date().getTime() / 1000) * 1000,
			};

		//Last Week
		case `Last Week`:
			return {
				startTime:
					(Math.round(new Date().getTime() / 1000) - 24 * 7 * 3600) * 1000,
				stopTime: Math.round(new Date().getTime() / 1000) * 1000,
			};

		//Last Month
		case `Last Month`:
			return {
				startTime:
					(Math.round(new Date().getTime() / 1000) - 24 * 30 * 3600) * 1000,
				stopTime: Math.round(new Date().getTime() / 1000) * 1000,
			};

		//Custom
		case `Custom`:
			if (isObject(value)) {
				if (isNaN(value.startDate)) {
					return {
						startTime: moment(value.startDate).toDate().getTime(),
						stopTime: moment(value.endDate).toDate().getTime(),
					};
				} else {
					return {
						startTime: value.startDate,
						stopTime: value.endDate,
					};
				}
			} else {
				return {
					startTime:
						(Math.round(new Date().getTime() / 1000) - 24 * 3600) * 1000,
					stopTime: Math.round(new Date().getTime() / 1000) * 1000,
				};
			}
	}
};

export const clearQosSelectionData = (isSaved = false) => {
	return (dispatch, getState) => {
		dispatch({
			type: CLEAR_QOS_SELECTION_DATA,
		});
		dispatch({
			type: SET_SAVE_STATUS,
			saved: isSaved,
		});
	};
};

export const expandChart = (chartId) => {
	return {
		type: EXPAND_CHART,
		payload: chartId,
	};
};

export const setReportTitle = (title) => {
	return (dispatch, getState) => {
		let newPath = getState().performanceReports.chartData.State.path;
		if (newPath.includes("/")) {
			const pathData = newPath.split("/");
			const titleOld = pathData[pathData.length - 1];
			newPath = newPath.substr(0, newPath.lastIndexOf(titleOld)) + title
		} else {
			newPath = title;
		}
		dispatch({
			type: SET_REPORT_TITLE,
			payload: {
				title: title,
				path: newPath,
				helpPath: newPath
			},
			});

	};
};

export const setAggregationLevel = (aggLevel) => {
	return (dispatch, getState) => {
		let selectedTimeFrame =
			getState().performanceReports.chartData.State.timeFrame;
		let startTime = getState().performanceReports.chartData.State.TimeStart;
		let stopTime = getState().performanceReports.chartData.State.TimeStop;
		dispatch({
			type: SET_AGGREGATION_LEVEL,
			payload: {
				aggLevel: aggLevel,
				aggLevelSeconds: aggregationLevel(aggLevel, selectedTimeFrame,startTime,stopTime),
			},
		});
	};
};
export const setIsInterpolation = (isInterpolation) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_IS_INTERPOLATION,
			payload: {
				isInterpolation: isInterpolation,
			},
		});
	};
};

export const findAggregationLevel = (aggInterval, startTime, stopTime) => {
	const QosChartTimeDiff = moment(+stopTime).diff(moment(+startTime), "seconds");
	let intervalObject = {
		aggregationInterval: "1 hour",
		timeFrame: timeFrameList[4],
	};

	if (QosChartTimeDiff <= 3600) {
		intervalObject.timeFrame = timeFrameList[0];
	} else if (QosChartTimeDiff > 3600 && QosChartTimeDiff <= 86400) {
		intervalObject.timeFrame = timeFrameList[1];
	} else if (QosChartTimeDiff > 86400 && QosChartTimeDiff <= 604800) {
		intervalObject.timeFrame = timeFrameList[2];
	} else if (QosChartTimeDiff > 604800 && QosChartTimeDiff <= 2592000) {
		intervalObject.timeFrame = timeFrameList[3];
	} else {
		intervalObject.timeFrame = timeFrameList[4];
	}

	switch (aggInterval) {
		case -1:
			intervalObject.aggregationInterval = "1 minute";
			break;
		case 0:
			intervalObject.aggregationInterval = "None";
			break;
		case 60:
			intervalObject.aggregationInterval = "1 minute";
			break;
		case 300:
			intervalObject.aggregationInterval = "5 minutes";
			break;
		case 900:
			intervalObject.aggregationInterval = "15 minutes";
			break;
		case 1800:
			intervalObject.aggregationInterval = "30 minutes";
			break;
		case 3600:
			intervalObject.aggregationInterval = "1 hour";
			break;
		case 7200:
			intervalObject.aggregationInterval = "2 hours";
			break;
		case 14400:
			intervalObject.aggregationInterval = "4 hours";
			break;
		case 28800:
			intervalObject.aggregationInterval = "8 hours";
			break;
		case 43200:
			intervalObject.aggregationInterval = "12 hours";
			break;
		case 86400:
			intervalObject.aggregationInterval = "1 Day";
			break;
		default:
			intervalObject.aggregationInterval = "1 hour";
			break;
	}

	return intervalObject;
};
const aggregationLevel = (aggLevel, selectedTimeFrame,startTime,stopTime) => {
	let QosChartTimeDiff = 3600;
	let newStartDate = Number.isInteger(startTime)?startTime:parseInt(startTime,10);
	let newStopDate = Number.isInteger(stopTime)?stopTime:parseInt(stopTime,10);
	if(newStopDate>newStartDate){
		QosChartTimeDiff = moment(newStopDate).diff(moment(newStartDate), "seconds");
	}
	switch (aggLevel) {
		case "Automatic":
			if (selectedTimeFrame == timeFrameList[0]) return 60;
			else if (selectedTimeFrame == timeFrameList[1]) return 60 * 5;
			else if (selectedTimeFrame == timeFrameList[2]) return 60 * 30;
			else if (selectedTimeFrame == timeFrameList[3]) return 60 * 60 * 2;
			else if (selectedTimeFrame == timeFrameList[4]){
				if (QosChartTimeDiff < 3600) {
					return 60;
				}  else if (QosChartTimeDiff > 3600 && QosChartTimeDiff <= 86400) {
					return 60 * 5;
				} else if (QosChartTimeDiff > 86400 && QosChartTimeDiff <= 604800) {
					return 60 * 30;
				} else if (QosChartTimeDiff > 604800) {
					return 60 * 60 * 2
				}
			}
		case "None":
			return 0;
		case "1 minute":
			return 60;
		case "5 minutes":
			return 60 * 5;
		case "15 minutes":
			return 60 * 15;
		case "30 minutes":
			return 60 * 30;
		case "1 hour":
			return 60 * 60;
		case "2 hours":
			return 60 * 60 * 2;
		case "4 hours":
			return 60 * 60 * 4;
		case "8 hours":
			return 60 * 60 * 8;
		case "12 hours":
			return 60 * 60 * 12;
		case "1 Day":
			return 60 * 60 * 24;
		default:
			return 0;
	}
};
export const updateTimeInterval = (timeInterval) => {
	return {
		type: SET_TIME_INTERVAL,
		payload: timeInterval,
	};
};

export const updateXmlData = (data) => {
	let chartData = data;
	if (chartData.State.groups.group) {
		chartData.State.groups.group =
			chartData.State.groups.group.length == 1
				? chartData.State.groups.group[0] == ""
					? []
					: chartData.State.groups.group
				: chartData.State.groups.group;
	} else {
		chartData.State.groups = { group: [] };
	}

	let rows = data.State.page.row;
	let chartId = 1;
	let isChartIdInsertionRequired = false;

	isChartIdInsertionRequired = isArray(rows)
		? isArray(rows[0].chart)
			? rows[0].chart[0].chartId
				? false
				: true
			: rows[0].chart.chartId
			? false
			: true
		: isArray(rows.chart)
		? rows.chart[0].chartId
			? false
			: true
		: rows.chart.chartId
		? false
		: true;
	if (isChartIdInsertionRequired) {
		if (!isArray(rows)) {
			if (!isArray(rows.chart)) {
				rows.chart.chartId = chartId;
			} else {
				for (let j = 0; j < rows.chart.length; j++) {
					rows.chart[j].chartId = chartId;
					rows.chart[j].series =
						rows.chart[j].series && rows.chart[j].series.length == 1
							? rows.chart[j].series[0] == ""
								? []
								: rows.chart[j].series
							: rows.chart[j].series;
					chartId++;
					if (rows.chart[j].minYAxis1 == undefined) {
						rows.chart[j] = {
							...rows.chart[j],
							minYAxis1: "0",
							maxYAxis1: "0",
							minYAxis2: "0",
							maxYAxis2: "0",
							rowId: 0,
							legend: {
								...rows.chart[j].legend,
								table: rows.chart[j].showLegend == "false",
							},
						};
					}
				}
			}
		} else {
			for (let i = 0; i < rows.length; i++) {
				for (let j = 0; j < rows[i].chart.length; j++) {
					rows[i].chart[j].chartId = chartId;
					rows[i].chart[j].series =
						rows[i].chart[j].series && rows[i].chart[j].series.length == 1
							? rows[i].chart[j].series[0] == ""
								? []
								: rows[i].chart[j].series
							: rows[i].chart[j].series;
					chartId++;
					if (rows[i].chart[j].minYAxis1 == undefined) {
						rows[i].chart[j] = {
							...rows[i].chart[j],
							minYAxis1: "0",
							maxYAxis1: "0",
							minYAxis2: "0",
							maxYAxis2: "0",
							rowId: i,
							legend: {
								...rows[i].chart[j].legend,
								table: rows[i].chart[j].showLegend == "false",
							},
						};
					}
				}
			}
		}
	} else {
		for (let i = 0; i < rows.length; i++) {
			for (let j = 0; j < rows[i].chart.length; j++) {
				rows[i].chart[j].series =
					rows[i].chart[j].series && rows[i].chart[j].series.length == 1
						? rows[i].chart[j].series[0] == ""
							? []
							: rows[i].chart[j].series
						: rows[i].chart[j].series;

				if (rows[i].chart[j].minYAxis1 == undefined) {
					rows[i].chart[j] = {
						...rows[i].chart[j],
						minYAxis1: "0",
						maxYAxis1: "0",
						minYAxis2: "0",
						maxYAxis2: "0",
						rowId: i,
						legend: {
							...rows[i].chart[j].legend,
							table: rows[i].chart[j].showLegend == "false",
						},
					};
				}
			}
		}
	}

	return { State: { ...chartData.State, page: { row: rows } } };
};

export const updateImportedXmlData = (data) => {
	return (dispatch, getState) => {
        dispatch(clearQosSelectionData(true))
		const newChartData = updateXmlData(data);
		let filterList = [];
		if (newChartData.State.groups.group) {
			newChartData.State.groups.group.forEach((group) => {
				isArray(group.rowSelectors.rowSelector) &&
					group.rowSelectors.rowSelector.forEach((filterRow, index) => {
						if (filterRow.attribute) {
							filterRow.field = filterRow.attribute;
							filterRow.number = index + 1;
						}
					});
				newChartData.State.page.row.forEach((row) => {
					row.chart.forEach((chart) => {
						if (chart.filterId) {
							if (chart.filterId == group.filterId) {
								chart.series = [];
								filterList.push({
									chartId: chart.chartId,
									data: group,
								});
							}
						}
					});
				});
			});
		}
		dispatch({
			type: UPDATE_IMPORTED_XML_DATA,
			payload: newChartData,
		});
		dispatch(setReportTitle(newChartData.State.title));
		let timeRangeObject = {
			startDate: newChartData.State.TimeStart,
			endDate: newChartData.State.TimeStop,
		};

		if (newChartData.State.timeFrame && newChartData.State.aggLevel) {
			dispatch(
				setTimeFrameAction(newChartData.State.timeFrame, timeRangeObject)
			);
			dispatch(setAggregationLevel(newChartData.State.aggLevel));
		} else {
			let intervalObject = findAggregationLevel(
				newChartData.State.aggregationInterval,
				newChartData.State.TimeStart,
				newChartData.State.TimeStop
			);
			dispatch(setTimeFrameAction(intervalObject.timeFrame, timeRangeObject));
			dispatch(setAggregationLevel(intervalObject.aggregationInterval));
		}
		if (filterList.length > 0) {
			dispatch(addNewPreparedFilterCharts(filterList));
		}
		//dispatch({type: SET_SAVE_STATUS, saved: true})
	};
};

export const addNewSeries = (chartObject) => {
	return (dispatch, getState) => {
		let rows = getState().performanceReports.chartData.State.page.row;
		let matchFound = false;
		for (let i = 0; i < rows.length; i++) {
			for (let j = 0; j < rows[i].chart.length; j++) {
				if (rows[i].chart[j].chartId == chartObject.chartId) {
					rows[i].chart[j] = chartObject;
					matchFound = true;
					break;
				}
			}
			if (matchFound) {
				break;
			}
		}
		dispatch({
			type: ADD_NEW_SERIES,
			payload: rows,
		});
	};
};

export const setMinMaxYChart = (values) => {
	return {
		type: SET_MIN_MAX_YAXIS,
		payload: values,
	};
};

export const setChartTitle = (chartObject) => {
	return (dispatch, getState) => {
		let rows = getState().performanceReports.chartData.State.page.row;
		let matchFound = false;
		for (let i = 0; i < rows.length; i++) {
			for (let j = 0; j < rows[i].chart.length; j++) {
				if (rows[i].chart[j].chartId == chartObject.chartId) {
					rows[i].chart[j] = chartObject;
					matchFound = true;
					break;
				}
			}
			if (matchFound) {
				break;
			}
		}
		dispatch({
			type: SET_CHART_TITLE,
			payload: rows,
		});
	};
};

export const updateChartListOrder = (chartList) => {
	return (dispatch, getState) => {
		let rows = getState().performanceReports.chartData.State.page.row;
		let newRows = [];
		let chart = [null,null];
		let matchFound = false;
		let chartIndex = 0;
		for(let k = 0; k < chartList.length; k++){
			matchFound = false;
			for (let i = 0; i < rows.length; i++) {
				for (let j = 0; j < rows[i].chart.length; j++) {
					if (rows[i].chart[j].chartId == chartList[k]) {
						chart[chartIndex] = rows[i].chart[j];
						if(chartIndex == 1){
							chartIndex = 0;
							newRows.push({chart});
							chart = ((chartList.length % 2 != 0) && k == chartList.length-2)?[null]:[null,null];
						}else if(k == chartList.length-1){
							newRows.push({chart});
						}
						else {chartIndex = 1;}

							matchFound = true;
						break;
					}
				}
				if (matchFound) {
					break;
				}
			}
		}
		dispatch({
			type: UPDATE_CHART_ORDER,
			payload: newRows,
		});
	};
};

export const deleteChart = (chartId) => {
	return (dispatch, getState) => {
		let rows = getState().performanceReports.chartData.State.page.row;
		let matchFound = false;
		for (let i = 0; i < rows.length; i++) {
			for (let j = 0; j < rows[i].chart.length; j++) {
				if (rows[i].chart[j].chartId == chartId) {
					rows[i].chart = rows[i].chart.filter(
						(chart) => chart.chartId != chartId
					);
					matchFound = true;
					break;
				}
			}
			if (matchFound) {
				break;
			}
		}
		dispatch({
			type: DELETE_CHART,
			payload: rows,
		});
	};
};

export const addNewChart = (chartData) => {
	return (dispatch, getState) => {
		let chartDataState = getState().performanceReports.chartData.State;
		let isNewRowRequired =
			chartDataState.page.row[0].chart.length == 1
				? chartDataState.GraphMaximized ==
				  chartDataState.page.row[0].chart[0].chartId
				: chartDataState.page.row[0].chart.length == 2
				? true
				: false;
		let rows = chartDataState.page.row;
		if (isNewRowRequired) {
			rows.unshift({ chart: [] });
		}

		let updatedList = [...rows[0].chart];
		updatedList.unshift(chartData);
		rows[0].chart = updatedList;
		dispatch({
			type: ADD_NEW_CHART,
			payload: rows,
		});
	};
};

export const addNewPreparedCharts = (charts) => {
	return {
		type: SET_NEW_PREPARED_CHARTS,
		newPreparedCharts: charts,
	};
};

export const addNewPreparedFilterCharts = (charts) => {
	return {
		type: SET_NEW_PREPARED_FILTER_CHARTS,
		filteredCharts: charts,
	};
};

export const AddChartFilter = (filter) => {
	return (dispatch, getState) => {
		let chartDataState = getState().performanceReports.chartData.State;
		let groups = chartDataState.groups;

		if (groups.group && groups.group.length >= 0) {
			let tempGroups = groups.group;
			let updateGroups = groups.group.findIndex(
				(f) => f.filterId == filter.filterId
			);
			if (updateGroups === -1) {
				groups.group.push(filter);
			} else {
				tempGroups[updateGroups] = filter;
			}
			groups = { group: tempGroups };
		}

		dispatch({
			type: SET_CHART_FILTER,
			payload: groups,
		});
	};
};

export const DeleteChartFilter = (filterId) => {
	return (dispatch, getState) => {
		let chartDataState = getState().performanceReports.chartData.State;
		let groups = chartDataState.groups;
		let groupArray = groups.group;
		let modifiedArray = groupArray.filter((item) => item.filterId !== filterId);
		groups.group = modifiedArray;
		dispatch({
			type: SET_CHART_FILTER,
			payload: groups,
		});
	};
};

export const updateSavedReport = (isSaved) => {
	return {
		type: SET_SAVE_STATUS,
		saved: isSaved,
	};
};

export const setStatusMessage = (message) =>{
	return {
		type : SET_STATUS_MESSAGE,
		payload : message
	}
}

export const setDataCount = (values) => {
	return {
		type: SET_DATA_COUNT,
		action: values,
	};
};
