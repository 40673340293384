/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
export const FILTER_ENTITIES = "FILTER_ENTITIES";
export const INVENTORY_FILTER_ENTITIES = "INVENTORY_FILTER_ENTITIES";
export const SET_ENTITIES = "SET_ENTITIES";
export const SET_ENTITY = "SET_ENTITY";
export const REMOVE_ENTITIES = "REMOVE_ENTITIES";
export const RENAME_ENTITY = "RENAME_ENTITY";
export const ADD_ENTITIES = "ADD_ENTITIES";
export const FILTERED_ROW_ENTITIES = "FILTERED_ROW_ENTITIES";
export const MONITORD_BY_FILTER_LIST = "MONITORD_BY_FILTER_LIST";
export const SET_QUICK_FILTER = "SET_QUICK_FILTER";
export const FILTERED_ROW_ENTITIES_CONTAINERS = "FILTERED_ROW_ENTITIES_CONTAINERS";
