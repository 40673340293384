/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React from "react";

const Icon = (props) => {
  let classes = ["icon", props.className].join(" ");
  return (
    <svg
      className={classes}
      role={props.role || "figure"}
      aria-label={props.title}
      width="24px"
      height="16px"
      viewBox="0 0 24 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <g>
        <polygon points="0 2.1817143 4.36342861 2.1817143 4.36342861 0 0 0"></polygon>
        <polygon points="6.54514291 2.1817143 10.9085715 2.1817143 10.9085715 0 6.54514291 0"></polygon>
        <polygon points="13.0902858 2.1817143 17.4537144 2.1817143 17.4537144 0 13.0902858 0"></polygon>
        <polygon points="19.6354287 2.1817143 23.9988573 2.1817143 23.9988573 0 19.6354287 0"></polygon>
        <polygon points="0 6.54514291 4.36342861 6.54514291 4.36342861 4.36342861 0 4.36342861"></polygon>
        <polygon points="6.54514291 6.54514291 10.9085715 6.54514291 10.9085715 4.36342861 6.54514291 4.36342861"></polygon>
        <polygon points="13.0902858 6.54514291 17.4537144 6.54514291 17.4537144 4.36342861 13.0902858 4.36342861"></polygon>
        <polygon points="19.6354287 6.54514291 23.9988573 6.54514291 23.9988573 4.36342861 19.6354287 4.36342861"></polygon>
        <polygon points="0 10.9085715 4.36342861 10.9085715 4.36342861 8.72685721 0 8.72685721"></polygon>
        <polygon points="6.54514291 10.9085715 10.9085715 10.9085715 10.9085715 8.72685721 6.54514291 8.72685721"></polygon>
        <polygon points="13.0902858 10.9085715 17.4537144 10.9085715 17.4537144 8.72685721 13.0902858 8.72685721"></polygon>
        <polygon points="19.6354287 10.9085715 23.9988573 10.9085715 23.9988573 8.72685721 19.6354287 8.72685721"></polygon>
        <polygon points="0 15.2720001 4.36342861 15.2720001 4.36342861 13.0902858 0 13.0902858"></polygon>
        <polygon points="6.54514291 15.2720001 10.9085715 15.2720001 10.9085715 13.0902858 6.54514291 13.0902858"></polygon>
        <polygon points="13.0902858 15.2720001 17.4537144 15.2720001 17.4537144 13.0902858 13.0902858 13.0902858"></polygon>
        <polygon points="19.6354287 15.2720001 23.9988573 15.2720001 23.9988573 13.0902858 19.6354287 13.0902858"></polygon>
      </g>
    </svg>
  );
};

export default Icon;
