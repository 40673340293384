/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  FETCH_PROBE_METRICS,
  FETCH_PROBE_METRICS_SUCCESS,
  FETCH_PROBE_METRICS_FAILURE,
  FETCH_PROBE_METRICS_DEVICES,
  FETCH_PROBE_METRICS_DEVICES_SUCCESS,
  FETCH_PROBE_METRICS_DEVICES_FAILURE,
  CLEAR_PROBE_CHART_DATA,
  FETCH_PROBE_METRICS_DEVICES_CINAME,
  FETCH_PROBE_METRICS_DEVICES_CINAME_SUCCESS,
  FETCH_PROBE_METRICS_DEVICES_CINAME_FAILURE,
  REQUEST_PROBE_METRICS_DEVICES_CHART,
  FETCH_PROBE_METRICS_DEVICES_CHART,
  FETCH_PROBE_METRICS_DEVICES_CHART_SUCCESS,
  FETCH_PROBE_METRICS_DEVICES_CHART_FAILURE,
  POLL_METRIC_DEVICE_CHART_START,
  POLL_METRIC_DEVICE_CHART_STOP,
} from "./actionTypes";
import { DashboardMetric } from "./dashboardMetric";

export function fetchMetrics() {
  return {
    type: FETCH_PROBE_METRICS,
  };
}
export function fetchMetricsSuccess(json) {
  return {
    type: FETCH_PROBE_METRICS_SUCCESS,
    metrics: json,
  };
}
export function fetchMetricsFailure(error) {
  return {
    type: FETCH_PROBE_METRICS_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function getMetricsData(probeId) {
  return (dispatch) => {
    dispatch(fetchMetrics());
    return DashboardMetric
      .getMetricsById(probeId)
      .then((response) =>
        response.data._items ? response.data._items : response.data
      )
      .then((data) => dispatch(fetchMetricsSuccess(data)))
      .catch(function (error) {
        dispatch(fetchMetricsFailure(error));
      });
  };
}
// GET DEVICE LIST

export function fetchMetricsDevices() {
  return {
    type: FETCH_PROBE_METRICS_DEVICES,
  };
}
export function fetchMetricsDevicesSuccess(json) {
  return {
    type: FETCH_PROBE_METRICS_DEVICES_SUCCESS,
    devices: json,
  };
}
export function fetchMetricsDevicesFailure(error) {
  return {
    type: FETCH_PROBE_METRICS_DEVICES_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function getMetricsDevicesData(probeId, metricTypeId) {
  return (dispatch) => {
    dispatch(fetchMetricsDevices());
    return DashboardMetric
      .getDevicesByMetric(probeId, metricTypeId)
      .then((response) =>
        response.data._items ? response.data._items : response.data
      )
      .then((data) => dispatch(fetchMetricsDevicesSuccess(data)))
      .catch(function (error) {
        dispatch(fetchMetricsDevicesFailure(error));
      });
  };
}

// GET CI NAMES LIST

export function fetchMetricsDevicesCiName() {
  return {
    type: FETCH_PROBE_METRICS_DEVICES_CINAME,
  };
}
export function fetchMetricsDevicesCiNameSuccess(json) {
  return {
    type: FETCH_PROBE_METRICS_DEVICES_CINAME_SUCCESS,
    ciNames: json,
  };
}
export function fetchMetricsDevicesCiNameFailure(error) {
  return {
    type: FETCH_PROBE_METRICS_DEVICES_CINAME_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function getMetricsDevicesCiNameData(probeId, qosMetric, csId) {
  return (dispatch) => {
    dispatch(fetchMetricsDevicesCiName());
    return DashboardMetric
      .getCiNames(probeId, qosMetric, csId)
      .then((response) =>
        response.data._items ? response.data._items : response.data
      )
      .then((data) => dispatch(fetchMetricsDevicesCiNameSuccess(data)))
      .catch(function (error) {
        dispatch(fetchMetricsDevicesCiNameFailure(error));
      });
  };
}

// GET METRIC CHART DATA 

export function fetchMetricDevicesChart() {
  return {
    type: REQUEST_PROBE_METRICS_DEVICES_CHART,
  };
}

export function fetchMetricDeviceChartSuccess(
  json,
  deviceIds,
  metricTypes,
  hours,
  selectedCIs,
  isInterface,
  endDate
) {
  return {
    type: FETCH_PROBE_METRICS_DEVICES_CHART_SUCCESS,
    metricData: json,
    deviceIds,
    metricTypes,
    hours,
    selectedCIs,
    isInterface,
    endDate,
  };
}

export function fetchMetricDeviceChartFailure(
  error,
  deviceIds,
  metricTypes,
  hours,
  selectedCIs,
  isInterface,
  endDate
) {
  return {
    type: FETCH_PROBE_METRICS_DEVICES_CHART_FAILURE,
    errorMessage: error.message,
    deviceIds,
    metricTypes,
    hours,
    selectedCIs,
    isInterface,
    endDate,
  };
}

// export function getMetricDevicesChart(
//   deviceIds,
//   metricTypes,
//   hours,
//   selectedCIs,
//   isInterface,
//   endDate
// ) {
//   return (dispatch) => {
//     dispatch(fetchMetricDevicesChart());
//     return (
//       dashboardMetric
//         .getAllMetricDevicesChart(
//           deviceIds,
//           metricTypes,
//           hours,
//           selectedCIs,
//           isInterface,
//           endDate
//         )
//         .then((response) => response.data._items ? response.data._items : response.data)
//         // .then((response) => [...response.map((res) => res.data._items[0])]) //TODO: Remove it, only for mock
//         .then((data) =>
//           dispatch(
//             fetchMetricDeviceChartSuccess(
//               data,
//               deviceIds,
//               metricTypes,
//               hours,
//               selectedCIs,
//               isInterface,
//               endDate
//             )
//           )
//         )
//         .catch(function (error) {
//           dispatch(
//             fetchMetricDeviceChartFailure(
//               error,
//               deviceIds,
//               metricTypes,
//               hours,
//               selectedCIs,
//               isInterface,
//               endDate
//             )
//           );
//         })
//     );
//   };
// }

export function resetChartData() {
  return {
    type: CLEAR_PROBE_CHART_DATA,
  };
}


export function fetchMetricDeviceChartPoll(deviceIds,metric,hours,selectedCIs,isInterface,endDate) {
  return dispatch => {
    dispatch(fetchMetricDevicesChart())
    dispatch(pollMetricDeviceChartStart(deviceIds,metric,hours,selectedCIs,isInterface,endDate))
    return {
      type:FETCH_PROBE_METRICS_DEVICES_CHART,
      
    }
  }
}

export function pollMetricDeviceChartStart(deviceIds,metric,hours,selectedCIs,isInterface,endDate) {
  return {
    type:POLL_METRIC_DEVICE_CHART_START,
    deviceIds:deviceIds,
    metric:metric,
    hours:hours,
    selectedCIs:selectedCIs,
    isInterface:isInterface,
    endDate:endDate
  }
}
export function pollMetricDeviceChartStop() {
  return {
    type:POLL_METRIC_DEVICE_CHART_STOP,
    
  }
} 


