/*
 * Copyright © 2024 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import { combineReducers } from "redux";

import groups from "./api/groups/groupsReducer";
import groupId from "./api/groups/idReducer";
import selectedCsIds from "./api/groups/selectedCsIdsReducer";
import selectedContainerIds from "./api/containers/selectedContainerIdsReducer";
import selectedIfIds from "./api/groups/selectedIfIdsReducer";
import groupSorter from "./api/groups/groupSorterReducer";
import entities from "./components/entities/setEntitiesReducer";
import entity from "./components/entities/setEntityReducer";
import inventoryEntityFilter from "./components/entities/inventoryEntityFilterReducer";
import columnFilter from "./components/entities/columnFilterReducer";
import containersColumnFilter from "./components/entities/columnFilterContainersReducer";
import cardPage from "./components/cards/cardPageReducer";
import breadCrumbs from "./components/breadcrumbs/breadcrumbReducer";
import walkThrough from "./components/walkThrough/WalkThroughReducer";
import user from "./api/login/reducer";
import computerSystems from "./api/computer-systems/computerSystemsReducer";

import containers,{containerEntitiesFilterSortConfig} from "./api/containers/containersReducer";
import historicAlarmsByGroup from "./api/historic-alarms/historicAlarmsByGroupReducer";
import historicAlarmsByCsId from "./api/historic-alarms/historicAlarmsByCsReducer";

import saas from "./api/saas/reducer";
import isTimedout from "./api/isTimedOut/reducer";
import heartbeat from "./api/heartbeat/reducer";
import hubs from "./api/hubs/hubsReducer";
import groupManagementPermission from "./api/groupMgmtPermission/reducer";
import discovery from "./api/discovery/discoveryReducer";
import configuration from "./api/configuration/configurationReducer";
import robots from "./api/robots/robotsReducer";
import upgradeableRobots from "./api/robots/upgradeableRobotsReducer";
import robotsStatus from "./api/robotsStatus/robotsStatusReducer";
import cabiInput from "./api/cabi/cabiInputReducer";
import clientRobots from "./components/robotDeploy/clientRobots/reducer";
import config from "./api/config/reducer";
import pagerDuty from "./api/integrations/pagerduty/reducer";
import selectedModal from "./api/modals/reducer";
import slack from "./api/integrations/slack/reducer";
import settings from "./api/settings/reducer";
import info from "./api/info/reducer";
import activeProbes from "./api/active-probes/reducer";
import inventoryFilter from "./components/inventory/filter/inventoryFilterReducer";
import closeRule from "./api/close-rules/reducer";
import serviceNow from "./api/integrations/servicenow/reducer";
import wizardProbeList from "./api/setup-wizard/reducer";
import acl from "./api/acl/reducer";
import ocviews from "./api/ocviews/reducer";
import productUsageMetrics from "./api/productUsageMetrics/reducer";
import wasp from "./api/wasp/reducer";
import reports from "./api/reports/reducer";

import account from "./api/account/reducer";
import credentials from "./api/DeviceDiscovery/credentials/reducer";
import unixCredentials from "./api/DeviceDiscovery/unixCredentials/reducer";
import scopes from "./api/DeviceDiscovery/scopes/reducer";
import agents from "./api/DeviceDiscovery/agents/agentsReducer";
import mcsDashboardReducer from "./api/mcsDashboard/mcsDashboardReducers";
import operating_periods from "./api/operating-period/reducer";
import tree_view from "./api/treeView/reducer";
import qos from "./api/QoS/reducer";
import maintenance from "./api/maintenance/reducer";
import automatic_group from "./api/automaticGroups/reducer";
import interfaces from "./api/interfaces/interfacesReducer";
import dashboardPaths from "./api/dashboard/reducer";
import changePassword from "./api/changePassword/reducer";
import homeScreen from "./api/home-screen/reducer";
import groupsDashboard from "./api/groups/groupsDashboardReducer";
import devices from "./api/devices/reducer";
import userdashboards from "./api/userdashboards/reducer";
import userNavigation from "./api/userNavigation/reducer";
import userpreferences from "./api/userpreferences/reducer";
import dashboardMetric from "./api/dashboardMetrics/reducer";
import devicegroups from "./api/devicegroups/reducer";
import filteredInventoryData from "./api/computer-systems/inventoryFilteredDataReducer";
import filteredGroupTreeData from "./api/groups/groupFilteredReducer";
import savedColsListView from "./api/groups/listSavedColumnsReducer";
import performanceReports from "./api/performanceReports/reducer";
import customuserpreferences from "./api/customuserpreferences/reducer";

const operatorConsoleApp = combineReducers({
  groupsDashboard,
  homeScreen,
  groups,
  groupId,
  selectedCsIds,
  selectedIfIds,
  groupSorter,
  entities,
  entity,
  inventoryEntityFilter,
  columnFilter,
  containersColumnFilter,
  computerSystems,
  selectedContainerIds,
  containers,
  containerEntitiesFilterSortConfig,
  cardPage,
  breadCrumbs,
  walkThrough,
  user,
  historicAlarmsByGroupId: historicAlarmsByGroup,
  historicAlarmsByCsId: historicAlarmsByCsId,
  groupManagementPermission,

  saas,
  isTimedout,
  heartbeat,
  hubs,
  discovery,
  configuration,
  robots,
  robotsStatus,
  cabiInput,
  clientRobots,
  config,
  pagerDuty,
  slack,
  serviceNow,
  selectedModal,
  settings,
  info,
  activeProbes,
  inventoryFilter,
  upgradeableRobots,
  closeRule,
  wizardProbeList,
  acl,
  ocviews,
  userpreferences,
  wasp,
  reports,

  account,
  credentials,
  unixCredentials,
  scopes,
  agents,
  mcsDashboardReducer,
  operating_periods,
  tree_view,
  qos,
  maintenance,
  automatic_group,
  interfaces,
  dashboardPaths,
  changePassword,
  userdashboards,
  userNavigation,
  devices,
  dashboardMetric,
  devicegroups,
  filteredInventoryData,
  filteredGroupTreeData,
  savedColsListView,
  performanceReports,
  productUsageMetrics,
  customuserpreferences
});

export default operatorConsoleApp;
