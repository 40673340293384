/*
 * Copyright © 2024 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, {memo} from "react";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ReferenceLine,
  ComposedChart,
} from "recharts";
import CustomTooltip from "./CustomTooltip";
import CustomLegend from "./CustomLegend";
import { CircularProgress } from '@mineral/core';
import { SEPERATORS } from '../../../../utils/common';

export const MineralChart = memo(({
  metricDevice,
  index,
  aggregationValue,
  xAxisTickFormatter,
  layout,
  columns,
}) => {
  const data = metricDevice.deviceData[0];
  let refRec;
  if(aggregationValue===-1)
  refRec = metricDevice.chartDefinationRaw[data.deviceName + '-' + data.ciName];
  else
  refRec = metricDevice.chartDefination;
  const toTimestamp = (strDate) => {
    return new Date(strDate).getTime()/1000;
  };
  const [refRecord, setRefRecord] = React.useState(refRec!==undefined && refRec[refRec?.length - 1]);
  const [defaultRefPoint, setDefaultRefPoint] = React.useState(refRecord);
  const startTime = refRec?.ts!==undefined?refRec[0].ts:refRec?toTimestamp(refRec[0]?.sampleTime):0;
  const endTime = refRec?.ts!==undefined?refRec[refRec.length - 1].ts:refRec?toTimestamp(refRec[refRec.length - 1]?.sampleTime):0;
  let recordCount = aggregationValue===-1?metricDevice.deviceData?.length * metricDevice.chartDefinationRaw[data.deviceName + '-' + data.ciName]?.length:metricDevice.deviceData?.length * metricDevice.chartDefination?.length;
  const maxRecord = 20000;
  const count = metricDevice?.deviceData?.length;
        let prevcount = 0
        if(sessionStorage.getItem("count_target_"+index)!==null)
        {
          prevcount = sessionStorage.getItem("count_target_"+index)
        }
        sessionStorage.setItem("count_target_"+index,count)
        const isColorChange = parseInt(prevcount)===parseInt(count)?false:true;
  const getMyColor = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  };

  let selectedCombos = [];
        let i;
        for(i=0;i<count;i++)
        {
          selectedCombos.push({
            color: getMyColor(),
            stroke: "dashed",
            icon: "filledCircle"
        })
        }
  const selectedColors = sessionStorage.getItem("color_"+index)!==null ? !isColorChange ? JSON.parse(sessionStorage.getItem("color_"+index)): selectedCombos : selectedCombos;
  // React.useEffect(()=>{
  //   setDefaultRefPoint(refRecord);
  // },[refRecord])

  React.useEffect(()=>{
    if(!isColorChange)
    {
      if(sessionStorage.getItem("color_"+index) == null){
        sessionStorage.setItem("color_"+index,JSON.stringify(selectedCombos))
      }
    }
    else
    {
      sessionStorage.setItem("color_"+index,JSON.stringify(selectedCombos))
    }

  },[index, isColorChange, selectedCombos])

  const isDatePickerOpen = () => {
    const value = sessionStorage.getItem('metricsChartDatePickerOpen');
    return value ? value === 'true' : false;
  };

 const loadSpinner = (styleValue) => {
  const ele = document.getElementById('metricSpinner');
  if(ele) {
    ele.style.display = styleValue;
  }
 }

  const getLineData = () => {
    const startTime = new Date();
    //console.log('METRICS-UI-101, Start Time: ' + startTime);

    if(aggregationValue !== -1) {
      //console.log(`METRICS-API-NonRaw-Response-102, Devices: ${metricDevice.deviceData?.length}, Total: ` + metricDevice.deviceData?.length * metricDevice.chartDefination?.length);
      return metricDevice.chartDefination;
    }

   if(sessionStorage.getItem('disableNewLineLogic') === 'true') {
      return null;
    }
    loadSpinner('block');
    //console.log(`METRICS-API-Raw-Response-103, Devices: ${metricDevice.deviceData?.length} , Data Points each device: ${ metricDevice.chartDefinationRaw[data.deviceName + '-' + data.ciName]?.length}, Total: ` + metricDevice.deviceData?.length * metricDevice.chartDefinationRaw[data.deviceName + '-' + data.ciName]?.length);
    let lineMap = new Map();
    metricDevice.deviceData && metricDevice.deviceData.forEach((device) => {
      let dataKey = device.deviceName;
      if (device.ciName) {
        dataKey = `${dataKey}-${device.ciName}`;
      }
      if(metricDevice.chartDefinationRaw) {
        const rawData = metricDevice.chartDefinationRaw[dataKey]
        rawData && rawData.forEach((row) => {
          const timeKey = row?.sampleTime + '=' + row?.ts
         if(lineMap.has(timeKey)) {
          const source = lineMap.get(timeKey);
          const target = dataKey + SEPERATORS.APPEND + row[dataKey];
          lineMap.set(timeKey, source + SEPERATORS.SEPERATOR + target);
         } else {
          const deviceVal = dataKey + SEPERATORS.APPEND + row[dataKey];
          lineMap.set(timeKey, deviceVal);
         }
        });
      }
    });

    let chartArr = [];
    lineMap && lineMap.forEach((value, key) => {
     let obj = {};
     obj.sampleTime = key.split('=')[0];
     obj.ts = Number(key.split('=')[1]);
     value.split(SEPERATORS.SEPERATOR).forEach((val) => {
      const device = val.split(SEPERATORS.APPEND);
      obj[device[0]] = isNaN(device[1]) ? null : Number(device[1]);
     });
     chartArr.push(obj);
    });
    const endTime = new Date();
    //console.log('METRICS-UI-104, End Time: ' + endTime);
    loadSpinner('none');
    chartArr && chartArr.sort((a,b) => { let dateA = new Date(a.sampleTime?.split(' ')[0]);
    let dateB = new Date(b.sampleTime?.split(' ')[0]); return dateA - dateB});
   return chartArr;
  };

  return (
    <>
    <div id="metricSpinner" style={{paddingLeft: '20rem', display: 'none'}}>
      <CircularProgress style={{color: '#3272D9'}} />
    </div>

      <ResponsiveContainer width={'95%'} height={'95%'}>
        <ComposedChart
          style={{ zindex:-1}}
          data={getLineData()}
          connectNulls={false}
          key={index}
          margin={{
            top: 20,
            right: 30,
            left: 30,
            bottom: 0,
          }}
          isAnimationActive={true}
          animationDuration={0}
          onClick={(nextState) => {
            if(parseInt(recordCount)<parseInt(maxRecord)){
              if (nextState) {
                setRefRecord(nextState.activePayload[0].payload);
                //setDefaultRefPoint(nextState.activePayload[0].payload);
              }
            }
          }}
          onMouseMove={(nextState) => {
            if(metricDevice.deviceData?.length <= 20) {
              if(!isDatePickerOpen()) {
                if (nextState.isTooltipActive) {
                  setDefaultRefPoint(nextState.activePayload[0].payload);
                }
                else {
                  setDefaultRefPoint(refRecord);
                }
              }
            }
          }}
          onMouseLeave={() => {
            if(metricDevice.deviceData?.length <= 20) {
              if(!isDatePickerOpen()) {
                setDefaultRefPoint(refRecord);
              }
            }
          }}
          id={`${metricDevice.name}_${index}`}
        >
          <CartesianGrid strokeDasharray={2} />

          <XAxis dataKey={aggregationValue !== -1 ? "sampleTime" : "ts"}
            type={aggregationValue !== -1 ? 'category': 'number'}
            interval={"preserveStartEnd"}
            tickFormatter={(tick) => xAxisTickFormatter(tick, `${metricDevice.name}_${index}`, aggregationValue)}
            domain={['dataMin', 'dataMax']}
            tickCount={15}
          />
          <YAxis axisLine={true} tickLine={false}
                  domain={((metricDevice.unit.toUpperCase() === "pct".toUpperCase() || metricDevice.unit.toUpperCase === "Percent".toUpperCase) && metricDevice.hasMax === 1)?[0,100]
                  :(metricDevice.isBool === 1?[0,1]:[0,'auto'])} />
          <Tooltip
            content={<CustomTooltip aggregationValue={aggregationValue} />}
          />
          <Legend
            wrapperStyle={{
              width: layout === 'vertical' ? '30%' : '95%',
              height: layout === 'vertical' ? '95%' : '50%',
              paddingTop: layout !== 'vertical' ? '10px' : undefined,
              paddingLeft: layout === 'vertical' ? '20px' : undefined,
              left: '38px',
              padding: '0 0 0 0',
            }}
            layout={layout}
            verticalAlign={layout === 'vertical' ? "top" : "bottom"}
            align={layout === 'vertical' ? "right" : undefined}
            content={(props) => CustomLegend({
              data: metricDevice,
              selectedCombos: selectedColors,
              referenceData: aggregationValue !== -1 ? defaultRefPoint?.sampleTime : defaultRefPoint?.ts,
              refLineData: refRecord,
              setRefLineData: setRefRecord,
              startTime: startTime,
              endTime: endTime,
              columns: columns,
              aggregationValue: aggregationValue,
              recordCount:recordCount,
              maxRecord:maxRecord,
              ...props
            })}
          />
          <ReferenceLine x={refRecord?.ts?refRecord?.ts:refRecord?.sampleTime} stroke="#434A54" strokeDasharray="3 3">
            <Label
              position={"top"}
              content={""}
            ></Label>
          </ReferenceLine>
         {metricDevice.deviceData && metricDevice.deviceData.map((device, i) => {
              let dataKey = device.deviceName;
              if (device.ciName) {
                dataKey = `${dataKey}-${device.ciName}`;
              }
              let lineData = metricDevice ? (metricDevice.chartDefinationRaw ?
                metricDevice.chartDefinationRaw[dataKey]
                : null) : null;

              if(lineData != null) {
                if(!(sessionStorage.getItem('disableNewLineLogic') === 'true')) {
                  lineData = null;
                }
              }

              return (
                <Line
                  type="monotone"
                  data={lineData}
                  dataKey={dataKey}
                  key={dataKey}
                  dot={false}
                  connectNulls={false}
                  stroke={selectedColors[i]?.color}
                  isAnimationActive={true}
                  animationDuration={0}
                  onAnimationStart={() => {
                    loadSpinner('block');
                  }}
                  onAnimationEnd={() => {
                    if(metricDevice.deviceData?.length - 1 === i) {
                      loadSpinner('none');
                    }
                  }}
                />
            )})
          }
          </ComposedChart>
          </ResponsiveContainer>
        </>
      );
    })
