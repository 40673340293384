/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Name message
  content["Display Name"] =
  "Nombre para mostrar";

  //Alias message
  content["Display Alias"] =
  "Mostrar alias";

  //Description message
  content["Description"] =
  "Descripción";

  //IPaddress message
  content["IP address"] =
  "Dirección IP";

  //instanceName message
  content["Instance Name"] =
  "Nombre de la instancia";

  //Name message
  content["Name"] =
  "Nombre";

  //Role message
  content["Role"] =
  "Rol";

  //OSType message
  content["OS Type"] =
  "Tipo de SO";

  //OSName message
  content["OS Name"] =
  "Nombre del SO";

  //OSVersion message
  content["OS Version"] =
  "Versión del SO";

  //AddDevice(s)
  content["Add Device(s)"] =
  "Agregación de dispositivos";

  //Nodevicestoadd
  content["No devices to add"] =
  "Ningún dispositivo para agregar";

  //Cancel
  content["Cancel"] =
  "Cancelar";

  //Add
  content["Add"] =
  "Agregar";

  //Noresultsfound message
  content["No results found"] =
  "No se ha encontrado ningún resultado.";

  //SearchforComputerSystems message
  content["Search for Computer Systems"] =
  "Buscar sistemas informáticos";

  //Namemustbelessthan256 characters message
  content["Name must be less than 256 characters"] =
  "El nombre no debe exceder los 256 caracteres.";

  //Groupnamelareadyexists message
  content["Group name already exists"] =
  "El nombre de grupo ya existe.";

  // A group may contain either devices or additional groups, not a mix.
  content[" A group may contain either devices or additional groups, not a mix."] =
  " Un grupo puede contener dispositivos o grupos adicionales. Sin embargo, no es posible que contenga una mezcla de ambos.";

  //Groupt Type
  content["Group Type"] =
  "Tipo de grupo";

  // Dynamic Group
  content["Dynamic Group"] =
  "Grupo dinámico";

  // Element Type
  content["Element Type"] =
  "Tipo de elemento";

  //Add group Message
  content["Add group"] =
  "Agregar grupo";

  //Group Name
  content["Group Name"] =
  "Nombre del grupo";

  //Group name
  content["Group name"] =
  "Nombre del grupo";

  //Static
  content["Static"] =
  "Estático";

  //Dynamic
  content["Dynamic"] =
  "Dinámico";

  //Container
  content["Container"] =
  "Contenedor";

  //Devices
  content["Devices"] =
  "Dispositivos";

  //Interfaces
  content["Interfaces"] =
  "Interfaces";

  //Delete
  content["Delete"] =
  "Suprimir";

  //DeleteDevice
  content["Delete Device"] =
  "Suprimir dispositivo";

  // help message
  content["This action will remove the selected device(s) from the system\'s inventory of discovered and monitored devices. It will no longer count against the billing cap."] =
  "Esta acción eliminará los dispositivos seleccionados del inventario del sistema de dispositivos detectados y monitorizados. Ya no se contabilizarán con respecto al máximo de facturación.";

  // help message1
  content["To restore the device and enable monitoring, go to\… Setting > Deleted devices"] =
  "Para restaurar el dispositivo y activar la monitorización, vaya a Configuración > Dispositivos suprimidos.";

  //Delete Group
  content["Delete Group"] =
  "Suprimir grupo";

  //Your individual devices will not be deleted.
  content[" Your individual devices will not be deleted. "] =
  " No se suprimirán los dispositivos individuales. ";

  //Group Management Failed message
  content["Group Management Failed"] =
  "Error de gestión de grupos";

  //Add Devices Failed message
  content["Add Devices Failed"] =
  "Error de agregación de archivos";

  //Delete Group Failed message
  content["Delete Group Failed"] =
  "Error de supresión de grupos";

  //Rename Group Failed message
  content["Rename Group Failed"] =
  "Error de cambio de nombre a un grupo";

  //Add Group Failed message
  content["Add Group Failed"] =
  "Error de agregación del grupo";

  //Removing Devices Failed message
  content["Removing Devices Failed"] =
  "Error de eliminación de dispositivos";

  //Removing Devices Failed message
  content["Importing Devices Failed."] =
  "Se ha producido un error al importar dispositivos.";

  //Close
  content["Close"] =
  "Cerrar";

  //Please contact an administrator
  content["Please contact an administrator"] =
  "Póngase en contacto con el administrador.";

  //Please check file format for data
  content["Please check file format for data"] =
  "Compruebe el formato del archivo para los datos.";

  //Deploy
  content["Deploy"] =
  "Implementar";

  //Deploy Robot
  content["Deploy Robot"] =
  "Implementar robot";

  // help message
  content["This action will deploy robot in selected device(s)"] =
  "Esta acción implementará el robot en el dispositivo o dispositivos seleccionados.";

  //Search For Computer Systems
  content["Search For Computer Systems"] =
  "Buscar sistemas informáticos";

  //Remove
  content["Remove"] =
  "Eliminar";

  //Your individual devices will not be deleted
  content["Your individual devices will not be deleted "] =
  "No se suprimirán los dispositivos individuales. ";

  //Save
  content["Save"] =
  "Guardar";

  //Rename group Message
  content["Rename group"] =
  "Renombrar grupo";

  //Logout
  content["Logout"] =
  "Cerrar sesión";

  //Unknown
  content["Unknown"] =
  "Desconocido";

  //Remove device(s) from Group Message
  content["Remove device(s) from Group"] =
  "Eliminar dispositivos del grupo";

  //Action Menu Name Message
  content["Action Menu"] =
  "Menú Acciones";

  //edit group Message
  content["Edit group"] =
  "Editar grupo";

  // Delete group message
  content["Delete group"] =
  "Suprimir grupo";

  //Add device(s) to Group Message
  content["Add device(s) to Group"] =
  "Agregar dispositivos al grupo";

  //Delete device(s) Menu Item title
  content["Delete device(s)"] =
  "Suprimir dispositivos";

  //Delete device(s) Menu Item title
  content["Deploy Robot(s)"] =
  "Implementar robots";

  // END OF LOCALIZATION

export default content;
