/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
//Open Alarms
export const REQUEST_OPEN_ALARMS = 'REQUEST_OPEN_ALARMS'
export const FETCH_OPEN_ALARMS = 'FETCH_OPEN_ALARMS'
export const FETCH_OPEN_ALARMS_SUCCESS = 'FETCH_OPEN_ALARMS_SUCCESS'
export const FETCH_OPEN_ALARMS_FAILURE = 'FETCH_OPEN_ALARMS_FAILURE'
export const POLL_OPEN_ALARMS_START = 'POLL_OPEN_ALARMS_START'
export const POLL_OPEN_ALARMS_STOP = 'POLL_OPEN_ALARMS_STOP'

//Active Devices
export const REQUEST_ACTIVE_DEVICES = 'REQUEST_ACTIVE_DEVICES'
export const FETCH_ACTIVE_DEVICES = 'FETCH_ACTIVE_DEVICES'
export const FETCH_ACTIVE_DEVICES_SUCCESS = 'FETCH_ACTIVE_DEVICES_SUCCESS'
export const FETCH_ACTIVE_DEVICES_FAILURE = 'FETCH_ACTIVE_DEVICES_FAILURE'
export const POLL_ACTIVE_DEVICES_START = 'POLL_ACTIVE_DEVICES_START'
export const POLL_ACTIVE_DEVICES_STOP = 'POLL_ACTIVE_DEVICES_STOP'

//Role Devices
export const REQUEST_ROLE_DEVICES = 'REQUEST_ROLE_DEVICES'
export const FETCH_ROLE_DEVICES = 'FETCH_ROLE_DEVICES'
export const FETCH_ROLE_DEVICES_SUCCESS = 'FETCH_ROLE_DEVICES_SUCCESS'
export const FETCH_ROLE_DEVICES_FAILURE = 'FETCH_ROLE_DEVICES_FAILURE'
export const POLL_ROLE_DEVICES_START = 'POLL_ROLE_DEVICES_START'
export const POLL_ROLE_DEVICES_STOP = 'POLL_ROLE_DEVICES_STOP'

//Technologies
export const REQUEST_TOP_TECHNOLOGIES = 'REQUEST_TOP_TECHNOLOGIES'
export const FETCH_TOP_TECHNOLOGIES = 'FETCH_TOP_TECHNOLOGIES'
export const FETCH_TOP_TECHNOLOGIES_SUCCESS = 'FETCH_TOP_TECHNOLOGIES_SUCCESS'
export const FETCH_TOP_TECHNOLOGIES_FAILURE = 'FETCH_TOP_TECHNOLOGIES_FAILURE'
export const POLL_TOP_TECHNOLOGIES_START = 'POLL_TOP_TECHNOLOGIES_START'
export const POLL_TOP_TECHNOLOGIES_STOP = 'POLL_TOP_TECHNOLOGIES_STOP'

//Top Groups
export const REQUEST_TOP_GROUPS = 'REQUEST_TOP_GROUPS'
export const FETCH_TOP_GROUPS = 'FETCH_TOP_GROUPS'
export const FETCH_TOP_GROUPS_SUCCESS = 'FETCH_TOP_GROUPS_SUCCESS'
export const FETCH_TOP_GROUPS_FAILURE = 'FETCH_TOP_GROUPS_FAILURE'
export const POLL_TOP_GROUPS_START = 'POLL_TOP_GROUPS_START'
export const POLL_TOP_GROUPS_STOP = 'POLL_TOP_GROUPS_STOP'

//Top Devices
export const REQUEST_TOP_DEVICES = 'REQUEST_TOP_DEVICES'
export const FETCH_TOP_DEVICES = 'FETCH_TOP_DEVICES'
export const FETCH_TOP_DEVICES_SUCCESS = 'FETCH_TOP_DEVICES_SUCCESS'
export const FETCH_TOP_DEVICES_FAILURE = 'FETCH_TOP_DEVICES_FAILURE'
export const POLL_TOP_DEVICES_START = 'POLL_TOP_DEVICES_START'
export const POLL_TOP_DEVICES_STOP = 'POLL_TOP_DEVICES_STOP'

export const HOME_SCREEN_COMPONENTS_CHANGE = 'HOME_SCREEN_COMPONENTS_CHANGE'
