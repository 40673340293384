/*
 * Copyright © 2024 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  FETCH_GROUPSTACK,
  FETCH_GROUPSTACK_SUCCESS,
  FETCH_GROUPSTACK_FAILURE,
  FETCH_STATE,
  FETCH_STATE_SUCCESS,
  FETCH_STATE_FAILURE,
  FETCH_COUNT_STATE,
  FETCH_COUNT_STATE_SUCCESS,
  FETCH_COUNT_STATE_FAILURE,
  SET_TREE_NODE_SELECTED,
  REFRESH_TREE,
  TOGGLE_ADD_GROUP_DIALOG,
  DELETE_GROUP_DIALOG,
  TOGGLE_ADD_DEVICE_TO_GROUP,
  TOGGLE_EDIT_SUB_GROUP
} from "./actionTypes";
import entityTypes from "../../api/entityTypes";
import { isEqual } from "../../utils/lodash-utils";

const tree_view = (
  state = {
    isOpenAddGroupDialog: false,
    isFetchingGroup: false,
    isFetchingState: false,
    isFetchingCount: false,
    didInvalidateGroupCall: false,
    didInvalidateStateCall: false,
    didInvalidateCountCall: false,
    refreshTree: false,
    groups: [],
    stateData: [],
    countData: {},
    groupError: "",
    stateError: "",
    countError: "",
    treeNode: {
      entityType: entityTypes.ROOT,
      isInterfaceGroup: false,
      element_id: 0,
    },
  },
  action
) => {
  switch (action.type) {
    case FETCH_GROUPSTACK:
      return Object.assign({}, state, {
        isFetchingGroup: true,
        didInvalidateGroupCall: false,
      });
    case FETCH_GROUPSTACK_SUCCESS:
      if (action.isPoller) {
        const groupsInfo = state.groups;
        action.groups[0] &&  action.groups[0].children.map((child) => {
          if(groupsInfo[0] && groupsInfo[0].children) {
            const cs = groupsInfo[0].children.find((device) => {
             return child.id === device.id
            });

            child.computerSystem = cs?.computerSystem ? cs?.computerSystem : [];
            child.interfaceSystem = cs?.interfaceSystem ? cs?.interfaceSystem : [];
          }
        });

        //console.log("isEquals tree states: " + isEqual(groupsInfo, action.groups));
        if(sessionStorage.getItem('enableConsoleLogs') === 'true') {
            //console.log('tree-updated state: ' + JSON.stringify(action.groups));
            //console.log('tree-old state: ' + JSON.stringify(groupsInfo));
          }
        if (isEqual(groupsInfo, action.groups)) {
          return state;
        } else {
          return Object.assign({}, state, {
            groups: action.groups,
          });
        }
      } else {
        return Object.assign({}, state, {
          isFetchingGroup: false,
          didInvalidateGroupCall: false,
          groups: action.groups,
        });
      }
    case FETCH_GROUPSTACK_FAILURE:
      return Object.assign({}, state, {
        isFetchingGroup: false,
        didInvalidateGroupCall: true,
        groupError: action.errorMessage,
        // groups: [],
      });
    case FETCH_STATE:
      return Object.assign({}, state, {
        isFetchingState: true,
        didInvalidateStateCall: false,
      });
    case FETCH_STATE_SUCCESS:
      if (action.isPoller) {
        //console.log("isEquals stateData: " + isEqual(state.stateData, action.stateData));
        if (isEqual(state.stateData, action.stateData)) {
          return state;
        } else {
          return Object.assign({}, state, {
            stateData: action.stateData,
          });
        }
      } else {
        return Object.assign({}, state, {
          isFetchingState: false,
          didInvalidateStateCall: false,
          stateData: action.stateData,
        });
      }
    case FETCH_STATE_FAILURE:
      return Object.assign({}, state, {
        isFetchingState: false,
        didInvalidateStateCall: true,
        stateError: action.errorMessage,
        // stateData: [],
      });
    case FETCH_COUNT_STATE:
      return Object.assign({}, state, {
        isFetchingCount: true,
        didInvalidateCountCall: false,
      });
    case FETCH_COUNT_STATE_SUCCESS:
      if (action.isPoller) {
        //console.log("isEquals countData: " + isEqual(state.countData, action.countData));
        if (isEqual(state.countData, action.countData)) {
          return state;
        } else {
          return Object.assign({}, state, {
            countData: action.countData,
          });
        }
      } else {
        return Object.assign({}, state, {
          isFetchingCount: false,
          didInvalidateCountCall: false,
          countData: action.countData,
        });
      }
    case FETCH_COUNT_STATE_FAILURE:
      return Object.assign({}, state, {
        isFetchingCount: false,
        didInvalidateCountCall: true,
        countError: action.errorMessage,
        // countData: [],
      });
    case SET_TREE_NODE_SELECTED:
      return Object.assign({}, state, {
        treeNode: { ...action.treeNode },
      });
    case REFRESH_TREE:
      return Object.assign({}, state, {
        refreshTree: action.refreshTree,
      });
    case TOGGLE_ADD_GROUP_DIALOG:
      return Object.assign({}, state, {
        isToggleAddGroupDialog: action.isToggleAddGroupDialog,
      });

    case DELETE_GROUP_DIALOG:
      return Object.assign({}, state, {
        isDeleteGroupDialog: action.isDeleteGroupDialog,
      });

    case TOGGLE_ADD_DEVICE_TO_GROUP:
      return Object.assign({}, state, {
        toggleAddDeviceToGroup: action.toggleAddDeviceToGroup,
      });

    case TOGGLE_EDIT_SUB_GROUP:
      return Object.assign({}, state, {
        toggleEditSubGroup: action.toggleEditSubGroup,
      });
    default:
      return state;
  }
};

export default tree_view;
