/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import axios from "axios";


// we have to renew the sid for every request because the alarm api is not under the same namespace


export class Devices {
  static getDevices(options, groupId) {
    options.id = options.profileId;
    return axios.all([
      axios.post(
        process.env.NODE_ENV === "development" ?
          "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getDevicesSummary" :
          groupId ?
            `/mcs-ui-app/api/mcsDashboard/getDevicesSummary?groupId=${groupId}` :
            "/mcs-ui-app/api/mcsDashboard/getDevicesSummary", options
      ),
      axios.get(
        process.env.NODE_ENV === "development" ?
          "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getAllDevicesCounts" :
          groupId ?
            `/mcs-ui-app/api/mcsDashboard/getAllDevicesCounts?groupId=${groupId}` :
            "/mcs-ui-app/api/mcsDashboard/getAllDevicesCounts"
      ),
      axios.get(
        process.env.NODE_ENV === "development" ?
          "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getAllDeviceProfilesCounts" :
          groupId ?
            `/mcs-ui-app/api/mcsDashboard/getAllDeviceProfilesCounts?groupId=${groupId}` :
            "/mcs-ui-app/api/mcsDashboard/getAllDeviceProfilesCounts"
      ),
    ])
      .then(
        axios.spread(
          (devicesSummary, devicePercentages, deviceProfilePercentages) => {
            let devicesInfo = { data: [] };

            devicesInfo.data.data = devicesSummary.data;
            devicesInfo.data.options = options;
            devicesInfo.data.devicePercentages = devicePercentages.data;
            /* devicesInfo.data.pageNumber=options.pageNumber;
devicesInfo.data.totalItems=99;
devicesInfo.data.filteredItems=5;*/
            devicesInfo.data.deviceProfilePercentages =
              deviceProfilePercentages.data;

            return devicesInfo;
          }
        )
      )
      .catch((error) => {
        error.options = options;
        throw error;
      })

    /*console.log(`getGroups`+options)
    if(options.status!==undefined && options.status!=='')
    {
      //return this.getDevicesByStatus(options.status)
      return  _renewSid('get').then(headers => 
            axios.all([
    axios.get(`/mcs-ui-app/api/mcsDashboard/getAllDevicesSummaryByStatus?status=`+ options.status),
    axios.get('/mcs-ui-app/api/mcsDashboard/getAllDevicesCounts'),
      axios.get('/mcs-ui-app/api/mcsDashboard/getAllDeviceProfilesCounts')
    

  ])
  .then(axios.spread((devicesSummary, devicePercentages,deviceProfilePercentages) => {
      let devicesInfo={data:[]};
      devicesInfo.data.data=devicesSummary.data;
      devicesInfo.data.devicePercentages=devicePercentages.data;
      devicesInfo.data.deviceProfilePercentages=deviceProfilePercentages.data;
      

    return devicesInfo;
  })))
    }
    else 
    {
    //return this.getAllDevicesSummary(options)
      return  _renewSid('get').then(headers => 
            axios.all([
    axios.get(`/mcs-ui-app/api/mcsDashboard/getAllDevicesSummary?pageSize=`+options.pageSize+`&page=`+options.pageNumber),
    axios.get('/mcs-ui-app/api/mcsDashboard/getAllDevicesCounts'),
      axios.get('/mcs-ui-app/api/mcsDashboard/getAllDeviceProfilesCounts')
    

  ])
  .then(axios.spread((devicesSummary, devicePercentages,deviceProfilePercentages) => {
      let devicesInfo={data:[]};
      
      devicesInfo.data.data={data:devicesSummary.data,totalCount:99};
      devicesInfo.data.devicePercentages=devicePercentages.data;
      devicesInfo.data.pageNumber=options.pageNumber;
      devicesInfo.data.totalItems=99;
      devicesInfo.data.filteredItems=5;
      devicesInfo.data.deviceProfilePercentages=deviceProfilePercentages.data;
      

    return devicesInfo;
  })))
    }*/
  }

  static getDeviceProfiles(options, groupId) {
    if (options.deviceId !== undefined && options.deviceId !== "") {
      options.id = options.deviceId;
      //return this.getAllDeviceProfilesByDeviceId(options.deviceId)
      return axios.all([
        axios.post(
          process.env.NODE_ENV === "development" ?
            "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getDeviceProfilesSummary" :
            groupId ?
              `/mcs-ui-app/api/mcsDashboard/getDeviceProfilesSummary?groupId=${groupId}` :
              "/mcs-ui-app/api/mcsDashboard/getDeviceProfilesSummary",
          options
        ),
        axios.get(
          `/mcs-ui-app/api/mcsDashboard/getAllDeviceProfilesCountsByDeviceId?deviceId=` +
          options.deviceId
        ),
      ])
        .then(
          axios.spread((devicesSummary, deviceProfilePercentages) => {
            let devicesInfo = { data: [] };
            devicesInfo.data.data = devicesSummary.data;
            devicesInfo.data.options = options;
            //devicesInfo.data.totalItems=100;
            devicesInfo.data.deviceProfilePercentages =
              deviceProfilePercentages.data;
            //devicesInfo.data.totalItems=100;
            //devicesInfo.data.totalItems=99;

            return devicesInfo;
          })
        )
        .catch((error) => {
          error.options = options;
          throw error;
        })

    } else if (options.deviceId == undefined || options.deviceId == "") {
      //return this.getDeviceProfilesByStatus(options.status)
      return axios.post(process.env.NODE_ENV === "development" ?
        "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getDeviceProfilesSummary" :
        groupId ?
          `/mcs-ui-app/api/mcsDashboard/getDeviceProfilesSummary?groupId=${groupId}` :
          "/mcs-ui-app/api/mcsDashboard/getDeviceProfilesSummary",
        options
      )
        .then((devicesSummary) => {
          let devicesInfo = { data: [] };
          devicesInfo.data.data = devicesSummary.data;
          devicesInfo.data.options = options;
          return devicesInfo;
        })
        .catch((error) => {
          error.options = options;
          throw error;
        })

    }
    /*else if(options.status!==undefined && options.status!=='' && options.deviceId!==undefined && options.deviceId!=='')
    {
    //return this.getDeviceProfilesByStatusAndDeviceId(options.status,options.groupId)
       var params = new URLSearchParams();
           params.append("deviceId", options.deviceId);
           params.append("status", options.status);

          var request = {
          params: params
          };
      

        return  _renewSid('get').then(headers => 
              axios.all([
      axios.get(`/mcs-ui-app/api/mcsDashboard/getDeviceProfilesByStatusAndDeviceId`,request),
      axios.get(`/mcs-ui-app/api/mcsDashboard/getAllDeviceProfilesCountsByDeviceId?deviceId=` + options.deviceId)
      

    ])
    .then(axios.spread((devicesSummary, deviceProfilePercentages) => {
        let devicesInfo={data:[]};
        devicesInfo.data.data=devicesSummary.data;     
        devicesInfo.data.deviceProfilePercentages=deviceProfilePercentages.data;
        

      return devicesInfo;
    })))

    }*/
  }

  static getDeviceProfileByProfileId(profileId) {
    return axios.get(
      `/mcs-ui-app/api/mcsDashboard/getDeviceProfile?profileId=` + profileId
    )

  }

  /* getAllDevicesSummary(options) {
        
console.log(options);
let devicesInfo={data:[]};
let dev={data: [{"groupName":null,"groupId":null,"cmGrpId":null,"deviceName":"ak652960-cdev","csId":1,"deviceProfileCount":null,"deviceCount":null,"groupProfileCount":null,"status":"Deployed","ancestorsGrpId":null,"groupProfileId":null,"profileName":"kolas01cred1","deviceProfileId":null,"latestDeploymentSummary":"Successfully DEPLOYED profile kolas01cred1(16) in -8770025ms to /ak652960-UIM_domain/ak652960-UIM_hub/ak652960-cdev/rsp","retries":1,"updated":1566380971213,"deployed":5,"pending":0,"failed":0,"total":5,"lastDeployedDate":"2019-08-21 17:49:31.187","totalRows":100,"na":0}
,{"groupName":null,"groupId":null,"cmGrpId":null,"deviceName":"ak652960-UIM","csId":2,"deviceProfileCount":null,"deviceCount":null,"groupProfileCount":null,"status":"Unknown","ancestorsGrpId":null,"groupProfileId":null,"profileName":null,"deviceProfileId":null,"latestDeploymentSummary":null,"retries":0,"updated":null,"deployed":0,"pending":0,"failed":0,"total":0,"lastDeployedDate":null,"totalRows":100,"na":0},
{"groupName":null,"groupId":null,"cmGrpId":null,"deviceName":"ak652960-UIM","csId":2,"deviceProfileCount":null,"deviceCount":null,"groupProfileCount":null,"status":"Unknown","ancestorsGrpId":null,"groupProfileId":null,"profileName":null,"deviceProfileId":null,"latestDeploymentSummary":null,"retries":0,"updated":null,"deployed":0,"pending":0,"failed":0,"total":0,"lastDeployedDate":null,"totalRows":100,"na":0},
{"groupName":null,"groupId":null,"cmGrpId":null,"deviceName":"ak652960-UIM","csId":2,"deviceProfileCount":null,"deviceCount":null,"groupProfileCount":null,"status":"Unknown","ancestorsGrpId":null,"groupProfileId":null,"profileName":null,"deviceProfileId":null,"latestDeploymentSummary":null,"retries":0,"updated":null,"deployed":0,"pending":0,"failed":0,"total":0,"lastDeployedDate":null,"totalRows":100,"na":0},
{"groupName":null,"groupId":null,"cmGrpId":null,"deviceName":"ak652960-dev","csId":3,"deviceProfileCount":null,"deviceCount":null,"groupProfileCount":null,"status":"Deployed","ancestorsGrpId":null,"groupProfileId":null,"profileName":"Memory Monitor (Enhanced)","deviceProfileId":null,"latestDeploymentSummary":"Successfully DEPLOYED profile Memory Monitor (Enhanced)(13) in 1733ms to /ak652960-UIM_domain/ak652960-dev_hub/ak652960-dev/cdm","retries":0,"updated":1565958067917,"deployed":3,"pending":0,"failed":0,"total":3,"lastDeployedDate":"2019-08-16 20:21:07.9","totalRows":100,"na":0}],
       devicePercentages: {"groupId":0,"csId":0,"profileId":0,"deployed":3.03,"pending":0.0,"failed":0.0,"total":99,"profileCount":0,"deviceCount":0,"name":null,"na":96.97},
        deviceProfilePercentages: {"groupId":0,"csId":0,"profileId":0,"deployed":100.0,"pending":0.0,"failed":0.0,"total":15,"profileCount":0,"deviceCount":0,"name":null,"na":0.0},
        totalItems: 99};

devicesInfo.data.data=dev.data;
devicesInfo.data.devicePercentages=dev.devicePercentages;
devicesInfo.data.pageNumber=options.pageNumber;
devicesInfo.data.totalItems=99;
devicesInfo.data.filteresItems=5;
devicesInfo.data.deviceProfilePercentages=dev.deviceProfilePercentages;
return devicesInfo;
        return  _renewSid('get').then(headers => 
            axios.all([
    axios.get(`/mcs-ui-app/api/mcsDashboard/getAllDevicesSummary?pageSize=`+options.pageSize+`&page=`+options.pageNumber),
    axios.get('/mcs-ui-app/api/mcsDashboard/getAllDevicesCounts'),
      axios.get('/mcs-ui-app/api/mcsDashboard/getAllDeviceProfilesCounts')
    

  ])
  .then(axios.spread((devicesSummary, devicePercentages,deviceProfilePercentages) => {
      let devicesInfo={};
      
      devicesInfo.data=devicesSummary.data;
      devicesInfo.data.devicePercentages=devicePercentages.data;
      devicesInfo.data.pageNumber=options.pageNumber;
      devicesInfo.data.totalItems=99;
      devicesInfo.data.filteresItems=5;
      devicesInfo.data.deviceProfilePercentages=deviceProfilePercentages.data;
      

    return devicesInfo;
  })))

  }
	
  getAllDeviceProfilesByDeviceId(deviceId) {
       
        return  _renewSid('get').then(headers => 
            axios.all([
    axios.get(`/mcs-ui-app/api/mcsDashboard/getDeviceProfiles?deviceId=` + deviceId),
    axios.get(`/mcs-ui-app/api/mcsDashboard/getAllDeviceProfilesCountsByDeviceId?deviceId=` + deviceId)
    

  ])
  .then(axios.spread((devicesSummary, deviceProfilePercentages) => {
      let devicesInfo={};
      devicesInfo.data=devicesSummary.data;     
      devicesInfo.data.deviceProfilePercentages=deviceProfilePercentages.data;
      

    return devicesInfo;
  })))
    }
   

  getAllDeviceProfilesSummary() {
        return _renewSid('get').then(headers => axios.get(`/mcs-ui-app/api/mcsDashboard/getAllDeviceProfiles`))
        }
        
    getDevicesByStatus(status){


        return  _renewSid('get').then(headers => 
            axios.all([
    axios.get(`/mcs-ui-app/api/mcsDashboard/getAllDevicesSummaryByStatus?status=`+ status),
    axios.get('/mcs-ui-app/api/mcsDashboard/getAllDevicesCounts'),
      axios.get('/mcs-ui-app/api/mcsDashboard/getAllDeviceProfilesCounts')
    

  ])
  .then(axios.spread((devicesSummary, devicePercentages,deviceProfilePercentages) => {
      let devicesInfo={};
      devicesInfo.data=devicesSummary.data;
      devicesInfo.data.devicePercentages=devicePercentages.data;
      devicesInfo.data.deviceProfilePercentages=deviceProfilePercentages.data;
      

    return devicesInfo;
  })))
    }

    getDeviceProfilesByStatus(status){
        return _renewSid('get').then(headers => axios.get(`/mcs-ui-app/api/mcsDashboard/getDeviceProfilesByStatus?status=`+ status))
    }
    getDeviceProfilesByStatusAndDeviceId(status,deviceId)
    {
         var params = new URLSearchParams();
         params.append("deviceId", deviceId);
         params.append("status", status);

        var request = {
        params: params
        };
    

      return  _renewSid('get').then(headers => 
            axios.all([
    axios.get(`/mcs-ui-app/api/mcsDashboard/getDeviceProfilesByStatusAndDeviceId`,request),
    axios.get(`/mcs-ui-app/api/mcsDashboard/getAllDeviceProfilesCountsByDeviceId?deviceId=` + deviceId)
    

  ])
  .then(axios.spread((devicesSummary, deviceProfilePercentages) => {
      let devicesInfo={};
      devicesInfo.data=devicesSummary.data;     
      devicesInfo.data.deviceProfilePercentages=deviceProfilePercentages.data;
      

    return devicesInfo;
  })))

	
    }*/
}
