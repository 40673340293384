/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Tooltip,
  FormControl,
  Select,
  MenuItem, InputLabel
} from "@mineral/core";
import { useDispatch, useSelector } from "react-redux";
import { Close as CloseIcon } from "@material-ui/icons";
import "./QosExportChartDialog.less";
import moment from "moment";
import { PRD } from "../../../../../../../api/performanceReports/prd";
import { isArray } from "../../../../../../../utils/lodash-utils";
import {
  EXPORT_PREFERENCES,
  EXPORT_CSV,
  CANCEL,
  AGG_INTERVAL,
  DATA_SERIES_CNT,
  DATA_POINTS_CNT,
  QOS_NONE,
} from "./QosExportChartDialogConstants";

const QosExportChartDialog = (props) => {
	const dispatch = useDispatch();
	const QosChartAggIntInSec = useSelector(
		(state) => state.performanceReports.chartData.State.aggregationInterval
	);
	const QosChartAggInt = useSelector(
		(state) => state.performanceReports.chartData.State.aggLevel
	);
	const QosChartTimeStart = useSelector(
		(state) => state.performanceReports.chartData.State.TimeStart
	);
	const QosChartTimeStop = useSelector(
		(state) => state.performanceReports.chartData.State.TimeStop
	);
	const QosChartTimeDiff = moment(QosChartTimeStop).diff(
		moment(QosChartTimeStart),
		"minutes"
	);
	const [chartAggInt, setChartAggInt] = React.useState(QosChartAggInt);
	const [chartAggIntSec, setChartAggIntSec] =
		React.useState(QosChartAggIntInSec);
	const [aggregation, setExportAggregation] =
		React.useState(QosChartAggIntInSec);
	const [seriesData, setSeries] = React.useState([]);
	const [seriesInfo, setSeriesInfo] = React.useState([]);
	const [dataPointCount, setDataPointCount] = React.useState(0);
	const [numberOfSeries, setNumberOfSeries] = React.useState(0);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		let aggregationInterval = "1 minute";
		if (QosChartAggInt == "Automatic") {
			if (QosChartTimeDiff <= 60) {
				aggregationInterval = "1 minute";
			} else if (QosChartTimeDiff > 60 && QosChartTimeDiff <= 1440) {
				aggregationInterval = "5 minutes";
			} else if (QosChartTimeDiff > 1440 && QosChartTimeDiff <= 10080) {
				aggregationInterval = "30 minutes";
			} else if (QosChartTimeDiff > 10080) {
				aggregationInterval = "2 hours";
			}
		} else if (QosChartAggInt != QOS_NONE) {
			aggregationInterval = QosChartAggInt;
		}
		setChartAggInt(aggregationInterval);
		setChartAggIntSec(QosChartAggIntInSec);
		setExportAggregation(QosChartAggIntInSec);
	}, [props.aggLevel, props.startTime]);

	const getOptimizedInterval = (sampleData, aggregation) => {
		let aggInterval = aggregation;
		if (aggregation == 0) {
			for (let i = 0; i < sampleData.length; i++) {
				let dataArray = sampleData[i].data.samplingData;
				if (dataArray && dataArray.length >= 2) {
					if (aggInterval === 0) {
						aggInterval = dataArray[1].t - dataArray[0].t;
					} else {
						let newAggInterval = dataArray[1].t - dataArray[0].t;
						aggInterval =
							newAggInterval < aggInterval ? newAggInterval : aggInterval;
					}
				}
			}
			aggInterval = Math.round(aggInterval / 1000);
		}

		return aggInterval;
	};
	const exportData = () => {
		createAndOpenCSVFile("download");
		closeChartDialog();
	};

	const copyData = () => {
		createAndOpenCSVFile("copy");
	};
	const createAndOpenCSVFile = async (copyOrExport) => {
		let csvData = [];
		let csvScale = [];
		let columnObject = {};
		for (let i = 0; i < seriesData.length; i++) {
			let filterData = seriesData[i];
			let filterScaleData = filterData.scale > 0 ? filterData.scale : 1; // data scale
			csvScale.push(filterScaleData);

			columnObject = {
				...columnObject,
				[`${filterData.source}|${filterData.qos}|${filterData.target}|${i + 1
					}`]: null,
			};
		}
		for (let i = 0; i < seriesData.length; i++) {
			let filterData = seriesData[i];
			filterData.samplingData.map((data) => {
				let existingObjectIndex = -1;
				let dataAvg = data.avg != null ? data.avg * csvScale[i] : null; // avg*scale

				if (data.t != null)
					existingObjectIndex = csvData.findIndex(
						(d) => d.time == moment(data.t).format("MM/DD/YYYY HH:mm:ss")
					);

				if (existingObjectIndex != -1) {
					csvData[existingObjectIndex] = {
						...csvData[existingObjectIndex],
						[`${filterData.source}|${filterData.qos}|${filterData.target}|${i + 1
							}`]: dataAvg,
					};
				} else {
					csvData.push({
						time:
							data.t != null
								? moment(data.t).format("MM/DD/YYYY HH:mm:ss")
								: null,
						...columnObject,
						[`${filterData.source}|${filterData.qos}|${filterData.target}|${i + 1
							}`]: dataAvg,
					});
				}
			});
		}
		function convertToCSV(arr) {
			const array = [Object.keys(arr[0])].concat(arr);
			return array
				.map((it) => {
					return Object.values(it).toString();
				})
				.join("\n");
		}
		function downloadCSV(filename, text) {
			var element = document.createElement("a");
			element.setAttribute(
				"href",
				"data:text/plain;charset=utf-8," + encodeURIComponent(text)
			);
			element.setAttribute("download", filename);
			element.style.display = "none";
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		}
		function copyChartDataToClipboard(textToCopy) {
			props.copiedSnackbar(true); // Open Snackbar message
			if (navigator.clipboard && window.isSecureContext)
				return navigator.clipboard.writeText(textToCopy);
			// navigator clipboard api method
			else
				return new Promise((res, rej) => {
					document.execCommand("copy") ? res() : rej();
				});
		}

		if (csvData.length == 0) {
			return;
		}

		if (copyOrExport === "copy")
			copyChartDataToClipboard(convertToCSV(csvData));
		// Copy url to clipboard
		else
			downloadCSV(`${props.chartData.State.title}.csv`, convertToCSV(csvData)); // Download CSV
	};
	const closeChartDialog = () => {
		setLoading(false)
		props.handleExportChartDialogClose();
	};
	useEffect(() => {
		let seriesInfo = props.chartObject.series ? props.chartObject.series : [];
		setSeriesInfo(seriesInfo);
		calculateSeriesData(seriesInfo, aggregation);
	}, [props.chartObject, props.aggLevel, props.startTime]);

	const calculateSeriesData = async (seriesInfo, aggregationInterval) => {
		setLoading(true);
		let seriesUrls = [];
		seriesInfo.forEach((urlRequest, index) => {
			seriesUrls.push(
				PRD.getchartSeriesData({
					...urlRequest,
					startTime: QosChartTimeStart,
					stopTime: QosChartTimeStop,
					interval: aggregationInterval,
					source: urlRequest.displayName.split("|")[0],
					qos: urlRequest.displayName.split("|")[1],
					target: urlRequest.displayName.split("|")[2],
					pla: null,
					ptile: null,
				})
			);
		});
		let resolvedData = [];
		try {
			resolvedData = await Promise.all(seriesUrls);
		} catch (e) {
			setLoading(false);
			setNumberOfSeries(0);
			setSeries([]);
			setDataPointCount(0);

			return;
		}
		setNumberOfSeries(seriesInfo.length);
		let finalSeriesChartData = [];
		let dataPointCount = 0;
		let samplingInterval = getOptimizedInterval(
			resolvedData,
			aggregationInterval
		);
		for (let i = 0; i < resolvedData.length; i++) {
			let chartData = {};
			if (
				resolvedData[i].data.samplingData &&
				resolvedData[i].data.samplingData != null &&
				(isArray(resolvedData[i].data.samplingData)
					? resolvedData[i].data.samplingData.length > 0
					: false)
			) {
				chartData = resolvedData[i].data;
				
				//dataInterPolation not required in UI as its done in Backend
				// chartData.samplingData = dataInterPolation(
				// 	resolvedData[i].data.samplingData,
				// 	samplingInterval
				// );
			} else {
				chartData = {
					dataAvg: null,
					dataMin: null,
					dataMax: null,
					dataStdDev: null,
					samplingData: [],
				};
			}
			dataPointCount += chartData.samplingData.length;
			finalSeriesChartData.push({
				...chartData,
				...seriesInfo[i],
				source: seriesInfo[i].source,
				qos: seriesInfo[i].qos,
				target: seriesInfo[i].target,
				scale: seriesInfo[i].scale,
			});
		}
		setSeries(finalSeriesChartData);
		setDataPointCount(dataPointCount);
		setLoading(false);
	};
	return (
		<div>
			<Dialog

				open={props.open}
				fullWidth={true}
				maxWidth={"sm"}
				onClose={closeChartDialog}
			>
				<DialogTitle component="h1" disableTypography>

					<Typography variant="h2" component="div">

						{EXPORT_PREFERENCES}: {props.chartObject.title}

					</Typography>
				</DialogTitle>

				<div style={{ height: '0px' }}>
					<Tooltip title="Close">
						<IconButton autoFocus style={{ float: 'right', top: '-50px', marginRight: '8px' }}
							aria-label="close"
							onClick={closeChartDialog}
						>
							<CloseIcon />
						</IconButton>
					</Tooltip>
				</div>
				<DialogContent dividers={true}>
					{!loading ? (
						<>
							{QosChartAggInt && QosChartAggInt != QOS_NONE && (
								<div>

									<div>
										<FormControl FullWidth>
											<InputLabel id="chart-agg-interval-label" htmlFor="chart-agg-interval">{AGG_INTERVAL}</InputLabel>
											<Select labelId="chart-agg-interval-label"
												id="chart-agg-interval"
												role='combobox'

												value={aggregation}
												onChange={(event) => {
													setExportAggregation(event.target.value);
													calculateSeriesData(seriesInfo, event.target.value);
												}}
												fullWidth


												aria-labelledby="chart-agg-interval-label"

												inputProps={{
													'aria-label': AGG_INTERVAL,
													role: 'combobox',
												}}
												MenuProps={{
													MenuListProps: {
														'aria-label': `Aggregation Interval options list`,
														'aria-labelledby': "chart-agg-interval-label",

													}
												}}

											>
												<MenuItem

													key="chart-agg-interval-0"
													value={0}
												>
													{QOS_NONE}
												</MenuItem>

												<MenuItem

													key="chart-agg-interval-1"
													value={chartAggIntSec}
												>
													{chartAggInt}
												</MenuItem>
											</Select>
										</FormControl>
									</div>
								</div>
							)}
							<div>
								<p>
									{DATA_SERIES_CNT}: {numberOfSeries}
								</p>
							</div>
							<div>
								<p>
									{DATA_POINTS_CNT}: {dataPointCount}
								</p>
							</div>
						</>
					) : (
						<div
							style={{
								width: "100%",
								height: "200px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							{" "}
							<CircularProgress style={{ color: "#3272D9" }} />{" "}
						</div>
					)}
				</DialogContent>
				{!loading && (
					<DialogActions>
						<Button onClick={closeChartDialog} color="primary">
							{CANCEL}
						</Button>
						<Tooltip title={window.location.protocol !== "https:" ? "Copy data feature only supports in https." : "Copy chart data to clipboard"}>
							<div><Button
								color="primary"
								variant="contained"
								disabled={
									props.filteredChartData.length > 0 || dataPointCount > 0
										? window.location.protocol !== "https:" ? "disabled" : ""
										: "disabled"
								}
								onClick={copyData}
							>
								Copy Data
							</Button></div>
						</Tooltip>
						<Button
							color="primary"
							variant="contained"
							disabled={
								props.filteredChartData.length > 0 || dataPointCount > 0
									? ""
									: "disabled"
							}
							style={{ textTransform: "none", marginRight: 0 }}
							onClick={exportData}
						>
							{EXPORT_CSV}
						</Button>
					</DialogActions>
				)}
			</Dialog>

		</div>
	);
};

export default QosExportChartDialog;
