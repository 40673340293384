/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component, useMemo, useCallback } from "react";
import connect from "./../../../utils/connect";
import {
  CenterError as Error,
  CenterInfo,
  ClearIcon as Clear,
} from "../../ui-components/uim-components";
import { Card, IconButton, Button, CircularProgress, Tooltip as MTooltip } from "@mineral/core";
import {
  Cell,
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";
import Resource from "./Resource";
import {
  fetchRoleDevicespoll,
  pollRoleDevicesStop,
} from "./../../../api/home-screen/actions";

const chartColors = [
  "#E91E63",
  "#FF9800",
  "#009688",
  "#2196F3",
  "#A11D2E",
  "#F7922F",
  "#FEE276",
  "#9EC6D0",
  "#6200EA",
  "#1A237E",
  "#006064",
  "#F57F17",
  "#795548",
];
const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul tabIndex={0}>
      {
        payload.map((entry, index) => (
          <li key={`item-${index}`}>{entry.value}</li>
        ))
      }
    </ul>
  );
}

let LegendSquare = (props) => {
  return (
    <div
      aria-label={props.entityId}
      onClick={props.onClick}
      //title={props.entityId}
      title={props.entityVal}
      style={{
        padding: "0px",
        padding: "0px",
        background: "none",
        border: "none",
        paddingTop: "4px",
        //cursor: props.isFromOperatorConsole ? "auto" : "pointer",
      }}
    >
      <svg
        width="20"
        height="21"
        aria-label={props.entityId}
        //title={props.entityId}
        title={props.entityVal}
        onClick={props.onClick}
        viewBox="0 0 20 21"
        fill={props.fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1.5"
          width="18"
          height="18"
          rx="4"
          fill={props.fill}
          stroke="white"
          stroke-width="2"
        />
      </svg>
    </div>
  );
};

const RenderLegend = (props) => {
  const { payload } = props;
  /*
  value: entry.deviceRole + " " + entry.deviceCount,
      type: "rect",
      id: `${entry.deviceRole + "-" + index}`,
      color: `${chartColors[index]}`,*/
  return (
    <div style={{ height: props.legendHeight, marginLeft: '8px' }}>
      <table tabIndex={0} summary="Device By Role chart legend">

        {props?.legendData?.map((entry, index) => (
          <tr style={{ padding: "none" }}>
            <td>
              <LegendSquare
                entityId={entry.id}
                strokeDasharray={"1"}
                stroke={entry.color}
                strokeWidth={1}
                fill={entry.color}
                entityVal={entry.value}
              />
            </td>
            <td>
              <span
                entityId={entry.value}
                key={`devicebyrolelegenditem-${index}`}

                style={{
                  textAlign: "left",
                  fontStyle: "normal",
                  paddingRight: "30px",
                  fontWeight: "normal",
                  fontSize: "14px",
                  color: "#333840",
                  textTransform: "capitalize",
                }}
              >
                {entry.value}
              </span>
            </td>

          </tr>
        ))}
      </table>
    </div>
  );
};



const Panel = (props) => {
  const getMyColor = useCallback(() => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6);
  }, []);

  const randomColorsList = useMemo(() => {
    const list = [];
    for (let i = 0; i < props.data.length - chartColors.length; i++) {
      list.push(getMyColor());
    }
    return [...chartColors, ...list];
  }, []);

  useMemo(() => {
    if (props?.data) {
      props?.data.map((item, idx) => {
        item.deviceRoleDeviceCount = `${item.deviceRole}-${item.deviceCount}`;
      });
    }
  }, [props?.data]);

  //Device Count
  let deviceCountText = Resource.get("Device Count");
  let legendData = props.data.map((entry, index) => {
    return {
      value: entry.deviceRole + " " + entry.deviceCount,
      type: "rect",
      id: `${entry.deviceRole + "-" + index}`,
      color: `${randomColorsList[index]}`,
    };
  });
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="chartTooltip">
          <div>{`${label.split('-')[0]}`}</div>
          <div>{`${deviceCountText} : ${payload[0].value}`}</div>
        </div>
      );
    }

    return null;
  };
  return (
    <div
      style={{ flex: "90%", height: "100%", width: "100%" }}
      role="img"
      tabIndex={0}
      aria-label={
        "A bar graph showing device count statistics by role with Roles on X axis and device count on Y axis: " +
        props?.data?.map(
          (entry) => entry?.deviceRole + " Device Count " + entry?.deviceCount
        ) +
        " Click on bars to navigate to device of a specific role"
      }
    >
      <ResponsiveContainer>
        <BarChart data={props.data} style={{ background: "#f4f4f4" }}>
          <Bar dataKey="deviceCount" maxBarSize={70}>
            <LabelList dataKey="deviceCount" position="top" />
            {props.data.map((entry, index) => (
              <Cell
                className={"chart__severity--" + entry.deviceRole}
                key={`cell-${index}`}
                fill={randomColorsList[index]}
              />
            ))}
          </Bar>
          <Tooltip content={<CustomTooltip />} cursor={{ fill: "#dedede70" }} />
          <Legend content={
            <RenderLegend legendData={legendData} legendHeight={props.homeScreenComponents.showActiveDevices
              ? "16vh"
              : window.innerHeight < 800
                ? "30vh"
                : window.innerHeight == 800
                  ? "34vh"
                  : window.innerHeight < 1080
                    ? "35vh"
                    : "36vh"}

            />
          }
            layout="vertical"
            verticalAlign="middle"
            align="right"
            iconSize={12} />


          <XAxis
            tick={false}
            tickLine={false}
            dataKey="deviceRoleDeviceCount"
          />
          <YAxis />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

class PanelRedux extends Component {
  state = {
    data: [],
    loadStatus: "loading",
  };

  apiError = () => {
    this.setState({
      status: "failure",
    });
  };

  componentDidMount() {
    this.props.fetchRoleDevicespoll(
      this.props.groupIds ? this.props.groupIds : null,
      this.props.probeId ? this.props.probeId : null
    );
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data,
      loadStatus: nextProps.isRoleDevicesFetching ? "loading" : "complete",
    });
  }
  componentWillUnmount() {
    this.props.pollRoleDevicesStop();
  }
  render() {
    //No Roles found
    let noRolesText = Resource.get("No Roles found");
    //Devices By Roles
    let title = Resource.get("Devices By Roles");
    //View All
    let viewAllText = Resource.get("View All");
    let cardHeader = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="overview_container_title">{title}</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <MTooltip title={viewAllText}>
            <Button
              variant="text" style={{ height: '16px' }}
              aria-label="Click to view all devices"
              onClick={() => {
                this.props.history.push("/inventory");
              }}
            >
              {viewAllText}
            </Button>
          </MTooltip>
          <span className="overview_header_seprator" aria-label="Separator">|</span>
          <MTooltip title={"Close"}>
            <IconButton
              style={{ padding: 0 }}
              title={"Close"} aria-label="Close Devices By Roles"
              onClick={() => {
                this.props.handleCloseDialog("showDevicesByRoles");
              }}
            >

              <Clear className="clearIcon" role="figure" title="Close" />
            </IconButton>
          </MTooltip>
        </div>
      </div>
    );
    let view = <Panel data={this.state.data} {...this.props} />;
    if (
      this.state.loadStatus === "loading" &&
      !this.props.didRoleDevicesInvalidate
    ) {
      view = (
        <div
          className="no_data_panel"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#3272D9" }} />
        </div>
      );
    } else if (
      this.state.loadStatus === "failure" ||
      this.props.didRoleDevicesInvalidate
    ) {
      view = (
        <div className="no_data_panel" style={{ height: "140%" }}>
          <Error />
        </div>
      );
    } else if (
      this.state.loadStatus === "complete" &&
      this.state.data.length == 0
    ) {
      view = (
        <div className="no_data_panel" style={{ height: "140%" }}>
          <CenterInfo title={noRolesText} />
        </div>
      );
    }
    return (
      <Card
        className={this.props.className}
        // style={{
        //   width: null,
        //   margin: null,
        // }}
        style={{
          height: this.props.homeScreenComponents.showActiveDevices
            ? "30vh"
            : window.innerHeight < 800
              ? "34vh"
              : window.innerHeight == 800
                ? "38vh"
                : window.innerHeight < 1080
                  ? "39vh"
                  : "40vh",
          minHeight: "22vh",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          boxSizing: "border-box"
        }}
      >
        {cardHeader}
        {view}
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRoleDevicespoll: (groupIds, probeId) =>
      dispatch(fetchRoleDevicespoll(groupIds, probeId)),
    pollRoleDevicesStop: () => dispatch(pollRoleDevicesStop()),
  };
};
const mapStateToProps = (state) => {
  return {
    data: state.homeScreen.roleDevices,
    isRoleDevicesFetching: state.homeScreen.isRoleDevicesFetching,
    didRoleDevicesInvalidate: state.homeScreen.didRoleDevicesInvalidate,
    homeScreenComponents: state.homeScreen.homeScreenComponents,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelRedux); //connect so that we can get a handle on history
