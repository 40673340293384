/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import { Dialog, DialogTitle, Button as FlatButton, DialogActions, DialogContent, Tooltip, IconButton, Typography } from "@mineral/core";
import Resource from "./Resource";
import { deployRobot } from './../../api/robots/actions';
import connect from './../../utils/connect';
import { Cross as CloseIcon } from '@mineral/icons';
import "./dialogButtonStyle.less";
import { RobotIcon } from "../ui-components/uim-components";
import CustomSnackbar from "../common/CustomSnackbar";

const INSTALL_ROBOTS_OPEN = "installRobotOpen";
const INSTALL_ROBOTS_CLOSE = "installRobotClose";
const DELETE_DEVICE = "deleteDevice";

class InstallRobotDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
      openSnackBar: false,
    };
  }
  componentWillReceiveProps() {
    this.setState({
      disableButton: false,
    });
  }
  handleCloseSnackBar = () => {
    this.setState({ openSnackBar: false });
  };
  render() {
    let installLabel = Resource.get("Proceed"); //Proceed
    let actions = [
      //Cancel
      <FlatButton
        variant= "text"
        children={Resource.get("Cancel")}
        color = "primary"
        onClick={this.onClose}
      />,
      <FlatButton
        variant= "contained"
        children={installLabel}
        color = "primary"
        keyboardFocused={true}
        onClick={this.handleInstallRobots}
        disabled={this.state.disableButton}
        style={{color: 'white !important'}}
      />,
    ];
    let title = Resource.get("Deploy Robot"); //Deploy Robot
    /*if(this.props.selectedCsIds.length >1) {
    			title= `Deploy ${this.props.selectedCsIds.length} robots`
    		}
    		else {
    			//Deploy Robot
    			title = Resource.get('Deploy Robot')
    		} */

    let content = (
      <div className="dialog-content-message">
        <p>
          {
            // help message
            Resource.get("Click proceed to deploy robot in selected device(s)")
          }
        </p>
      </div>
    );

    return (
      <div>
        <CustomSnackbar
          open={this.state.openSnackBar}
          message={
            "Deployment is in progress. Your changes will be reflected in the system in a few moments."
          }
          autoHideDuration={4000}
          close={this.handleCloseSnackBar}
        />
        <Dialog
            modal={false}
            open={this.props.open}
            aria-labelledby="dialog-title"
            className= "dialogConatiner"
            style={{borderRadius: '0px'}}
          >
           <DialogTitle style={{padding: '0px 24px'}} component="div" disableTypography>
              <div className="dialogTitle">
                <div className="dialog-title-alignment" style={{display:'flex',flexDirection:'row'}}>
                  <span className="icon">
                    <RobotIcon title="robot" role="figure"/>
                  </span>
                    <Typography  variant="h3" component="h1">
                        {title}
                    </Typography>
                </div>
              </div>
            </DialogTitle>
            <div style={{height:'0px'}}>
            <Tooltip title="Close">
              <IconButton
              aria-label="close"

              onClick={this.onClose.bind(this)}
              style={{float: 'right', marginRight: '20px', marginTop: '-38px'}}
              autoFocus
              >
                <CloseIcon />
              </IconButton>
              </Tooltip>
            </div>
            <DialogContent>
            {content}
            <div>{this.errorMsg}</div>
            </DialogContent>
            <DialogActions>
              {actions}
            </DialogActions>
        </Dialog>
      </div>
    );
  }
  onClose = () => {
    this.errorMsg = null;
    this.props.handleClose(INSTALL_ROBOTS_CLOSE);
  };
  handleInstallRobots = () => {
    this.setState({
      disableButton: true,
    });
      this.props.deployRobot(this.props.selectedCsIds)
      .then((response) => {
        this.setState({ openSnackBar: true });
        this.props.setSelectedCsIds && this.props.setSelectedCsIds([]);
        this.props.handleClose(INSTALL_ROBOTS_OPEN);
      })
      .catch((error) => {
       // console.log("error in robot deployment", error);
        this.props.handleClose(INSTALL_ROBOTS_CLOSE);
      });
  };
}
InstallRobotDialog.defaultProps = {
  selectedCsIds: [],
};
const mapDispatchToProps = (dispatch) => {
  return {

    deployRobot: (data) => dispatch(deployRobot(data)),

  }
}
export default connect(null,mapDispatchToProps)(InstallRobotDialog);
