// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // No View Message
  content["You chose to hide all widgets. Use the Dashboard Options menu on the top right to restore them"] =
  "You chose to hide all widgets. Use the Dashboard Options menu on the top right to restore them";

  //Top 10 Entities
  content["Top 10 Entities"] =
  "Top 10 Entities";

  //By Severity, By Alarms
  content["By Severity, By Alarms"] =
  "By Severity, By Alarms";

  // END OF LOCALIZATION

export default content;
