/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
export const FETCH_DEVICES = "FETCH_DEVICES";
export const FETCH_DEVICES_SUCCESS = "FETCH_DEVICES_SUCCESS";
export const FETCH_DEVICES_FAILURE = "FETCH_DEVICES_FAILURE";
export const FETCH_SAVED_METRICS = "FETCH_SAVED_METRICS";
export const FETCH_SAVED_METRICS_SUCCESS = "FETCH_SAVED_METRICS_SUCCESS";
export const FETCH_SAVED_METRICS_FAILURE = "FETCH_SAVED_METRICS_FAILURE";
export const FETCH_DEVICE_METRICS = "FETCH_DEVICE_METRICS";
export const FETCH_DEVICE_METRICS_SUCCESS = "FETCH_DEVICE_METRICS_SUCCESS";
export const FETCH_DEVICE_METRICS_FAILURE = "FETCH_DEVICE_METRICS_FAILURE";
export const FETCH_GOLDEN_METRICS = "FETCH_GOLDEN_METRICS";
export const FETCH_GOLDEN_METRICS_SUCCESS = "FETCH_GOLDEN_METRICS_SUCCESS";
export const FETCH_GOLDEN_METRICS_FAILURE = "FETCH_GOLDEN_METRICS_FAILURE";
export const UPDATE_ALIAS = "UPDATE_ALIAS";
export const UPDATE_ALIAS_SUCCESS = "UPDATE_ALIAS_SUCCESS";
export const UPDATE_ALIAS_FAILURE = "UPDATE_ALIAS_FAILURE";
export const FETCH_DEVICE_DATA = "FETCH_DEVICE_DATA";
export const FETCH_DEVICE_DATA_SUCCESS = "FETCH_DEVICE_DATA_SUCCESS";
export const FETCH_DEVICE_DATA_FAILURE = "FETCH_DEVICE_DATA_FAILURE";
export const FETCH_MONITORINGHOSTS_DATA = "FETCH_MONITORINGHOSTS_DATA";
export const FETCH_MONITORINGHOSTS_DATA_SUCCESS = "FETCH_MONITORINGHOSTS_DATA_SUCCESS";
export const FETCH_MONITORINGHOSTS_DATA_FAILURE = "FETCH_MONITORINGHOSTS_DATA_FAILURE";
