/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React from "react";

const Icon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3596 11.2426C13.9453 11.8284 13.9453 12.7781 13.3596 13.3639C12.774 13.9497 11.8245 13.9497 11.2388 13.3639L6.9971 9.12128L2.7554 13.3639C2.16975 13.9497 1.22021 13.9497 0.634555 13.3639C0.0488985 12.7781 0.0488985 11.8284 0.634555 11.2426L4.87625 6.99996L0.634555 2.75732C0.0488985 2.17153 0.0488985 1.22178 0.634555 0.635995C1.22021 0.0502087 2.16975 0.0502087 2.7554 0.635995L6.9971 4.87863L11.2388 0.635995C11.8245 0.0502087 12.774 0.0502087 13.3596 0.635995C13.9453 1.22178 13.9453 2.17153 13.3596 2.75732L9.11795 6.99996L13.3596 11.2426Z"
        fill="#999999"
      />
    </svg>
  );
};

export default Icon;
