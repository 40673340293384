// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // import { Link } from '@mineral/core';
  //Homestub
  content["Home"] =
  "Home";

  //Monitoring Configuring Service
  content["Monitoring Configuring Service"] =
  "Monitoring Configuring Service";

  //Home
  content["breadcrumb"] =
  "Home";

  // END OF LOCALIZATION

export default content;
