/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  FETCH_PROBE_METRICS,
  FETCH_PROBE_METRICS_SUCCESS,
  FETCH_PROBE_METRICS_FAILURE,
  FETCH_PROBE_METRICS_DEVICES,
  FETCH_PROBE_METRICS_DEVICES_SUCCESS,
  FETCH_PROBE_METRICS_DEVICES_FAILURE,
  CLEAR_PROBE_CHART_DATA,
  FETCH_PROBE_METRICS_DEVICES_CINAME,
  FETCH_PROBE_METRICS_DEVICES_CINAME_SUCCESS,
  FETCH_PROBE_METRICS_DEVICES_CINAME_FAILURE,
  REQUEST_PROBE_METRICS_DEVICES_CHART,
  FETCH_PROBE_METRICS_DEVICES_CHART_SUCCESS,
  FETCH_PROBE_METRICS_DEVICES_CHART_FAILURE,
} from "./actionTypes";
  import fetchStatusTypes from '../fetchStatusTypes'
  
  const dashboard_metric_data = (
    state = {
      isFetching: false,
      didInvalidate: false,
      metrics: [],
      error: "",
      isFetchingDevice: false,
      didInvalidateDevice: false,
      metricsForDevices: null,
      errorDevice: "",
      deviceIds: "",
      selectedMetrics: [],
      hours: "",
      endDate: null,
      selectedCIs: {},
      isInterface: false,
      isFetchingAll: false,
      didInvalidateFetchAll: false,
      errorInFetchingAll: "",
      isFetchingById: false,
      didInvalidateFetchById: false,
      errorInFetchingById: "",
      
      fetchStatus: fetchStatusTypes.UNDEFINED,
      devices: [],
    }
    , action) => {
  
    switch (action.type) {
      case FETCH_PROBE_METRICS:
        return Object.assign({}, state, {
          isFetching: true,
          fetchStatus: fetchStatusTypes.FETCHING,
          didInvalidate: false,
          items: [],
          devices: [],
          ciNames:[],
          metricsForDevices:null,
        });
      case FETCH_PROBE_METRICS_SUCCESS:
        return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        metrics: action.metrics,
          
        });
      case FETCH_PROBE_METRICS_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          fetchStatus: fetchStatusTypes.FAIL,
          didInvalidate: true,
          error: action.errorMessage,
          metrics: [],
        });
        case FETCH_PROBE_METRICS_DEVICES:
        return Object.assign({}, state, {
          isFetching: true,
          fetchStatus: fetchStatusTypes.FETCHING,
          didInvalidate: false,
          devices: []
        });
      case FETCH_PROBE_METRICS_DEVICES_SUCCESS:
        return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        devices: action.devices,
          
        });
      case FETCH_PROBE_METRICS_DEVICES_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          fetchStatus: fetchStatusTypes.FAIL,
          didInvalidate: true,
          error: action.errorMessage,
          devices: [],
        });
        case FETCH_PROBE_METRICS_DEVICES_CINAME:
        return Object.assign({}, state, {
          isFetching: true,
          fetchStatus: fetchStatusTypes.FETCHING,
          didInvalidate: false,
          ciNames: []
        });
      case FETCH_PROBE_METRICS_DEVICES_CINAME_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
        didInvalidate: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        ciNames: action.ciNames,
          
        });
      case FETCH_PROBE_METRICS_DEVICES_CINAME_FAILURE:
        return Object.assign({}, state, {
          isFetching: false,
          fetchStatus: fetchStatusTypes.FAIL,
          didInvalidate: true,
          error: action.errorMessage,
          ciNames: [],
        });
        case REQUEST_PROBE_METRICS_DEVICES_CHART:
          return Object.assign({}, state, {
            isFetchingDevice: true,
            didInvalidateDevice: false,
          });
        case FETCH_PROBE_METRICS_DEVICES_CHART_SUCCESS:
          return Object.assign({}, state, {
            isFetchingDevice: false,
            didInvalidateDevice: false,
            metricsForDevices: action.metricData,
            deviceIds: action.deviceIds,
            selectedMetrics: action.metricTypes,
            hours: action.hours,
            selectedCIs: action.selectedCIs,
            isInterface: action.isInterface,
            endDate: action.endDate,
          });
        case FETCH_PROBE_METRICS_DEVICES_CHART_FAILURE:
          return Object.assign({}, state, {
            isFetchingDevice: false,
            didInvalidateDevice: true,
            errorDevice: action.errorMessage,
            deviceIds: action.deviceIds,
            selectedMetrics: action.metricTypes,
            hours: action.hours,
            selectedCIs: action.selectedCIs,
            isInterface: action.isInterface,
            endDate: action.endDate,
          });
        case CLEAR_PROBE_CHART_DATA:
          return Object.assign({}, state, {
            metricsForDevices: null,
            deviceIds: "",
            selectedMetrics: [],
            selectedCIs: {},
            hours: 24,
            endDate: null,
          });  
      default:
        return state
    }
  };
  
  export default dashboard_metric_data
  