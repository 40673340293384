/**
 * Copyright © 2024 Broadcom. All rights reserved. The term "Broadcom" refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION "AS IS" WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */

/* eslint-disable no-loop-func */
export const collectionInterval = 5; //5mins

const getMetricsDataPointsBenchmark = () => {
  return Number(sessionStorage.getItem('metrics_data_points_limit')) > 0 ? Number(sessionStorage.getItem('metrics_data_points_limit')) : 1500000; //1Million
}


export const calculateDataPoints = (totalQos, totalDevices, timeRange, timeInterval) => {
  totalQos = totalQos ? totalQos?.length : 0;
  let sumDevices = 0;
  for(const [_k, v] of Object.entries(totalDevices)) {
    sumDevices = sumDevices + v.length
  }

  totalDevices = sumDevices;
  const minutesTimeRange = timeRange * 60;
  let tInterval = 0;
  let resultObj = {};
  tInterval = timeInterval === -1 ? collectionInterval : timeInterval;

  // console.log(`METRICS-UI-Predictions-100, Devices: ${totalDevices}, QoS: ${totalQos}, Total Data Points: ${totalDevices * minutesTimeRange / tInterval},
  // Limit: ${getMetricsDataPointsBenchmark()}, minutesTimeRange: ${minutesTimeRange}, timeInterval: ${tInterval}`);
  resultObj.totalQos = totalQos;
  resultObj.totalDevices = totalDevices;
  resultObj.totalDataPoints = totalDevices * minutesTimeRange / tInterval;
  resultObj.restrict = (totalDevices * minutesTimeRange / tInterval) > getMetricsDataPointsBenchmark();
  let newIntervals = {};
  let newTimeRange = [];
  let firstOccurenceFound = false;
  let suggestedTime = '';
  for(const [key, value] of Object.entries(intervals)) {
      const minsTimeRange = key === "Custom" ? minutesTimeRange: key * 60;
      const newTimeIntervals = value.map((row) => {
        const tInterval = row?.interval === -1 ? collectionInterval : row?.interval;
        row.isDisabled = (totalDevices * minsTimeRange / tInterval) > getMetricsDataPointsBenchmark();
        if(!row.isDisabled && !firstOccurenceFound) {
          firstOccurenceFound = true;
          const interval = row?.interval === -1 ? 'Raw' : row?.interval;
          suggestedTime = `Time Range ${key} hour, Aggregation Interval ${interval}`;
          //console.log('METRICS-UI, Suggested Time: ' + suggestedTime);
        }
        return row;
      });
      newIntervals = {...newIntervals, ...{[key] : newTimeIntervals}};
      const obj = {value: key, text: timeRangeMap[key], isDisabled: newIntervals[key].every((val) => val.isDisabled === true)};
      if(key !== "Custom") {
        newTimeRange.push(obj);

        timeRangeItems.map((item) => {
          if(item.value === Number(key)) {
            item.isDisabled = newIntervals[key].every((val) => val.isDisabled === true);
          }
          return item;
        });
      }
  }
    resultObj.latestTimeIntervals = newIntervals;
    resultObj.latestTimeRanges = newTimeRange;
    resultObj.latestTimeRangeIntervals = newIntervals[timeRange>2160 ? "Custom" : timeRange];

    if(resultObj.restrict) {
      if(firstOccurenceFound) {
        resultObj.restrictMsg = 'Results of the selected time interval exceeded graph plotting limits. Pick a shorter time range and interval.';
      } else {
        if(totalDevices > 0 && totalQos > 0) {
          resultObj.restrictMsg = 'Limit your Device or QoS selections; the current choice surpasses the graph plotting limit.';
        } else if(totalDevices > 0) {
          resultObj.restrictMsg = 'Limit your Device selections; the current choice surpasses the graph plotting limit.';
        } else if(totalQos > 0) {
          resultObj.restrictMsg = 'Limit your QoS selections; the current choice surpasses the graph plotting limit.';
        }
      }
    }
    // sessionStorage.setItem('restrictMetrics', JSON.stringify(resultObj));
    return resultObj;
};

export const getAggIntervalMenuItems = (timeRange, timeRangeValue) => {
  if(timeRange === "Custom") {
    let value = "Custom";
    if(timeRangeValue < 12) {
      value = 1;
    } else if(timeRangeValue >= 12 && timeRangeValue < 24) {
      value = 12;
    } else if(timeRangeValue >= 24 && timeRangeValue < 168) {
      value = 24;
    } else if(timeRangeValue >= 168 && timeRangeValue < 720) {
      value = 168;
    } else if(timeRangeValue >= 720 && timeRangeValue < 2160) {
      value = 720;
    } else if(timeRangeValue === 2160) {
      value = 2160;
    }
    return intervals[value];
  }
  return intervals[timeRange];
};

export const getTimeRangeItems = () => {
  return timeRangeItems;
}

const timeRangeMap = {
  1: "1 Hour",
  12: "12 Hours",
  24: "24 Hours",
  168: "1 Week",
  720: "1 Month",
  2160: "3 Months"
};

const timeRangeItems = [
  { value: 1, text: "1 Hour", isDisabled: false},
  { value: 12, text: "12 Hours", isDisabled: false},
  { value: 24, text: "24 Hours", isDisabled: false},
  { value: 168, text: "1 Week", isDisabled: false}, // 24 * 7
  { value: 720, text: "1 Month", isDisabled: false}, // 24 * 30
  { value: 2160, text: "3 Months", isDisabled: false}, // 24 * 30 * 3
];

const intervals = {
    1: [
      {interval: -1, label: 'Raw', isDisabled: false},
      {interval: 1, label: '1 Minute', isDisabled: false},
      {interval: 5, label: '5 Minutes', isDisabled: false},
      {interval: 15, label: '15 Minutes', isDisabled: false},
      {interval: 30, label: '30 Minutes', isDisabled: false}
    ],
    12: [
      {interval: -1, label: 'Raw', isDisabled: false},
      {interval: 1, label: '1 Minute', isDisabled: false},
      {interval: 5, label: '5 Minutes', isDisabled: false},
      {interval: 15, label: '15 Minutes', isDisabled: false},
      {interval: 30, label: '30 Minutes', isDisabled: false},
      {interval: 60, label: '1 Hour', isDisabled: false},
      {interval: 120, label: '2 Hours', isDisabled: false},
      {interval: 240, label: '4 Hours', isDisabled: false},
      {interval: 480, label: '8 Hours', isDisabled: false}
    ],
    24: [
      {interval: -1, label: 'Raw', isDisabled: false},
      {interval: 1, label: '1 Minute', isDisabled: false},
      {interval: 5, label: '5 Minutes', isDisabled: false},
      {interval: 15, label: '15 Minutes', isDisabled: false},
      {interval: 30, label: '30 Minutes', isDisabled: false},
      {interval: 60, label: '1 Hour', isDisabled: false},
      {interval: 120, label: '2 Hours', isDisabled: false},
      {interval: 240, label: '4 Hours', isDisabled: false},
      {interval: 480, label: '8 Hours', isDisabled: false},
      {interval: 720, label: '12 Hours', isDisabled: false}
    ],
    168: [
      {interval: -1, label: 'Raw', isDisabled: false},
      {interval: 1, label: '1 Minute', isDisabled: false},
      {interval: 5, label: '5 Minutes', isDisabled: false},
      {interval: 15, label: '15 Minutes', isDisabled: false},
      {interval: 30, label: '30 Minutes', isDisabled: false},
      {interval: 60, label: '1 Hour', isDisabled: false},
      {interval: 120, label: '2 Hours', isDisabled: false},
      {interval: 240, label: '4 Hours', isDisabled: false},
      {interval: 480, label: '8 Hours', isDisabled: false},
      {interval: 720, label: '12 Hours', isDisabled: false},
      {interval: 1440, label: '1 Day', isDisabled: false}
    ],
    720: [
      {interval: -1, label: 'Raw', isDisabled: false},
      {interval: 1, label: '1 Minute', isDisabled: false},
      {interval: 5, label: '5 Minutes', isDisabled: false},
      {interval: 15, label: '15 Minutes', isDisabled: false},
      {interval: 30, label: '30 Minutes', isDisabled: false},
      {interval: 60, label: '1 Hour', isDisabled: false},
      {interval: 120, label: '2 Hours', isDisabled: false},
      {interval: 240, label: '4 Hours', isDisabled: false},
      {interval: 480, label: '8 Hours', isDisabled: false},
      {interval: 720, label: '12 Hours', isDisabled: false},
      {interval: 1440, label: '1 Day', isDisabled: false}
    ],
    2160: [
      {interval: -1, label: 'Raw', isDisabled: false},
      {interval: 1, label: '1 Minute', isDisabled: false},
      {interval: 5, label: '5 Minutes', isDisabled: false},
      {interval: 15, label: '15 Minutes', isDisabled: false},
      {interval: 30, label: '30 Minutes', isDisabled: false},
      {interval: 60, label: '1 Hour', isDisabled: false},
      {interval: 120, label: '2 Hours', isDisabled: false},
      {interval: 240, label: '4 Hours', isDisabled: false},
      {interval: 480, label: '8 Hours', isDisabled: false},
      {interval: 720, label: '12 Hours', isDisabled: false},
      {interval: 1440, label: '1 Day', isDisabled: false}
    ],
    "Custom": [
      {interval: -1, label: 'Raw', isDisabled: false},
      {interval: 1, label: '1 Minute', isDisabled: false},
      {interval: 5, label: '5 Minutes', isDisabled: false},
      {interval: 15, label: '15 Minutes', isDisabled: false},
      {interval: 30, label: '30 Minutes', isDisabled: false},
      {interval: 60, label: '1 Hour', isDisabled: false},
      {interval: 120, label: '2 Hours', isDisabled: false},
      {interval: 240, label: '4 Hours', isDisabled: false},
      {interval: 480, label: '8 Hours', isDisabled: false},
      {interval: 720, label: '12 Hours', isDisabled: false},
      {interval: 1440, label: '1 Day', isDisabled: false},
      {interval: 43800, label: '1 Month', isDisabled: false}
    ]
};
