/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import Resource from './Resource'

// Source
export const SOURCE = Resource.get('Source')

// Target
export const TARGET = Resource.get('Target')

// Proceed
export const PROCEED = Resource.get('Proceed')

//Cancel
export const CANCEL = Resource.get('Cancel')

//Today
export const TODAY = Resource.get('Today')

//Apply
export const APPLY = Resource.get('Apply')

//Successfully deleted QOS
export const SUCCESSFULLY_DELETED_QOS = Resource.get('Deleted successful.')

//Successfully deleted QOS
export const EDITED_QOS_SUCCESSFULLY = Resource.get('Edited QOS successfully.')

//Something went wrong
export const SOMETHING_WENT_WRONG = Resource.get('Something went wrong.')

//Value should be greater than
export const VALUE_SHOULD_BE_GREATER_THAN = Resource.get('Value should be greater than')

//Value should be greater than
export const VALUE_SHOULD_BE_LESS_THAN = Resource.get('Value should be less than')

//qos created successfully
export const QOS_CREATED_SUCCESSFULLY = Resource.get('Qos created successfully.')

//qos created successfully
export const EXPORTED_QOS_DATA_WILL_BE_SAVED_TO = Resource.get('Exported QOS data will be saved to ')

//qos created successfully
export const PLEASE_CHECK_AFTER_SOME_TIME = Resource.get('Please check after some time.')

// Delete Data Operation
export const DELETE_DATA_OPERATION = Resource.get('Delete Data Operation')

// Invalidate Data
export const INVALIDATE_DATA = Resource.get('Invalidate Data')

// Delete Data
export const DELETE_DATA = Resource.get('Delete Data')

// DELETE_DATA_WARNING_TEXT
export const DELETE_DATA_WARNING_TEXT = Resource.get(
  'Please note that a manual restart of the data engine probe is needed to complete this action. This operation is irreversible...'
)

// DO_YOU_WISH_TO_DELETE
export const DO_YOU_WISH_TO_DELETE = Resource.get('Do you wish to delete ')

// OBJECTS_QUESTION_MARK
export const OBJECTS_QUESTION_MARK = Resource.get('object(s)?')

// DELETE_QOS_OBJECT
export const DELETE_QOS_OBJECT = Resource.get('Delete QoS Object')

// Delete Historic Data
export const DELETE_HISTORIC_DATA = Resource.get('Delete Historic Data')

// Period Settings
export const PERIOD_SETTINGS = Resource.get('Period Settings')

// Ignore (all available data)
export const IGNORE_ALL_AVAILABLE_DATA = Resource.get('Ignore (all available data)')

// Selected Period
export const SELECTED_PERIOD = Resource.get('Selected Period')

// Start Date & Time
export const START_DATE_TIME = Resource.get('Start Date & Time')

// End Date & Time
export const END_DATE_TIME = Resource.get('End Date & Time')
// Calculation Settings
export const CALCULATIONSETTINGS = Resource.get('Calculation Settings')
//CALCULATIONTYPE
export const CALCULATIONTYPE = Resource.get('Calculation Type')

//Start date should be before end date
export const START_DATE_SHOULD_BE_BEFORE_END_DATE = Resource.get('Start date should be before end date')

// formula
export const FORMULA = Resource.get('Formula')
// profile
export const PROFILE = Resource.get('Profile')
// Saving SLA...
export const SAVINGSLA = Resource.get('Saving SLA...')
// Saving JOB...
export const SAVINGJOB = Resource.get('Saving Job...')
// Fetching SLA...
export const FETCHINGSLA = Resource.get('Fetching SLA...')
// Saving SLO...
export const SAVINGSLO = Resource.get('Saving SLO...')
// Fetching SLA...
export const FETCHINGSLO = Resource.get('Fetching SLO...')
// Failed to fetch SLA
export const FETCHSLAERROR = Resource.get('Failed to fetch SLA')
// Saving SLA Group
export const SAVINGSLAGROUP = Resource.get('Saving SLA Group')
// Save SLA & Add New Job
export const SAVESLAANDADDNEWJOB = Resource.get('Save SLA & Add New Job')
// Save SLA
export const SAVESLA = Resource.get('Save SLA')
// Save SLO
export const SAVESLO = Resource.get('Save SLO')
// Job ID
export const JOBID = Resource.get('Job ID')
// Confirm
export const CONFIRM = Resource.get('Confirm ')
// Deletion
export const DELETION = Resource.get('Deletion')
// Are you sure do you want to delete the
export const AREYOUSUREYOUWANTTODELETETHE = Resource.get('Are you sure do you want to delete the ')

// This operation is irreversible.
export const THISOPERATIONISIRREVERSIBLE = Resource.get('This operation is irreversible.')
//CONFIRMQOSCONSTRAINTDELETEION
export const CONFIRMQOSCONSTRAINTDELETEION = Resource.get('Confirm Qos Constraint Deletion')
//WITHSOURCE
export const WITHSOURCE = Resource.get(' with source ')
//ANDTARGET
export const ANDTARGET = Resource.get(' and target ')
// Period Start
export const PERIODSTART = Resource.get('Period Start')
// Period Begin
export const PERIODBEGIN = Resource.get('Period Begin')

// Period End
export const PERIODEND = Resource.get('Period End')

// Owner
export const OWNER = Resource.get('Owner')

// Job Start Time
export const JOBSTARTTIME = Resource.get('Job Start Time')
//JOBEXPIRATIONTIME
export const JOBEXPIRATIONTIME = Resource.get('Job Expiration Time')

// Create Historic Data
export const CREATE_HISTORIC_DATA = Resource.get('Create Historic Data')
//AT24HR
export const AT24HR = Resource.get('At (24 Hour)')

//JOBEXPIRATIONTIME
export const JOB_EXPIRATION_TIME = Resource.get('Job Expiration Time')

// No Calculation s available. Save SLA and
export const NOCALCAVAILABLESAVESLAAND = Resource.get('No Calculation s available. Save SLA and')

// Configure
export const CONFIGURE = Resource.get('Configure')
// Excluded Periods
export const EXCLUDEDPERIODS = Resource.get('Excluded Periods')

// Title
export const TITLE = Resource.get('Title')
// From Date
export const FROMDATE = Resource.get('From Date')
// To Date
export const TODATE = Resource.get('To Date')
// Updating...
export const UPDATING = Resource.get('Updating...')
// Update SLA
export const UPDATESLA = Resource.get('Update SLA')
// Update SLO
export const UPDATESLO = Resource.get('Update SLO')
// Save SLA and Create SLO
export const SAVESLACREATESLO = Resource.get('Save SLA and Create SLO')
// Day
export const DAY = Resource.get('Day')
// Week(s)
export const WEEKS = Resource.get('Week(s)')
// Month(s)
export const MONTHS = Resource.get('Month(s)')
// Period
export const PERIOD = Resource.get('Period')
// Starting from
export const STARTINGFROM = Resource.get('Starting from')

// Timezone
export const TIMEZONE = Resource.get('Timezone')

// Select a timezone...
export const TIMEZONEPLACEHOLDER = Resource.get('Select a timezone...')

// Date
export const DATE = Resource.get('Date')

// Notes Title
export const NOTES_TITLE = Resource.get('Notes Title')

//Time
export const TIME = Resource.get('Time')

// QoS Definition
export const QOS_DEFINITION = Resource.get('QoS Definition')

// weight
export const WEIGHT = Resource.get('Weight(%)')
//filled
export const FILLED = Resource.get('Fulfilled(%)')
//Operator
export const OPERATOR = Resource.get('Operator')

//Search by SLA or Group name...
export const SLA_SEARCH_PLACEHOLDER = Resource.get('Search by SLA or Group name...')

//Search by QoS name...
export const QOS_SEARCH_PLACEHOLDER = Resource.get('Search by QoS name...')
//FILTER_SEARCH_PLACEHOLDER
export const FILTER_SEARCH_PLACEHOLDER = Resource.get('Search...')

// qos
export const QOS_NAME = Resource.get('QoS Name')

// Accounts
export const ACCOUNTS = Resource.get('Accounts')
// Account
export const ACCOUNT = Resource.get('Account')
//Account placeholder
export const ACCOUNT_PLACEHOLDER = Resource.get('Select an account...')

// Public
export const GROUPS = Resource.get('Groups & SLA(s)')

// View Details
export const VIEW_DETAILS = Resource.get('View Details')

// Delete
export const DELETE = Resource.get('Delete')

// Create SLA from selection
export const CREATE_SLA_FROM_SELECTION = Resource.get('Create SLA from selection')

// Recalculate
export const RECALCULATE = Resource.get('Recalculate')

// History
export const HISTORY = Resource.get('History')

// Service Level Agreement
export const SERVICE_LEVEL_AGREEMENT = Resource.get('Service Level Agreement')

// Quality of Service
export const QUALITY_OF_SERVICE = Resource.get('Quality of Service')

// Operating Period
export const OPERATING_PERIOD = Resource.get('Operating Period')

// Calculation Profiles
export const CALCULATION_PROFILES = Resource.get('Calculation Profiles')

// SQL Query Editor
export const SQL_QUERY_EDITOR = Resource.get('SQL Query Editor')

//QoS group
export const QOS_GROUP = Resource.get('QoS group')

//Group
export const GROUP = Resource.get('Group')
//GROUP_FETCHING_PLACEHOLDER
export const GROUP_FETCHING_PLACEHOLDER = Resource.get('Fetching SLA groups...')
//GROUP_SEARCH_SELECT
export const GROUP_SEARCH_SELECT = Resource.get('Search and select a group')
//CALCULATION_METHOD_PLACEHOLDER
export const CALCULATION_METHOD_PLACEHOLDER = Resource.get('Select a calculation method...')
//Copy SLA
export const COPYSLA = Resource.get('Copy SLA')
//QoS
export const QOS = Resource.get('QoS')

//Probe
export const PROBE = Resource.get('Probe')

//Refresh
export const REFRESH = Resource.get('Refresh')

// Use DAta Type
export const USE_SPECIFIC_DATATYPE_PROPERTIES = Resource.get('Use specific datatype properties')

// Has max
export const HAS_MAXIMUM_VALUE = Resource.get('Has maximum value?')

// Object Type
export const OBJECT_TYPE = Resource.get('Object Type')

// Is Bool
export const IS_BOOL = Resource.get('Is Bool?')

// To see the Operating period details
export const NO_DATA_OP_ONE = Resource.get('No Operating period(s) found')

// Start creating a
export const NO_DATA_OP_TWO = Resource.get('Start creating a ')

//new operating period
export const NO_DATA_OP_THREE = Resource.get('new operating period')

// To see the Operating period details
export const NO_DATA_CP_ONE = Resource.get('No Calculation profile(s) found')

//new operating period
export const NO_DATA_CP_THREE = Resource.get('new calculation profile')

// To see the SLA details, Click on a parent node on the Tree.
export const NO_DATA_SLA = Resource.get('To see the SLA details, Click on a parent node on the Tree.')

// Add new
export const ADD_NEW = Resource.get('Add New')

// Create Operating Period
export const CREATE_OPERATING_PERIOD = Resource.get('Create Operating Period')

// Name
export const NAME = Resource.get('Name')

//profile Type
export const PROFILE_TYPE = Resource.get('Profile Type')

//QOS Calculation
export const QOS_CALCULATION = Resource.get('QoS Calculation')
//SLO Calculation
export const SLO_CALCULATION = Resource.get('SLO Calculation')

//Calculate availability Plugin Description
export const CALCULATE_AVAILABILITY_PLUGIN_DESCRIPTION = Resource.get('Calculate availability Plugin Description')
//AVAILABILITY_PLUGIN_PLACEHOLDER
export const AVAILABILITY_PLUGIN_PLACEHOLDER = Resource.get('Select availability plugin description...')
//Value
export const VALUE = Resource.get('Value')
// From
export const FROM = Resource.get('From')
//Clear
export const CLEAR = Resource.get('Clear')

// To
export const TO = Resource.get('To')

// Action
export const ACTION = Resource.get('Action')

// Alarm Notification
export const ALARM_NOTIFICATION = Resource.get('Alarm Notification')

// Alarm when compliance is breached
export const ALARMBREACH = Resource.get('Alarm when compliance is breached ')

// Alarm when warning threshold is breached
export const ALARMWARN = Resource.get('Alarm when warning threshold is breached')

// Message
export const MESSAGE = Resource.get('Message')

// Severity Level
export const SEVERITYLEVEL = Resource.get('Severity Level')

// Select severity...
export const SEVERITYPLACEHOLDER = Resource.get('Select severity...')

// Subsystem
export const SUBSYSTEM = Resource.get('Subsystem')
// Select subsystem...
export const SUBSYSTEMPLACEHOLDER = Resource.get('Select subsystem...')

// Actions
export const ACTIONS = Resource.get('Actions')

// No SLOs available. Save SLA and
export const NOSLOSAVAILABLE = Resource.get('No SLOs available. Save SLA and')
// Create SLO
export const CREATESLO = Resource.get('Create SLO')
// now.
export const NOW = Resource.get('now.')
// Description
export const DESCRIPTION = Resource.get('Description')

// Time Specification
export const TIME_SPECIFICATION = Resource.get('Time Specification')

// Quality of Service Constraints
export const QoS_CONSTRAINTS = Resource.get('Quality of Service Constraints')

// CREATE_QoS_CONSTRAINT
export const CREATE_QoS_CONSTRAINT = Resource.get('Create New QoS Constraint')

// SLA/SLO name
export const SLA_SLO_NAME = Resource.get('SLA/SLO name')

// SLO name
export const SLO_NAME = Resource.get('SLO Name')

//Object
export const OBJECT = Resource.get('Object')

//Select an item...
export const SELECT_AN_ITEM = Resource.get('Select an item...')

//Expect Quality of Service to be
export const EXPECT_QOS_TO_BE = Resource.get('Expect Quality of Service to be')

// Historic SLA Compliance
export const HISTORIC_SLA_COMPLIANCE = Resource.get('Historic SLA Compliance')

// General View
export const GENERAL_VIEW = Resource.get('General View')

// Graphic view
export const GRAPHIC_VIEW = Resource.get('Graphic View')

// View as PDF
export const VIEW_AS_PDF = Resource.get('View as PDF')

// Add New SLA
export const ADD_NEW_SLA = Resource.get('Add New SLA')

// Show SLA Alarms
export const SHOW_SLA_ALARMS = Resource.get('Show SLA Alarms')

// Create SLA by Service
export const CREATE_SLA_BY_SERVICE = Resource.get('Create SLA by Service')

// No data found
export const NO_DATA_FOUND = Resource.get('No data found')

// SLA Report
export const SLA_REPORT = Resource.get('SLA Report')

// SLO Report
export const SLO_REPORT = Resource.get('SLO Report')

// FULL Report
export const FULL_REPORT = Resource.get('FULL Report')

// Periods
export const PERIODS = Resource.get('Periods')

// Achieved
export const ACHIEVED = Resource.get('Achieved')

// Expected
export const EXPECTED = Resource.get('Expected')

// Compliance Met
export const COMPLIANCE_MET = Resource.get('Compliance Met')

// Pending to Achieve
export const PENDING_TO_ACHIEVE = Resource.get('Pending to Achieve')

// Threshold Compliance
export const THRESHOLD_COMPLIANCE = Resource.get('Threshold Compliance')

// Official
export const OFFICIAL = Resource.get('Official')
// Threshold
export const THRESHOLD = Resource.get('Threshold')
//Notes
export const NOTES = Resource.get('Notes')
//Unit
export const UNIT = Resource.get('Unit')

//NOQOSAVAILABLE
export const NOQOSAVAILABLE = Resource.get('No QOS constraints available.')

//AREUSUREQOSCONSTRAINT
export const AREUSUREQOSCONSTRAINT = Resource.get('Are you sure do you want to delete the QoS Constraint:')

// New slo
export const NEW_SLO = Resource.get('Service Level Objective')

// Service Level Objectives
export const SERVICE_LEVEL_OBJECTIVES = Resource.get('Service Level Objectives')

// The following service level objectives are defined in this SLA. Each objective is listed with its weight and the percentage of fulfillment.
export const SLO_SUMMARY_FIRST = Resource.get(
  'The following service level objectives are defined in this SLA. Each objective is listed with its weight and the percentage of fulfillment.'
)

// The fulfillment is the relationship between the weight and the compliancy of the Quality of Service constraints defined within the SLO.
export const SLO_SUMMARY_SECOND = Resource.get(
  'The fulfillment is the relationship between the weight and the compliancy of the Quality of Service constraints defined within the SLO.'
)

// Show history
export const SHOW_HISTORY = Resource.get('Show History')

// The following Quality of Service objects (QoS) are defined in this SLO. Each QoS is listed with its weight and the percentage of fulfillment.
export const QOS_SUMMARY_FIRST = Resource.get(
  'The following Quality of Service objects (QoS) are defined in this SLO. Each QoS is listed with its weight and the percentage of fulfillment.'
)

// The fulfillment is the relationship between the weight and the compliancy for the constrained Quality of Service.
export const QOS_SUMMARY_SECOND = Resource.get(
  'The fulfillment is the relationship between the weight and the compliancy for the constrained Quality of Service.'
)

// The following service level objectives are defined in this SLA. Each objective is listed with its weight and the percentage of fulfillment.
export const INFO_TOOLTIP = Resource.get(
  'The following service level objectives are defined in this SLA. Each objective is listed with its weight and the percentage of fulfillment.'
)

// set des
export const SETTINGS_DESC = Resource.get(
  'You may configure the way the SLO is computed.Select the method to use when computing the compliance percentage for this SLO.The method available are dependent on the selected type.'
)
//Total Unavailability
export const TOTAL_UNAVAILABILITY = Resource.get('Total Unavailability')

//Mins
export const MINS_TEXT = Resource.get('Mins')

// The report summarizes the periods with threshold breaches and where the service is unavailable. A service is considered unavailable if it is impossible to determine the state of the service. A breach is defined to be where the samplevalue does not meet the compliance criteria.
export const UNAVAILABILITY_DESCRIPTION = Resource.get(
  'The report summarizes the periods with threshold breaches and where the service is unavailable. A service is considered unavailable if it is impossible to determine the state of the service. A breach is defined to be where the samplevalue does not meet the compliance criteria.'
)

// SLA history
export const SLA_HISTORY = Resource.get('SLA History')

// Compliance
export const COMPLIANCE = Resource.get('Compliance')

//Create Service Level Agreement
export const CREATE_SERVICE_LEVEL_AGREEMENT = Resource.get('Create Service Level Agreement')

//Edit Service Level Agreement
export const EDIT_SERVICE_LEVEL_AGREEMENT = Resource.get('Edit Service Level Agreement')

//Modify Service Level Agreement
export const MODIFY_SERVICE_LEVEL_AGREEMENT = Resource.get('Modify Service Level Agreement')
//Desc length
export const DESC_LENGTH = Resource.get('Description can not be empty and more than 255 characters.')
//Msg length
export const MSG_LENGTH = Resource.get('Message can not be empty and more than 255 characters.')
// Name Length
export const NAME_LENGTH = Resource.get('Name is required and can not be more than 64 characters.')

// Name Required
export const NAME_REQUIRED = Resource.get('Name is required')

// JOB properties
export const JOB_PROPERTIES = Resource.get('Job Properties')
// Required
export const REQUIRED = Resource.get('Required')
//SLA Name
export const SLA_NAME = Resource.get('SLA Name')

//Compliance Type
export const COMPLIANCE_TYPE = Resource.get('Compliance Type')

// low
export const LOW = Resource.get('Low')
// avg
export const AVG = Resource.get('Avg')
// high
export const HIGH = Resource.get('High')
// max
export const MAX = Resource.get('Max')

//Compliance Period
export const COMPLIANCE_PERIOD = Resource.get('Compliance Period')

//Compliance Period placeholder
export const COMPLIANCE_PERIOD_PLACEHOLDER = Resource.get('Create/Enter the compliance period')

// Calculation Method
export const CALCULATION_METHOD = Resource.get('Calculation Method')

// Row delimiter
export const ROW_DELIMITER = Resource.get('Row delimiter')

// Column delimiter
export const COLUMN_DELIMITER = Resource.get('Column delimiter')

// Text Qualifier
export const TEXT_QUALIFIER = Resource.get('Text Qualifier')

// Include column headers
export const INCLUDE_COLUMN_HEADERS = Resource.get('Include column headers')

// Newline
export const NEWLINE = Resource.get('Newline')

// SemiColon
export const SEMICOLON = Resource.get('Semicolon')

// Comma
export const COMMA = Resource.get('Comma')

// Tab
export const TAB = Resource.get('Tab')

// Vertical bar
export const VERTICAL_BAR = Resource.get('Vertical bar')

// None
export const NONE = Resource.get('<None>')

// Double quote
export const DOUBLE_QUOTE = Resource.get('Double quote')

// Single quote
export const SINGLE_QUOTE = Resource.get('Single quote')

// Calculation Method
export const EXPORT_QOS = Resource.get('Export Qos')

//Percentage
export const PERCENTAGE = Resource.get('Percentage')

// This report is getting downloaded, please wait.
export const PDF_DOWNLOAD_SUCCESS = Resource.get('This report is getting downloaded, please wait.')

// PDF download failed for
export const PDF_DOWNLOAD_FAIL = Resource.get('PDF download failed for')

// Manage QoS Data
export const MANAGE_QOS_DATA = Resource.get('Manage QoS Data')

// Create
export const CREATE = Resource.get('Create')

// QoS Active Objects
export const QOS_ACTIVE_OBJECTS = Resource.get('QoS Active Objects')

// The SLA has no data. Please check your sla configuration.
export const NO_SLA_DATA = Resource.get('The SLA has no data. Please check your sla configuration.')

// Update
export const UPDATE = Resource.get('Update')

// Change Origin
export const CHANGE_ORIGIN = Resource.get('Change Origin')

// Merge Objects
export const MERGE_OBJECTS = Resource.get('Merge Objects')

// Show Statistics
export const SHOW_STATISTICS = Resource.get('Show Statistics')

// Save
export const SAVE = Resource.get('Save')

// List of Origins
export const LIST_OF_ORIGINS = Resource.get('List of Origins')

// View Data
export const VIEW_DATA = Resource.get('View Data')

// Export Data
export const EXPORT_DATA = Resource.get('Export Data')

// Delete Object(s)
export const DELETE_OBJECTS = Resource.get('Delete Object(s)')

//SALAWITHNAME
export const SLAWITHNAME = Resource.get('SLA with name ')
//ALREADYEXISTS
export const ALREADYEXISTS = Resource.get(' already exists.')
//SAVINGSLAEXCEPTION
export const SAVINGSLAEXCEPTION = Resource.get('An exception occurred while saving SLA')
//SAVINGSLAJOBEXCEPTION
export const SAVINGSLAJOBEXCEPTION = Resource.get('An exception occurred while saving SLA Job')

// Data
export const DATA = Resource.get('Data')

// Are you sure do you want to delete the QoS data of
export const DELETE_DIALOG_QOS_OBJ_DATA_TEXT = Resource.get('Are you sure do you want to delete the QoS data of')

// This operation is irreversible
export const IRREVERSIBLE_OPERATION = Resource.get('This operation is irreversible')

// Confirm QoS Data Deletion
export const CONFIRM_QOS_DATA_DELETION = Resource.get('Confirm QoS Data Deletion')

// A manual restart of the data_engine probe is required to complete this action
export const DELETE_OBJECTS_CONFIRMATION_TEXT = Resource.get(
  'A manual restart of the data_engine probe is required to complete this action'
)

// Are you sure do you want to delete the QoS object(s) of
export const DELETE_OBJECTS_CONFIRMATION_TEXT_2 = Resource.get('Are you sure do you want to delete the QoS object of')

// Are you sure do you want to delete
export const DELETE_OBJECTS_CONFIRMATION_TEXT_3 = Resource.get('Are you sure do you want to delete')

// Objects
export const OBJECTS = Resource.get('Objects')

// First Sample
export const FIRST_SAMPLE = Resource.get('First Sample')

// Last Sample
export const LAST_SAMPLE = Resource.get('Last Sample')

// Delete the Source QoS after the merge operation
export const DELETE_SOURCE_QOS_AFTER_MERGE = Resource.get('Delete the Source QoS after the merge operation')

// Merge
export const MERGE = Resource.get('Merge')

// Merge QoS Data
export const MERGE_QOS_DATA = Resource.get('Merge QoS Data')

// Compliant
export const COMPLIANT = Resource.get('Compliant')

// Not Compliant
export const NOT_COMPLIANT = Resource.get('Not Compliant')

//Saving
export const SAVING = Resource.get('Saving...')

//Save SLA & Add New SLO
export const SAVE_SLA_AND_ADD_NEW_SLO = Resource.get('Save SLA & Add New SLO')

// Close
export const CLOSE = Resource.get('Close')

// Are you sure do you want to delete the Group of
export const DELETE_GROUP_CONFIRMATION = Resource.get('Are you sure do you want to delete the Group of ')

//Period Begin
export const PERIOD_BEGIN = Resource.get('Period Begin')

// Are you sure do you want to delete the SLA of
export const DELETE_SLA_CONFIRMATION = Resource.get('Are you sure do you want to delete the SLA of ')

// Confirm Group Deletion
export const CONFIRM_GROUP_DELETION = Resource.get('Confirm Group Deletion')

// Confirm SLA Deletion
export const CONFIRM_SLA_DELETION = Resource.get('Confirm SLA Deletion')

// Create New Group
export const CREATE_NEW_GROUP = Resource.get('Create New Group')

// Delete Group
export const DELETE_GROUP = Resource.get('Delete Group')

// View / Edit Group
export const VIEW_EDIT_GROUP = Resource.get('View / Edit Group')

// Period End
export const PERIOD_END = Resource.get('Period End')

// Trend Analysis
export const TREND_ANALYSIS = Resource.get('Trend Analysis')

// Breached at
export const BREACHED_AT = Resource.get('Breached at ')

// SLA Description
export const SLA_DESCRIPTION = Resource.get('SLA Description')

// SLO Description
export const SLO_DESCRIPTION = Resource.get('SLO Description')

// Create single report with start/end dates different from the configured compliance period
export const CREATE_REPORT = Resource.get('Create single report with start/end dates different from the configured compliance period')

// Please select 2 QoS objects of the same Qos type
export const MERGE_OBJECTS_HELP_TEXT = Resource.get('Please select 2 QoS objects of the same Qos type')

// Please select at least 1 QoS object
export const SHOW_STATISTICS_HELP_TEXT = Resource.get('Please select at least 1 QoS object')

// Add Custom Origin
export const ADD_CUSTOM_ORIGIN = Resource.get('Add Custom Origin')
// Add
export const ADD = Resource.get('Add')

// Select a source first..
export const SELECT_A_SOURCE_FIRST = Resource.get('Select a source first..')

// Always
export const ALWAYS = Resource.get('Always')

// Get Data first..
export const GET_DATA_FIRST = Resource.get('Get Data first..')

// Data Period
export const DATA_PERIOD = Resource.get('Data Period')

// Last Day
export const LAST_DAY = Resource.get('Last Day')

// Last Week
export const LAST_WEEK = Resource.get('Last Week')

// Last Month
export const LAST_MONTH = Resource.get('Last Month')

// Custom
export const CUSTOM = Resource.get('Custom')

// Get Data
export const GET_DATA = Resource.get('Get Data')

// View Report
export const VIEW_REPORT = Resource.get('View Report')

// op.period
export const OP_PERIOD = Resource.get('Op.Period')

// total
export const TOTAL = Resource.get('Total')
// Accuracy
export const ACCURACY = Resource.get('Accuracy')

// order
export const ORDER = Resource.get('Order')
