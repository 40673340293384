// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Extension message
  content["Extension requested"] =
  "Extension requested";

  //Request message
  content["Request extension"] =
  "Request extension";

  //Expired
  content["Expired"] =
  "Expired";

  //Trial
  content["Trial"] =
  "Trial";

  //BUY
  content["BUY"] =
  "BUY";

  // END OF LOCALIZATION

export default content;
