/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
export const REQUEST_COMPUTER_SYSTEM = "REQUEST_COMPUTER_SYSTEM";
export const REQUEST_COMPUTER_SYSTEMS = "REQUEST_COMPUTER_SYSTEMS";
export const FETCH_COMPUTER_SYSTEMS = "FETCH_COMPUTER_SYSTEMS";
export const FETCH_COMPUTER_SYSTEM_SUCCESS = "FETCH_COMPUTER_SYSTEM_SUCCESS";
export const FETCH_COMPUTER_SYSTEMS_SUCCESS = "FETCH_COMPUTER_SYSTEMS_SUCCESS";
export const FETCH_COMPUTER_SYSTEM_FAILURE = "FETCH_COMPUTER_SYSTEM_FAILURE";
export const FETCH_COMPUTER_SYSTEMS_FAILURE = "FETCH_COMPUTER_SYSTEMS_FAILURE";
export const DELETE_DEVICES = "DELETE_DEVICES";
export const DELETE_DEVICES_SUCCESS = "DELETE_DEVICES_SUCCESS";
export const DELETE_DEVICES_FAILURE = "DELETE_DEVICES_FAILURE";
export const INVENTORY_TREE_SELECTION = "INVENTORY_TREE_SELECTION";
export const FETCH_INVENTORY_TREE = "FETCH_INVENTORY";
export const FETCH_INVENTORY_TREE_SUCCESS = "FETCH_INVENTORY_SUCCESS";
export const FETCH_INVENTORY_TREE_FAILURE = "FETCH_INVENTORY_FAILURE";
export const DELETE_DEVICES_FROM_INVENTORY = "DELETE_DEVICES_FROM_INVENTORY";
export const DELETE_DEVICES_FROM_INVENTORY_SUCCESS =
  "DELETE_DEVICES_FROM_INVENTORY_SUCCESS";
export const DELETE_DEVICES_FROM_INVENTORY_FAILURE =
  "DELETE_DEVICES_FROM_INVENTORY_FAILURE";

export const FILTERED_INVENTORY_ROWS = "FILTERED_INVENTORY_ROWS";

export const SET_COMPUTER_SYSTEMS_TOTAL_ITEMS =
  "SET_COMPUTER_SYSTEMS_TOTAL_ITEMS";
export const SET_COMPUTER_SYSTEMS_SORT = "SET_COMPUTER_SYSTEMS_SORT";
export const SET_COMPUTER_SYSTEMS_FILTER = "SET_COMPUTER_SYSTEMS_FILTER";
export const SET_COMPUTER_SYSTEMS_COLUMN_FILTER =
  "SET_COMPUTER_SYSTEMS_COLUMN_FILTER";
export const SET_COMPUTER_SYSTEMS_QUICK_FILTER =
  "SET_COMPUTER_SYSTEMS_QUICK_FILTER";
export const SET_COMPUTER_SYSTEMS_REFRESH_GRID_DATA =
  "SET_COMPUTER_SYSTEMS_REFRESH_GRID_DATA";
export const SET_COMPUTER_SYSTEMS_TREE = "SET_COMPUTER_SYSTEMS_TREE";

export const FETCH_LIST = "FETCH_LIST";
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_FAILURE = "FETCH_LIST_FAILURE";
export const UPDATE_COMPUTER_SYSTEM = "UPDATE_COMPUTER_SYSTEM";
