/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { useState, useEffect } from "react";
import {
  makeStyles
} from "@material-ui/core/styles";
import {
  OutlinedInput,
  Button,
  Checkbox,
  FormControlLabel,
  Select as SelectField,
  Typography, FormControl, Popover,
  List, MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow, InputLabel,
  IconButton
} from "@mineral/core";
import { find } from "../../utils/lodash-utils";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./copyDialog.less";
import {
  ChevronDownIcon,
  Circle
} from "../ui-components/uim-components";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import Resource from "./Resource";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    width: "unset !important",
  },
  tableContainer: {
    borderRadius: "3px",
    margin: "16px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #3272D9",
    overflow: "hidden",
  },
  tableRow: {
    height: 33,
    border: 'none'
  },
  tableBodyRow: {
    height: 34,
    border: 'none !important',
    display: 'flex !important'
  },
  tableHeaderCell: {
    //position: 'absolute',
    position: "relative",
    height: "16px",
    // maxWidth: '100%',
    padding: "0px 16px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",

    color: "#666666",
    backgroundColor: "#FFFFFF",
    //color: theme.palette.getContrastText(theme.palette.primary.light)
    borderBottom: "none !important",
    border: "none !important",
    whiteSpace: "nowrap",
    // width: "40px"
  },

  tableBodyCell: {
    //position: 'absolute',
    position: "relative",
    height: "32px",
    padding: "0px 10px",
    color: "#666666",
    backgroundColor: "#FFFFFF",
    //color: theme.palette.getContrastText(theme.palette.primary.light)
    border: "none !important",
    //smaxWidth: '100%',
    whiteSpace: "nowrap",
  },
  avatar: {
    backgroundColor: "#FFFFFF",
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  add_remove_btn: {
    position: "relative",
    width: "24px",
    height: "24px",
    color: "#3272D9",
    background: "#FFFFFF",
    border: "1px solid #C8D1E0",
    boxSizing: "border-box",
    borderRadius: "3px",
    marginRight: "8px",
  },
  input: {
    padding: "1px 10px",
  },
}));

const MetricViewAdvancedFilter = (props) => {
  const hideLabel = "Hide"; //Hide
  const showLabel = "Show"; //Show
  const TITLE = "Define Criteria to select device(s)"; //Define Criteria
  const GROUP_TITLE = "Define Criteria to select group(s)"; //Define Grpop Criteria
  const deviceBtnName = "Find Devices"; //Find Devices
  const groupBtnName = "Find Groups"; //Find Groups
  const resetLabel = "Reset"; //Reset

  const classes = useStyles();
  const [isGroupTypeField, setGroupTypeField] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState([
    {
      field: props.targetType == "group" ? "group_name" : "name",
      operator: "contains",
      value: "",
      notApplied: false,
      isGroupTypeField: false,
    },
  ]);

  const deviceFieldItems = [
    { value: "name", label: "Name" },
    { value: "alias", label: "Alias" },
    { value: "ip_address", label: "IP Address" },
    { value: "role", label: "Dedicated" },
    { value: "mac_address", label: "Mac Address" },
    { value: "os_name", label: "OS Name" },
    { value: "os_type", label: "OS Type" },
    { value: "origin", label: "Origin" },
    { value: "user_tag1", label: "User Tag 1" },
    { value: "user_tag2", label: "User Tag 2" },
  ];
  const groupFieldItems = [
    { value: "group_name", label: "Group Name" },
    { value: "parent_group", label: "Parent Group" },
    { value: "group_type", label: "Group Type" },
  ];

  const operatorOptions = [
    { value: "contains", label: "contains" },
    { value: "equals", label: "equals" },
    { value: "startswith", label: "starts with" },
    { value: "endswith", label: "ends with" },
  ];

  const groupTypeOptions = [
    { value: "2", label: "Automatic" },
    { value: "3", label: "Container" },
    { value: "1", label: "Dynamic" },
    { value: "0", label: "Static" },
  ];

  const [advanceFilterCondition, setAdvanceFilterCondition] = useState("All");
  const [advanceCustomFilter, setAdvanceCustomFilter] = useState("");
  const [advanceFilterOpen, setAdvanceFilterOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [advanceFilterApplied, setAdvanceFilterApplied] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [openGroupTypeDialog, setOpenGroupTypeDialog] = useState(false);
  const [selectedGroupType, setSelectedGroupType] = useState([]);

  const FILTER = Resource.get("Filter"); //filter

  useEffect(() => {}, [show]);

  useEffect(() => {
    setAdvanceFilter([
      {
        field: props.targetType == "group" ? "group_name" : "name",
        operator: "contains",
        value: "",
        notApplied: false,
        isGroupTypeField: false,
      },
    ]);
  }, [props.targetType]);

  const setShowVal = () => {
    const newShowVal = show;
    setShow(!newShowVal);
  };
  const handleFieldChange = (event, index) => {
    let value = event.target.value;
    if (value == "group_type") {
      setGroupTypeField(true);
    } else {
      setGroupTypeField(false);
    }
    const advanceFilter1 = [...advanceFilter];
    let advanceFilterObj = advanceFilter1[index];
    advanceFilterObj.field = value;
    advanceFilterObj.value = value == "group_type" ? "1" : "";
    advanceFilterObj.operator = value == "group_type" ? "in" : "contains";
    advanceFilterObj.isGroupTypeField = value == "group_type" ? true : false;
    advanceFilter1[index] = advanceFilterObj;
    setAdvanceFilter(advanceFilter1);
  };
  const handleOperatorChange = (event, index) => {
    let value = event.target.value;
    const advanceFilter1 = [...advanceFilter];
    let advanceFilterObj = advanceFilter1[index];
    advanceFilterObj.operator = value;
    advanceFilter1[index] = advanceFilterObj;
    setAdvanceFilter(advanceFilter1);
  };
  /*  const handleValueChangeGroupType = (index) => {
        let options = [...selectedGroupType];
        const advanceFilter1 = [...advanceFilter];
        let advanceFilterObj = advanceFilter1[index];
        advanceFilterObj.value = options.toString();
        advanceFilter1[index] = advanceFilterObj;
        setAdvanceFilter(advanceFilter1)
        toggleGroupTypeMenu();
    }; */
  const updateSelectedGroupType = (event, index) => {
    let eventValue = event.target.value;
    //let selctedVal = [...selectedGroupType];
    const advanceFilter1 = [...advanceFilter];
    let advanceFilterObj = advanceFilter1[index];
    let selectedVal = advanceFilterObj.value.split(",");
    if (event.target.checked) {
      selectedVal.push(eventValue);
    } else {
      selectedVal = selectedVal.filter((value) => value != eventValue);
    }
    advanceFilterObj.value = selectedVal.toString();
    advanceFilter1[index] = advanceFilterObj;
    setAdvanceFilter(advanceFilter1);
    //setSelectedGroupType(selctedVal);
  };
  const handleValueChange = (index, eventValue) => {
    let value = eventValue;
    const advanceFilter1 = [...advanceFilter];
    let advanceFilterObj = advanceFilter1[index];
    advanceFilterObj.value = value;
    advanceFilter1[index] = advanceFilterObj;
    setAdvanceFilter(advanceFilter1);
  };
  const handleAddFilter = (index) => {
    setGroupTypeField(false);
    const advanceFilter1 = [...advanceFilter];
    advanceFilter1.push({
      field: props.targetType == "group" ? "group_name" : "name",
      operator: "contains",
      value: "",
      notApplied: false,
      isGroupTypeField: false,
    });
    setAdvanceFilter(advanceFilter1);
  };
  const handleRemoveFilter = (index) => {
    const advanceFilter1 = [...advanceFilter];
    advanceFilter1.splice(index, 1);
    setAdvanceFilter(advanceFilter1);
  };
  const handleResetFilter = () => {
    setAdvanceFilterOpen(false);
    setAnchorEl(null);
    setAdvanceFilterApplied(false);
    setAdvanceFilter([
      {
        field: props.targetType == "group" ? "group_name" : "name",
        operator: "contains",
        value: "",
        notApplied: false,
        isGroupTypeField: false,
      },
    ]);
    setAdvanceFilterCondition("All");
    setAdvanceCustomFilter("");
    setPageNo(0);
    setSearchString("");
    setGroupTypeField(false);

    /* const { groupId, filterDeviceList } = props;
        const advanceFilterText = {
            groupId: groupId,
            searchText: "",
            advanceFilter: [],
            advanceFilterCondition: "All",
            advanceCustomFilter: "",
            pageNo: 0,
        }; */
    //filterDeviceList(advanceFilterText);
  };

  const handleAdvanceSearch = () => {
    const advanceFilter1 = [...advanceFilter];
    const advanceFilterCondition1 = advanceFilterCondition;
    const advanceCustomFilter1 = advanceCustomFilter;
    const { groupId, filterDeviceList } = props;
    const advanceFilterText = {
      groupId: groupId,
      searchText: "",
      advanceFilter: advanceFilter1,
      advanceFilterCondition: advanceFilterCondition1,
      advanceCustomFilter: advanceCustomFilter1,
      pageNo: 0,
    };
    setAdvanceFilterOpen(false);
    setAnchorEl(null);
    setAdvanceFilterApplied(false);
    setPageNo(0);

    filterDeviceList(advanceFilterText);
  };

  const handleCustomFilter = (event) => {
    let eventValue = event.target.value;
    setAdvanceCustomFilter(eventValue);
  };

  const handleFilterCondition = (event) => {
    let payload = event.target.value;
    let customFilterText = payload !== "Custom" ? "" : advanceCustomFilter;
    setAdvanceFilterCondition(payload);
    setAdvanceCustomFilter(customFilterText);
  };
  const toggleGroupTypeMenu = () => {
    const opneVal = openGroupTypeDialog;
    setOpenGroupTypeDialog(!opneVal);
  };
  const isCheckboxSelected = (index, value) => {
    const advanceFilter1 = [...advanceFilter];
    let advanceFilterObj = advanceFilter1[index];
    let groupType = advanceFilterObj.value.split(",");
    let data = find(groupType, function (o) {
      return o === value;
    });
    return data ? true : false;
  };
  const handleNotCondition = (event, index) => {
    const advanceFilter1 = [...advanceFilter];
    let advanceFilterObj = advanceFilter1[index];
    if (event.target.checked) {
      advanceFilterObj.notApplied = true;
    } else {
      advanceFilterObj.notApplied = false;
    }
    advanceFilter1[index] = advanceFilterObj;
    setAdvanceFilter(advanceFilter1);
  };
  const isNotSelected = (index) => {
    const advanceFilter1 = [...advanceFilter];
    let advanceFilterObj = advanceFilter1[index];
    return advanceFilterObj.notApplied;
  };

  return (
    <div
      // className={classes.tableContainer}
      style={
        show
          ? {
              maxHeight: "222px",
              border: "1px solid #1D5BBF",
              borderRadius: "3px",
            }
          : {
              height: "32px",
              backgroundColor: "#F0F5FC",
              borderRadius: "3px",
            }
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "32px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => {
              setShowVal();
            }}
            style={{padding:'2px',color:'#1D5BBF',marginLeft:'3px',marginRight:'3px'}}
           aria-label={show?"Collapse":"Expand"}
          >
            {show ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </IconButton>

          <Typography className="device_filter_title">
            {props.targetType == "group" ? GROUP_TITLE : TITLE}
          </Typography>
        </div>
        {show && (
          <div className="filter_type_box" style={{ marginTop: "9px" }}>
            <span
              style={{ marginRight: "8px", height: "16px" }}
              id="show-records-matching"
            >
              Show records matching
            </span>
            <SelectField

              variant="outlined"
              onChange={handleFilterCondition}
              value={advanceFilterCondition}
              labelId="show-records-matching"
              inputProps={{  role: 'combobox', }}
                      MenuProps={{
       MenuListProps:{

         'aria-label' :"Filter type options list",

        }}}
            >
              <MenuItem value={"Any"} children={"Any"} />
              <MenuItem value={"All"} children={"All"} />
              <MenuItem value={"Custom"} children={"Custom"} />
            </SelectField>
            <span style={{ marginLeft: "8px", height: "16px" }}>
              of the following criteria
            </span>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "10px",
        }}
      >
        {show ? (
          <div style={{ height: "140px", overflow: "auto" }}>
            <Table className={classes.table} aria-label="Criteria table">
              <TableHead style={{display: 'flex'}}>
                <TableRow className={classes.tableRow}>
                  <TableCell
                    align="left"
                    className={classes.tableHeaderCell}
                  ></TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeaderCell}
                    style={{width: '39px'}}
                  ></TableCell>
                  <TableCell align="left" className={classes.tableHeaderCell} id="filter-field" style={{width: props.targetType == "group" ? "111px" : "68px"}}>
                    Field
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeaderCell}
                    id="filter-selectfield-operator-filter"
                    style={{width: "82px"}}
                  >
                    Operator
                  </TableCell>
                  <TableCell align="left" className={classes.tableHeaderCell}>
                    Value
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.tableHeaderCell}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                displayRowCheckbox={false}
                deselectOnClickaway={false}
                showRowHover={false}
                stripedRows={false}
              >
                {advanceFilter.map((row, index) => (
                  <TableRow className={classes.tableBodyRow} key={index} style={{marginBottom: '1em'}}>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      <div
                        style={{
                          // display: "flex",
                          // justifyContent: "center",
                          // alignItems: "center",
                          margin:'0.5em 0'
                        }}
                      >
                        <Circle ariaLabel={index+1} data={index + 1} />
                      </div>
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isNotSelected(index)}
                              color="primary"
                              onChange={(event) =>
                                handleNotCondition(event, index)
                              }
                            />
                          }
                          label={"Not"}
                          style={{margin:'0.7em auto'}}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      <div>
                        <SelectField labelId="filter-field"
                          role="combobox"
                          variant="outlined"
                          onChange={(event, key, value) =>
                            handleFieldChange(event, index)
                          }
                          value={row.field}
                          style={{width: 150, maxWidth: 150}}

             inputProps={{  role: 'combobox', 'aria-label':'Filter at row'+index}}
                      MenuProps={{
       MenuListProps:{

         'aria-label' :"Filter field options list",

        }}}
                        >
                          {props.targetType == "device" &&
                            deviceFieldItems.map((item) => (
                              <MenuItem
                                value={item.value}
                                label={item.label}
                                children={item.label}
                              />
                            ))}
                          {props.targetType == "group" &&
                            groupFieldItems.map((item) => (
                              <MenuItem
                                value={item.value}
                                label={item.label}
                                children={item.label}
                              />
                            ))}
                        </SelectField>
                      </div>
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      <div>
                        <SelectField
                           role="combobox"
                          variant="outlined"
                          onChange={(event, key, value) =>
                            handleOperatorChange(event, index)
                          }
                          value={row.isGroupTypeField ? "in" : row.operator}
                          disabled={row.isGroupTypeField}
                          style={{width: 150, maxWidth: 150}}
                          labelId="filter-selectfield-operator-filter"
                          aria-label={FILTER}

             inputProps={{  role: 'combobox','aria-label':'Operator at row'+index }}
                      MenuProps={{
       MenuListProps:{

         'aria-label' :"Filter operator options list",

        }}}
                        >
                          {!row.isGroupTypeField &&
                            operatorOptions.map((operator) => (
                              <MenuItem
                                value={operator.value}
                                label={operator.label}
                                children={operator.label}
                              />
                            ))}
                          {row.isGroupTypeField && (
                            <MenuItem
                              value={"in"}
                              label={"in"}
                              children={"in"}
                            />
                          )}
                        </SelectField>
                      </div>
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      <div>
                        {!row.isGroupTypeField && (
                          <OutlinedInput
                            placeholder=""
                            required
                            value={row.value}
                            align="center"
                            onChange={(event) =>
                              handleValueChange(index, event.target.value)
                            }
                            inputProps={{'aria-label':'Value at row'+index}}
                            variant="outlined"
                            className="valueTextRoot"


                          />
                        )}
                        {row.isGroupTypeField && (
                          <>                            
                            <Button size="small" onClick={toggleGroupTypeMenu} variant="text"  endIcon={<ArrowDropDownIcon />}>
                                Type
                            </Button>
                            <Popover
                              open={openGroupTypeDialog}
                              anchorEl={openGroupTypeDialog}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "center",
                              }}
                              onClose={toggleGroupTypeMenu}
                              className="popover_groupType"
                              sx={{
                                border: 0,
                                left: '-250px'
                              }}
                            >
                              <List style={{marginLeft:'16px'}}>
                                {groupTypeOptions.map((type) => (
                                  <ListItem
                                    value={type.value}
                                    style={{ paddingBottom: "0px !important" }}
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        edge="start"
                                        checked={isCheckboxSelected(
                                          index,
                                          type.value
                                        )}
                                        onChange={(e) =>
                                          updateSelectedGroupType(e, index)
                                        }
                                        aria-labelledby={type.label}
                                        color="primary"
                                        value={type.value}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={type.label}
                                      primary={type.label}
                                    />
                                  </ListItem>
                                ))}
                                <button
                                  className="divGroupType__button"
                                  onClick={toggleGroupTypeMenu}
                                >
                                  {" "}
                                  {"Ok"}
                                </button>
                              </List>
                            </Popover>
                          </>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="left" className={classes.tableBodyCell}>
                      <div
                        style={{
                          display: "flex",
                          margin: "auto",
                          width: "100%",
                        }}
                      >
                        {advanceFilter.length - 1 === index ? (
                          <IconButton

                            onClick={() => handleAddFilter(index)}
                            aria-label="Add"
                          >
                            <AddIcon role="figure" title="Add" />
                          </IconButton>
                        ) : null}
                        {advanceFilter.length !== 1 &&
                        advanceFilter.length - 1 >= index ? (
                          <IconButton

                            onClick={() => handleRemoveFilter(index)}
                            aria-label="Remove"
                          >
                            <RemoveIcon role="figure" title="Remove" />
                          </IconButton>
                        ) : null}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : null}
        {advanceFilterCondition === "Custom" ? (
          <div style={{width: '400px'}}>

            <FormControl fullWidth style={{marginTop:'16px'}}>
              <InputLabel

                id="advancefilter-component-heading"
              >
                {"Custom Filter"}
              </InputLabel>


              <OutlinedInput fullWidth
                placeholder="e.g.((1 AND 2) OR 3)"
                value={advanceCustomFilter}
                 sx={{
   input: {

    "&::placeholder": {  // <----- Add this.
      color: 'black !important',
      opacity:0.8

    },
   }}}

                onChange={handleCustomFilter}
                variant="outlined"
                autoFocus={true}
                style={{ height: "20px" }}
                inputProps={{
                  "aria-label": "Custom Filter",
                }}
              />
              </FormControl>
            </div>

        ) : null}
      </div>
      {show ? (
        <div className="action" style={{ marginBottom: "10px" }}>
          <Button
            variant="text"
            style={{ marginRight: "20px" }}
            onClick={handleResetFilter}
          >
            {resetLabel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              props.applyFilter(
                advanceFilter,
                advanceFilterCondition,
                advanceCustomFilter
              )
            }
          >
            {props.targetType == "group" ? groupBtnName : deviceBtnName}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
export default MetricViewAdvancedFilter;
