/*
 * Copyright © 2024 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import axios from 'axios'
import config from './../config'
export class ComputerSystems {
  static getMonitoredSystemsCount() {
    const url = [config.basename, 'api/v1/computer-systems/monitoredComputerSystemCount'].join('/')
    return axios.get(url)
  }

  static get(params = {}) {
    const id = params.id
    delete params.id
    if (process.env.NODE_ENV == "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/xb77Al/computers");
    } else {
      var url = [config.basename, 'api/v1/computer-systems', id != null ? id : '']
      return axios.get(url.join('/'), {
        params: params,
      })
    }
  }

  static search(params = {}) {
    if (process.env.NODE_ENV === "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/1QXW0n/application/eFsW7r/computer-systems/search", params);
    }
    else {
      var url = [config.basename, 'api/v1/computer-systems/search'];
      return axios.post(url.join('/'), params)
    }
  }

  static export(params = {}) {
    if (process.env.NODE_ENV === "development") {
      return axios.get("http://10.252.25.208:8080/castlemock/mock/rest/project/1QXW0n/application/eFsW7r/computer-systems/search", params);
    } else {
      const url = [config.basename, 'api/v1/computer-systems/exportInventoryData']
      return axios.post(url.join('/'), params)
    }
  }

  static getCount() {
    var url = [config.basename, 'api/v1/computer-systems/counts?type=windows&type=unix&type=apache']
    return axios.get(url.join('/'))
  }

  static delete(ids = []) {
    var url = [config.basename, 'api/v1/computer-systems/delete']
    return axios.post(url.join('/'), ids)
  }

  static deleteFromInventory(ids = [], options) {
    let formData = { csIds: [] }
    formData.csIds = ids
    Object.assign(formData, options)
    var url = [config.basename, 'api/v1/devices/deleteDevices']
    return axios.post(url.join('/'), formData)
  }

  static getDeletedDevices() {
    var url = [config.basename, 'api/v1/computer-systems/deletedDevices']
    return axios.get(url.join('/'))
  }

  static undelete(ids = []) {
    var url = [config.basename, 'api/v1/computer-systems/undelete']
    return axios.post(url.join('/'), ids)
  }

  static discoveryImport(formData) {
    var url = [config.basename, 'api/v1/computer-systems/discoveryImport']
    return axios.post(url.join('/'), formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  }

  static getInventory() {
    var url = process.env.NODE_ENV == "development" ?
      'http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/inventoryTree' :
      [config.basename, 'api/v1/app-discovery/inventoryTree'].join('/');

    return axios.get(url)
  }

  //dp039841
  static getSavedColumns() {
    var url = process.env.NODE_ENV == "development" ?

      // uncomment to get all columns
      "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/column"

      // uncomment to get only four columns
      // "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/fourColumns"
      :
      [config.basename, 'api/v1/computer-systems/column'].join("/");

    return axios.get(url);
  }

  static saveSelectedColumns(params) {
    var url = [config.basename, 'api/v1/computer-systems/column'].join("/");
    return axios.post(url, params);
  }

  static getMcsDetails(id) {
    return axios.get("/mcs-ui-app/api/profile-summary?addMonitoringStatus=true&fullSummary=true&groupId=" + id +
      "&rootProfiles=true&useEnhancedTemplates=true");
  }

  static getCSMcsDetails(csId) {
    return axios.get("/mcs-ui-app/api/profile-summary?addMonitoringStatus=true&csId=" + csId +
      "&fullSummary=true&rootProfiles=true&useEnhancedTemplates=true");
  }

  static getAlarmPolicyDetails(groupId) {
    return axios.post("/policy_management_ws/api/v0/policy/list", {
      groupId: groupId
    });
  }

  static getCSAlarmPolicyDetails(csId) {
    return axios.post("/policy_management_ws/api/v0/policy/export?deviceId=" + csId);
  }

  static getLookupList(lookupType) {
    var url = ''
    switch (lookupType) {
      case 'monitoringprobes':
        url = process.env.NODE_ENV == "development" ?
          // uncomment to get all probes
          "http://10.252.25.208:8080/castlemock/mock/rest/project/kIbM1R/application/mEyHAm/monitoringprobes"
          :
          [config.basename, 'api/v1/computer-systems/monitoringprobes'].join("/");
        break;
      default:
        url = process.env.NODE_ENV == "development" ?
          // uncomment to get all roles
          "http://10.252.25.208:8080/castlemock/mock/rest/project/kIbM1R/application/mEyHAm/roles"
          :
          [config.basename, 'api/v1/computer-systems/roles'].join("/");
        break;
    }

    return axios.get(url);
  }

  static decommissionFromInventory(ids = [], options) {
    let formData = { csIds: [] }
    formData.csIds = ids;
    Object.assign(formData, options)
    let url = config.basename + '/api/v1/computer-systems/bulk_actions';
    if (process.env.NODE_ENV === "development") {
      url = 'http://10.252.25.208:8080/castlemock/mock/rest/project/1QXW0n/application/eFsW7r/bulk_actions'
    }
    return axios.post(url, formData)
  }

  static fetchDecommissionJobs() {
    let url = config.basename + '/api/v1/devices/bulk_action_jobs';
    if (process.env.NODE_ENV === "development") {
      url = 'http://10.252.25.208:8080/castlemock/mock/rest/project/1QXW0n/application/eFsW7r/bulk_action_jobs'
    }
    return axios.post(url, JSON.parse(sessionStorage.getItem('jobPayload')))
  }

  static retryDeletedDevices(payload) {
    let url = config.basename + '/api/v1/devices/bulk_action_retry';
    if (process.env.NODE_ENV === "development") {
      url = 'http://10.252.25.208:8080/castlemock/mock/rest/project/1QXW0n/application/eFsW7r/bulk_actions'
    }
    return axios.post(url, payload)
  }

  static exportDeleteDevicesStatusReport() {
    let url = config.basename + '/api/v1/devices/bulk_action_export';
    if (process.env.NODE_ENV === "development") {
      url = 'http://10.252.25.208:8080/castlemock/mock/rest/project/1QXW0n/application/eFsW7r/bulk_action_export'
    }
    const payload = JSON.parse(sessionStorage.getItem('jobPayload'));
    payload.pageNumber = -1;
    payload.pageSize = -1;
    return axios.post(url, payload, { responseType: 'blob' });
  }

}
