/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import "./QosChartUrl.less";
import QosChart from "./QosChart";
import React, { useEffect } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  CircularProgress,
  Divider,
  Button,
} from "@mineral/core";
import {
  setAggregationLevel,
  setTimeFrameAction,
  findAggregationLevel,
  expandChart
} from "../../../../../../../api/performanceReports/actions";
import { timeFrameList, aggregationOptions } from "./QosChartToolbar";
import moment from "moment";
import X2JS from "xmljson-converter";

//DateTimePicker - mui
/*import DateTextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";*/

import { MineralDateTimePicker } from "../../../../../../common/datepickers/MineralDateTimePicker";
import DatePickerIcon from "../../../../../../icons/DatePickerIcon";
import { PRD } from "../../../../../../../api/performanceReports/prd";
import { has } from "../../../../../../../utils/lodash-utils";
import HelpingText from "../../../../../../common/HelpingText";

const getJsonFromXML = (xmlString) => {
  const x2js = new X2JS({
    //emptyNodeForm: "object",
    arrayAccessFormPaths: [
      "State.page.row",
      "State.page.row.chart",
      "State.page.row.chart.series",
      "State.groups.group",
      "State.groups.group.rowSelectors.rowSelector",
    ],
  });
  return x2js.xml_str2json(xmlString);
};

const QosChartUrl = (props) => {
  const dispatch = useDispatch();

  const timeFrameRef = React.useRef();
  const [showCustomDate, setShowCustomDate] = React.useState(false);

  const [qosData, setQosData] = React.useState(null);
  const [loadingState, setLoadingState] = React.useState("initial");

  const [open, setOpen] = React.useState(false);

  const [qosStartDate, setQosStartDate] = React.useState(new Date(moment().subtract(1, "hours")));
  const [qosEndDate, setQosEndDate] = React.useState(new Date());
  const [startDateError, setStartDateError] = React.useState(null);
  const [endDateError, setEndDateError] = React.useState(null);

  const aggLevel = useSelector(
    (state) => state.performanceReports.chartData.State.aggLevel
  );
  const timeFrame = useSelector(
    (state) => state.performanceReports.chartData.State.timeFrame
  );

  const handleSelectClose = () => {
    setOpen(false);
  };

  const handleSelectOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (qosData && qosData.timeData.timeFrame === timeFrameList[4]) {
      dispatch(
        setTimeFrameAction(timeFrameList[4], {
          startDate: qosStartDate.getTime ? qosStartDate.getTime(): new Date(moment().subtract(1, "hours")),
          endDate: qosEndDate.getTime ? qosEndDate.getTime() : new Date(),
        })
      );
    } else if (qosData) {
      dispatch(setTimeFrameAction(qosData.timeData.timeFrame, ""));
    }
  }, [qosData, qosStartDate, qosEndDate, dispatch]);

  useEffect(() => {
    if (qosData && qosData.chartData) {
      dispatch(expandChart(qosData.chartData.chartId));
      if (qosData.timeData.timeFrame === timeFrameList[4]) {
        dispatch(
          setTimeFrameAction(timeFrameList[4], {
            startDate: +qosData.timeData.startTime,
            endDate: +qosData.timeData.stopTime,
          })
        );
      } else {
        dispatch(setTimeFrameAction(qosData.timeData.timeFrame, ""));
      }
      dispatch(
        setAggregationLevel(
          qosData.timeData.aggregationInterval && qosData.timeData.aggLevel
            ? qosData.timeData.aggLevel
            : "Automatic"
        )
      );
      if (qosData.timeData.timeFrame === timeFrameList[4]) {
        setQosStartDate(new Date(+qosData.timeData.startTime));
        setQosEndDate(new Date(+qosData.timeData.stopTime));
      }
    }
  }, [dispatch, qosData]);

  const parseReportWithCustomSourceAndTarget = (chartData) => {
    const tempChartData = Object.assign({}, chartData);
    const params = new URLSearchParams(props.location.search);
    let source = params.get("source");
    let target = params.get("target");

    if (!source && !target) {
      return tempChartData;
    }

    if (Array.isArray(chartData.series)) {
      const tempSeriesData = [...chartData.series];
      tempChartData.series = tempSeriesData.filter((i) => {
        if (source && target) {
          if (i.source === source && i.target === target) {
            return true;
          }
        } else {
          if (source && i.source === source) {
            return true;
          }
          if (target && i.target === target) {
            return true;
          }
        }

        return false;
      });
    }

    return tempChartData;
  };

  const getChartDataUsingReportName = async (reportName) => {
    try {
      const params = new URLSearchParams(props.location.search);
      const chartId = params.get("chartId");
      const response = await PRD.loadReport(reportName);
      const data = response.data;
      if (data.reportStructure !== "" && data.reportStructure !== null) {
        const newData = getJsonFromXML(data.reportStructure);
        let chartData = null;
        let timeData = null;

        if (chartId) {
          if (Array.isArray(newData.State.page.row)) {
            newData.State.page.row.forEach(row => {
              if (
                has(row, "chart") &&
                Array.isArray(row.chart)
              ) {
                const tempChartData = row.chart.find(
                  (i) => i.chartId == chartId
                );
                if (tempChartData) {
                  chartData = tempChartData;
                }
              }
            })
          }

        } else if (has(newData, "State.page.row[0].chart[0]")) {
          chartData = newData.State.page.row[0].chart[0];
        }

        if (chartData) {
          chartData.legend.table = chartData.legend.table === "true";
          const aggrigationData = findAggregationLevel(
            parseInt(newData.State.aggregationInterval),
            parseInt(newData.State.TimeStart),
            parseInt(newData.State.TimeStop)
          );

          timeData = {
            aggregationInterval: parseInt(newData.State.aggregationInterval),
            aggLevel: aggrigationData.aggregationInterval || "Automatic",
            timeFrame: newData.State.timeFrame
              ? newData.State.timeFrame
              : aggrigationData.timeFrame,
            startTime: parseInt(newData.State.TimeStart),
            stopTime: parseInt(newData.State.TimeStop),
          };

          chartData = parseReportWithCustomSourceAndTarget(chartData);
          setQosData({ chartData, timeData, source: "", qosValue: "" });
          setLoadingState("complete");
        } else {
          setQosData(null);
          setLoadingState("noContent");
        }
      }
    } catch (ex) {
      //console.log(ex);
    }
  };

  useEffect(() => {
    const searchQuery = props.location.search;
    if (searchQuery) {
      const params = new URLSearchParams(searchQuery);
      const paramsData = params.get("params");
      const reportName = params.get("report");

			if (paramsData) {
				setQosData(JSON.parse(decodeURIComponent(window.atob(paramsData))));
				setLoadingState("complete");
			} else if (reportName) {
				getChartDataUsingReportName(reportName);
			} else {
				alert("Either params or QOS Id is required");
			}
		} else {
			alert("Search Query not found")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.location.search]);

  return (
    <div className="chart-url-container">
      {!qosData && loadingState === "initial" ? (
        <CircularProgress />
      ) : !qosData && loadingState === "noContent" ? (
        <div>
          <p>No data found</p>
        </div>
      ) : (
        <>
          <div className="toolbar-left-action-content" style={{display:'none'}}>
            <FormControl className="timeFrame-dropdown">
              <InputLabel id="tame-frame-select-label">Time Frame</InputLabel>
              <Select
                labelId="tame-frame-select-label"
                ref={timeFrameRef}
                id="time-range-select"
                inputProps={{
                  role: "combobox",
                }}
                value={timeFrame ? timeFrame : "Custom"}
				onClose={handleSelectClose}
                onOpen={handleSelectOpen}
                onChange={(event) => {
					if (event.target.value == timeFrameList[4]) {
						setShowCustomDate(true);
					  } else {
						handleSelectClose();
						dispatch(setTimeFrameAction(event.target.value, ""));
						setShowCustomDate(false);
					  }
					  dispatch(setAggregationLevel(aggLevel));
                }}
				disabled={props.isloading}
              >
                {timeFrameList.map((source, index) => (
                  (source == "Last Month") ? (
                    <MenuItem
                      classes={{ root: "timeFrame-menu-item" }}
                      key={`${source}-${index}-menuitem`}
                      value={source}
                      divider={true}
                    >
                      {source}
                    </MenuItem>)
                    :
                    ((source == "Custom") ?
                      (<MenuItem
                        classes={{ root: "timeFrame-menu-item" }}
                        key={`${source}-${index}-menuitem`}
                        value={source}
                        style={{ pointerEvents: "none" }}
                      >
                        {source}
                      </MenuItem>) : (<MenuItem
                        classes={{ root: "timeFrame-menu-item" }}
                        key={`${source}-${index}-menuitem`}
                        value={source}
                      >
                        {source}
                      </MenuItem>)
                    )
                ))}

<div style={{ display: "grid", margin: "1px 10px 10px 10px" }}>
                  {(
                    <>
                      <div className="qos-performance-date-picker">
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            marginRight: "8px",
                          }}
                        >
                          <MineralDateTimePicker
                            value={qosStartDate}
                            onValueChange={(event) => {
                              if (moment(event).isAfter(moment(qosEndDate)))
                                setStartDateError('Start date cannot be after end date')
                              else {
                                setStartDateError(null)
                                setQosStartDate(moment(event).toDate());

                              }
                            }}


                            variant="inline"
                            //maxDateTime={endDate}
                            keyboardIcon={<DatePickerIcon />}
                            label="Start Date"
                            ampm={false}
                          />
                          <HelpingText
                            hint={null}
                            errorMessage={startDateError}
                            disabled={!startDateError}
                          />


                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            marginRight: "8px",
                          }}
                        >
                          <MineralDateTimePicker
                            value={qosEndDate}
                            onValueChange={(event) => {
                              if (moment(event).isBefore(moment(qosStartDate).add(1, "minute")))
                                setEndDateError('End date cannot be before start date')
                              if (moment(event).isAfter())
                                setEndDateError('End date cannot be in future')
                              else {
                                setEndDateError(null)
                                setQosEndDate(moment(event).toDate());
                              }
                            }}


                            variant="inline"
                            // minDateTime={new Date(moment(startDate).add(1, "minute"))}
                            // maxDateTime={new Date()}
                            keyboardIcon={<Tooltip title="Change date"><DatePickerIcon /></Tooltip>}
                            label="End Date"
                            ampm={false}
                          />
                          <HelpingText
                            hint={null}
                            errorMessage={endDateError}
                            disabled={!endDateError}
                          />

                        </div>

                      </div>
                    </>
                  )}
                </div>
                <Divider />
                <div>
                  <Button
                    variant="text"
                    children="Apply"
                    style={{ float: "right" }}
                    disabled={
                      startDateError || endDateError || qosStartDate == null || qosEndDate == null || !moment(qosStartDate).isBefore(qosEndDate)
                    }
                    onClick={(event) => {
                      handleSelectClose();
                      dispatch(
                        setTimeFrameAction("Custom", {
                          startDate: moment(qosStartDate).valueOf(),
                          endDate: moment(qosEndDate).valueOf(),
                        })
                      );
                      dispatch(setAggregationLevel(aggLevel));
                    }}
                  />
                </div>
              </Select>
            </FormControl>

            <FormControl className="timeFrame-dropdown">
              <InputLabel id="agg-level-select-label">
                Aggregation Interval
              </InputLabel>
              <Select
                id="agg-level-select"
                aria-labelledby="agg-level-select-label"
                inputProps={{
                  "aria-label": " Aggregation Interval",
                  role: "combobox",
                }}
                MenuProps={{
                  MenuListProps: {
                    "aria-label": `Aggregation Interval options list`,
                    "aria-labelledby": "agg-level-select-label",
                  },
                }}
                value={aggLevel ? aggLevel : "Automatic"}
                onChange={(event) => {
                  dispatch(setAggregationLevel(event.target.value));
                }}
              >
                {aggregationOptions.map((source, index) => (
                  <MenuItem
				  	key={`${source}-${index}-agglevel`}
					value={source}
					disabled={(timeFrame==="Last Month" && (index===2 || index===3)) && true}
				>
                    {source}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="chart-container">
            <DndProvider backend={HTML5Backend}>
            {qosData!==null && <QosChart
                chartData={qosData.chartData}
                data={{
                  source: qosData.source,
                  qosValue: qosData.qosValue,
                  target: qosData.target,
                }}
                fromUrl
              />}
            </DndProvider>
          </div>
        </>
      )}
    </div>
  );
};
export default QosChartUrl;
