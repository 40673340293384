/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  SET_ACL,
  FETCH_ACL,
  FETCH_ACL_SUCCESS,
  FETCH_ACL_FAILURE,
  SET_SELF_CERT_STATE,
} from "./actionTypes";
import fetchStatusTypes from "./../fetchStatusTypes";
import { get } from "../../utils/lodash-utils";

const acl = (
  state = {
    isFetching: false,
    fetchStatus: fetchStatusTypes.UNDEFINED,
    didInvalidate: false,
    aclInfo: {},
    acl: "",
    canEditMaintMode: false,
    canEditMaintModeDevice: false,
    canViewMaintenanceMode: false,
    canDesignDashboard: false,
    canManagePolicy: false,
    isBusUser:false,
    isAccountUser:false,//dp039841
    selfcert:false,
    canManageDiscovery:false,
    canDeployRobot:false,
    canAdministerAccount:false,
    isPortalAdministrator:false,
    canManageMCS:false,
    canScheduleReports:false,
    canEditDevice:false,
    canListDesigner:false,
    canListViewer: false,
    canProductUsageData:false,
    accountName:""
  },
  action
) => {
  switch (action.type) {
    case SET_ACL:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        didInvalidate: false,
        // normalize `userName`
        acl: action.acl,
      });
    case FETCH_ACL:
      return Object.assign({}, state, {
        isFetching: true,
        fetchStatus: fetchStatusTypes.FETCHING,
        didInvalidate: false,
        aclInfo: {},
      });
    case FETCH_ACL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.COMPLETE,
        didInvalidate: false,
        // normalize `userName`
        canEditMaintMode: get(action.aclInfo, "roles", "").includes(
          "Edit Maintenance Mode Schedules"),
        canEditMaintModeDevice: get(action.aclInfo, "roles", "").includes(
          "Edit Maintenance Mode Devices"),
        canViewMaintenanceMode: get(action.aclInfo, "roles", "").includes(
          ",Maintenance Mode") || get(action.aclInfo, "roles", "").includes(
            ", Maintenance Mode") ,
        canDesignDashboard:
          get(action.aclInfo, "roles", "").includes("Dashboard Design") ||
          get(action.aclInfo, "roles", "").includes("Dashboard Designer"),
        canManagePolicy: get(action.aclInfo, "roles", "").includes(
          "Policy Management"
        ),
        canViewPolicy: get(action.aclInfo, "roles", "").includes(
          "Policy Basic"
        ),
        isBusUser:get(action.aclInfo, "userType", "").includes(
          "nimbus"
        ),
        // //dp039841
        // isAccountUser:get(action.aclInfo, "userType", "").includes(
        //   "contact"
        // ),
        accountName: get(action.aclInfo, "accountName", ""),
        canManageDiscovery: get(action.aclInfo, "roles", "").includes(
          "Discovery Management"),
        canDeployRobot: get(action.aclInfo, "roles", "").includes(
         "USM Automatic Robot Installation"),
        canAdministerAccount: get(action.aclInfo, "roles", "").includes(
         "Account Administration"),
        isPortalAdministrator: get(action.aclInfo, "roles", "").includes(
         "Portal Administration"),
        canManageMCS: get(action.aclInfo, "roles", "").includes(
         "USM Self Service Monitoring"),
         canScheduleReports: get(action.aclInfo, "roles", "").includes(
         "Report Scheduler"),
         canPerformanceReports: get(action.aclInfo, "roles", "").includes(
         "QoS Access"),
         canEditMCSTemplates: get(action.aclInfo, "roles", "").includes(
          "USM Edit Monitoring Templates"),
          canMonitorConfigService: get(action.aclInfo, "roles", "").includes(
            "USM Monitoring Configuration Service"),
            canPersonalize:   get(action.aclInfo, "roles", "").includes(
              "User Customization"),
          canEditDevice: get(action.aclInfo, "roles", "").includes(
              "OC Device Update"),
          canListDesigner: get(action.aclInfo, "roles", "").includes(
              "List Designer"),
          canListViewer: get(action.aclInfo, "roles", "").includes(
              "List Viewer"),
        canProductUsageData: get(action.aclInfo, "roles", "").includes(
          "Product Usage Data"),
        aclInfo: Object.assign({}, action.aclInfo),
      });
    case FETCH_ACL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        fetchStatus: fetchStatusTypes.FAIL,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        aclInfo: {},
      });
      case SET_SELF_CERT_STATE:
        return Object.assign({}, state, {
          isFetching: false,
          fetchStatus: fetchStatusTypes.COMPLETE,
          didInvalidate: false,
          selfcert: action.selfcert,
        });
    default:
      return state;
  }
};
export default acl;
