/**
 * Copyright © 2024 Broadcom. All rights reserved. The term "Broadcom" refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION "AS IS" WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */

import React from 'react';
import { IconButton, Chip, Link } from '@mineral/core';
import { Cross, Success } from "@mineral/icons";
import { severityObj } from "./../alarms/SeverityBadges";
import { Route } from 'react-router-dom'

const addAlarmIconToData = (model) => {
  var item = model.data;
  if (!item) {
    return <div></div>;
  }
  const severity = item.thresholdSeverity ? item.thresholdSeverity.toUpperCase() : '';
  let sevObj = severityObj(severity);
  return (
    <Chip
    style={{
      backgroundColor: sevObj?.backgroundColor,
      color: sevObj?.color,
      border: sevObj?.border ? sevObj?.border : null,
      margin: "8px",
    }}
    label={sevObj?.label}
  />
  );
}

const getStyle = (model, type) => {
  if(type === 'L')
  return model.data.monitored ? '0rem' : '0.6rem';
  else if(type === 'T')
  return model.data.monitored ? '-0.5rem' : '0rem';
}

const samplesDataCellRenderer = (model) => {
  if (!model.data) {
    return <div></div>;
  }
  let diff = 0;
  let color = '#B80D0D'; // red
  const expectedCount = parseInt(model.data?.expectedSampleCount);
  const actualCount = parseInt(model.data?.actualSampleCount);
  // expected - actual
  diff = expectedCount - actualCount;
   if(diff === 0) {
      diff = 100;
      color = '#008000'; // green
   } else {
      diff = 100 - (diff/expectedCount) * 100;
   }
    return (
      <>
        <div style={{ width: '180px', position: 'relative', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <div style={{ width: '100%', display: 'block' }}>
            <div
              style={{
                width: `${parseInt(diff)}%`,
                backgroundColor: `${color}`,
                height: '10px',
                float: 'left',
                display: 'inline-block'
              }}>
            </div>
          </div>
        </div>
        <div style={{ width: '180px', position: 'absolute', marginBottom: '-1.8rem', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{fontSize: '12px'}}>{'Actual: '}<span style={{fontWeight: 600}}>{actualCount ? actualCount : 0}</span></div>
              <div style={{fontSize: '12px'}}>{'Expected: '}<span style={{fontWeight: 600}}>{expectedCount ? expectedCount: 0}</span></div>
        </div>
      </>
    );
}

const monitoredComponent = (model) => {
  if (!model.data) {
    return <div></div>;
  }
  return (
    <IconButton style={{marginLeft: getStyle(model, 'L'), marginTop: getStyle(model, 'T')}}
      color={model.data.monitored ? "success" : "error"}
      children={model.data.monitored ? <Success/>: <Cross style={{fontSize: '0.9rem'}}/>}
      variant={model.data.monitored ? "text": "contained"}
      size={model.data.monitored ? "large": "small"}
    />
  );
}

export const getMonitoringGoveranceGridCols = (selectedColumns, currentTab) => {
  const gridCols = currentTab === TAB_MENUS.QOS ? qosGridCols : thresholdGridCols;
  if(selectedColumns.length > 0) {
    return [...gridCols.filter((col) => col.optional != true), ...selectedColumns];
  } else {
    return gridCols.filter((col) => col.optional != true);
  }

}

export const getMGAvailableGridCols = (currentTab) => {
  const gridCols = currentTab === TAB_MENUS.QOS ? qosGridCols : thresholdGridCols;
  return gridCols.filter((col) => col.optional === true);
}

export const getMGAllGridCols = (currentTab) => {
  return currentTab === TAB_MENUS.QOS ? qosGridCols : thresholdGridCols;
}

export const TAB_MENUS = {
  QOS: "qos",
  THRESHOLD: "threshold",
};

const qosGridCols = [{
  headerName: "Device Name",
  field: "deviceName",
  tooltipField: "deviceName",
  headerTooltip: "Device Name",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 150,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 0
},
{
  headerName: "QoS Name",
  field: "qosName",
  tooltipField: "qosName",
  headerTooltip: "QoS Name",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 250,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 1
},
{
  headerName: "Source",
  field: "source",
  tooltipField: "source",
  headerTooltip: "Source",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 150,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 2
},
{
  headerName: "Target",
  field: "target",
  tooltipField: "target",
  headerTooltip: "Target",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 150,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 3
},
{
  headerName: "Origin",
  field: "origin",
  tooltipField: "origin",
  headerTooltip: "Origin",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 150,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 4
},
{
  headerName: "Probe",
  field: "probe",
  tooltipField: "probe",
  headerTooltip: "Probe",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 150,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 5
},
{
  headerName: "Last Sample Time",
  field: "lastSampleTime",
  tooltipField: "lastSampleTime",
  headerTooltip: "Last Sample Time",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 200,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  optional: true,
  order: 6
},
{
  headerName: "Last Sample Rate",
  field: "lastSampleRate",
  tooltipField: "lastSampleRate",
  headerTooltip: "Last Sample Rate",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 200,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  optional: true,
  order: 7
},
{
  headerName: "Last Sample Value",
  field: "lastSampleValue",
  tooltipField: "lastSampleValue",
  headerTooltip: "Last Sample Value",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 200,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  optional: true,
  order: 8
},
{
  headerName: "Samples Expected vs Actual",
  field: "expectedActual",
  // tooltipField: "samplesDiff",
  headerTooltip: "Samples Expected vs Actual",
  cellRendererFramework: samplesDataCellRenderer,
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 250,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agCustomTextColumnFilter",
  options: [
    { id: 'EQUALS', name: 'Equals' },
    { id: 'NOTEQUALS', name: 'Not equals' },
    { id: 'GT', name: 'Greater Than' },
    { id: 'GTE', name: 'Greater Than Or Equal' },
    { id: 'LT', name: 'Less Than' },
    { id: 'LTE', name: 'Less Than Or Equal' },
  ],
  suppressSizeToFit: false,
  columnFilter: true,
  order: 9
},
{
  headerName: "Monitored",
  field: "monitored",
  tooltipField: "monitored",
  headerTooltip: "Monitored",
  cellRendererFramework: monitoredComponent,
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 120,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  suppressSizeToFit: false,
  optional: true,
  filter: "customSetFilter",
  filterParams: {
    values: ["Yes", "No"]
  },
  order: 10
}];

const thresholdGridCols = [{
    headerName: "Device Name",
    field: "deviceName",
    tooltipField: "deviceName",
    headerTooltip: "Device Name",
    suppressMovable: true,
    suppressResize: false,
    resizable:true,
    minWidth: 150,
    flex: 0.9,
    cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
    sortable: true,
    filter: "agTextColumnFilter",
    suppressSizeToFit: false,
    columnFilter: true,
    order: 0
},
{
  headerName: "QoS Name",
  field: "qosName",
  tooltipField: "qosName",
  headerTooltip: "QoS Name",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 250,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 1
},
{
  headerName: "Source",
  field: "source",
  tooltipField: "source",
  headerTooltip: "Source",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 150,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 2
},
{
  headerName: "Target",
  field: "target",
  tooltipField: "target",
  headerTooltip: "Target",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 150,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 3
},
{
  headerName: "Origin",
  field: "origin",
  tooltipField: "origin",
  headerTooltip: "Origin",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 150,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 4
},
{
  headerName: "Probe",
  field: "probe",
  tooltipField: "probe",
  headerTooltip: "Probe",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 150,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 5
},
{
  headerName: "Alarm Policy",
  field: "alarmPolicy",
  tooltipField: "alarmPolicy",
  headerTooltip: "Alarm Policy",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 200,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  cellRendererFramework: (params) => {
    return (
     <Route render={({ history}) => (
        <Link
          style={{
            overflow: "hidden",
            color: "#3272D9",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: 'pointer'
          }}
        onClick={() => {history.push('/settings/alarm-policies/'+params.data?.policyId);}}
        >
          {params.data?.alarmPolicy ? params.data.alarmPolicy : ""}
        </Link>
    )} />
    );
  },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 6
},
{
  headerName: "Threshold Operator",
  field: "thresholdOperator",
  tooltipField: "thresholdOperator",
  headerTooltip: "Threshold Operator",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 200,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agCustomMultiSelectColumnFilter",
  options: [
    { id: 'EQUALS', name: 'Equals To (=)' },
    { id: 'NOTEQUALS', name: 'Not Equals To (!=)' },
    { id: 'GT', name: 'Greater Than (>)' },
    { id: 'GTE', name: 'Greater Than Or Equal (>=)' },
    { id: 'LT', name: 'Less Than (<)' },
    { id: 'LTE', name: 'Less Than Or Equal (<=)' },
  ],
  suppressSizeToFit: false,
  columnFilter: true,
  order: 7
},
{
  headerName: "Threshold Severity",
  field: "thresholdSeverity",
  tooltipField: "thresholdSeverity",
  headerTooltip: "Threshold Severity",
  cellRendererFramework: addAlarmIconToData,
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 200,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "5px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agCustomMultiSelectColumnFilter",
  options: [
    { id: 'Info', name: 'Info' },
    { id: 'Warning', name: 'Warning' },
    { id: 'Minor', name: 'Minor' },
    { id: 'Major', name: 'Major' },
    { id: 'Critical', name: 'Critical' },
  ],
  suppressSizeToFit: false,
  columnFilter: true,
  order: 8
},
{
  headerName: "Threshold Type",
  field: "thresholdType",
  tooltipField: "thresholdType",
  headerTooltip: "Threshold Type",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 200,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  optional: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 9
},
{
  headerName: "Threshold Value",
  field: "thresholdValue",
  tooltipField: "thresholdValue",
  headerTooltip: "Threshold Value",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 200,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  filter: "agTextColumnFilter",
  suppressSizeToFit: false,
  columnFilter: true,
  order: 10
},
{
  headerName: "Policy Metric Precedence",
  field: "policyPrecedence",
  tooltipField: "policyPrecedence",
  headerTooltip: "Policy Metric Precedence",
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 200,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  optional: true,
  filter: "agCustomTextColumnFilter",
  options: [
    { id: 'EQUALS', name: 'Equals' },
    { id: 'NOTEQUALS', name: 'Not equals' },
    { id: 'GT', name: 'Greater Than' },
    { id: 'GTE', name: 'Greater Than Or Equal' },
    { id: 'LT', name: 'Less Than' },
    { id: 'LTE', name: 'Less Than Or Equal' },
  ],
  suppressSizeToFit: false,
  columnFilter: true,
  order: 11
},
{
  headerName: "Monitored",
  field: "monitored",
  tooltipField: "monitored",
  headerTooltip: "Monitored",
  cellRendererFramework: monitoredComponent,
  suppressMovable: true,
  suppressResize: false,
  resizable:true,
  minWidth: 120,
  flex: 0.9,
  cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
  sortable: true,
  suppressSizeToFit: false,
  optional: true,
  filter: "customSetFilter",
  filterParams: {
    values: ["Yes", "No"]
  },
  order: 12
}];
