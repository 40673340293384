// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Cancel
  content["Cancel"] =
  "Cancel";

  //Ok
  content["Save"] =
  "Save";

  //Deep Clean Tooltip
  content["A deep clean involves removing the robot/agent, deleting the robot cache, probe configurations, QoS measurements, and acknowledging any open alarms related to the device. Uninstallation is supported for robots from version 23.4.2"] =
  "A deep clean involves removing the robot/agent, deleting the robot cache, probe configurations, QoS measurements, and acknowledging any open alarms related to the device. Uninstallation is supported for robots from version 23.4.2";

  //Ok
  content["Delete"] =
  "Delete";

  //Delete Device
  content["Delete Device"] =
  "Delete Device";

  //Delete devices text
  content["Are you sure you want to queue devices for deletion? Please make sure that monitoring for all these devices stopped before this operation."] =
  "Are you sure you want to queue devices for deletion? Please make sure that monitoring for all these devices stopped before this operation.";

  //Delete device text
  content["Are you sure you want to queue device for deletion? Please make sure that monitoring for this device stopped before this operation."] =
  "Are you sure you want to queue device for deletion? Please make sure that monitoring for this device stopped before this operation.";

  //Prevent rediscovery
  content["Prevent rediscovery"] =
  "Prevent rediscovery";

  //Deep Clean
  content["Deep Clean"] =
  "Deep Clean";

  //Acknowledge alarms
  content["Acknowledge alarms"] =
  "Acknowledge alarms";

  //Delete measurements
  content["Delete measurements"] =
  "Delete measurements";

  // help message1
  content["Note: This process may take a few minutes"] =
  "Note: This process may take a few minutes";

  //delete in progress
  content["Deletion is in progress. Click on Refresh button to see the changes."] =
  "Deletion is in progress. Click on Refresh button to see the changes.";

  //Click here
  content["Click here"] =
  "Click here";

  //View device status
  content["to view the device(s) status for the Job Id"] =
  "to view the device(s) status for the Job Id";

  //File Exported msg
  content["The file has been exported successfully!"] =
  "The file has been exported successfully!";

  //File Exported Failed msg
  content["The file could not be exported due to an error."] =
  "The file could not be exported due to an error.";

  //Inventory Management
  content["Inventory Management"] =
  "Inventory Management";

  //Inventory
  content["Inventory"] =
  "Inventory";

  //Delete Devices Status
  content["Delete Devices Status"] =
  "Delete Devices Status";

  //Retry Failed Jobs
  content["Retry Failed Jobs"] =
  "Retry Failed Jobs";

  //Refresh
  content["Refresh"] =
  "Refresh";

  //Export
  content["Export"] =
  "Export";

  //File Details:
  content["File Details:"] =
  "File Details:";

  //File Name
  content["File Name:"] =
  "File Name:";

  //File Type:
  content["File Type:"] =
  "File Type:";

  //File upload text
  content["Select file before Pressing the Upload button"] =
  "Select file before Pressing the Upload button";

  //Import
  content["Import"] =
  "Import";

  //device import
  content["Import Device"] =
  "Import Device";

  // Upload an XML file
  content["Upload an XML file"] =
  "Upload an XML file";

  // Re-upload an XML file
  content["Re-upload an XML file"] =
  "Re-upload an XML file";

  // From your computer
  content["from your computer"] =
  "from your computer";

  // Upload file
  content["Upload File"] =
  "Upload File";

  //Discovery Agents and Scopes
  content["Discovery Agents and Scopes"] =
  "Discovery Agents and Scopes";

  //Close
  content["Close"] =
  "Close";

  //Setup Wizard
  content["Setup Wizard"] =
  "Setup Wizard";

  //Refreshed
  content["Refreshed"] =
  "Refreshed";

  //Results
  content["Results"] =
  "Results";

  //Discovery Wizard
  content["Discovery Wizard"] =
  "Discovery Wizard";

  //Robot Deployment
  content["Robot Deployment"] =
  "Robot Deployment";

  //Delete Devices
  content["Delete Devices"] =
  "Delete Devices";

  //Filter (Minimum 3 characters)
  content["Filter (Minimum 3 characters)"] =
  "Filter (Minimum 3 characters)";

  //editcol
  content["Edit Columns"] =
  "Edit Columns";

  //FIX ISSUES
  content["FIX ISSUES"] =
  "FIX ISSUES";

  // Host
  content["Host"] =
  "Host";

  //Robot Address
  content["Robot Address"] =
  "Robot Address";

  //Robot Status
  content["Robot Status"] =
  "Robot Status";

  //Message
  content["Message"] =
  "Message";

  //Robot Deployment Status
  content["Robot Deployment Status"] =
  "Robot Deployment Status";

  // Status not available
  content["Status is not available, Please wait .... "] =
  "Status is not available, Please wait .... ";

  //device
  content["device"] =
  "device";

  //devices
  content["devices"] =
  "devices";

  //LasrDeployment message
  content["Last Deployment"] =
  "Last Deployment";

  //OK
  content["Ok"] =
  "Ok";

  // Search computer systems(Enter minimum 3 characters)
  content["Search computer systems (Minimum 3 characters)"] =
  "Search computer systems (Minimum 3 characters)";

  //Maintenance Schedule
  content["Schedule"] =
  "Schedule";

  //Maintenance Status
  content["Status"] =
  "Status";

  //Maintenance Disassociate
  content["Disassociate"] =
  "Disassociate";

  //Name
  content["Name"] =
  "Name";

  //alias
  content["Alias"] =
  "Alias";

  //devicetype
  content["Device Type"] =
  "Device Type";

  //caption
  content["Caption"] =
  "Caption";

  //dedicated
  content["Dedicated"] =
  "Dedicated";

  //origin
  content["Origin"] =
  "Origin";

  //latestorigin
  content["Latest Origin"] =
  "Latest Origin";

  //mac
  content["MAC Address"] =
  "MAC Address";

  //monitor
  content["Monitored By"] =
  "Monitored By";

  //robot
  content["Bus Type"] =
  "Bus Type";

  //csid
  content["CS Id"] =
  "CS Id";

  //cskey
  content["CS Key"] =
  "CS Key";

  //discover
  content["Discovered"] =
  "Discovered";

  //changetime
  content["Last Updated"] =
  "Last Updated";

  //deleted
  content["Removed"] =
  "Removed";

  //OS TYPE
  content["OS Type"] =
  "OS Type";

  //Operating system type
  content["Operating System Type"] =
  "Operating System Type";

  //osname
  content["OS Name"] =
  "OS Name";

  //Operating system name
  content["Operating System Name"] =
  "Operating System Name";

  //osversion
  content["OS Version"] =
  "OS Version";

  //Operating system version
  content["Operating System Version"] =
  "Operating System Version";

  //osdesc
  content["OS Description"] =
  "OS Description";

  //Operating system desc
  content["Operating System Description"] =
  "Operating System Description";

  //Origin
  content["User Tag 1"] =
  "User Tag 1";

  //usertag1
  content["UserTag 1"] =
  "UserTag 1";

  //tag2
  content["User Tag 2"] =
  "User Tag 2";

  //usertag2
  content["UserTag 2"] =
  "UserTag 2";

  //IP address
  content["IP Address"] =
  "IP Address";

  //ipaddr
  content["IP address"] =
  "IP address";

  //Has KeyIcon
  content["Has KeyIcon"] =
  "Has KeyIcon";

  // END OF LOCALIZATION

export default content;
