/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import Resource from './Resource'
export const ERROR_FETCH = 'ERROR_FETCH';
export const ERROR_GENERAL = 'ERROR_GENERAL';

export const ERROR_PAGERDUTY_APIKEY = 'ERROR_PAGERDUTY_APIKEY';
export const ERROR_PAGERDUTY_SERVICENAME = 'ERROR_PAGERDUTY_SERVICENAME';

export const ERROR_SLACK_WEBHOOK= 'ERROR_SLACK_WEBHOOK';
export const ERROR_SLACK_CHANNELNAME = 'ERROR_SLACK_CHANNELNAME';

export const ERROR_SERVICENOW_USERNAME = 'ERROR_SERVICENOW_USERNAME';
export const ERROR_SERVICENOW_PASSWORD = 'ERROR_SERVICENOW_PASSWORD';
export const ERROR_SERVICENOW_URL = 'ERROR_SERVICENOW_URL';

export function getDefaultError() {
  return {
    type : ERROR_GENERAL,
    //Communication Error
    message : Resource.get('Communication Error')
  }
}

export function getErrorMessageForKey(key, responseError) {
  if(responseError && responseError.type && responseError.type === key) {
    return responseError.message;
  }
  return null;
}
