/*
 * Copyright © 2024 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./table.less";
import { Grid, AlarmsIcon } from "../ui-components/uim-components";
import Resource from "./Resource";
import connect from "../../utils/connect";
import SeverityIcon from "./../icons/SeverityIcon";
import { Link } from "@mineral/core";
import { defaultPayload } from "../../api/groups/groupsSaga";

const KBPS = 1000;
const MBPS = KBPS * 1000;
const GBPS = MBPS * 1000;

const CustomTooltip = ({ active, payload, label }) => {
  if (!active) return null;
  return (
    <div
      style={{
        position: "relative",
        width: "230px",
        marginBottom: "20px",
        marginTop: "-28px",
        border: "1px solid #f5f5f5",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        paddingBottom: "20px",
      }}
    >
      {payload && payload.length > 0 ? (
        <div style={{position: 'relative', top: "10px"}}>
          {payload[0].value !== null ? (
            <div style={{ margin: "0", color: "#8884d8", fontSize: "12px" }}>
              {`${payload[0].name} : ${payload[0].value}`}{" "}
            </div>
          ) : null}
          {payload[1].value !== null ? (
            <div
              style={{ margin: "0", color: "#4CAF50", fontSize: "12px" }}
            >{`${payload[1].name} : ${payload[1].value}`}</div>
          ) : null}
          {payload[2].value !== null ? (
            <div
              style={{ margin: "0", color: "#EB144C", fontSize: "12px" }}
            >{`${payload[2].name} : ${payload[2].value}`}</div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

class InterfaceTable extends Component {
  constructor(props) {
    super(props);
    this.page_start_index = 0;
    this.state = {
      sortedData: this.props.interfaceData,
      metricMap: new Map(),
      grpMasterMap: new Map(),
      tooltipShowDelay: 0,
      overlayNoRowsDisplay:
        '<div><div class="nohubs__contentImg"></div><span style="color: #333333;font-family: Roboto;font-size: 1.25rem;">No Data found.</span></div>'
    };
  }

  getCols = () => {
    //Severity
    const severity= Resource.get("Severity");
    //Name
    const name= Resource.get("Name");
    //Information
    const information = Resource.get("Information");
    //Device
    const device= Resource.get("Device");
    //Utilization %
    const utilization = Resource.get("Utilization %");
    //Errors %
    const errors = Resource.get("Errors %");
    //Discards %
    const discards = Resource.get("Discards %");
    //Speed
    const speed= Resource.get("Speed");
    //Index
    const index= Resource.get("Index");
    //MAC
    const mac = Resource.get("MAC");
    //Admin Status
    const adminStatus= Resource.get("Admin Status");
    //Oper Status
    const operStatus= Resource.get("Oper Status");
    //Type
    const type= Resource.get("Type");
    //MTU
    const mtu= Resource.get("MTU");
    //Description
    const description= Resource.get("Description");
    //IfName
    const ifName= Resource.get("IfName");
    //Alias
    const alias = Resource.get("Display Alias");

    let groupCols = [
      {
        headerName: severity,
        field: "severity",
        headerTooltip: severity,
        tooltipField: "severity",
        headerTooltip: severity,
        sort: JSON.parse(sessionStorage.getItem('groupsPayload')).sortOrder ? JSON.parse(sessionStorage.getItem('groupsPayload')).sortOrder.toLowerCase() : "",
        cellRendererFramework: this.addAlarmIconToData,
        suppressMovable: true,
        suppressResize: true,
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        sortable: true,
        headerComponentParams: {
          // Max Alarm Severity
          icon: <div style={{paddingLeft:'0.5rem'}}><AlarmsIcon role='figure' title={Resource.get("Max Alarm Severity")} /></div>,
        },
      },
      {
        headerName: name,
        field: "displayName",
        resizable: true,
        tooltipField: "displayName",
        headerTooltip: name,
        suppressMovable: true,
        suppressResize: false,
        minWidth: 200,
        flex: 1,
        cellRendererFramework: (params) => {
          return (
            <Link
              style={{
                overflow: "hidden",
                color: "#3272D9",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer"
              }}
              onClick={() => {
                //console.log("Global table row click will get invoked");
              }}
            >
              {params.data?.displayName ? params.data.displayName : ""}
            </Link>
          );
        },
      },
      {
        headerName: alias,
        field: "displayAlias",
        tooltipField: "displayAlias",
        headerTooltip: alias,
        suppressMovable: true,
        suppressResize: false,
        resizable:true,
        minWidth: 150,
        flex: 0.9,
        cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
        suppressSizeToFit: false
      },
      {
        headerName: device,
        field: "parent_displayName",
        resizable: true,
        tooltipField: "parent_displayName",
        headerTooltip: device,
        suppressMovable: true,
        suppressResize: false,
        minWidth: 200,
        flex: 1,
        cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
        suppressSizeToFit: false,
      },
      {
        headerName: utilization,
        field: "masterId",
        resizable: true,
        cellRendererFramework: this.metricDataCellRenderer,
        headerTooltip: utilization,
        suppressMovable: true,
        suppressResize: false,
        minWidth: 150,
        flex: 1,
        suppressSizeToFit: false,
        equals: function (masterId1, masterId2) {
          return false;
        },
      },
      {
        headerName: errors,
        field: "Errors",
        resizable:true,
        cellRendererFramework: this.metricDataCellRenderer,
        headerTooltip: errors,
        suppressMovable: true,
        suppressResize: false,
        minWidth: 150,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: discards,
        field: "Discards",
        resizable:true,
        cellRendererFramework: this.metricDataCellRenderer,
        headerTooltip: discards,
        suppressMovable: true,
        suppressResize: false,
        minWidth: 150,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: speed,
        field: "ifSpeed",
        resizable: true,
        cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
        valueFormatter: this.speedDataCellRenderer,
        tooltipField: "ifSpeed",
        headerTooltip: speed,
        minWidth: 90,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: index,
        field: "ifIndex",
        resizable: true,
        cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
        tooltipField: "ifIndex",
        headerTooltip: index,
        minWidth: 100,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: mac,
        field: "ifPhysAddress",
        resizable: true,
        cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
        tooltipField: "ifPhysAddress",
        headerTooltip: mac,
        minWidth: 130,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: adminStatus,
        field: "ifAdminStatus",
        resizable:true,
        cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "1.5rem" },
        valueFormatter: (params) => {
          return (this.getadminStatus(params.data && params.data.ifAdminStatus))
        },
        tooltipField: "ifAdminStatus",
        headerTooltip: adminStatus,
        minWidth: 110,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: operStatus,
        field: "ifOperStatus",
        resizable: true,
        cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "1.5rem" },
        valueFormatter: (params) => {
          return (this.getOperStatus(params.data && params.data.ifOperStatus))
          },
        tooltipField: "ifOperStatus",
        headerTooltip: operStatus,
        minWidth: 110,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: type,
        field: "ifType",
        minWidth: 130,
        flex: 1,
        resizable:true,
        cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
        valueFormatter: (params) => {
          return (this.getType(params.data && params.data.ifType))
        },
        tooltipField: "ifType",
        headerTooltip: type,
        suppressSizeToFit: false,
      },
      {
        headerName: mtu,
        field: "ifMtu",
        minWidth: 70,
        cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
        flex: 1,
        resizable:true,
        tooltipField: "ifMtu",
        headerTooltip: mtu,
        suppressSizeToFit: false,
      },
      {
        headerName: description,
        field: "ifDescr",
        resizable:true,
        cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
        tooltipField: "ifDescr",
        headerTooltip: description,
        minWidth: 130,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: ifName,
        field: "ifName",
        minWidth: 130,
        cellStyle: { display: "block", paddingTop: "15px", paddingLeft: "0.5rem" },
        flex: 1,
        resizable:true,
        tooltipField: "ifName",
        headerTooltip: ifName,
        suppressSizeToFit: false,
      },
    ];
    return groupCols;
  };

  getadminStatus = (adminStatusId) => {
    let adminStatus = '';
    if(adminStatusId === '0')
      adminStatus = 'Unknown';
    else if(adminStatusId === '1')
      adminStatus = 'Up';
    else if(adminStatusId === '2')
      adminStatus = 'Down';
    else if(adminStatusId === '3')
      adminStatus = 'Testing';

    return adminStatus;
  }

  getOperStatus = (operStatusId) => {
    let operStatus = '';
    if(operStatusId === '1')
      operStatus = 'Up';
    else if(operStatusId === '2')
      operStatus = 'Down';
    else if(operStatusId === '3')
      operStatus = 'Testing';
    else if(operStatusId === '4')
      operStatus = 'Unknown';
    else if(operStatusId === '5')
      operStatus = 'Dormant';
    else if(operStatusId === '6')
      operStatus = 'Not Present';
    else if(operStatusId === '7')
      operStatus = 'Lower Layer Down';

    return operStatus;

  }

  getType = (typeId) => {
    let typeVal = '';
    switch(typeId) {
      case '1':
      typeVal = "other";
      break;
    case '2':
      typeVal = "regular1822";
      break;
    case '3':
      typeVal = "hdh1822";
      break;
    case '4':
      typeVal = "ddnX25";
      break;
    case '5':
      typeVal = "rfc877x25";
      break;
    case '6':
      typeVal = "ethernetCsmacd";
      break;
    case '7':
      typeVal = "iso88023Csmacd";
      break;
    case '8':
      typeVal = "iso88024TokenBus";
      break;
    case '9':
      typeVal = "iso88025TokenRing";
      break;
    case '10':
      typeVal = "iso88026Man";
      break;
    case '11':
      typeVal = "starLan";
      break;
    case '12':
      typeVal = "proteon10Mbit";
      break;
    case '13':
      typeVal = "proteon80Mbit";
      break;
    case '14':
      typeVal = "hyperchannel";
      break;
    case '15':
      typeVal = "fddi";
      break;
    case '16':
      typeVal = "lapb";
      break;
    case '17':
      typeVal = "sdlc";
      break;
    case '18':
      typeVal = "ds1";
      break;
    case '19':
      typeVal = "e1";
      break;
    case '20':
      typeVal = "basicISDN";
      break;
    case '21':
      typeVal = "primaryISDN";
      break;
    case '22':
      typeVal = "propPointToPointSerial";
      break;
    case '23':
      typeVal = "ppp";
      break;
    case '24':
      typeVal = "softwareLoopback";
      break;
    case '25':
      typeVal = "eon";
      break;
    case '26':
      typeVal = "ethernet3Mbit";
      break;
    case '27':
      typeVal = "nsip";
      break;
    case '28':
      typeVal = "slip";
      break;
    case '29':
      typeVal = "ultra";
      break;
    case '30':
      typeVal = "ds3";
      break;
    case '31':
      typeVal = "sip";
      break;
    case '32':
      typeVal = "frameRelay";
      break;
    case '33':
      typeVal = "rs232";
      break;
    case '34':
      typeVal = "para";
      break;
    case '35':
      typeVal = "arcnet";
      break;
    case '36':
      typeVal = "arcnetPlus";
      break;
    case '37':
      typeVal = "atm";
      break;
    case '38':
      typeVal = "miox25";
      break;
    case '39':
      typeVal = "sonet";
      break;
    case '40':
      typeVal = "x25ple";
      break;
    case '41':
      typeVal = "iso88022llc";
      break;
    case '42':
      typeVal = "localTalk";
      break;
    case '43':
      typeVal = "smdsDxi";
      break;
    case '44':
      typeVal = "frameRelayService";
      break;
    case '45':
      typeVal = "v35";
      break;
    case '46':
      typeVal = "hssi";
      break;
    case '47':
      typeVal = "hippi";
      break;
    case '48':
      typeVal = "modem";
      break;
    case '49':
      typeVal = "aal5";
      break;
    case '50':
      typeVal = "sonetPath";
      break;
    case '51':
      typeVal = "sonetVT";
      break;
    case '52':
      typeVal = "smdsIcip";
      break;
    case '53':
      typeVal = "propVirtual";
      break;
    case '54':
      typeVal = "propMultiplexor";
      break;
    case '55':
      typeVal = "ieee80212";
      break;
    case '56':
      typeVal = "fibreChannel";
      break;
    case '57':
      typeVal = "hippiInterface";
      break;
    case '58':
      typeVal = "frameRelayInterconnect";
      break;
    case '59':
      typeVal = "aflane8023";
      break;
    case '60':
      typeVal = "aflane8025";
      break;
    case '61':
      typeVal = "cctEmul";
      break;
    case '62':
      typeVal = "fastEther";
      break;
    case '63':
      typeVal = "isdn";
      break;
    case '64':
      typeVal = "v11";
      break;
    case '65':
      typeVal = "v36";
      break;
    case '66':
      typeVal = "g703at64k";
      break;
    case '67':
      typeVal = "g703at2mb";
      break;
    case '68':
      typeVal = "qllc";
      break;
    case '69':
      typeVal = "fastEtherFX";
      break;
    case '70':
      typeVal = "channel";
      break;
    case '71':
      typeVal = "ieee80211";
      break;
    case '72':
      typeVal = "ibm370parChan";
      break;
    case '73':
      typeVal = "escon";
      break;
    case '74':
      typeVal = "dlsw";
      break;
    case '75':
      typeVal = "isdns";
      break;
    case '76':
      typeVal = "isdnu";
      break;
    case '77':
      typeVal = "lapd";
      break;
    case '78':
      typeVal = "ipSwitch";
      break;
    case '79':
      typeVal = "rsrb";
      break;
    case '80':
      typeVal = "atmLogical";
      break;
    case '81':
      typeVal = "ds0";
      break;
    case '82':
      typeVal = "ds0Bundle";
      break;
    case '83':
      typeVal = "bsc";
      break;
    case '84':
      typeVal = "async";
      break;
    case '85':
      typeVal = "cnr";
      break;
    case '86':
      typeVal = "iso88025Dtr";
      break;
    case '87':
      typeVal = "eplrs";
      break;
    case '88':
      typeVal = "arap";
      break;
    case '89':
      typeVal = "propCnls";
      break;
    case '90':
      typeVal = "hostPad";
      break;
    case '91':
      typeVal = "termPad";
      break;
    case '92':
      typeVal = "frameRelayMPI";
      break;
    case '93':
      typeVal = "x213";
      break;
    case '94':
      typeVal = "adsl";
      break;
    case '95':
      typeVal = "radsl";
      break;
    case '96':
      typeVal = "sdsl";
      break;
    case '97':
      typeVal = "vdsl";
      break;
    case '98':
      typeVal = "iso88025CRFPInt";
      break;
    case '99':
      typeVal = "myrinet";
      break;
    case '100':
      typeVal = "voiceEM";
      break;
    case '101':
      typeVal = "voiceFXO";
      break;
    case '102':
      typeVal = "voiceFXS";
      break;
    case '103':
      typeVal = "voiceEncap";
      break;
    case '104':
      typeVal = "voiceOverIp";
      break;
    case '105':
      typeVal = "atmDxi";
      break;
    case '106':
      typeVal = "atmFuni";
      break;
    case '107':
      typeVal = "atmIma";
      break;
    case '108':
      typeVal = "pppMultilinkBundle";
      break;
    case '109':
      typeVal = "ipOverCdlc";
      break;
    case '110':
      typeVal = "ipOverClaw";
      break;
    case '111':
      typeVal = "stackToStack";
      break;
    case '112':
      typeVal = "virtualIpAddress";
      break;
    case '113':
      typeVal = "mpc";
      break;
    case '114':
      typeVal = "ipOverAtm";
      break;
    case '115':
      typeVal = "iso88025Fiber";
      break;
    case '116':
      typeVal = "tdlc";
      break;
    case '117':
      typeVal = "gigabitEthernet";
      break;
    case '118':
      typeVal = "hdlc";
      break;
    case '119':
      typeVal = "lapf";
      break;
    case '120':
      typeVal = "v37";
      break;
    case '121':
      typeVal = "x25mlp";
      break;
    case '122':
      typeVal = "x25huntGroup";
      break;
    case '123':
      typeVal = "transpHdlc";
      break;
    case '124':
      typeVal = "interleave";
      break;
    case '125':
      typeVal = "fast";
      break;
    case '126':
      typeVal = "ip";
      break;
    case '127':
      typeVal = "docsCableMaclayer";
      break;
    case '128':
      typeVal = "docsCableDownstream";
      break;
    case '129':
      typeVal = "docsCableUpstream";
      break;
    case '130':
      typeVal = "a12MppSwitch";
      break;
    case '131':
      typeVal = "tunnel";
      break;
    case '132':
      typeVal = "coffee";
      break;
    case '133':
      typeVal = "ces";
      break;
    case '134':
      typeVal = "atmSubInterface";
      break;
    case '135':
      typeVal = "l2vlan";
      break;
    case '136':
      typeVal = "l3ipvlan";
      break;
    case '137':
      typeVal = "l3ipxvlan";
      break;
    case '138':
      typeVal = "digitalPowerline";
      break;
    case '139':
      typeVal = "mediaMailOverIp";
      break;
    case '140':
      typeVal = "dtm";
      break;
    case '141':
      typeVal = "dcn";
      break;
    case '142':
      typeVal = "ipForward";
      break;
    case '143':
      typeVal = "msdsl";
      break;
    case '144':
      typeVal = "ieee1394";
      break;
    case '145':
      typeVal = "if_gsn";
      break;
    case '146':
      typeVal = "dvbRccMacLayer";
      break;
    case '147':
      typeVal = "dvbRccDownstream";
      break;
    case '148':
      typeVal = "dvbRccUpstream";
      break;
    case '149':
      typeVal = "atmVirtual";
      break;
    case '150':
      typeVal = "mplsTunnel";
      break;
    case '151':
      typeVal = "srp";
      break;
    case '152':
      typeVal = "voiceOverAtm";
      break;
    case '153':
      typeVal = "voiceOverFrameRelay";
      break;
    case '154':
      typeVal = "idsl";
      break;
    case '155':
      typeVal = "compositeLink";
      break;
    case '156':
      typeVal = "ss7SigLink";
      break;
    case '157':
      typeVal = "propWirelessP2P";
      break;
    case '158':
      typeVal = "frForward";
      break;
    case '159':
      typeVal = "rfc1483";
      break;
    case '160':
      typeVal = "usb";
      break;
    case '161':
      typeVal = "ieee8023adLag";
      break;
    case '162':
      typeVal = "bgppolicyaccounting";
      break;
    case '163':
      typeVal = "frf16MfrBundle";
      break;
    case '164':
      typeVal = "h323Gatekeeper";
      break;
    case '165':
      typeVal = "h323Proxy";
      break;
    case '166':
      typeVal = "mpls";
      break;
    case '167':
      typeVal = "mfSigLink";
      break;
    case '168':
      typeVal = "hdsl2";
      break;
    case '169':
      typeVal = "shdsl";
      break;
    case '170':
      typeVal = "ds1FDL";
      break;
    case '171':
      typeVal = "pos";
      break;
    case '172':
      typeVal = "dvbAsiIn";
      break;
    case '173':
      typeVal = "dvbAsiOut";
      break;
    case '174':
      typeVal = "plc";
      break;
    case '175':
      typeVal = "nfas";
      break;
    case '176':
      typeVal = "tr008";
      break;
    case '177':
      typeVal = "gr303RDT";
      break;
    case '178':
      typeVal = "gr303IDT";
      break;
    case '179':
      typeVal = "isup";
      break;
    case '180':
      typeVal = "propDocsWirelessMaclayer";
      break;
    case '181':
      typeVal = "propDocsWirelessDownstream";
      break;
    case '182':
      typeVal = "propDocsWirelessUpstream";
      break;
    case '183':
      typeVal = "hiperlan2";
      break;
    case '184':
      typeVal = "propBWAp2Mp";
      break;
    case '185':
      typeVal = "sonetOverheadChannel";
      break;
    case '186':
      typeVal = "digitalWrapperOverheadChannel";
      break;
    case '187':
      typeVal = "aal2";
      break;
    case '188':
      typeVal = "radioMAC";
      break;
    case '189':
      typeVal = "atmRadio";
      break;
    case '190':
      typeVal = "imt";
      break;
    case '191':
      typeVal = "mvl";
      break;
    case '192':
      typeVal = "reachDSL";
      break;
    case '193':
      typeVal = "frDlciEndPt";
      break;
    case '194':
      typeVal = "atmVciEndPt";
      break;
    case '195':
      typeVal = "opticalChannel";
      break;
    case '196':
      typeVal = "opticalTransport";
      break;
    case '197':
      typeVal = "propAtm";
      break;
    case '198':
      typeVal = "voiceOverCable";
      break;
    case '199':
      typeVal = "infiniband";
      break;
    case '200':
      typeVal = "teLink";
      break;
    case '201':
      typeVal = "q2931";
      break;
    case '202':
      typeVal = "virtualTg";
      break;
    case '203':
      typeVal = "sipTg";
      break;
    case '204':
      typeVal = "sipSig";
      break;
    case '205':
      typeVal = "docsCableUpstreamChannel";
      break;
    case '206':
      typeVal = "econet";
      break;
    case '207':
      typeVal = "pon155";
      break;
    case '208':
      typeVal = "pon622";
      break;
    case '209':
      typeVal = "bridge";
      break;
    case '210':
      typeVal = "linegroup";
      break;
    case '211':
      typeVal = "voiceEMFGD";
      break;
    case '212':
      typeVal = "voiceFGDEANA";
      break;
    case '213':
      typeVal = "voiceDID";
      break;
    case '214':
      typeVal = "mpegTransport";
      break;
    case '215':
      typeVal = "sixToFour";
      break;
    case '216':
      typeVal = "gtp";
      break;
    case '217':
      typeVal = "pdnEtherLoop1";
      break;
    case '218':
      typeVal = "pdnEtherLoop2";
      break;
    case '219':
      typeVal = "opticalChannelGroup";
      break;
    case '220':
      typeVal = "homepna";
      break;
    case '221':
      typeVal = "gfp";
      break;
    case '222':
      typeVal = "ciscoISLvlan";
      break;
    case '223':
      typeVal = "actelisMetaLOOP";
      break;
    case '224':
      typeVal = "fcipLink";
      break;
    case '225':
      typeVal = "rpr";
      break;
    case '226':
      typeVal = "qam";
      break;
    case '227':
      typeVal = "lmp";
      break;
    case '228':
      typeVal = "cblVectaStar";
      break;
    case '229':
      typeVal = "docsCableMCmtsDownstream";
      break;
    case '230':
      typeVal = "adsl2";
      break;
    case '231':
      typeVal = "macSecControlledIF";
      break;
    case '232':
      typeVal = "macSecUncontrolledIF";
      break;
    case '233':
      typeVal = "aviciOpticalEther";
      break;
    case '234':
      typeVal = "atmbond";
      break;
    case '235':
      typeVal = "voiceFGDOS";
      break;
    case '236':
      typeVal = "mocaVersion1";
      break;
    case '237':
      typeVal = "ieee80216WMAN";
      break;
    case '238':
      typeVal = "adsl2plus";
      break;
    case '239':
      typeVal = "dvbRcsMacLayer";
      break;
    case '240':
      typeVal = "dvbTdm";
      break;
    case '241':
      typeVal = "dvbRcsTdma";
      break;
    case '242':
      typeVal = "x86Laps";
      break;
    case '243':
      typeVal = "wwanPP";
      break;
    case '244':
      typeVal = "wwanPP2";
      break;
    case '245':
      typeVal = "voiceEBS";
      break;
    case '246':
      typeVal = "ifPwType";
      break;
    case '247':
      typeVal = "ilan";
      break;
    case '248':
      typeVal = "pip";
      break;
    case '249':
      typeVal = "aluELP";
      break;
    case '250':
      typeVal = "gpon";
      break;
    case '251':
      typeVal = "vdsl2";
      break;
    default:
      typeVal = "";

    }
    return typeVal;
  }

  addAlarmIconToData = (model) => {
    if (!model.data) {
      return <div></div>;
    }
    return (
      <div className="alarm__icon-link">
        <SeverityIcon role='figure' level={model.data.severity ? model.data.severity : 0} class="grid__icon" />
      </div>
    );
  };

  backgroundColors = (index) => {
    if(index === "UtilizationIn") {
      return "#066391";
    } else if (index === "UtilizationOut") {
      return "#229AD6";
    } else if (index === "ErrorsIn") {
      return "#B80D0D";
    }else if (index === "ErrorsOut") {
      return "#F55353";
    }else if (index === "DiscardsIn") {
      return "#AB8507";
    }else if (index === "DiscardsOut") {
      return "#F7CD3B";
    }else {
      return "#EB144C";
    }
  }

  metricDataCellRenderer = (model) => {
    if (!model.data) {
      return <div></div>;
    }
    var masterId = model.data.masterId;
    let colName = model.colDef.headerName;
    const grpMasterMap = model.data.grpMasterMap;
    const metricMap =
      grpMasterMap && grpMasterMap.has(masterId)
        ? grpMasterMap.get(masterId)
        : new Map();
    let data = [];
    let key = "";
    if (metricMap.size > 0) {
      if (colName === "Utilization %") {
        key = "Utilization";
      } else if (colName === "Errors %") {
        key = "Errors";
      } else if (colName === "Discards %") {
        key = "Discards";
      }
      data = metricMap.has(key) ? metricMap.get(key) : [];
      var dataArray = Object.keys(data[0]).map(val => val + ':' + data[0][val] + '%');
      delete dataArray[0];
      return (
        <>
          <div style={{ paddingTop: '5px' }}>
            {dataArray &&
              dataArray.map((data) => {
                return (
                  <div style={{ width: '150px', display: 'flex', marginTop: '2px' }}>
                    <div style={{ width: '100%', maxWidth: '150px', display: 'block' }}>
                      <div
                        style={{
                          width: `${parseInt(data.split(':')[1])}%`,
                          maxWidth: '85px',
                          backgroundColor: `${this.backgroundColors(data.split(':')[0])}`,
                          height: '10px',
                          float: 'left',
                          display: 'inline-block'
                        }}>
                        {' '}
                      </div>
                      <div
                        style={{
                          marginTop: '-3px',
                          marginLeft: '2px',
                          display: 'inline-block',
                          float: 'left',
                          fontSize: '11px',
                          width: '45px'
                        }}>
                        {`${data.split(':')[0]}`.replace(key, '')}
                        {': '}
                        <b>{`${data.split(':')[1]}`}</b>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      );
    } else {
      return ('');
    }
  };

  speedDataCellRenderer = (model) => {
    if (!model.data) {
      return null;
    }
    let value = model.data.ifSpeed;
    if (value == null || isNaN(value) || value === 0) {
      return null;
    }

    if (value >= GBPS) {
      value = value / GBPS;
      //field value of interface speed -- {0}: speed in gigabits per second
      return `${parseFloat(value).toFixed(2)} Gbps`;
    } else if (value >= MBPS) {
      value = value / MBPS;
      //field value of interface speed -- {0}: speed in megabits per second
      return `${parseFloat(value).toFixed(2)} Mbps`;
    } else if (value >= KBPS) {
      value = value / KBPS;
      //field value of interface speed -- {0}: speed in kilobits per second
      return `${parseFloat(value).toFixed(2)} Kbps`;
    } else {
      //field value of interface speed -- {0}: speed in bits per second
      return `${parseFloat(value).toFixed(2)} bps`;
    }
  };

  componentWillMount() {
    sessionStorage.setItem('groupsPayload', JSON.stringify(defaultPayload()));
    if(this.props.metricData) {
      const masterMetricMap = this.updateMetricMap(this.props.metricData);
      this.createGroupMapForMetrics(masterMetricMap);
    }
  }

  componentWillReceiveProps(nextProps) {
    const masterMetricMap = this.updateMetricMap(nextProps.metricData);
    const groupMapForMetrics = this.createGroupMapForMetrics(masterMetricMap);
    if(this.api) {
      const data = JSON.parse(JSON.stringify(nextProps.interfaceData.iface));
      data && data.map((row) => {
        row.grpMasterMap = groupMapForMetrics;
      });

      if(this.paramsInfo != null) {
        this.updateData(
          data,
          nextProps.interfaceData.total,
          this.paramsInfo
        );
       } else {
        this.page_start_index = 0;
        this.setGridDataSource(
          data,
          nextProps.interfaceData.total
        );
       }
    }
  }

  updateMetricMap = (metricData) => {
    let metricMap = new Map();
    let metricsArray = metricData.metric;
    if (metricsArray) {
      metricsArray.map((item) => {
        let masterId = item.masterId;
        let metricId = item.ci_metric_type;
        let value = item.samplevalue;
        let internalMap = metricMap.has(masterId)
          ? metricMap.get(masterId)
          : new Map();
        let valueArray = internalMap.has(metricId)
          ? internalMap.get(metricId)
          : [];
        if (valueArray.length >= 5) {
          valueArray.pop();
        }
        valueArray.push({ value: value });
        internalMap.set(metricId, valueArray);
        metricMap.set(masterId, internalMap);
        return item.masterId;
      });
    }
    this.setState({metricMap: metricMap});
    return metricMap;
  };

  createGroupMapForMetrics(masterMap) {
    if (masterMap) {
      let grpMasterMap = new Map();
      for (let [key, value] of masterMap) {
        let masterId = key;
        let internalMap = value;
        let grpInternalMap = grpMasterMap.has(masterId)
          ? grpMasterMap.get(masterId)
          : new Map();
        let umain = null;
        let udataIn = null;
        let udataOut = null;
        let emain = null;
        let edataIn = null;
        let edataOut = null;
        let dmain = null;
        let ddataIn = null;
        let ddataOut = null;
        for (let [key, value] of internalMap) {
          let metricId = key;
          let valueArray = value;
          const lastElement = valueArray[valueArray.length - 1];
          if (
            metricId === "11.1:1" ||
            metricId === "11.1:2" ||
            metricId === "11.1:3"
          ) {
            if (metricId === "11.1:1") {
              umain = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:2") {
              udataIn = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:3") {
              udataOut = lastElement ? lastElement.value : 0.0;
            }
          } else if (
            metricId === "11.1:24" ||
            metricId === "11.1:25" ||
            metricId === "11.1:26"
          ) {
            if (metricId === "11.1:24") {
              dmain = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:25") {
              ddataIn = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:26") {
              ddataOut = lastElement ? lastElement.value : 0.0;
            }
          } else if (
            metricId === "11.1:27" ||
            metricId === "11.1:28" ||
            metricId === "11.1:29"
          ) {
            if (metricId === "11.1:27") {
              emain = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:28") {
              edataIn = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:29") {
              edataOut = lastElement ? lastElement.value : 0.0;
            }
          }
        }
        if (umain !== null || udataIn !== null || udataOut !== null) {
          let valueArrayUtil = grpInternalMap.has("Utilization")
            ? grpInternalMap.get("Utilization")
            : [];
          if (valueArrayUtil.length >= 5) {
            valueArrayUtil.pop();
          }
          valueArrayUtil.push({
            Utilization: umain,
            UtilizationIn: udataIn,
            UtilizationOut: udataOut,
          });
          grpInternalMap.set("Utilization", valueArrayUtil);
        }
        if (emain !== null || edataIn !== null || edataOut !== null) {
          let valueArrayErr = grpInternalMap.has("Errors")
            ? grpInternalMap.get("Errors")
            : [];
          if (valueArrayErr.length >= 5) {
            valueArrayErr.pop();
          }
          valueArrayErr.push({
            Errors: emain,
            ErrorsIn: edataIn,
            ErrorsOut: edataOut,
          });
          grpInternalMap.set("Errors", valueArrayErr);
        }
        if (dmain !== null || ddataIn !== null || ddataOut !== null) {
          let valueArrayDsc = grpInternalMap.has("Discards")
            ? grpInternalMap.get("Discards")
            : [];
          if (valueArrayDsc.length >= 5) {
            valueArrayDsc.pop();
          }
          valueArrayDsc.push({
            Discards: dmain,
            DiscardsIn: ddataIn,
            DiscardsOut: ddataOut,
          });
          grpInternalMap.set("Discards", valueArrayDsc);
        }
        grpMasterMap.set(masterId, grpInternalMap);
      }
      this.setState({grpMasterMap: grpMasterMap});
      return grpMasterMap;
    }
  }

  handleDrillDown = (row) => {
   this.props.openDetailedViewInterface(row.data);
  };

  onGridReady(params) {
    this.api = params.api;
    this.api.showLoadingOverlay();
    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
    if (
      this.state.sortedData?.total === undefined ||
      this.state.sortedData?.total === 0
    ) {
      this.api.hideOverlay();
      this.api.showNoRowsOverlay();
    }

    const data = JSON.parse(JSON.stringify(this.state.sortedData.iface));
    data && data.map((row) => {
      row.grpMasterMap = this.state.grpMasterMap;
    });

    this.setGridDataSource(
      data,
      this.state.sortedData?.total
    );
  }

  setGridDataSource(data, total_data_length) {
    const datasource = {
      rowCount: null,
      getRows: function (params) {
        params.context.updateData(data, total_data_length, params);
      },
    };
    this.api.setDatasource(datasource);
  }

  updateData(data, total_data_length, params) {
    if (params && params.context) {
      params.context.paramsInfo = params;
      if (params.context.page_start_index !== params.startRow) {
        params.context.page_start_index = params.startRow;
        let pagesize = params.endRow - params.startRow,
          next_page_number = params.startRow / pagesize;
        if (params.context.api) {
          params.context.api.showLoadingOverlay();
        }
        this.fetchInterfaces(next_page_number, JSON.parse(sessionStorage.getItem('groupsPayload')).sortOrder);
      } else {
        let lastRow = -1;
        if (total_data_length <= params.endRow) {
          lastRow = total_data_length;
        }
        if (params.context && params.context.api) {
          params.context.api.hideOverlay();
        }
        params.successCallback(data, lastRow);
      }
    }
  }

  fetchInterfaces(nextPageNumber, sortOrder) {
    const payload = JSON.parse(sessionStorage.getItem('groupsPayload'));
    payload.pageNumber = nextPageNumber;
    payload.sortOrder = sortOrder;
    sessionStorage.setItem('groupsPayload', JSON.stringify(payload));
    const csId = this.props.csId;
    const groupId = this.props.groupId;
    const info = {
      csId: csId ? csId : groupId,
      isGroup: csId ? false : true,
      isLoad: true
    };
    this.props.getInterfaces(info);
  }

  onGridSizeChanged() {
    if (this.api) {
      this.api.sizeColumnsToFit();
    }
  }

  backendSort = (event) => {
    if (this.state.sortedData?.total > 0) {
        const sortColumn = event.sortOrder;
        this.paramsInfo = null;
        this.api.showLoadingOverlay();
        this.fetchInterfaces(0, sortColumn ? sortColumn.toUpperCase() : "");
    }
  }

  render() {
    return (
      <div ref="interfaceRef" style={{height:'100%'}}>
        <div
          className="ag-material tablecontainer"
          style={{margin:'0px',
            height: this.props.fromTreeView?"100%":(this.props.fromGroupsView?"calc(100% - 3px)":"calc(100vh - 135px)"),
          }}
        >
          <Grid
            {...this.props}
            headerHeight={36}
            rowHeight={48}
            columnDefs={this.getCols()}
            onGridReady={this.onGridReady.bind(this)}
            onGridSizeChanged={this.onGridSizeChanged.bind(this)}
            enableColResize={true}
            overlayLoadingTemplate={
              '<div><div class="loading__progress"></div></div>'
            }
            overlayNoRowsTemplate={this.state.overlayNoRowsDisplay}
            rowSelection={"multiple"}
            onRowClicked={this.handleDrillDown.bind(this)}
            tooltipShowDelay={this.state.tooltipShowDelay}
            getRowNodeId={(rowData) => {
              return rowData.masterId;
            }}
            suppressHorizontalScroll={false}
            rowModelType="infinite"
            context={this}
            rowBuffer={0}
            cacheBlockSize={100}
            cacheOverflowSize={1}
            maxConcurrentDatasourceRequests={1}
            maxBlocksInCache={1}
            debounceVerticalScrollbar={true}
            backendSort={
              this.backendSort.bind(this)
            }
            backendSortConfig={{
              enable: true,
              callback: this.backendSort.bind(this),
              defaultSortColumnConfig: {
                id: "displayName",
                type: "asc",
              },
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  try {
    return {
    
      metricData: state.interfaces.metricData,
      interfaceData: state.interfaces.interfaceData
    };
  } catch (e) {
    return {
      error: e,
    };
  }
};
export default connect(
  mapStateToProps,
  null
)(withRouter(InterfaceTable));
