/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Name message
  content["Display Name"] =
  "表示名";

  //Alias message
  content["Display Alias"] =
  "エイリアスの表示";

  //Description message
  content["Description"] =
  "説明";

  //IPaddress message
  content["IP address"] =
  "IP アドレス";

  //instanceName message
  content["Instance Name"] =
  "インスタンス名";

  //Name message
  content["Name"] =
  "名前";

  //Role message
  content["Role"] =
  "役割";

  //OSType message
  content["OS Type"] =
  "OS タイプ";

  //OSName message
  content["OS Name"] =
  "OS 名";

  //OSVersion message
  content["OS Version"] =
  "OS バージョン";

  //AddDevice(s)
  content["Add Device(s)"] =
  "デバイスの追加";

  //Nodevicestoadd
  content["No devices to add"] =
  "追加するデバイスが見つかりません";

  //Cancel
  content["Cancel"] =
  "キャンセル";

  //Add
  content["Add"] =
  "追加";

  //Noresultsfound message
  content["No results found"] =
  "結果が見つかりません";

  //SearchforComputerSystems message
  content["Search for Computer Systems"] =
  "コンピュータ システムの検索";

  //Namemustbelessthan256 characters message
  content["Name must be less than 256 characters"] =
  "名前は 256 文字未満にする必要があります";

  //Groupnamelareadyexists message
  content["Group name already exists"] =
  "グループ名はすでに存在します";

  // A group may contain either devices or additional groups, not a mix.
  content[" A group may contain either devices or additional groups, not a mix."] =
  "グループにはデバイスまたは追加のグループのいずれかを含めることができますが、混在させることはできません。";

  //Groupt Type
  content["Group Type"] =
  "グループ タイプ";

  // Dynamic Group
  content["Dynamic Group"] =
  "動的グループ";

  // Element Type
  content["Element Type"] =
  "エレメント タイプ";

  //Add group Message
  content["Add group"] =
  "グループの追加";

  //Group Name
  content["Group Name"] =
  "グループ名";

  //Group name
  content["Group name"] =
  "グループ名";

  //Static
  content["Static"] =
  "静的";

  //Dynamic
  content["Dynamic"] =
  "動的";

  //Container
  content["Container"] =
  "コンテナ";

  //Devices
  content["Devices"] =
  "デバイス";

  //Interfaces
  content["Interfaces"] =
  "インターフェース";

  //Delete
  content["Delete"] =
  "削除";

  //DeleteDevice
  content["Delete Device"] =
  "デバイスの削除";

  // help message
  content["This action will remove the selected device(s) from the system\'s inventory of discovered and monitored devices. It will no longer count against the billing cap."] =
  "このアクションによって、選択されたデバイスがシステムの検出およびモニタ対象デバイスのインベントリから削除されます。これは、請求上限に対してカウントされなくなります。";

  // help message1
  content["To restore the device and enable monitoring, go to\… Setting > Deleted devices"] =
  "デバイスをリストアし、モニタリングを有効にするには、\… [設定] - [削除されたデバイス] に移動します";

  //Delete Group
  content["Delete Group"] =
  "グループの削除";

  //Your individual devices will not be deleted.
  content[" Your individual devices will not be deleted. "] =
  "個別のデバイスは削除されません。";

  //Group Management Failed message
  content["Group Management Failed"] =
  "グループ管理に失敗しました";

  //Add Devices Failed message
  content["Add Devices Failed"] =
  "失敗したデバイスの追加";

  //Delete Group Failed message
  content["Delete Group Failed"] =
  "グループの削除に失敗しました";

  //Rename Group Failed message
  content["Rename Group Failed"] =
  "グループの名前変更に失敗しました";

  //Add Group Failed message
  content["Add Group Failed"] =
  "グループの追加に失敗しました";

  //Removing Devices Failed message
  content["Removing Devices Failed"] =
  "デバイスの削除に失敗しました";

  //Removing Devices Failed message
  content["Importing Devices Failed."] =
  "デバイスのインポートに失敗しました。";

  //Close
  content["Close"] =
  "閉じる";

  //Please contact an administrator
  content["Please contact an administrator"] =
  "管理者にお問い合わせください。";

  //Please check file format for data
  content["Please check file format for data"] =
  "データのファイル形式を確認してください";

  //Deploy
  content["Deploy"] =
  "展開";

  //Deploy Robot
  content["Deploy Robot"] =
  "ロボットの展開";

  // help message
  content["This action will deploy robot in selected device(s)"] =
  "このアクションによって、選択されたデバイスにロボットが展開されます";

  //Search For Computer Systems
  content["Search For Computer Systems"] =
  "コンピュータ システムの検索";

  //Remove
  content["Remove"] =
  "削除";

  //Your individual devices will not be deleted
  content["Your individual devices will not be deleted "] =
  "個別のデバイスは削除されません";

  //Save
  content["Save"] =
  "保存";

  //Rename group Message
  content["Rename group"] =
  "グループ名の変更";

  //Logout
  content["Logout"] =
  "ログアウト";

  //Unknown
  content["Unknown"] =
  "なし";

  //Remove device(s) from Group Message
  content["Remove device(s) from Group"] =
  "グループからのデバイスの削除";

  //Action Menu Name Message
  content["Action Menu"] =
  "アクション メニュー";

  //edit group Message
  content["Edit group"] =
  "グループの編集";

  // Delete group message
  content["Delete group"] =
  "グループの削除";

  //Add device(s) to Group Message
  content["Add device(s) to Group"] =
  "デバイスのグループへの追加";

  //Delete device(s) Menu Item title
  content["Delete device(s)"] =
  "デバイスの削除";

  //Delete device(s) Menu Item title
  content["Deploy Robot(s)"] =
  "ロボットの展開";

  // END OF LOCALIZATION

export default content;
