/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import { 
  FETCH_CUSTOM_USER_PREFERENCES,
  FETCH_CUSTOM_USER_PREFERENCES_SUCCESS,
  FETCH_CUSTOM_USER_PREFERENCES_FAILURE,
  
  CREATE_CUSTOM_USER_PREFERENCES,
  CREATE_CUSTOM_USER_PREFERENCES_SUCCESS,
  CREATE_CUSTOM_USER_PREFERENCES_FAILURE,
  
  
  UPDATE_CUSTOM_USER_PREFERENCES,
  UPDATE_CUSTOM_USER_PREFERENCES_SUCCESS,
  UPDATE_CUSTOM_USER_PREFERENCES_FAILURE,
  
    
  DELETE_CUSTOM_USER_PREFERENCES,
  DELETE_CUSTOM_USER_PREFERENCES_SUCCESS,
  DELETE_CUSTOM_USER_PREFERENCES_FAILURE,
  
  RESET_STORE,
  
  } from './actionTypes'
  import { CustomUserPreferences as customuserpreferencesApi } from './customuserpreferences'
  
  
  export function requestCustomUserPreferences() {
      return {
        type: FETCH_CUSTOM_USER_PREFERENCES,
      }
    }
    
    export function fetchCustomUserPreferencesSuccess(json) {
      return {
        type: FETCH_CUSTOM_USER_PREFERENCES_SUCCESS,
        userpreferences: json,
      }
    }
    
    export function fetchCustomUserPreferencesFailure(error) {
      return {
        type: FETCH_CUSTOM_USER_PREFERENCES_FAILURE,
        errorMessage: error.message,
      }
    }
    
    export function fetchCustomUserPreferences() {
      return dispatch => {
        dispatch(requestCustomUserPreferences())
        return customuserpreferencesApi
          .getCustomUserPreferences()
          .then(response => response.data)
          .then(json => dispatch(fetchCustomUserPreferencesSuccess(json)))
          .catch(function(error) {
            dispatch(fetchCustomUserPreferencesFailure(error))
          })
      }
    }
    
  
   
  
    export function createCustomUserPreferenceStart() {
      return {
        type: CREATE_CUSTOM_USER_PREFERENCES,
        
      };
    }
    
    export function createCustomUserPreferenceSuccess(customUserpreference) {
      return {
        type: CREATE_CUSTOM_USER_PREFERENCES_SUCCESS,
        customUserpreference:customUserpreference,
        
        
      };
    }
    
    export function createCustomUserPreferenceFailure(error) {
      return {
        type: CREATE_CUSTOM_USER_PREFERENCES_FAILURE,
        error: error.message,
  
       
      };
    }
    
    export function createCustomUserPreference(userPreferenceParams) {
      return (dispatch) => {
        dispatch(createCustomUserPreferenceStart(userPreferenceParams));
        return customuserpreferencesApi
          .createCustomUserPreference(userPreferenceParams)
          .then((response) => response.data)
          .then((json) => {dispatch(fetchCustomUserPreferences())
            dispatch(createCustomUserPreferenceSuccess(json))})
          .catch(function (error) {
            dispatch(createCustomUserPreferenceFailure(error));
          });
      };
    }
  
  
    export function updateCustomUserPreferenceStart() {
      return {
        type: UPDATE_CUSTOM_USER_PREFERENCES,
        
      };
    }
    
    export function updateCustomUserPreferenceSuccess(userpreference) {
      return {
        type: UPDATE_CUSTOM_USER_PREFERENCES_SUCCESS,
        userpreference:userpreference,
      };
    }
    
    export function updateCustomUserPreferenceFailure(error) {
      return {
        type: UPDATE_CUSTOM_USER_PREFERENCES_FAILURE,
        error: error.message,
       
      };
    }
    
    export function updateCustomUserPreference(userPreferenceParams) {
      return (dispatch) => {
        dispatch(updateCustomUserPreferenceStart(userPreferenceParams));
        return customuserpreferencesApi
          .updateCustomUserPreference(userPreferenceParams)
          .then((response) => response.data)
          .then((json) => {dispatch(fetchCustomUserPreferences());
            dispatch(updateCustomUserPreferenceSuccess(json))})
          .catch(function (error) {
            dispatch(updateCustomUserPreferenceFailure(error));
          });
      };
    }
  
  
    
  
    export function resetStore() {
      return {
        type: RESET_STORE,
        
       
      };
    }
    
  
    export function deleteCustomUserPreferenceStart() {
      return {
        type: DELETE_CUSTOM_USER_PREFERENCES
        
      };
    }
    
    export function deleteCustomUserPreferenceSuccess() {
      return {
        type: DELETE_CUSTOM_USER_PREFERENCES_SUCCESS,
        
        
      };
    }
    
    export function deleteCustomUserPreferenceFailure(error) {
      return {
        type: DELETE_CUSTOM_USER_PREFERENCES_FAILURE,
        error: error.message,
       
      };
    }
    
    export function deleteCustomUserPreference(userPreferenceId) {
      return (dispatch) => {
        dispatch(deleteCustomUserPreferenceStart());
        return customuserpreferencesApi
          .deleteCustomUserPreference(userPreferenceId)
          .then((response) => response.data)
          .then((json) => dispatch(deleteCustomUserPreferenceSuccess()))
          .catch(function (error) {
            dispatch(deleteCustomUserPreferenceFailure(error));
          });
      };
    }
    
