/*
 * Copyright © 2024 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import connect from "./utils/connect";
import axios from "axios";
import { get } from "./utils/lodash-utils";
import { CircularProgress } from '@mineral/core';
import KeymapApp from "./KeymapApp";

import { setIsSaas } from "./api/saas/actions";
import { setIsTimedout } from "./api/isTimedOut/actions";
import { fetchConfiguration } from "./api/configuration/actions";
import { fetchUpgradeableRobots } from "./api/robots/actions";
import { openModal } from "./api/modals/actions";
import appConfig from "./api/config";
import { loadCommon, getCookieBaseUrl } from "./utils/common";
import { LocalStorageManager as storageManagerApi } from "./utils/LocalStorageManager";
import { Logout as logoutApi } from "./api/logout/api";
import { Account } from "./api/account/account";
import { fetchAccount, fetchAxaAccount } from "./api/account/actions";
import { setACL, fetchAclSuccess, getAclData, setSelfCertState } from "./api/acl/actions";
import { getOcviewData } from "./api/ocviews/actions";
import { setProductList, setDoiUrl } from "./api/login/actions";
import AclTypes from "./api/aclTypes";
import { setGroupManagementPermission } from "./api/groupMgmtPermission/actions";
import { Wasp } from './api/wasp/wasp';
import { setEcometerAdmin,setIsContainersMonitored } from './api/wasp/actions';

import { fetchGlobalAndPersonalUserPreferences } from "./api/userpreferences/actions";
import {fetchCustomUserPreferences } from "./api/customuserpreferences/actions";


let isSaas = false;


class Bootstrap extends Component {
  state = {
    loggedIn: false,
    isproductfetching: true,
    isTimedout:false,
  };
  /*_getSid = () => {
    let OPERATOR_CONSOLE = window.OPERATOR_CONSOLE;
    let sid = OPERATOR_CONSOLE["sid"];
    const cookie = getCookie();
    const token = cookie && cookie.token;
    const refreshToken = cookie && cookie.refresh_token;
    sid = sid === "null" ? null : sid;

    if (sid) {
      return new Promise((resolve, reject) => {
        resolve(sid);
      });
    }
    if (token && refreshToken) {
      return getSidFromAxaTokens({
        token: token,
        refresh_token: refreshToken,
      }).then((response) => get(response, "data.sid"));
    }
    return new Promise((resolve, reject) => {
      sid = storageManagerApi.getItem("OPERATOR_CONSOLE", "sid");
      if (!sid) {
        reject("No sid found - sid:", sid);
      }
      resolve(sid);
    });
  };*/

  _handleSid = (sid) => {
    //this.props.setSid(sid);
    this.props.setIsSaas(isSaas);
    //axios.defaults.headers.common["Authorization"] = sid;
    /*axios.interceptors.request.use(req => {
        let overrideSid=axios.defaults.headers.common["Authorization"];

        if(window.OPERATOR_CONSOLE.refreshToken && window.OPERATOR_CONSOLE.refreshToken!=="")
            {
            overrideSid=window.OPERATOR_CONSOLE.refreshToken;
            axios.defaults.headers.common["Authorization"]=window.OPERATOR_CONSOLE.refreshToken;
            window.OPERATOR_CONSOLE.refreshToken="";

            }
        if (req && req.headers)
            {
            if(req.method && req.method=='get')
            req.headers.get["Authorization"] = overrideSid;
            if(req.method && req.method=='delete')
           req.headers.delete["Authorization"] = overrideSid;
            if(req.method && req.method=='post')
            req.headers.post["Authorization"] = overrideSid;
            if(req.method && req.method=='put')
            req.headers.put["Authorization"] = overrideSid;
            req.headers.common["Authorization"] = overrideSid;
            return req;
            }
      }, error => {
        // handle the error
        return Promise.reject(error);
      });*/
    axios.interceptors.response.use(res => {
        if(window.OPERATOR_CONSOLE.sessionExpired)
        {
            //console.log('session expired in the frame')
            this.props.setIsTimedout(true)

        }
         /*if(res && res.headers && res.headers.refreshtoken){
            axios.defaults.headers.common["Authorization"] = 'Bearer '+res.headers.refreshtoken;

         }*/
        return res;

      }, error => {
        // handle the error
          //console.log(error);
          //console.log(error.response);
          //console.log(error.response.status);
          if(
            (error && error.response && error.response.status == 440) ||
            (error && error.response && error.response.status == 401)
          ) {

            if (error.response.status === 440 && window.parent) {
              window.parent.OPERATOR_CONSOLE.sessionExpired = true;
            }

            this.props.setIsTimedout(true)
          } else {
            return Promise.reject(error);
          }
      });
    // validate sid with an actual request
    this.props.getConfig().then(
      (configRes) => {
        if(configRes?.data?.metrics_data_points_limit) {
          sessionStorage.setItem('metrics_data_points_limit', configRes?.data?.metrics_data_points_limit);
        }
        this.props.getOcviewData().then((ocViewsres)=>{
          //console.log("Got ocviews here", ocViewsres);

          let landingPageIfNotSet="";
          let canOcviewOverview=!ocViewsres.ocviewinfo.includes("Home");
          let canOcviewAlarms= !ocViewsres.ocviewinfo.includes("Alarms");
          let canOcviewInventory= !ocViewsres.ocviewinfo.includes("Inventory");
          let canOcviewContainers= !(ocViewsres.ocviewinfo.includes("Cloud Native") || ocViewsres.ocviewinfo.includes("CN"));
          let canOcviewReports= !(ocViewsres.ocviewinfo.includes("Reports") || ocViewsres.ocviewinfo.includes("CR"));
          let canOcviewDashboards= !(ocViewsres.ocviewinfo.includes("Dashboard") || ocViewsres.ocviewinfo.includes("DV"));
          let canOcviewSLA= !ocViewsres.ocviewinfo.includes("SLA");
          let canOcviewSLM= !(ocViewsres.ocviewinfo.includes("SLA"));
          let canOcviewGroups= !(ocViewsres.ocviewinfo.includes("Groups"));
          let canOcviewListViewer= !(ocViewsres.ocviewinfo.includes("LV"));
          let canOcviewObserViewer= !(ocViewsres.ocviewinfo.includes("OV"));

          if(canOcviewOverview)
             landingPageIfNotSet="/overview";
            else if(canOcviewAlarms)
             landingPageIfNotSet="/uim-alarms" ;
            else if(canOcviewGroups)
             landingPageIfNotSet="/groups/0/treeviewnew" ;
           else if(canOcviewInventory)
              landingPageIfNotSet="/inventory" ;
            else if(canOcviewContainers)
              landingPageIfNotSet="/cloud" ;
            else if(canOcviewDashboards)
              landingPageIfNotSet="/technologies" ;
            else if(canOcviewReports)
              landingPageIfNotSet="/reports" ;
            else if(canOcviewSLM)
              landingPageIfNotSet="/slm" ;
            else if(canOcviewListViewer)
              landingPageIfNotSet="/settings/listviewer" ;
            else if(canOcviewObserViewer)
            landingPageIfNotSet="/mydashboardsOV" ;
            else
              landingPageIfNotSet="No access" ;

              Wasp.isEcometerAdminInstall().then((response) =>
              {
                this.props.setEcometerAdmin(response.data)
              }
          )
          Wasp.isContainersMonitored().then((response) =>
          {
            this.props.setIsContainersMonitored(response.data)
          }
      )

          const isUserPersonalizationEnabled = get(configRes,
            "data.enableUserPersonalization",false
          );
          //console.log(isUserPersonalizationEnabled);

          if(isUserPersonalizationEnabled && isUserPersonalizationEnabled==true)
          {
            storageManagerApi.setItem("OPERATOR_CONSOLE", {
            
              baseUrl: getCookieBaseUrl(),

            });
            let waspOcViews={canOcviewOverview,canOcviewAlarms,canOcviewInventory,canOcviewContainers,canOcviewReports,canOcviewDashboards,canOcviewSLA,canOcviewSLM,canOcviewGroups, canOcviewListViewer, canOcviewObserViewer}

            this.getPersonalizedView(landingPageIfNotSet,waspOcViews);
          }
          else
          {
            storageManagerApi.setItem("OPERATOR_CONSOLE", {
           
              baseUrl: getCookieBaseUrl(),
              landingPage:landingPageIfNotSet
            });
            this.setState({ loggedIn: true });

          }
        });


      },
      (error) => {
        console.warn(
          "provided SID did not validate - returning to login:",
          error
        );
        const localStoreBaseUrl = storageManagerApi.getItem(
          "OPERATOR_CONSOLE",
          "baseUrl"
        );
       
        this.logout(localStoreBaseUrl);
      }
    );



      this.props.getAclData();
      this.props.fetchGlobalAndPersonalUserPreferences();
      this.props.fetchCustomUserPreferences();

      Account.getUser().then((response) => {
        let acl = get(response, "data.roles", "").includes(
          "Portal Administration"
        )
          ? "Administrator"
          : "Operator";
          let canModifyGroup = get(response, "data.roles", "").includes(
              "USM Group Modification"
            );
          let canAddGroup = get(response, "data.roles", "").includes(
              "OC Group Add"
              );
          let canEditGroup = get(response, "data.roles", "").includes(
                "OC Group Edit"
                );
            let canRemoveGroup = get(response, "data.roles", "").includes(
                  "OC Group Delete"
                  );

        this.props.setGroupManagementPermission(canModifyGroup||canAddGroup||canEditGroup||canRemoveGroup);

        if (isSaas && acl !== AclTypes.OPERATOR) {
          this.props.fetchUpgradeableRobots().then((data) => {
            if (get(data, "robots.total", 0) > 0) {
              this.props.openModal("SHOW_ROBOTS_FOR_UPGRADE");
            }
          });


          this.props.getConfig().then((configRes) => {
            const walkMe_environment = get(
              configRes,
              "data.walkMe_environment"
            );


            if (walkMe_environment === "test") {
              // load(
              //   "https://cdn.walkme.com/users/9d163c4e65c146419dea80aec4f8a869/test/walkme_9d163c4e65c146419dea80aec4f8a869_https.js"
              // );
            } else {
              // load(
              //   "https://cdn.walkme.com/users/9d163c4e65c146419dea80aec4f8a869/walkme_9d163c4e65c146419dea80aec4f8a869_https.js"
              // );
            }
          });
        }
        this.props.setACL(acl);
      });
      Account.getSelfCertState().then((response) => {
        let selfcert = response.data.selfcert;
        this.props.setSelfCertState(selfcert)
        })


  };
  getProducts = () => {
    loadCommon()
      .then((ca_cloud) => {
        ca_cloud.getProducts().then((response) => {
          let products = response.data.products;

          this.props.setProductList(products);
          var newArray = products.filter(function (el) {
            return el.name === "DOI";
          });
          if (newArray.length !== 0) {
            this.props.setDoiUrl(newArray[0].axaTokenRedirectUrl);
          }
          this.setState({ isproductfetching: false });
        });
      })
      .catch((e) => {
        //console.error("Error fetching products =>", e);
        this.props.setProductList([]);
        this.setState({ isproductfetching: false });
      });
  };
  componentDidMount() {
    window.dispatchEvent(new CustomEvent('updateNavLinks'))
    // OPERATOR_CONSOLE is defined in standalone.jsp
    let OPERATOR_CONSOLE = window.OPERATOR_CONSOLE;
    isSaas = OPERATOR_CONSOLE["isSaas"];
    if (isSaas === "false" || !isSaas) {
      isSaas = false; // changes made to check the saas files
    } else {
      isSaas = true;
    }
    this._handleSid();
    if (isSaas) {
      this.props.fetchAxaAccount();
    } else {
      this.props.fetchAccount();
    }
    /*this._getSid().then(
      (sid) => {
        this._handleSid(sid);
        if (isSaas) {
          this.props.fetchAxaAccount();
        } else {
          this.props.fetchAccount();
        }

      },
      (e) => {
        console.warn("no SID returning to login");
        const localStoreBaseUrl = storageManagerApi.getItem(
          "OPERATOR_CONSOLE",
          "baseUrl"
        );
        this.logout(localStoreBaseUrl);
      }

    );*/


    this.getProducts();
  }
  getPersonalizedView = (landingPageBasedOnOCViews,waspOcViews) => {
    //console.log("landingPageBasedOnOCViews ",landingPageBasedOnOCViews);
    let slmAdmin = '';
    let slmView = '';
    let sloAccess = '';
    Account.getUser().then((response) => {
      slmAdmin = get(response, "data.roles", "").includes("SLM Admin");
      slmView = get(response, "data.roles", "").includes("SLM View");
      sloAccess = get(response, "data.roles", "").includes("SLO Access");
      let landingPageUrl=process.env.NODE_ENV=='development'?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getLandingPageByUser"
      :"/operatorconsole_portlet/api/v1/user/preferences/getLandingPageByUser"

      axios
      .get(
        landingPageUrl
      )
      .then((res) => {
        //console.log("view ",res.data );
        let landingPage = "";
        switch (res.data) {

          case "Home":
            if(waspOcViews.canOcviewOverview)
             landingPage="/overview";
            else
             landingPage = landingPageBasedOnOCViews;
            break;

          case "Alarms":
            if(waspOcViews.canOcviewAlarms)
              landingPage="/uim-alarms";
            else
             landingPage = landingPageBasedOnOCViews;
            break;

          case "Inventory":
            if(waspOcViews.canOcviewInventory)
              landingPage="/inventory";
           else
             landingPage = landingPageBasedOnOCViews;
            break;

            case "CN":
            case "Cloud Native":
              if(waspOcViews.canOcviewContainers)
                landingPage="/cloud";
             else
               landingPage = landingPageBasedOnOCViews;
              break;

          case "CR":
          case "Reports":
            if(waspOcViews.canOcviewReports)
              landingPage="/reports";
            else
              landingPage = landingPageBasedOnOCViews;
            break;

          case "DV":
          case "Dashboard":
            if(waspOcViews.canOcviewDashboards)
              landingPage="/technologies";
             else
               landingPage = landingPageBasedOnOCViews;
            break;

          case "SLM":
          case "SLA":
             if((slmAdmin || slmView || sloAccess) && waspOcViews.canOcviewSLM)
                landingPage="/slm";
            else
              landingPage = landingPageBasedOnOCViews;
            break;

          case "Groups":
            if(waspOcViews.canOcviewGroups)
              landingPage="/groups/0/treeviewnew";
            else
              landingPage = landingPageBasedOnOCViews;
            break;

          case "LV":
            if(waspOcViews.canOcviewListViewer)
              landingPage="/settings/listviewer";
            else
              landingPage = landingPageBasedOnOCViews;
            break;

          case "OV":
            if(waspOcViews.canOcviewObserViewer)
              landingPage="/mydashboardsOV";
            else
              landingPage = landingPageBasedOnOCViews;
            break;

          default:
            if (res.data.includes('custompage/')) {
              const dashboardId = res.data.split('/')[1];
              landingPage = `/mydashboardscustompages/${dashboardId}?canvasId=${dashboardId}&focused=true`;
            } else {
              landingPage = "No access";
            }
            break;
        }
        //console.log("Final landing ", landingPage);
        if(landingPage == "No access" )
        {
          alert("You don't access to this portal");
          const localStoreBaseUrl = storageManagerApi.getItem(
            "OPERATOR_CONSOLE",
            "baseUrl"
          );
        
          this.logout("/operatorconsole_portlet/standalone_login.jsp");
        }else{
         
          let baseUrl = getCookieBaseUrl();
          if (!isSaas) {
            baseUrl = "standalone_login.jsp";
          }
          storageManagerApi.setItem("OPERATOR_CONSOLE", {
          
            baseUrl: baseUrl,
            landingPage:landingPage
          });
          this.setState({ loggedIn: true });
        }


      })
      .catch(function (response) {
        //handle error
        //console.log(response);
      });
    });


  };
  logout = (localStoreBaseUrl) => {
    const referrer =
      window.location.pathname.replace(appConfig.basename, "") +
      window.location.search;
    // this will be used to redirect the user back to the last location when logging back in
    storageManagerApi.setItem("OPERATOR_CONSOLE", {
      referrer: referrer,
    });
    loadCommon()
      .then((ca_cloud) => ca_cloud.logout())
      .then((response) => {
        logoutApi.delete();
        window.location = response.data.redirectUrl;
      })
      .catch((error) => {
        let baseUrl = getCookieBaseUrl() || localStoreBaseUrl;
        console.error("Error logging out user:", error);
        return baseUrl;
      })
      .then((baseUrl) => {
        if (baseUrl) {
          console.info("Redirecting to:", baseUrl);
          window.location = baseUrl;
        }
        // get `baseUrl` from configuration 'wasp.cfg'
        return this.props.getConfig();
      })
      .then((response) => response.data)
      .then((json) => {
        const baseUrl = json.baseUrl;
        console.info("Redirecting to:", baseUrl);
        window.location = baseUrl;
      })
      .catch((error) => {
        // last resort we're lost
        let baseUrl = storageManagerApi.getItem(
          "OPERATOR_CONSOLE",
          "baseUrl"
        );
        if (!isSaas) {
          baseUrl = "standalone_login.jsp";
        }
        console.error("Error getting redirect url:", error);
        console.info("Redirecting to:", baseUrl);
        window.location = baseUrl;
      });
  };
  render() {
    return this.state.loggedIn && !this.state.isproductfetching ? (
      <KeymapApp />
    ) : (
      <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ocviews: state.ocviews,
    wasp:state.wasp
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getConfig: () => dispatch(fetchConfiguration()),
   
    setIsSaas: (isSaas) => dispatch(setIsSaas(isSaas)),
    setIsTimedout: (isTimedout) => dispatch(setIsTimedout(isTimedout)),
    fetchUpgradeableRobots: () => dispatch(fetchUpgradeableRobots()),
    openModal: (id) => dispatch(openModal(id)),
    setACL: (acl) => dispatch(setACL(acl)),

    fetchAccount: () => dispatch(fetchAccount()),
    fetchAxaAccount: () => dispatch(fetchAxaAccount()),
    setGroupManagementPermission: (groupMgmt) =>
      dispatch(setGroupManagementPermission(groupMgmt)),
    setProductList: (products) => dispatch(setProductList(products)),
    setDoiUrl: (url) => dispatch(setDoiUrl(url)),
    fetchAclSuccess: (json) => dispatch(fetchAclSuccess(json)),
    getAclData: () => dispatch(getAclData()),
    getOcviewData: () => dispatch(getOcviewData()),
    setEcometerAdmin: ecometerAdmin => dispatch(setEcometerAdmin(ecometerAdmin)),
    setIsContainersMonitored: isContainersMonitored => dispatch(setIsContainersMonitored(isContainersMonitored)),
    setSelfCertState: (selfcert) => dispatch(setSelfCertState(selfcert)),
    fetchGlobalAndPersonalUserPreferences: ()=> dispatch(fetchGlobalAndPersonalUserPreferences()),
    fetchCustomUserPreferences: ()=> dispatch(fetchCustomUserPreferences()),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Bootstrap);
