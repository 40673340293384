/*
 * Copyright © 2024 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import { call, put, take, takeEvery, takeLatest, cancel, delay } from "redux-saga/effects";
import {
  POLL_GROUPS_START,
  POLL_GROUPS_STOP,
  REQUEST_GROUP,
  FETCH_TOP_SUB_GROUP,
  POLL_TOP_SUB_GROUP_START,
  POLL_TOP_SUB_GROUP_STOP,
} from "./actionTypes";
import entityTypes from "./../entityTypes";
import { Group } from "./groups";
import {
  fetchGroupsSuccess, fetchGroupsFailure, fetchTopSubGroupsFailure,
  fetchTopSubGroupsSuccess
} from "./actions";
import { setEntities, setEntity, batchEntityUpdtes } from "./../../components/entities/actions";
import { TreeView } from "./../../api/treeView/treeView";
import { batchTreeUpdtes } from "./../../api/treeView/actions";
import { Interfaces } from "./../../api/interfaces/interfaces";
import { fetchInterfacesSuccess, fetchInterfacesFailure } from "./../../api/interfaces/actions";

const isComputerSystemContainer = (entity) => {
  return (
    entity &&
    entity.type !== entityTypes.ROOT &&
    entity.type !== entityTypes.CONTAINER &&
    !entity.masterElementGroup
  );
};
const isInterfaceContainer = (entity) => {
  return (
    entity &&
    entity.type !== entityTypes.ROOT &&
    entity.type !== entityTypes.CONTAINER &&
    entity.masterElementGroup
  );
};

export const getGrpEntities = (group, entityKey) => {
  const entityType = isComputerSystemContainer(group)
    ? entityTypes.COMPUTER_SYSTEM
    : isInterfaceContainer(group)
    ? entityTypes.INTERFACES
    : entityTypes.GROUP;
  const items =
    group[entityKey] &&
    group[entityKey].map((item) => {
      item.entityType = entityType;
      return item;
    });
  return items;
};


export const getEntities = (group) => {
  let entityKey = isComputerSystemContainer(group)
    ? "computer-systems"
    : isInterfaceContainer(group)
    ? "interfaceSystems"
    : "groups";
  if (entityKey == "interfaceSystems" && !group.interfaceSystems) {
    entityKey = "interfaceSystem";
  }
  const entityType = isComputerSystemContainer(group)
    ? entityTypes.COMPUTER_SYSTEM
    : isInterfaceContainer(group)
    ? entityTypes.INTERFACES
    : entityTypes.GROUP;
  const items =
    group[entityKey] &&
    group[entityKey].map((item) => {
      item.entityType = entityType;
      return item;
    });
  return items;
};

export const getEntity = (group) => {
  group.entityType = entityTypes.GROUP;
  return group;
};

export const defaultPayload = () => {
  const payload = {
    scopeId:null,
    probeName:null,
    daId:null,
    pageNumber: 0,
    pageSize:100,
    sortColumn:"",
    sortOrder:"",
    quickFilter:"",
    colFilters:[],
    onDemand: false
  };
  return payload;
}

function* fetchInterfaces(params) {
  try {
    const response = yield call(Interfaces.getRegularFields, {csId: params.id, isGroup: params.isGroup});
    yield put(fetchInterfacesSuccess(response.data));
    return response;
  } catch (error) {
    yield put(fetchInterfacesFailure({message: error}));
  }
}

function* fetchGroups(params) {
  try {
    const response = yield call(Group.get, params);
    const entities = getEntities(response.data);
    //yield put(setEntities(entities));
    //yield put(setEntity(getEntity(response.data)));
    yield put(batchEntityUpdtes(entities, getEntity(response.data)));
    return response;
  } catch (error) {
   yield put(fetchGroupsFailure(error));
  }
}

function* fetchTree() {
  try {
    const response = yield call(TreeView.getAllGroups, 0);
    //yield put(fetchGroupSuccess(response.data, true));
    const res = yield call(TreeView.getAlarmState, 0);
    //yield put(fetchStateSuccess(res.data, true));
    const resCount = yield call(TreeView.getCountDetails, 0);
    //yield put(fetchCountSuccess(resCount.data, true));

    yield put(batchTreeUpdtes(response.data, res.data, resCount.data));

    return response;
  } catch (error) {
   yield put(fetchGroupsFailure(error));
  }
}

function* pollGroups(action) {
  while (true) {
    if (action?.isInterface) {
      yield delay(30000);
    } else {
      yield delay(30000);
    }
    const groupsView = JSON.parse(sessionStorage.getItem('groupsView'));
    if(groupsView.currentGroupsTabView === 'DETAIL_VIEW' || groupsView.currentGroupsTabView === 'INTERFACES') {
      if (action?.isInterface || window.location.pathname.endsWith("interfaces")) {
        yield call(fetchInterfaces, { id: action.id, isGroup: action?.isGroup ? true : false });
      } else {
        yield call(fetchGroups, { id: action.id });
      }
    }

    if(groupsView.currentGroupsView === 3) {
      yield call(fetchTree);
    }
  }
}

export function* groupsPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_GROUPS_START, pollGroups);
    // cancel polling on POLL_GROUPS_STOP
    yield take(POLL_GROUPS_STOP);
    yield cancel(watcherInstance);
  }
}

function* fetchGroupSetEntities(action) {
  const payload = action?.payload ? action?.payload : defaultPayload();
  sessionStorage.setItem('groupsPayload', JSON.stringify(payload));
  sessionStorage.setItem('groupId', action.params?.id);
  if(!payload?.cardsPagination) {
    sessionStorage.removeItem('cardsPage');
  }
  let finalResponse = '';

  if (!payload?.onDemand) {
    const res = yield call(TreeView.getAllGroups, 0);
    //yield put(fetchGroupSuccess(res.data, true));

    const res1 = yield call(TreeView.getAlarmState, 0);
    //yield put(fetchStateSuccess(res1.data, true));

    const resCount = yield call(TreeView.getCountDetails, 0);
    //yield put(fetchCountSuccess(resCount.data, true));

    yield put(batchTreeUpdtes(res.data, res1.data, resCount.data));
 }

  try {
    if (window.location.pathname.endsWith("interfaces")) {
      const response = yield call(Interfaces.getRegularFields, {csId: action.params?.id, isGroup: true});
      finalResponse = response;
      yield put(fetchInterfacesSuccess(response.data));
    } else {
      const response = yield call(Group.get, action.params);
      finalResponse = response;
      const group = response.data;
      yield put(setEntities(getEntities(group)));
      yield put(setEntity(getEntity(group)));
      yield put(fetchGroupsSuccess(group));
    }

    return finalResponse;
  } catch (error) {
    if (window.location.pathname.endsWith("interfaces")) {
      yield put(fetchInterfacesFailure({message: error}));
    } else {
      yield put(fetchGroupsFailure(error));
    }
  }
}

export function* fetchGroupSetEntitiesSaga() {
  yield takeLatest(REQUEST_GROUP, fetchGroupSetEntities);
}


 //------------------------------------ TopSubGroups Polling Functions----------------------------
 function* fetchTopSubGroupspoll(params) {

  try {
    const response = yield call(Group.getTopSubGroupsByDeviceCount, params)
    yield put(fetchTopSubGroupsSuccess(response.data))
  } catch (error) {
    yield put(fetchTopSubGroupsFailure(error))
  }
}

function* pollTopSubGroups(action) {
  while (true) {
    yield call(fetchTopSubGroupspoll,{
      groupId:action.groupId,
    })
    yield delay(30000)

  }
}

export function* topSubGroupsRequestSaga() {
  yield takeEvery(FETCH_TOP_SUB_GROUP, fetchTopSubGroupspoll)
}

export function* topSubGroupsPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_TOP_SUB_GROUP_START, pollTopSubGroups)
    // cancel polling on POLL_HUBS_STOP
    yield take(POLL_TOP_SUB_GROUP_STOP)
    yield cancel(watcherInstance)
  }
}
