/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component} from 'react'
import PropTypes from 'prop-types'
import inputs from './inputs'

const defaultProps = {
  actions: [
    <input type='submit' />
  ],
  actionsContainerStyle: {
    boxSizing: 'border-box',
    padding: '8px',
    width: '100%',
    textAlign: 'right',
    marginTop: '0px',
    borderTop: 'none',
  },
  contentStyle: {
    padding: '0px 24px 24px',
  },
  onChange: () => { },
}

class Form extends Component {
  static propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func,
  }
  state = {
    fields: this.props.fields || {},
    fieldErrors: {},
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.fields) {
      this.setState({ fields: nextProps.fields })
    }
  }
  onFormSubmit = (evt) => {
    evt.preventDefault()

    if (this.validate()) return
    this.props.onFormSubmit(this)
  }
  onInputChange = ({ name, value, error }) => {
    const fields = this.state.fields
    const fieldErrors = this.state.fieldErrors

    fields[name] = value
    fieldErrors[name] = error

    this.setState({ fields, fieldErrors })
    this.props.onChange(this)
  }
  validate = () => {
    const fieldErrors = this.state.fieldErrors
    const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k])
    const error = this.props.validate ? this.props.validate(this.state.fields) : false

    // has form errors
    if (error) return true
    // has field errors
    if (errMessages.length) return true

    return false
  }
  render() {
    return (
      <form onSubmit={this.onFormSubmit}>
        <div style={Object.assign({}, defaultProps.contentStyle, this.props.contentStyle)}>
          {React.Children.map(this.props.children, child => {
            let clone = child
            if (inputs.includes(child.type)) {
              clone = React.cloneElement(child, {
                value: this.state.fields[child.props.name],
                onChange: this.onInputChange,
              })
            }
            return clone
          })}
        </div>
        <div style={Object.assign({}, defaultProps.actionsContainerStyle, this.props.actionsContainerStyle)}>
          {this.props.actions.map((action, index) => {
            let clone = React.cloneElement(action, {
              key: index,
            })
            // add the disabled attribute to the submit action based on validation
            // The submit button *MUST* be the last action
            if (index === this.props.actions.length - 1) {
              clone = React.cloneElement(action, {
                key: index,
                disabled: this.validate(),
              })
            }
            return clone
          })}
        </div>
      </form>
    )
  }
}
Form.defaultProps = defaultProps
export default Form