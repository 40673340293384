/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  REQUEST_COMPUTER_SYSTEM,
  REQUEST_COMPUTER_SYSTEMS,
  FETCH_COMPUTER_SYSTEM_SUCCESS,
  FETCH_COMPUTER_SYSTEMS_SUCCESS,
  FETCH_COMPUTER_SYSTEM_FAILURE,
  FETCH_COMPUTER_SYSTEMS_FAILURE,
  INVENTORY_TREE_SELECTION,
  SET_COMPUTER_SYSTEMS_TOTAL_ITEMS,
  SET_COMPUTER_SYSTEMS_SORT,
  SET_COMPUTER_SYSTEMS_FILTER,
  SET_COMPUTER_SYSTEMS_COLUMN_FILTER,
  SET_COMPUTER_SYSTEMS_QUICK_FILTER,
  SET_COMPUTER_SYSTEMS_REFRESH_GRID_DATA,
  SET_COMPUTER_SYSTEMS_TREE,
  FETCH_LIST_SUCCESS,
  UPDATE_COMPUTER_SYSTEM,
} from "./actionTypes";
import entityTypes from "./../entityTypes";
import { cleanItems } from "../../components/entities/setEntitiesReducer";

const computerSystems = (
  state = {
    isFetching: false,
    didInvalidate: false,
    monitoringProbes: [],
    totalItems: 0,
    suppressGridUpdate: false,
    config: {
      scopeId: null,
      probeName: null,
      daId: null,
      pageSize: 100,
      sortColumn: null,
      sortOrder: null,
      quickFilter: null,
      colFilters: [],
    },
  },
  action
) => {
  switch (action.type) {
    case REQUEST_COMPUTER_SYSTEM:
    case REQUEST_COMPUTER_SYSTEMS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case UPDATE_COMPUTER_SYSTEM:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        refreshGridData: action.refreshGridData,
      });
    case FETCH_COMPUTER_SYSTEM_SUCCESS:
      const item = Object.assign({}, action.data, {
        entityType: entityTypes.COMPUTER_SYSTEM,
      });
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        item: item,
      });
    case FETCH_COMPUTER_SYSTEMS_SUCCESS:
      const items = cleanItems(
        action.data._items.map((item) => {
          return {
            ...structuredClone(item),
            entityType: entityTypes.COMPUTER_SYSTEM,
          };
        })
      );
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        totalItems: action.data?.total,
        item: {
          total: action.data?.total,
          responseCode: action.data?.responseCode,
          entityType: entityTypes.COMPUTER_SYSTEM,
          items,
        },
      });
    case FETCH_COMPUTER_SYSTEM_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        item: {},
      });
    case FETCH_COMPUTER_SYSTEMS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        item: {},
      });
    case INVENTORY_TREE_SELECTION:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        errorMessage: action.errorMessage,
        item: {},
      });
    case FETCH_LIST_SUCCESS:
      return Object.assign({}, state, {
        monitoringProbes: action.data,
      });

    case SET_COMPUTER_SYSTEMS_TOTAL_ITEMS:
      const newState = { ...state };
      newState.totalItems = action.totalItems;
      return newState;
    case SET_COMPUTER_SYSTEMS_SORT:
      const newState1 = { ...state };
      newState1.config.sortColumn = action.sort.sortColumn;
      newState1.config.sortOrder = action.sort.sortDirection;
      return newState1;
    case SET_COMPUTER_SYSTEMS_FILTER:
      const newState2 = { ...state };
      newState2.config.filters = action.filters;
      return newState2;
    case SET_COMPUTER_SYSTEMS_COLUMN_FILTER:
      const newState3 = { ...state };
      newState3.config.colFilters = action.colFilters;
      return newState3;
    case SET_COMPUTER_SYSTEMS_QUICK_FILTER:
      const newState4 = { ...state };
      newState4.config.quickFilter = action.filter;
      return newState4;
    case SET_COMPUTER_SYSTEMS_TREE:
      const newState5 = { ...state };
      newState5.config.scopeId = action.config.scopeId;
      newState5.config.daId = action.config.daId;
      return newState5;
    case SET_COMPUTER_SYSTEMS_REFRESH_GRID_DATA:
      const newState6 = { ...state };
      newState6.refreshGridData = action.refreshGridData;
      return newState6;
    default:
      return state;
  }
};
export default computerSystems;
