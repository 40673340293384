/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  REQUEST_ROBOTS_STATUS,
  FETCH_ROBOTS_STATUS_SUCCESS,
  FETCH_ROBOTS_STATUS_FAILURE,
  RESET_ROBOTS_STATUS,
  STORE_PROBE_JOBID,
  REQUEST_PROBES_STATUS_SUCCESS
} from './actionTypes';
import { isEqual } from '../../utils/lodash-utils';

const robotsStatus = (state = {
  isFetching: false,
  didInvalidate: false,
  status: [],
  probesStatus:[],
  probeJobIds:[],
  robotsStatusfulfilled:false
}, action) => {
  switch (action.type) {
    case REQUEST_ROBOTS_STATUS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      })
    case RESET_ROBOTS_STATUS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        status: [],
        probesStatus:[],
        robotsStatusfulfilled:false,
        probeJobIds:[]
      })
    case FETCH_ROBOTS_STATUS_SUCCESS:
      const status = action.robots._items
      const updatedStatusState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        status: status
      })
      return isEqual(updatedStatusState, state) ? state : updatedStatusState
    case FETCH_ROBOTS_STATUS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        status: []
      })
    case STORE_PROBE_JOBID:
    	var data = state.probeJobIds.slice(0);
    	data = data.concat(action.idsArr);
    	return Object.assign({}, state, {
    		probeJobIds:data,
    		robotsStatusfulfilled:action.status
        })
    case REQUEST_PROBES_STATUS_SUCCESS:
    	const probesStatus = action.probes;
    	return  Object.assign({}, state, {
    		probesStatus:probesStatus
        });
    default:
      return state
  }
};

export default robotsStatus
