/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import * as React from 'react';
import { SvgIcon } from '@mineral/core';

export const OutlinedPolygon = React.forwardRef(
  (props, ref) => (
    <SvgIcon data-testid="LearnIcon" viewBox="0 0 40 40" fill="none" {...props} ref={ref}>
    <path d="M26.3234 11.2444L26.3479 11.2628L26.373 11.2805L32.839 15.8284L30.2811 23.3083L30.2712 23.3373L30.2622 23.3666L27.9349 30.9215L20.0307 30.8002L20 30.7998L19.9693 30.8002L12.0651 30.9215L9.73785 23.3666L9.72881 23.3373L9.71889 23.3083L7.16102 15.8284L13.627 11.2805L13.6521 11.2628L13.6766 11.2444L20 6.50029L26.3234 11.2444Z" fill={props.fill} stroke="#1D5BBF" stroke-width="4"/>
    </SvgIcon>
  )
);