/*
 * Copyright © 2024 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Dialog, DialogTitle, Button as FlatButton, Typography, Tooltip, IconButton, DialogContent, DialogActions } from "@mineral/core";
import Resource from "./Resource";
import "./dialogButtonStyle.less";
import {

  ClearIcon as Clear,
} from "../ui-components/uim-components";
import { OptimizeBreadcrumb } from "../breadcrumbs/BreadcrumbUtil";

class DeleteSubgroupDialog extends Component {
  state = {
    disableDeleteButton: false,
  };
  static propTypes = {
    history: PropTypes.object.isRequired,
  };
  componentWillReceiveProps() {
    this.setState({
      disableDeleteButton: false,
    });
  }
  _updateRoute = (parentGroupId) => {
    const history = this.props.history;
    const pathname = history.location.pathname;
    const listViewPath =!history.location.pathname.endsWith("card")
      ? ""
      : "/card";
    // new path is just the current path minus the last id
    let path = pathname.slice(0, pathname.lastIndexOf(`/${parentGroupId}`));
    path = listViewPath
      ? `${path}/${parentGroupId}/card`
      : `${path}/${parentGroupId}`;
    if (this.props.location.pathname.search("treeviewnew") < 0) {
      history.push(path);
      this.props.history.replace(path);
    } else {
      sessionStorage.setItem("groupDeleted", true);
      this.props.refreshTree();
    }
  };

  render() {
    let actions = [
      <FlatButton
        variant="text"
        //Cancel
        children={Resource.get("Cancel")}
        color="primary"
        onClick={this.onClose}
      />,
      <FlatButton

        //Delete
        children={Resource.get("Delete")}
        color="error"
        variant="contained"
                onClick={this.handleDelete}
        disabled={this.state.disableDeleteButton}
      />,
    ];
    //Delete Group
    let title = Resource.get("Delete Group");
    //Your individual devices will not be deleted.
    const helplabelmessage = Resource.get(
      " Your individual devices will not be deleted. "
    );
    let content = (
      <div
        style={{
          fontSize: "16px",
          padding: "0px 24px 24px",
        }}
      >
        <p style={{
          maxWidth: "500px",
          textOverflow: "ellipsis",
          overflow: "hidden"
        }}>
          This action removes the '{this.props.selectedName}' group from
          Infrastructure Management.
        </p>
        <p>{helplabelmessage} </p>
      </div>
    );

    return (
      <Dialog
        title={title}
        modal={false}
        open={this.props.open}
        onClose={this.onClose}
      >
       <DialogTitle component="div" disableTypography>


         <Typography component="h1" variant="h4">
         {title}
         </Typography>

   </DialogTitle>
   <div style={{height:'0px'}}>

   <Tooltip title={"Close"} style={{float:'right',marginRight:'8px',top:'-50px'}}>
    <IconButton autoFocus  onClick={this.onClose}>
      <Clear role='figure' />
    </IconButton>
     </Tooltip>
   </div>
        <DialogContent >

        {content}
        </DialogContent>

        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
  handleDelete = () => {
    this.setState({
      disableDeleteButton: true,
    });
    this.props.deleteGroup(
      this.props.selectedId,
      this._updateRoute,
      this.props.handleClose,
      this.props.parentId,
      this.props.handleErrorDialogOpen,
      this.props.handleDeleteSnackbar
    );
    const breadcrumbs = JSON.parse(sessionStorage.getItem('entity'));
    if(breadcrumbs) {
      breadcrumbs.splice(breadcrumbs.findIndex(crumb=>crumb.id===this.props.parentId)+1);
      sessionStorage.setItem('entity', JSON.stringify(OptimizeBreadcrumb(breadcrumbs)));
    }
    sessionStorage.setItem("groupDeleted", true);
    this.props.refreshTree();
  };
  onClose = () => {
    this.props.handleClose("deleteSubGroupOpen");
  };
}

export default withRouter(DeleteSubgroupDialog);
