// This file was generated by GenerateES6Bundle.
import en from './FhResourceBundle';
import de from './FhResourceBundle_de';
import es from './FhResourceBundle_es';
import ja from './FhResourceBundle_ja';
import ko from './FhResourceBundle_ko';
import pt from './FhResourceBundle_pt';
import zh from './FhResourceBundle_zh';
let locale = null;
class Resource {
    static bundles = {
      en:en,
      de:de,
      es:es,
      ja:ja,
      ko:ko,
      pt:pt,
      zh:zh,
    };

  static get(key, defVal) {
    let bundle = this.bundles[this.getLocale()];
    if(!bundle) {
      bundle = this.bundles['en']
   }
    let val = bundle[key];
    return (val) ? val : ((defVal) ? defVal : key);
  }

  static setLocale(loc) {
    locale = loc;
  }

  static getLocale() {
    if(locale) {
      return locale;
    } else if(window.GlobalLocale) {
      return window.GlobalLocale;
    } else if(navigator.languages) {
      for(let i = 0; i < navigator.languages.length; i++) {
        var lang = navigator.languages[i];
        if(lang) {
          if(lang.indexOf('-') != -1) {
            lang = lang.substring(0, lang.indexOf('-'));
          }

          if(this.bundles[lang]) {
            return lang;
          }
        }
      }
    }
    return locale;
  }
}
export default Resource;
