/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  FETCH_OPS,
  FETCH_OPS_SUCCESS,
  FETCH_OPS_FAILURE,
  SAVE_PERIOD,
  SAVE_PERIOD_SUCCESS,
  SAVE_PERIOD_FAILURE,
  DELETE_OP,
  DELETE_OP_SUCCESS,
  DELETE_OP_FAILURE,
  FETCH_BY_ID,
  FETCH_ID_SUCCESS,
  FETCH_ID_FAILURE
} from "./actionTypes";

const operating_periods = (
  state = {
    isFetching: false,
    didInvalidate: false,
    isSaving: false,
    error: "",
    isDelete: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_OPS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case FETCH_OPS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.operating_periods,
      });
    case FETCH_OPS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: [],
      });

      case FETCH_BY_ID:
        return Object.assign({}, state, {
          idFetching: true,
          didInvalidate: false,
        });
      case FETCH_ID_SUCCESS:
        return Object.assign({}, state, {
          idFetching: true,
          didInvalidate: false,
          opitems: action.opitems,
        });
      case FETCH_ID_FAILURE:
        return Object.assign({}, state, {
          idFetching: false,
          didInvalidate: true,
          errorMessage: action.errorMessage
        });

    case SAVE_PERIOD:
      return {
        ...state,
        isSaving: true,
      };
    case SAVE_PERIOD_SUCCESS:
      return {
        ...state,
        isSaving: false,
        didInvalidate: false,
      };
    case SAVE_PERIOD_FAILURE:
      return {
        ...state,
        isSaving: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
      };

      case DELETE_OP:
        return {
          ...state,
          isDelete: true,
        };
      case DELETE_OP_SUCCESS:
        return {
          ...state,
          isDelete: true,
          didInvalidate: false,
        };
      case DELETE_OP_FAILURE:
        return {
          ...state,
          isDelete: false,
          didInvalidate: true,
          errorMessage: action.errorMessage,
        };

    default:
      return state;
  }
};

export default operating_periods;
