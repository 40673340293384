/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import { 
  FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES,
  FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_SUCCESS,
  FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_FAILURE,
  
  FETCH_ACCOUNT_USER_PREFERENCES,
  FETCH_ACCOUNT_USER_PREFERENCES_SUCCESS,
  FETCH_ACCOUNT_USER_PREFERENCES_FAILURE,
  
  
  CREATE_USER_PREFERENCES,
  CREATE_USER_PREFERENCES_SUCCESS,
  CREATE_USER_PREFERENCES_FAILURE,
  
  
  UPDATE_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES_SUCCESS,
  UPDATE_USER_PREFERENCES_FAILURE,
  
  
  SETDEFAULTVIEW_USER_PREFERENCES,
  SETDEFAULTVIEW_USER_PREFERENCES_SUCCESS,
  SETDEFAULTVIEW_USER_PREFERENCES_FAILURE,
  
  DELETE_USER_PREFERENCES,
  DELETE_USER_PREFERENCES_SUCCESS,
  DELETE_USER_PREFERENCES_FAILURE,
  
  RESET_STORE,
  
  } from './actionTypes'
  import { UserPreferences as userpreferencesApi } from './userpreferences'
  
  
  export function requestGlobalAndPersonalUserPreferences() {
      return {
        type: FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES,
      }
    }
    
    export function fetchGlobalAndPersonalUserPreferencesSuccess(json) {
      return {
        type: FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_SUCCESS,
        userpreferences: json,
      }
    }
    
    export function fetchGlobalAndPersonalUserPreferencesFailure(error) {
      return {
        type: FETCH_GLOBAL_AND_PERSONAL_USER_PREFERENCES_FAILURE,
        errorMessage: error.message,
      }
    }
    
    export function fetchGlobalAndPersonalUserPreferences() {
      return dispatch => {
        dispatch(requestGlobalAndPersonalUserPreferences())
        return userpreferencesApi
          .getGlobalAndPersonalUserPreferences()
          .then(response => response.data)
          .then(json => dispatch(fetchGlobalAndPersonalUserPreferencesSuccess(json)))
          .catch(function(error) {
            dispatch(fetchGlobalAndPersonalUserPreferencesFailure(error))
          })
      }
    }
    
  
    export function requestAccountUserPreferences() {
      return {
        type: FETCH_ACCOUNT_USER_PREFERENCES,
      }
    }
    
    export function fetchAccountUserPreferencesSuccess(json) {
      return {
        type: FETCH_ACCOUNT_USER_PREFERENCES_SUCCESS,
        userpreferences: json,
      }
    }
    
    export function fetchAccountUserPreferencesFailure(error) {
      return {
        type: FETCH_ACCOUNT_USER_PREFERENCES_FAILURE,
        errorMessage: error.message,
      }
    }
    
    export function fetchAccountUserPreferences() {
      return dispatch => {
        dispatch(requestAccountUserPreferences())
        return userpreferencesApi
          .getAccountUserPreferences()
          .then(response => response.data)
          .then(json => dispatch(fetchAccountUserPreferencesSuccess(json)))
          .catch(function(error) {
            dispatch(fetchAccountUserPreferencesFailure(error))
          })
      }
    }
    /*export function fetchUserPreference(userpreferenceId) {
      return dispatch => {
        dispatch(requestUserPreference())
        return userpreferences
          .getUserPreference(userpreferenceId)
          .then(response => response.data)
          .then(json => dispatch(fetchUserPreferenceSuccess(json)))
          .catch(function(error) {
            dispatch(fetchUserPreferenceFailure(error))
          })
      }
    }*/
  
    export function createUserPreferenceStart(userPreferenceParams) {
      return {
        type: CREATE_USER_PREFERENCES,
        
      };
    }
    
    export function createUserPreferenceSuccess(userpreference) {
      return {
        type: CREATE_USER_PREFERENCES_SUCCESS,
        userpreference:userpreference,
        
        
      };
    }
    
    export function createUserPreferenceFailure(error) {
      return {
        type: CREATE_USER_PREFERENCES_FAILURE,
        error: error.message,
  
       
      };
    }
    
    export function createUserPreference(userPreferenceParams) {
      return (dispatch) => {
        dispatch(createUserPreferenceStart(userPreferenceParams));
        return userpreferencesApi
          .createUserPreference(userPreferenceParams)
          .then((response) => response.data)
          .then((json) => dispatch(createUserPreferenceSuccess(json)))
          .catch(function (error) {
            dispatch(createUserPreferenceFailure(error));
          });
      };
    }
  
  
    export function updateUserPreferenceStart() {
      return {
        type: UPDATE_USER_PREFERENCES,
        
      };
    }
    
    export function updateUserPreferenceSuccess(userpreference) {
      return {
        type: UPDATE_USER_PREFERENCES_SUCCESS,
        userpreference:userpreference,
      };
    }
    
    export function updateUserPreferenceFailure(error) {
      return {
        type: UPDATE_USER_PREFERENCES_FAILURE,
        error: error.message,
       
      };
    }
    
    export function updateUserPreference(userPreferenceParams) {
      return (dispatch) => {
        dispatch(updateUserPreferenceStart(userPreferenceParams));
        return userpreferencesApi
          .updateUserPreference(userPreferenceParams)
          .then((response) => response.data)
          .then((json) => dispatch(updateUserPreferenceSuccess(json)))
          .catch(function (error) {
            dispatch(updateUserPreferenceFailure(error));
          });
      };
    }
  
  
    export function setDefaultViewUserPreferenceStart() {
      return {
        type: SETDEFAULTVIEW_USER_PREFERENCES,
        
      };
    }
    
    export function setDefaultViewUserPreferenceSuccess(userpreference) {
      return {
        type: SETDEFAULTVIEW_USER_PREFERENCES_SUCCESS,
        userpreference:userpreference,
      };
    }
    
    export function setDefaultViewUserPreferenceFailure(error) {
      return {
        type: SETDEFAULTVIEW_USER_PREFERENCES_FAILURE,
        error: error.message,
       
      };
    }
    
    export function setDefaultViewUserPreference(userPreferenceParams) {
      return (dispatch) => {
        dispatch(setDefaultViewUserPreferenceStart(userPreferenceParams));
        return userpreferencesApi
          .updateUserPreference(userPreferenceParams)
          .then((response) => response.data)
          .then((json) => dispatch(setDefaultViewUserPreferenceSuccess(json)))
          .catch(function (error) {
            dispatch(setDefaultViewUserPreferenceFailure(error));
          });
      };
    }
  
  
    export function resetStore() {
      return {
        type: RESET_STORE,
        
       
      };
    }
    
  
    export function deleteUserPreferenceStart() {
      return {
        type: DELETE_USER_PREFERENCES
        
      };
    }
    
    export function deleteUserPreferenceSuccess() {
      return {
        type: DELETE_USER_PREFERENCES_SUCCESS,
        
        
      };
    }
    
    export function deleteUserPreferenceFailure(error) {
      return {
        type: DELETE_USER_PREFERENCES_FAILURE,
        error: error.message,
       
      };
    }
    
    export function deleteUserPreference(userPreferenceId) {
      return (dispatch) => {
        dispatch(deleteUserPreferenceStart());
        return userpreferencesApi
          .deleteUserPreference(userPreferenceId)
          .then((response) => response.data)
          .then((json) => dispatch(deleteUserPreferenceSuccess()))
          .catch(function (error) {
            dispatch(deleteUserPreferenceFailure(error));
          });
      };
    }
    
