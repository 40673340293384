// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Close
  content["Close"] =
  "Close";

  //Client:
  content["Client:"] =
  "Client:";

  //Branch:
  content["Branch:"] =
  "Branch:";

  //Rev:
  content["Rev:"] =
  "Rev:";

  //Date:
  content["Date:"] =
  "Date:";

  //Server/API:
  content["Server/API:"] =
  "Server/API:";

  // END OF LOCALIZATION

export default content;
