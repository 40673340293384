/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
export const GET_PERFORMANCE_REPORTS_HOST_BY_HOST = 'GET_PERFORMANCE_REPORTS_HOST_BY_HOST'
export const GET_PERFORMANCE_REPORTS_QOS_BY_HOST = 'GET_PERFORMANCE_REPORTS_QOS_BY_HOST'
export const GET_PERFORMANCE_REPORTS_TARGETS_BY_HOST = 'GET_PERFORMANCE_REPORTS_TARGETS_BY_HOST'

export const GET_PERFORMANCE_REPORTS_QOS_BY_TARGET = 'GET_PERFORMANCE_REPORTS_QOS_BY_TARGET'
export const GET_PERFORMANCE_REPORTS_TARGET_BY_TARGET = 'GET_PERFORMANCE_REPORTS_TARGET_BY_TARGET'
export const GET_PERFORMANCE_REPORTS_HOSTS_BY_TARGET = 'GET_PERFORMANCE_REPORTS_HOSTS_BY_TARGET'

export const SET_PRD_SOURCE = 'SET_PRD_SOURCE';
export const SET_TIME_FRAME = 'SET_TIME_FRAME';
export const CLEAR_QOS_SELECTION_DATA='CLEAR_QOS_SELECTION_DATA';

export const SET_REPORT_TITLE="SET_REPORT_TITLE";
export const SAVE_REPORT="SAVE_REPORT";
export const SET_TIME_INTERVAL="SET_TIME_INTERVAL";
export const SET_AGGREGATION_LEVEL="SET_AGGREGATION_LEVEL";
export const SET_MIN_MAX_YAXIS="SET_MIN_MAX_YAXIS";

export const SET_CHART_TITLE="SET_CHART_TITLE";
export const SET_REPORT_PATH_VISIBILITY="SET_REPORT_PATH_VISIBILITY";
export const ADD_NEW_CHART="ADD_NEW_CHART";
export const ADD_NEW_SERIES="ADD_NEW_SERIES";
export const EXPAND_CHART="EXPAND_CHART";
export const DELETE_CHART="DELETE_CHART";
export const SET_NEW_PREPARED_CHARTS = 'SET_NEW_PREPARED_CHARTS'
export const SET_NEW_PREPARED_FILTER_CHARTS = 'SET_NEW_PREPARED_FILTER_CHARTS'

export const UPDATE_IMPORTED_XML_DATA="UPDATE_IMPORTED_XML_DATA";
export const SET_BASELINE="SET_BASELINE";
export const SET_CHART_FILTER = "SET_CHART_FILTER";
export const SET_SAVE_STATUS = 'SET_SAVE_STATUS';
export const SET_STATUS_MESSAGE = 'SET_STATUS_MESSAGE';
export const UPDATE_CHART_ORDER = "UPDATE_CHART_ORDER";
export const SET_DATA_COUNT = "SET_DATA_COUNT";
export const SET_IS_INTERPOLATION = "SET_IS_INTERPOLATION";
