/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import axios from 'axios'

import config from '../config'
const headersConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}
const chartSeriesParams = ["pLine", "scale", "style", "graphColor", "displayName", "unit_type", "unit_short", "unit"];
export class PRD {
  static getChartBaselineData(params) {

    let url = [config.basename, `api/v1/prd/chartBaselineData?`].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.post("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/prd/chartBaselineData");
    } else {

      for (var key in params) {
        let keyValue = (params[key] && params[key] != null) ? encodeURIComponent(params[key]) : ''

        if (key === "interval" && keyValue === "")
          keyValue = 0

        if (url.indexOf('?') == url.length - 1)
          url = url.concat(`${key}=${keyValue}`)
        else
          url = url.concat(`&${key}=${keyValue}`)
      }

      return axios.post(url, {}, headersConfig);
    }
  }

  static getchartSeriesInfo(params) {
    let url = [config.basename, `api/v1/prd/chartSeriesInfo?`].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.post("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/prd/chartSeriesInfo");
    } else {
      for (var key in params) {
        let keyValue = (params[key] && params[key] != null) ? encodeURIComponent(params[key]) : ''

        if (key === "interval" && keyValue === "")
          keyValue = 0

        if (url.indexOf('?') == url.length - 1)
          url = url.concat(`${key}=${keyValue}`)
        else
          url = url.concat(`&${key}=${keyValue}`)
      }

      return axios.post(url, {}, headersConfig);
    }
  }

  static getchartSeriesData(params) {
    let url = [config.basename, `api/v1/prd/chartSeriesData?`].join('/')
    if (process.env.NODE_ENV == "development") {
      return axios.post("http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/prd/chartSeriesData");
    } else {
      for (var key in params) {
        if (chartSeriesParams.includes(key)) {
          continue;
        }

        let keyValue = (params[key] && params[key] != null) ? encodeURIComponent(params[key]) : ''

        if (key === "interval" && keyValue === "")
          keyValue = 0

        keyValue =  key === "interpolateData" ? params[key] : keyValue;
        
        if (url.indexOf('?') == url.length - 1)
          url = url.concat(`${key}=${keyValue}`)
        else
          url = url.concat(`&${key}=${keyValue}`)
      }

      return axios.post(url, {}, headersConfig);
    }
  }

  static getQosSource() {

    let url = [config.basename, `api/v1/qosData/sourceList?searchText=`].join('/');
    if (process.env.NODE_ENV == 'development') {
      url = "http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/3XH68b/host"
    }
    return url;
  }

  static getQosListBySource(hostName) {

    let url = [config.basename, `api/v1/qosData/qosListBySource?source=${encodeURIComponent(hostName)}&searchText=`].join('/');
    if (process.env.NODE_ENV == 'development') {
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/3XH68b/host/${encodeURIComponent(hostName)}`
    }
    return url;
  }

  static gettargetListBySourceAndQos(hostName, qos) {

    let url = [config.basename, `api/v1/qosData/targetListBySourceAndQos?source=${encodeURIComponent(hostName)}&qos=${encodeURIComponent(qos)}&searchText=`].join('/');
    if (process.env.NODE_ENV == 'development') {
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/3XH68b/host/${encodeURIComponent(hostName)}/${encodeURIComponent(qos)}`
    }
    return url;
  }

  static getQosList() {

    let url = [config.basename, `api/v1/qosData/qosList?searchText=`].join('/');
    if (process.env.NODE_ENV == 'development') {
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/sqVEHr/target`
    }
    return url;
  }

  static getTargetListByQos(qos) {

    let url = [config.basename, `api/v1/qosData/targetListByQos?qos=${encodeURIComponent(qos)}&searchText=`].join('/');
    if (process.env.NODE_ENV == 'development') {
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/sqVEHr/target/${encodeURIComponent(qos)}`
    }
    return url;
  }

  static getSourceListByQosAndTarget(qos, target) {

    let url = [config.basename, `api/v1/qosData/sourceListByQosAndTarget?target=${encodeURIComponent(target)}&qos=${encodeURIComponent(qos)}&searchText=`].join('/');
    if (process.env.NODE_ENV == 'development') {
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/sqVEHr/target/${encodeURIComponent(qos)}/${encodeURIComponent(target)}`
    }
    return url;
  }

  static savePrdReport(requestBody) {
    let url = [config.basename, `api/v1/prd/saveReport`].join('/')
    return axios.post(url, requestBody);
  }

  static deletePrdReport(reportName) {
    let url = [config.basename, `/api/v1/prd/deleteReport?report=${encodeURIComponent(reportName)}`].join('/')
    return axios.post(url, {});
  }

  static loadReport(reportName) {
    let url = [config.basename, `api/v1/prd/loadReport?report=${encodeURIComponent(reportName)}`].join('/')
    if (process.env.NODE_ENV == 'development') {
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/prd/loadReport`
    }
    return axios.post(url, {});
  }

  static listPrdReports() {
    let url = [config.basename, `api/v1/prd/listReports`].join('/')
    if (process.env.NODE_ENV == 'development') {
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/prd/listReports`
    }
    return axios.post(url, {});
  }

  static filterPRDOutput(requestBody, filterId, apply) {
    let url = [config.basename, `api/v1/prd/filterSearch?filterId=${filterId}&apply=${apply}`].join('/')
    if (process.env.NODE_ENV == 'development') {
      url = `http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/SbSbmz/filter`
    }
    return axios.post(url, requestBody);
  }

  static getRestmonUploadUrl() {
    let url = [config.basename, `api/v1/restmon/uploadSchema`].join('/');

    if (process.env.NODE_ENV == 'development')
      url = "http://10.252.25.208:8080/castlemock/mock/rest/project/ek7Poq/application/mH7xXt/restmon/uploadSchema"

    return url;
  }

  // 90 Days Password Expired Notification API
  static expiredPasswordInfo() {
    let url = [config.basename, `api/v1/contactPasswordLog/expiredPasswordInfo`].join('/')
    if (process.env.NODE_ENV == 'development') {
      url = "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/expiredPasswordURI"
    }
    return axios.get(url);
  }

  static getChartRefreshInterval() {    
    let url = "";
    if (process.env.NODE_ENV == "production") {
      url = "/operatorconsole_portlet/api/v1/wasp/prdpollinginterval";
    } else {
      url =
        "http://10.252.25.208:8080/castlemock/mock/rest/project/1QXW0n/application/mA7L8U/alarmpollinginterval";
    }
    return axios.get(url);
  }


}
