// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Confirm  Delete
  content["Confirm Delete"] =
  "Confirm Delete";

  //Close
  content["Close"] =
  "Close";

  //Are you sure you want to delete the metric view
  content["Are you sure you want to delete the metric view with name"] =
  "Are you sure you want to delete the metric view with name";

  //This operation is irreversible
  content["This operation is irreversible"] =
  "This operation is irreversible";

  //Cancel
  content["Cancel"] =
  "Cancel";

  //Delete
  content["Delete"] =
  "Delete";

  //filter
  content["Filter"] =
  "Filter";

  //ErrorFindingDevicesGroups
  content["Error in finding devices/groups. Please check the filter criteria."] =
  "Error in finding devices/groups. Please check the filter criteria.";

  //SearchforComputerSystems message
  content["Filter by Device Name"] =
  "Filter by Device Name";

  // END OF LOCALIZATION

export default content;
