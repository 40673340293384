/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React from 'react'

const NotComplianceSlaIcon = (props) => {
  return (
    <svg
      width={props.size || '16'}
      height={props.size || '16'}
      viewBox="0 0 16 16"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex="-1"
    >
      <g clipPath="url(#clip0_3040_69026)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.47208 15.3336C2.04933 15.3336 1.63352 15.2256 1.26446 15.0197C0.0713045 14.3542 -0.353804 12.8485 0.320393 11.6604L5.8487 1.91765C6.07151 1.52498 6.39818 1.2011 6.79277 0.981006C7.97928 0.319177 9.48166 0.736142 10.1521 1.91765L15.6804 11.6604C15.89 12.0298 16.0003 12.4471 16.0003 12.8717C16.0003 14.234 14.8912 15.3336 13.5287 15.3336H2.47208Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.47183 14C2.27632 14 2.08411 13.9501 1.91372 13.855C1.36583 13.5494 1.17156 12.8613 1.47979 12.3181L7.00811 2.5754C7.11029 2.39531 7.26039 2.24649 7.44203 2.14517C7.98992 1.83956 8.68394 2.03218 8.99218 2.5754L14.5205 12.3181C14.6164 12.487 14.6667 12.6776 14.6667 12.8715C14.6667 13.4947 14.1571 14 13.5285 14H2.47183ZM8.02482 10.8612C8.46575 10.8612 8.8232 11.2156 8.8232 11.6528C8.8232 12.09 8.46575 12.4444 8.02482 12.4444C7.58389 12.4444 7.22644 12.09 7.22644 11.6528C7.22644 11.2156 7.58389 10.8612 8.02482 10.8612ZM8.74017 5.16627V9.38835H7.30308V5.16627H8.74017Z"
          fill="#DE1B1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_3040_69026">
          <rect width={props.size || '16'} height={props.size || '16'} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NotComplianceSlaIcon
