// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  // Refresh
  content["Refresh"] =
  "Refresh";

  // view
  content["View"] =
  "View";

  // Delete
  content["Delete"] =
  "Delete";

  // Properties
  content["Properties"] =
  "Properties";

  // NEW
  content["New"] =
  "New";

  // Navigator
  content["Navigator"] =
  "Navigator";

  // SERVICE LEVEL AGREEMENT
  content["Service Level Agreement"] =
  "Service Level Agreement";

  // Operating Periods
  content["Operating Periods"] =
  "Operating Periods";

  // New Operating Period
  content["New Operating Period"] =
  "New Operating Period";

  // Calculation Profiles
  content["Calculation Profiles"] =
  "Calculation Profiles";

  // Quality Of Service
  content["Quality Of Service"] =
  "Quality Of Service";

  // New Quality Of Service
  content["New Quality Of Service"] =
  "New Quality Of Service";

  //Ok
  content["Ok"] =
  "Ok";

  // cancel
  content["Cancel"] =
  "Cancel";

  // Time Specification
  content["Time Specification"] =
  "Time Specification";

  // WEEKDAY
  content["WEEKDAY"] =
  "WEEKDAY";

  // FROM
  content["FROM"] =
  "FROM";

  // TO
  content["TO"] =
  "TO";

  // Edit
  content["Edit"] =
  "Edit";

  //Name
  content["Name"] =
  "Name";

  // Operating Period
  content["Operating Period"] =
  "Operating Period";

  // Description
  content["Description"] =
  "Description";

  // Name Required
  content["Name is required"] =
  "Name is required";

  // Description Required
  content["Description is required"] =
  "Description is required";

  // Qos
  content["QoS"] =
  "QoS";

  // Group
  content["Group"] =
  "Group";

  // Unit
  content["Unit"] =
  "Unit";

  // Unit Abbreaviation
  content["Unit Abbreaviation"] =
  "Unit Abbreaviation";

  // Object Type
  content["Object type"] =
  "Object type";

  // Is bool
  content["Is Bool?"] =
  "Is Bool?";

  // Has max
  content["Has maximum value?"] =
  "Has maximum value?";

  // Use DAta Type
  content["Use specific datatype properties"] =
  "Use specific datatype properties";

  // Source
  content["Source"] =
  "Source";

  // Target
  content["Target"] =
  "Target";

  // Proceed
  content["Proceed"] =
  "Proceed";

  //Today
  content["Today"] =
  "Today";

  //Apply
  content["Apply"] =
  "Apply";

  //Successfully deleted QOS
  content["Deleted successful."] =
  "Deleted successful.";

  //Successfully deleted QOS
  content["Edited QOS successfully."] =
  "Edited QOS successfully.";

  //Something went wrong
  content["Something went wrong."] =
  "Something went wrong.";

  //Value should be greater than
  content["Value should be greater than"] =
  "Value should be greater than";

  //Value should be greater than
  content["Value should be less than"] =
  "Value should be less than";

  //qos created successfully
  content["Qos created successfully."] =
  "Qos created successfully.";

  //qos created successfully
  content["Exported QOS data will be saved to "] =
  "Exported QOS data will be saved to ";

  //qos created successfully
  content["Please check after some time."] =
  "Please check after some time.";

  // Delete Data Operation
  content["Delete Data Operation"] =
  "Delete Data Operation";

  // Invalidate Data
  content["Invalidate Data"] =
  "Invalidate Data";

  // Delete Data
  content["Delete Data"] =
  "Delete Data";

  // DELETE_DATA_WARNING_TEXT
  content["Please note that a manual restart of the data engine probe is needed to complete this action. This operation is irreversible..."] =
  "Please note that a manual restart of the data engine probe is needed to complete this action. This operation is irreversible...";

  // DO_YOU_WISH_TO_DELETE
  content["Do you wish to delete "] =
  "Do you wish to delete ";

  // OBJECTS_QUESTION_MARK
  content["object(s)?"] =
  "object(s)?";

  // DELETE_QOS_OBJECT
  content["Delete QoS Object"] =
  "Delete QoS Object";

  // Delete Historic Data
  content["Delete Historic Data"] =
  "Delete Historic Data";

  // Period Settings
  content["Period Settings"] =
  "Period Settings";

  // Ignore (all available data)
  content["Ignore (all available data)"] =
  "Ignore (all available data)";

  // Selected Period
  content["Selected Period"] =
  "Selected Period";

  // Start Date & Time
  content["Start Date & Time"] =
  "Start Date & Time";

  // End Date & Time
  content["End Date & Time"] =
  "End Date & Time";

  // Calculation Settings
  content["Calculation Settings"] =
  "Calculation Settings";

  //CALCULATIONTYPE
  content["Calculation Type"] =
  "Calculation Type";

  //Start date should be before end date
  content["Start date should be before end date"] =
  "Start date should be before end date";

  // formula
  content["Formula"] =
  "Formula";

  // profile
  content["Profile"] =
  "Profile";

  // Saving SLA...
  content["Saving SLA..."] =
  "Saving SLA...";

  // Saving JOB...
  content["Saving Job..."] =
  "Saving Job...";

  // Fetching SLA...
  content["Fetching SLA..."] =
  "Fetching SLA...";

  // Saving SLO...
  content["Saving SLO..."] =
  "Saving SLO...";

  // Fetching SLA...
  content["Fetching SLO..."] =
  "Fetching SLO...";

  // Failed to fetch SLA
  content["Failed to fetch SLA"] =
  "Failed to fetch SLA";

  // Saving SLA Group
  content["Saving SLA Group"] =
  "Saving SLA Group";

  // Save SLA & Add New Job
  content["Save SLA & Add New Job"] =
  "Save SLA & Add New Job";

  // Save SLA
  content["Save SLA"] =
  "Save SLA";

  // Save SLO
  content["Save SLO"] =
  "Save SLO";

  // Job ID
  content["Job ID"] =
  "Job ID";

  // Confirm
  content["Confirm "] =
  "Confirm ";

  // Deletion
  content["Deletion"] =
  "Deletion";

  // Are you sure do you want to delete the
  content["Are you sure do you want to delete the "] =
  "Are you sure do you want to delete the ";

  // This operation is irreversible.
  content["This operation is irreversible."] =
  "This operation is irreversible.";

  //CONFIRMQOSCONSTRAINTDELETEION
  content["Confirm Qos Constraint Deletion"] =
  "Confirm Qos Constraint Deletion";

  //WITHSOURCE
  content[" with source "] =
  " with source ";

  //ANDTARGET
  content[" and target "] =
  " and target ";

  // Period Start
  content["Period Start"] =
  "Period Start";

  // Period Begin
  content["Period Begin"] =
  "Period Begin";

  // Period End
  content["Period End"] =
  "Period End";

  // Owner
  content["Owner"] =
  "Owner";

  // Job Start Time
  content["Job Start Time"] =
  "Job Start Time";

  //JOBEXPIRATIONTIME
  content["Job Expiration Time"] =
  "Job Expiration Time";

  // Create Historic Data
  content["Create Historic Data"] =
  "Create Historic Data";

  //AT24HR
  content["At (24 Hour)"] =
  "At (24 Hour)";

  // No Calculation s available. Save SLA and
  content["No Calculation s available. Save SLA and"] =
  "No Calculation s available. Save SLA and";

  // Configure
  content["Configure"] =
  "Configure";

  // Excluded Periods
  content["Excluded Periods"] =
  "Excluded Periods";

  // Title
  content["Title"] =
  "Title";

  // From Date
  content["From Date"] =
  "From Date";

  // To Date
  content["To Date"] =
  "To Date";

  // Updating...
  content["Updating..."] =
  "Updating...";

  // Update SLA
  content["Update SLA"] =
  "Update SLA";

  // Update SLO
  content["Update SLO"] =
  "Update SLO";

  // Save SLA and Create SLO
  content["Save SLA and Create SLO"] =
  "Save SLA and Create SLO";

  // Day
  content["Day"] =
  "Day";

  // Week(s)
  content["Week(s)"] =
  "Week(s)";

  // Month(s)
  content["Month(s)"] =
  "Month(s)";

  // Period
  content["Period"] =
  "Period";

  // Starting from
  content["Starting from"] =
  "Starting from";

  // Timezone
  content["Timezone"] =
  "Timezone";

  // Select a timezone...
  content["Select a timezone..."] =
  "Select a timezone...";

  // Date
  content["Date"] =
  "Date";

  // Notes Title
  content["Notes Title"] =
  "Notes Title";

  //Time
  content["Time"] =
  "Time";

  // QoS Definition
  content["QoS Definition"] =
  "QoS Definition";

  // weight
  content["Weight(%)"] =
  "Weight(%)";

  //filled
  content["Fulfilled(%)"] =
  "Fulfilled(%)";

  //Operator
  content["Operator"] =
  "Operator";

  //Search by SLA or Group name...
  content["Search by SLA or Group name..."] =
  "Search by SLA or Group name...";

  //Search by QoS name...
  content["Search by QoS name..."] =
  "Search by QoS name...";

  //FILTER_SEARCH_PLACEHOLDER
  content["Search..."] =
  "Search...";

  // qos
  content["QoS Name"] =
  "QoS Name";

  // Accounts
  content["Accounts"] =
  "Accounts";

  // Account
  content["Account"] =
  "Account";

  //Account placeholder
  content["Select an account..."] =
  "Select an account...";

  // Public
  content["Groups & SLA(s)"] =
  "Groups & SLA(s)";

  // View Details
  content["View Details"] =
  "View Details";

  // Create SLA from selection
  content["Create SLA from selection"] =
  "Create SLA from selection";

  // Recalculate
  content["Recalculate"] =
  "Recalculate";

  // History
  content["History"] =
  "History";

  // Quality of Service
  content["Quality of Service"] =
  "Quality of Service";

  // SQL Query Editor
  content["SQL Query Editor"] =
  "SQL Query Editor";

  //QoS group
  content["QoS group"] =
  "QoS group";

  //GROUP_FETCHING_PLACEHOLDER
  content["Fetching SLA groups..."] =
  "Fetching SLA groups...";

  //GROUP_SEARCH_SELECT
  content["Search and select a group"] =
  "Search and select a group";

  //CALCULATION_METHOD_PLACEHOLDER
  content["Select a calculation method..."] =
  "Select a calculation method...";

  //Copy SLA
  content["Copy SLA"] =
  "Copy SLA";

  //Probe
  content["Probe"] =
  "Probe";

  // Object Type
  content["Object Type"] =
  "Object Type";

  // To see the Operating period details
  content["No Operating period(s) found"] =
  "No Operating period(s) found";

  // Start creating a
  content["Start creating a "] =
  "Start creating a ";

  //new operating period
  content["new operating period"] =
  "new operating period";

  // To see the Operating period details
  content["No Calculation profile(s) found"] =
  "No Calculation profile(s) found";

  //new operating period
  content["new calculation profile"] =
  "new calculation profile";

  // To see the SLA details, Click on a parent node on the Tree.
  content["To see the SLA details, Click on a parent node on the Tree."] =
  "To see the SLA details, Click on a parent node on the Tree.";

  // Add new
  content["Add New"] =
  "Add New";

  // Create Operating Period
  content["Create Operating Period"] =
  "Create Operating Period";

  //profile Type
  content["Profile Type"] =
  "Profile Type";

  //QOS Calculation
  content["QoS Calculation"] =
  "QoS Calculation";

  //SLO Calculation
  content["SLO Calculation"] =
  "SLO Calculation";

  //Calculate availability Plugin Description
  content["Calculate availability Plugin Description"] =
  "Calculate availability Plugin Description";

  //AVAILABILITY_PLUGIN_PLACEHOLDER
  content["Select availability plugin description..."] =
  "Select availability plugin description...";

  //Value
  content["Value"] =
  "Value";

  // From
  content["From"] =
  "From";

  //Clear
  content["Clear"] =
  "Clear";

  // To
  content["To"] =
  "To";

  // Action
  content["Action"] =
  "Action";

  // Alarm Notification
  content["Alarm Notification"] =
  "Alarm Notification";

  // Alarm when compliance is breached
  content["Alarm when compliance is breached "] =
  "Alarm when compliance is breached ";

  // Alarm when warning threshold is breached
  content["Alarm when warning threshold is breached"] =
  "Alarm when warning threshold is breached";

  // Message
  content["Message"] =
  "Message";

  // Severity Level
  content["Severity Level"] =
  "Severity Level";

  // Select severity...
  content["Select severity..."] =
  "Select severity...";

  // Subsystem
  content["Subsystem"] =
  "Subsystem";

  // Select subsystem...
  content["Select subsystem..."] =
  "Select subsystem...";

  // Actions
  content["Actions"] =
  "Actions";

  // No SLOs available. Save SLA and
  content["No SLOs available. Save SLA and"] =
  "No SLOs available. Save SLA and";

  // Create SLO
  content["Create SLO"] =
  "Create SLO";

  // now.
  content["now."] =
  "now.";

  // Quality of Service Constraints
  content["Quality of Service Constraints"] =
  "Quality of Service Constraints";

  // CREATE_QoS_CONSTRAINT
  content["Create New QoS Constraint"] =
  "Create New QoS Constraint";

  // SLA/SLO name
  content["SLA/SLO name"] =
  "SLA/SLO name";

  // SLO name
  content["SLO Name"] =
  "SLO Name";

  //Object
  content["Object"] =
  "Object";

  //Select an item...
  content["Select an item..."] =
  "Select an item...";

  //Expect Quality of Service to be
  content["Expect Quality of Service to be"] =
  "Expect Quality of Service to be";

  // Historic SLA Compliance
  content["Historic SLA Compliance"] =
  "Historic SLA Compliance";

  // General View
  content["General View"] =
  "General View";

  // Graphic view
  content["Graphic View"] =
  "Graphic View";

  // View as PDF
  content["View as PDF"] =
  "View as PDF";

  // Add New SLA
  content["Add New SLA"] =
  "Add New SLA";

  // Show SLA Alarms
  content["Show SLA Alarms"] =
  "Show SLA Alarms";

  // Create SLA by Service
  content["Create SLA by Service"] =
  "Create SLA by Service";

  // No data found
  content["No data found"] =
  "No data found";

  // SLA Report
  content["SLA Report"] =
  "SLA Report";

  // SLO Report
  content["SLO Report"] =
  "SLO Report";

  // FULL Report
  content["FULL Report"] =
  "FULL Report";

  // Periods
  content["Periods"] =
  "Periods";

  // Achieved
  content["Achieved"] =
  "Achieved";

  // Expected
  content["Expected"] =
  "Expected";

  // Compliance Met
  content["Compliance Met"] =
  "Compliance Met";

  // Pending to Achieve
  content["Pending to Achieve"] =
  "Pending to Achieve";

  // Threshold Compliance
  content["Threshold Compliance"] =
  "Threshold Compliance";

  // Official
  content["Official"] =
  "Official";

  // Threshold
  content["Threshold"] =
  "Threshold";

  //Notes
  content["Notes"] =
  "Notes";

  //NOQOSAVAILABLE
  content["No QOS constraints available."] =
  "No QOS constraints available.";

  //AREUSUREQOSCONSTRAINT
  content["Are you sure do you want to delete the QoS Constraint:"] =
  "Are you sure do you want to delete the QoS Constraint:";

  // New slo
  content["Service Level Objective"] =
  "Service Level Objective";

  // Service Level Objectives
  content["Service Level Objectives"] =
  "Service Level Objectives";

  // The following service level objectives are defined in this SLA. Each objective is listed with its weight and the percentage of fulfillment.
  content["The following service level objectives are defined in this SLA. Each objective is listed with its weight and the percentage of fulfillment."] =
  "The following service level objectives are defined in this SLA. Each objective is listed with its weight and the percentage of fulfillment.";

  // The fulfillment is the relationship between the weight and the compliancy of the Quality of Service constraints defined within the SLO.
  content["The fulfillment is the relationship between the weight and the compliancy of the Quality of Service constraints defined within the SLO."] =
  "The fulfillment is the relationship between the weight and the compliancy of the Quality of Service constraints defined within the SLO.";

  // Show history
  content["Show History"] =
  "Show History";

  // The following Quality of Service objects (QoS) are defined in this SLO. Each QoS is listed with its weight and the percentage of fulfillment.
  content["The following Quality of Service objects (QoS) are defined in this SLO. Each QoS is listed with its weight and the percentage of fulfillment."] =
  "The following Quality of Service objects (QoS) are defined in this SLO. Each QoS is listed with its weight and the percentage of fulfillment.";

  // The fulfillment is the relationship between the weight and the compliancy for the constrained Quality of Service.
  content["The fulfillment is the relationship between the weight and the compliancy for the constrained Quality of Service."] =
  "The fulfillment is the relationship between the weight and the compliancy for the constrained Quality of Service.";

  // set des
  content["You may configure the way the SLO is computed.Select the method to use when computing the compliance percentage for this SLO.The method available are dependent on the selected type."] =
  "You may configure the way the SLO is computed.Select the method to use when computing the compliance percentage for this SLO.The method available are dependent on the selected type.";

  //Total Unavailability
  content["Total Unavailability"] =
  "Total Unavailability";

  //Mins
  content["Mins"] =
  "Mins";

  // The report summarizes the periods with threshold breaches and where the service is unavailable. A service is considered unavailable if it is impossible to determine the state of the service. A breach is defined to be where the samplevalue does not meet the compliance criteria.
  content["The report summarizes the periods with threshold breaches and where the service is unavailable. A service is considered unavailable if it is impossible to determine the state of the service. A breach is defined to be where the samplevalue does not meet the compliance criteria."] =
  "The report summarizes the periods with threshold breaches and where the service is unavailable. A service is considered unavailable if it is impossible to determine the state of the service. A breach is defined to be where the samplevalue does not meet the compliance criteria.";

  // SLA history
  content["SLA History"] =
  "SLA History";

  // Compliance
  content["Compliance"] =
  "Compliance";

  //Create Service Level Agreement
  content["Create Service Level Agreement"] =
  "Create Service Level Agreement";

  //Edit Service Level Agreement
  content["Edit Service Level Agreement"] =
  "Edit Service Level Agreement";

  //Modify Service Level Agreement
  content["Modify Service Level Agreement"] =
  "Modify Service Level Agreement";

  //Desc length
  content["Description can not be empty and more than 255 characters."] =
  "Description can not be empty and more than 255 characters.";

  //Msg length
  content["Message can not be empty and more than 255 characters."] =
  "Message can not be empty and more than 255 characters.";

  // Name Length
  content["Name is required and can not be more than 64 characters."] =
  "Name is required and can not be more than 64 characters.";

  // JOB properties
  content["Job Properties"] =
  "Job Properties";

  // Required
  content["Required"] =
  "Required";

  //SLA Name
  content["SLA Name"] =
  "SLA Name";

  //Compliance Type
  content["Compliance Type"] =
  "Compliance Type";

  // low
  content["Low"] =
  "Low";

  // avg
  content["Avg"] =
  "Avg";

  // high
  content["High"] =
  "High";

  // max
  content["Max"] =
  "Max";

  //Compliance Period
  content["Compliance Period"] =
  "Compliance Period";

  //Compliance Period placeholder
  content["Create/Enter the compliance period"] =
  "Create/Enter the compliance period";

  // Calculation Method
  content["Calculation Method"] =
  "Calculation Method";

  // Row delimiter
  content["Row delimiter"] =
  "Row delimiter";

  // Column delimiter
  content["Column delimiter"] =
  "Column delimiter";

  // Text Qualifier
  content["Text Qualifier"] =
  "Text Qualifier";

  // Include column headers
  content["Include column headers"] =
  "Include column headers";

  // Newline
  content["Newline"] =
  "Newline";

  // SemiColon
  content["Semicolon"] =
  "Semicolon";

  // Comma
  content["Comma"] =
  "Comma";

  // Tab
  content["Tab"] =
  "Tab";

  // Vertical bar
  content["Vertical bar"] =
  "Vertical bar";

  // None
  content["<None>"] =
  "<None>";

  // Double quote
  content["Double quote"] =
  "Double quote";

  // Single quote
  content["Single quote"] =
  "Single quote";

  // Calculation Method
  content["Export Qos"] =
  "Export Qos";

  //Percentage
  content["Percentage"] =
  "Percentage";

  // This report is getting downloaded, please wait.
  content["This report is getting downloaded, please wait."] =
  "This report is getting downloaded, please wait.";

  // PDF download failed for
  content["PDF download failed for"] =
  "PDF download failed for";

  // Manage QoS Data
  content["Manage QoS Data"] =
  "Manage QoS Data";

  // Create
  content["Create"] =
  "Create";

  // QoS Active Objects
  content["QoS Active Objects"] =
  "QoS Active Objects";

  // The SLA has no data. Please check your sla configuration.
  content["The SLA has no data. Please check your sla configuration."] =
  "The SLA has no data. Please check your sla configuration.";

  // Update
  content["Update"] =
  "Update";

  // Change Origin
  content["Change Origin"] =
  "Change Origin";

  // Merge Objects
  content["Merge Objects"] =
  "Merge Objects";

  // Show Statistics
  content["Show Statistics"] =
  "Show Statistics";

  // Save
  content["Save"] =
  "Save";

  // List of Origins
  content["List of Origins"] =
  "List of Origins";

  // View Data
  content["View Data"] =
  "View Data";

  // Export Data
  content["Export Data"] =
  "Export Data";

  // Delete Object(s)
  content["Delete Object(s)"] =
  "Delete Object(s)";

  //SALAWITHNAME
  content["SLA with name "] =
  "SLA with name ";

  //ALREADYEXISTS
  content[" already exists."] =
  " already exists.";

  //SAVINGSLAEXCEPTION
  content["An exception occurred while saving SLA"] =
  "An exception occurred while saving SLA";

  //SAVINGSLAJOBEXCEPTION
  content["An exception occurred while saving SLA Job"] =
  "An exception occurred while saving SLA Job";

  // Data
  content["Data"] =
  "Data";

  // Are you sure do you want to delete the QoS data of
  content["Are you sure do you want to delete the QoS data of"] =
  "Are you sure do you want to delete the QoS data of";

  // This operation is irreversible
  content["This operation is irreversible"] =
  "This operation is irreversible";

  // Confirm QoS Data Deletion
  content["Confirm QoS Data Deletion"] =
  "Confirm QoS Data Deletion";

  // A manual restart of the data_engine probe is required to complete this action
  content["A manual restart of the data_engine probe is required to complete this action"] =
  "A manual restart of the data_engine probe is required to complete this action";

  // Are you sure do you want to delete the QoS object(s) of
  content["Are you sure do you want to delete the QoS object of"] =
  "Are you sure do you want to delete the QoS object of";

  // Are you sure do you want to delete
  content["Are you sure do you want to delete"] =
  "Are you sure do you want to delete";

  // Objects
  content["Objects"] =
  "Objects";

  // First Sample
  content["First Sample"] =
  "First Sample";

  // Last Sample
  content["Last Sample"] =
  "Last Sample";

  // Delete the Source QoS after the merge operation
  content["Delete the Source QoS after the merge operation"] =
  "Delete the Source QoS after the merge operation";

  // Merge
  content["Merge"] =
  "Merge";

  // Merge QoS Data
  content["Merge QoS Data"] =
  "Merge QoS Data";

  // Compliant
  content["Compliant"] =
  "Compliant";

  // Not Compliant
  content["Not Compliant"] =
  "Not Compliant";

  //Saving
  content["Saving..."] =
  "Saving...";

  //Save SLA & Add New SLO
  content["Save SLA & Add New SLO"] =
  "Save SLA & Add New SLO";

  // Close
  content["Close"] =
  "Close";

  // Are you sure do you want to delete the Group of
  content["Are you sure do you want to delete the Group of "] =
  "Are you sure do you want to delete the Group of ";

  // Are you sure do you want to delete the SLA of
  content["Are you sure do you want to delete the SLA of "] =
  "Are you sure do you want to delete the SLA of ";

  // Confirm Group Deletion
  content["Confirm Group Deletion"] =
  "Confirm Group Deletion";

  // Confirm SLA Deletion
  content["Confirm SLA Deletion"] =
  "Confirm SLA Deletion";

  // Create New Group
  content["Create New Group"] =
  "Create New Group";

  // Delete Group
  content["Delete Group"] =
  "Delete Group";

  // View / Edit Group
  content["View / Edit Group"] =
  "View / Edit Group";

  // Trend Analysis
  content["Trend Analysis"] =
  "Trend Analysis";

  // Breached at
  content["Breached at "] =
  "Breached at ";

  // SLA Description
  content["SLA Description"] =
  "SLA Description";

  // SLO Description
  content["SLO Description"] =
  "SLO Description";

  // Create single report with start/end dates different from the configured compliance period
  content["Create single report with start/end dates different from the configured compliance period"] =
  "Create single report with start/end dates different from the configured compliance period";

  // Please select 2 QoS objects of the same Qos type
  content["Please select 2 QoS objects of the same Qos type"] =
  "Please select 2 QoS objects of the same Qos type";

  // Please select at least 1 QoS object
  content["Please select at least 1 QoS object"] =
  "Please select at least 1 QoS object";

  // Add Custom Origin
  content["Add Custom Origin"] =
  "Add Custom Origin";

  // Add
  content["Add"] =
  "Add";

  // Select a source first..
  content["Select a source first.."] =
  "Select a source first..";

  // Always
  content["Always"] =
  "Always";

  // Get Data first..
  content["Get Data first.."] =
  "Get Data first..";

  // Data Period
  content["Data Period"] =
  "Data Period";

  // Last Day
  content["Last Day"] =
  "Last Day";

  // Last Week
  content["Last Week"] =
  "Last Week";

  // Last Month
  content["Last Month"] =
  "Last Month";

  // Custom
  content["Custom"] =
  "Custom";

  // Get Data
  content["Get Data"] =
  "Get Data";

  // View Report
  content["View Report"] =
  "View Report";

  // op.period
  content["Op.Period"] =
  "Op.Period";

  // total
  content["Total"] =
  "Total";

  // Accuracy
  content["Accuracy"] =
  "Accuracy";

  // order
  content["Order"] =
  "Order";

  //SLM
  content["SLM"] =
  "SLM";

  //Create SLAs, SLOs and QoS constraints
  content["Create SLAs, SLOs and QoS constraints"] =
  "Create SLAs, SLOs and QoS constraints";

  // END OF LOCALIZATION

export default content;
