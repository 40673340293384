/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import axios from "axios";


// we have to renew the sid for every request because the alarm api is not under the same namespace


export class Groups {
  /*getAllGroupsSummary(options) {
        return  _renewSid('get').then(headers => 
            axios.all([
    axios.get('/mcs-ui-app/api/mcsDashboard/getGroupSummary'),
    axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupCounts'),
      axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupProfilesCounts'),
    axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupProfileDevicesCounts')

  ])
  .then(axios.spread((groupsSummary, groupPercentages,groupProfilePercentages,groupDevicePercentages) => {
      let groupsInfo={};
      groupsInfo.data.data=groupsSummary.data;
      groupsInfo.data.groupsPercentages=groupPercentages.data;
      groupsInfo.data.groupProfilePercentages=groupProfilePercentages.data;
      groupsInfo.data.groupDevicePercentages=groupDevicePercentages.data;

    return groupsInfo;
  })))
            
            
    }

    getGroupProfilesSummary(groupId) {
        return  _renewSid('get').then(headers => 
            axios.all([
    axios.get(`/mcs-ui-app/api/mcsDashboard/getGroupProfiles?groupId=` + groupId),
    axios.get(`/mcs-ui-app/api/mcsDashboard/getProfileCountByGroupId?groupId=` + groupId)
  ])
  .then(axios.spread((groupProfilesSummary, groupProfilePercentages) => {
      let groupProfilesInfo={};
      groupProfilesInfo.data=groupProfilesSummary.data;
      groupProfilesInfo.data.groupProfilePercentages=groupProfilePercentages.data;
      
    return groupProfilesInfo;
  })))
   
    }

    getApplicableDevices(profileId) {
        
   return  _renewSid('get').then(headers => 
            axios.all([
    axios.get(`/mcs-ui-app/api/mcsDashboard/getApplicableDevices?profileId=` + profileId),
    axios.get(`/mcs-ui-app/api/mcsDashboard/getGroupDevicesCountByProfileId?groupProfileId=` + profileId)
  ])
  .then(axios.spread((groupDevicesSummary, groupDevicesPercentages) => {
      let groupDevicesInfo={};
      groupDevicesInfo.data=groupDevicesSummary.data;
      groupDevicesInfo.data.groupDevicePercentages=groupDevicesPercentages.data;
      
      
    return groupDevicesInfo;
  })))
   
    }
*/
  static getDeviceByDeviceId(deviceId, profileId) {
    var params = new URLSearchParams();
    params.append("profileId", profileId);
    params.append("csID", deviceId);

    var request = {
      params: params
    }
    return axios.get(`/mcs-ui-app/api/mcsDashboard/getGroupProfileDeviceDetails`, request)

  }

  //getAllGroupMemberDevicesDetails
  /* getAllGroupsProfiles(){
        return _renewSid('get').then(headers => axios.get(`/mcs-ui-app/api/mcsDashboard/getAllGroupsProfiles`))
    }
    getGroupMemberDevicesByStatus(status){
      
        return _renewSid('get').then(headers => axios.get(`/mcs-ui-app/api/mcsDashboard/getAllGroupDevicesByStatus?status=`+ status))
    }

    getGroupMemberDevicesByStatusAndProfileId(status,profileId){
        var params = new URLSearchParams();
params.append("status", status);
params.append("groupProfileId", profileId);

var request = {
  params: params
};


return  _renewSid('get').then(headers => 
            axios.all([
   axios.get(`/mcs-ui-app/api/mcsDashboard/getAllGroupDevicesByStatusAndProfileId`,request),
    axios.get(`/mcs-ui-app/api/mcsDashboard/getGroupDevicesCountByProfileId?groupProfileId=` + profileId)
  ])
  .then(axios.spread((groupDevicesSummary, groupDevicesPercentages) => {
      let groupDevicesInfo={};
      groupDevicesInfo.data=groupDevicesSummary.data;
      groupDevicesInfo.data.groupDevicePercentages=groupDevicesPercentages.data;
      
      
    return groupDevicesInfo;
  })))
    }

    getGroupProfilesByStatusAndGroupId(status,groupId){
             var params = new URLSearchParams();
params.append("status", status);
params.append("groupId", groupId);

var request = {
  params: params
};


        return  _renewSid('get').then(headers => 
            axios.all([
    axios.get(`/mcs-ui-app/api/mcsDashboard/getAllGroupsProfilesByStatusAndGroupId`,request),
    axios.get(`/mcs-ui-app/api/mcsDashboard/getProfileCountByGroupId?groupId=` + groupId)
  ])
  .then(axios.spread((groupProfilesSummary, groupProfilePercentages) => {
      let groupProfilesInfo={};
      groupProfilesInfo.data=groupProfilesSummary.data;
      groupProfilesInfo.data.groupProfilePercentages=groupProfilePercentages.data;
      
    return groupProfilesInfo;
  })))
    }

    getGroupProfilesByStatus(status){
        return _renewSid('get').then(headers => axios.get(`/mcs-ui-app/api/mcsDashboard/getAllGroupsProfilesByStatus?status=`+ status))
    }

    getGroupsByStatus(status){
       return  _renewSid('get').then(headers => 
            axios.all([
    axios.get(`/mcs-ui-app/api/mcsDashboard/getAllGroupsByStatus?status=`+ status),
    axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupCounts'),
      axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupProfilesCounts'),
    axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupProfileDevicesCounts')

  ])
  .then(axios.spread((groupsSummary, groupPercentages,groupProfilePercentages,groupDevicePercentages) => {
      let groupsInfo={};
      groupsInfo.data=groupsSummary.data;
      groupsInfo.data.groupsPercentages=groupPercentages.data;
      groupsInfo.data.groupProfilePercentages=groupProfilePercentages.data;
      groupsInfo.data.groupDevicePercentages=groupDevicePercentages.data;

    return groupsInfo;
  })))
         
    }*/

  static getGroups(options, groupId) {
    return axios.all([
      axios.post(
        process.env.NODE_ENV === "development" ?
          "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getGroupsSummary" :
          groupId ?
            `/mcs-ui-app/api/mcsDashboard/getGroupsSummary?groupId=${groupId}` :
            "/mcs-ui-app/api/mcsDashboard/getGroupsSummary", options
      ),
      axios.get(
        process.env.NODE_ENV === "development" ?
          "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getAllGroupCounts" :
          groupId ?
            `/mcs-ui-app/api/mcsDashboard/getAllGroupCounts?groupId=${groupId}` :
            "/mcs-ui-app/api/mcsDashboard/getAllGroupCounts"
      ),
      axios.get(
        process.env.NODE_ENV === "development" ?
          "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getAllGroupProfilesCounts" :
          groupId ?
            `/mcs-ui-app/api/mcsDashboard/getAllGroupProfilesCounts?groupId=${groupId}` :
            "/mcs-ui-app/api/mcsDashboard/getAllGroupProfilesCounts"
      ),
      axios.get(
        process.env.NODE_ENV === "development" ?
          "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getAllGroupProfileDevicesCounts" :
          groupId ?
            `/mcs-ui-app/api/mcsDashboard/getAllGroupProfileDevicesCounts?groupId=${groupId}` :
            "/mcs-ui-app/api/mcsDashboard/getAllGroupProfileDevicesCounts"
      ),
    ])
      .then(
        axios.spread(
          (
            groupsSummary,
            groupPercentages,
            groupProfilePercentages,
            groupDevicePercentages
          ) => {
            let groupsInfo = { data: [] };
            groupsInfo.data.data = groupsSummary.data;
            groupsInfo.data.options = options;
            //groupsInfo.data.data.totalItems=11;
            // groupsInfo.data.totalItems=11;
            groupsInfo.data.groupsPercentages = groupPercentages.data;
            groupsInfo.data.groupProfilePercentages =
              groupProfilePercentages.data;
            groupsInfo.data.groupDevicePercentages =
              groupDevicePercentages.data;

            return groupsInfo;
          }
        )
      )
      .catch((error) => {
        error.options = options;
        throw error;
      })

    /*console.log(`getGroups`+options)
    if(options.status!==undefined && options.status!=='')
      {//groupsbysttaus
      return  _renewSid('get').then(headers => 
            axios.all([
    axios.get(`/mcs-ui-app/api/mcsDashboard/getAllGroupsByStatus?status=`+ options.status),
    axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupCounts'),
      axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupProfilesCounts'),
    axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupProfileDevicesCounts')

  ])
  .then(axios.spread((groupsSummary, groupPercentages,groupProfilePercentages,groupDevicePercentages) => {
      let groupsInfo={};
      groupsInfo.data=groupsSummary.data;
      groupsInfo.data.groupsPercentages=groupPercentages.data;
      groupsInfo.data.groupProfilePercentages=groupProfilePercentages.data;
      groupsInfo.data.groupDevicePercentages=groupDevicePercentages.data;

    return groupsInfo;
  })))
      }
      else
      {//return this.getAllGroupsSummary(options);
        return  _renewSid('get').then(headers => 
              axios.all([
      axios.post('/mcs-ui-app/api/mcsDashboard/getGroupSummary',options),
      axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupCounts'),
        axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupProfilesCounts'),
      axios.get('/mcs-ui-app/api/mcsDashboard/getAllGroupProfileDevicesCounts')

    ])
    .then(axios.spread((groupsSummary, groupPercentages,groupProfilePercentages,groupDevicePercentages) => {
        let groupsInfo={data:[]};
        groupsInfo.data.data=groupsSummary.data;
        groupsInfo.data.groupsPercentages=groupPercentages.data;
        groupsInfo.data.groupProfilePercentages=groupProfilePercentages.data;
        groupsInfo.data.groupDevicePercentages=groupDevicePercentages.data;

      return groupsInfo;
    })))
    	
      }*/
  }

  static getGroupProfiles(options, groupId) {
    //		/POST /mcsDashboard/getGroupProfilesSummary
    if (options.groupId !== undefined && options.groupId !== "") {
      options.id = options.groupId;
      //return this.getGroupProfilesSummary.call(this,options.groupId)
      return axios.all([
        axios.post(
          process.env.NODE_ENV === "development" ?
            "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getGroupProfilesSummary" :
            groupId ?
              `/mcs-ui-app/api/mcsDashboard/getGroupProfilesSummary?groupId=${groupId}` :
              "/mcs-ui-app/api/mcsDashboard/getGroupProfilesSummary",
          options
        ),
        axios.get(process.env.NODE_ENV === "development" ? "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getProfileCountByGroupId" :
          `/mcs-ui-app/api/mcsDashboard/getProfileCountByGroupId?groupId=` +
          options.groupId
        ),
      ])
        .then(
          axios.spread((groupProfilesSummary, groupProfilePercentages) => {
            let groupProfilesInfo = { data: [] };
            groupProfilesInfo.data.data = groupProfilesSummary.data;
            groupProfilesInfo.data.options = options;
            groupProfilesInfo.data.groupProfilePercentages =
              groupProfilePercentages.data;

            return groupProfilesInfo;
          })
        )
        .catch((error) => {
          error.options = options;
          throw error;
        })
    } else if (options.groupId == undefined || options.groupId == "") {
      options.id = options.groupId;
      //return this.getGroupProfilesByStatus(options.status)
      return axios.post(
        process.env.NODE_ENV === "development" ?
          "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getGroupProfilesSummary" :
          groupId ?
            `/mcs-ui-app/api/mcsDashboard/getGroupProfilesSummary?groupId=${groupId}` :
            "/mcs-ui-app/api/mcsDashboard/getGroupProfilesSummary",
        options
      )
        .then((groupProfilesSummary) => {
          let groupProfilesInfo = { data: [] };
          groupProfilesInfo.data.data = groupProfilesSummary.data;
          groupProfilesInfo.data.options = options;
          return groupProfilesInfo;
        })
        .catch((error) => {
          error.options = options;
          throw error;
        })

    }
    /*else if(options.status!==undefined && options.status!=='' && options.groupId!==undefined && options.groupId!=='')
    {
    //return this.getGroupProfilesByStatusAndGroupId(options.status,options.groupId)
      var params = new URLSearchParams();
      params.append("status", options.status);
      params.append("groupId", options.groupId);

      var request = {
        params: params
      };


              return  _renewSid('get').then(headers => 
                  axios.all([
                    axios.post('/mcs-ui-app/api/mcsDashboard/getGroupProfilesSummary', options),
          axios.get(`/mcs-ui-app/api/mcsDashboard/getProfileCountByGroupId?groupId=` + options.groupId)
        ])
        .then(axios.spread((groupProfilesSummary, groupProfilePercentages) => {
            let groupProfilesInfo={data:[]};
            groupProfilesInfo.data.data=groupProfilesSummary.data;
            groupProfilesInfo.data.groupProfilePercentages=groupProfilePercentages.data;
            
          return groupProfilesInfo;
        })))
    }*/
  }

  static getGroupDevices(options, groupId) {
    if (options.profileId !== undefined && options.profileId !== "") {
      options.id = options.profileId;
      //return  this.getApplicableDevices(options.profileId)
      return axios.all([
        axios.post(
          process.env.NODE_ENV === "development" ?
            "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getGroupProfileDevicesSummary" :
            groupId ?
              `/mcs-ui-app/api/mcsDashboard/getGroupProfileDevicesSummary?groupId=${groupId}` :
              "/mcs-ui-app/api/mcsDashboard/getGroupProfileDevicesSummary",
          options
        ),
        axios.get(process.env.NODE_ENV === "development" ? "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getGroupDevicesCountByProfileId" :
          `/mcs-ui-app/api/mcsDashboard/getGroupDevicesCountByProfileId?groupProfileId=` +
          options.profileId
        ),
      ])
        .then(
          axios.spread((groupDevicesSummary, groupDevicesPercentages) => {
            let groupDevicesInfo = { data: [] };
            groupDevicesInfo.data.data = groupDevicesSummary.data;
            groupDevicesInfo.data.options = options;
            groupDevicesInfo.data.groupDevicePercentages =
              groupDevicesPercentages.data;

            return groupDevicesInfo;
          })
        )
        .catch((error) => {
          error.options = options;
          throw error;
        })

    } else if (options.profileId == undefined || options.profileId == "") {
      options.id = options.profileId;
      //return this.getGroupMemberDevicesByStatus(options.status)
      return axios.post(
        process.env.NODE_ENV === "development" ?
          "http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/getGroupProfileDevicesSummary" :
          groupId ?
            `/mcs-ui-app/api/mcsDashboard/getGroupProfileDevicesSummary?groupId=${groupId}` :
            "/mcs-ui-app/api/mcsDashboard/getGroupProfileDevicesSummary",
        options
      )
        .then((groupDevicesSummary) => {
          let groupDevicesInfo = { data: [] };
          groupDevicesInfo.data.data = groupDevicesSummary.data;
          groupDevicesInfo.data.options = options;
          return groupDevicesInfo;
        })
        .catch((error) => {
          error.options = options;
          throw error;
        })

    }
    /*else if(options.status!==undefined && options.status!=='' && options.profileId!==undefined && options.profileId!=='')
    {
    //return this.getGroupMemberDevicesByStatusAndProfileId(options.status,options.profileId)
      var params = new URLSearchParams();
      params.append("status", options.status);
      params.append("groupProfileId", options.profileId);

      var request = {
        params: params
      };


      return  _renewSid('get').then(headers => 
                  axios.all([
         axios.get(`/mcs-ui-app/api/mcsDashboard/getAllGroupDevicesByStatusAndProfileId`,request),
          axios.get(`/ mcs-ui-app/api/mcsDashboard/getGroupDevicesCountByProfileId?groupProfileId=` + options.profileId)
        ])
        .then(axios.spread((groupDevicesSummary, groupDevicesPercentages) => {
            let groupDevicesInfo={data:[]};
            groupDevicesInfo.data.data=groupDevicesSummary.data;
            groupDevicesInfo.data.groupDevicePercentages=groupDevicesPercentages.data;
            
            
          return groupDevicesInfo;
        })))
    }*/
  }
}
