/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import axios from 'axios'
import config from './../config'

export class Interfaces {
  static getInterfaceData(fields) {
    const url = process.env.NODE_ENV == "development"
      ? [
        "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/VgCY5v/interface_id",
      ]
      : [config.basename, 'api/v1/interface', fields.csId].join('/')

    const sortOrder = JSON.parse(sessionStorage.getItem('groupsPayload')).sortOrder;
    const params = {
      isGroup: fields.isGroup,
      pageNumber: JSON.parse(sessionStorage.getItem('groupsPayload')).pageNumber,
      sortOrder: sortOrder ? sortOrder : null
    };
    return axios.get(url, {params});
  }

  static getRegularFields(fields) {
    const url = process.env.NODE_ENV == "development"
      ? [
        "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/VgCY5v/interface_id",
      ]
      : [config.basename, 'api/v1/interface', fields.csId].join('/')

    const sortOrder = fields.sortOrder ? fields.sortOrder : JSON.parse(sessionStorage.getItem('groupsPayload')).sortOrder;
    const params = {
      isGroup: fields.isGroup,
      pageNumber: fields.pageNumber ? fields.pageNumber : JSON.parse(sessionStorage.getItem('groupsPayload')).pageNumber,
      sortOrder: sortOrder ? sortOrder : null
    };
    return axios.get(url, {params}).then((interfaceResponse) => {
      const resData = interfaceResponse.data;
      resData.interfaceData = {iface: interfaceResponse.data.iface, itemId: interfaceResponse.data.itemId,
        itemName: interfaceResponse.data.itemName, total: interfaceResponse.data.total};

      let masterIds = resData.iface
        ? resData.iface.map((item) => {
            return item.masterId;
          })
        : [];

     let metricTypes = [
        "11.1:1", // Utilization
        "11.1:2", // UtilizationIn
        "11.1:3", // UtilizationOut
        "11.1:24",// PctDiscards
        "11.1:25",// PctDiscardsIn
        "11.1:26",// PctDiscardsOut
        "11.1:27",// PctErrors
        "11.1:28",// PctErrorsIn
        "11.1:29",// PctErrorsOut
      ];

      const urlParams = new URLSearchParams();
      urlParams.append("cs_id", resData?.itemId);
      urlParams.append("masterIds", masterIds);
      urlParams.append("metricTypes", metricTypes);
      const id = urlParams.get("cs_id")
      var url = process.env.NODE_ENV == "development"
        ? [
          "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/VgCY5v/interface/metric",
        ]
        : [config.basename, 'api/v1/interface/metric', id != null ? id : '']
      return axios.post(url.join('/'), urlParams).then((response) => {
        const res = response.data;
        resData.metricData = res;
        interfaceResponse.data = resData;
        return interfaceResponse;
      });
    });
  }

  static getMetricFields(params) {
    const id = params.get("cs_id")
    var url = process.env.NODE_ENV == "development"
      ? [
        "http://10.252.25.208:8080/castlemock/mock/rest/project/ViXRbO/application/VgCY5v/interface/metric",
      ]
      : [config.basename, 'api/v1/interface/metric', id != null ? id : '']
    return axios.post(url.join('/'), params)
  }

  static getMasterElements(filter) {
    const url = [config.basename, 'api/v1/interface'].join('/')
    if (filter) {
      return axios.get(url + "?q=" + filter)
    } else {
      return axios.get(url)
    }
  }

  static updateAliasInterface(newAlias, master_id) {
    return axios.put(
      [config.basename, `api/v1/interface/update/${master_id}?name=${newAlias}`].join("/")
    );
  }

  static getInterfaceAlias(master_id) {
    return axios.get(
      [config.basename, `api/v1/interface/getAlias/${master_id}`].join("/")
    );
  }
}
