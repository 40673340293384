/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import axios from 'axios'
import config from './../api/config'
import { setHeartbeat } from './../api/heartbeat/actions'
class Heartbeat {
  constructor(dispatch) {
    this.pollInterval = 15000
    this.dispatch = dispatch
    // 3 minutes
    this.failTimeout = 3 * 60 * 1000
    this.failCount = 0
  }
  ping() {
    return axios.get(`${config.basename}/api/v1/session/keep-alive`);
  }

  start() {
    if (process.env.NODE_ENV === 'development') return
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.ping()
        .then(response=> {
          this.failCount = 0
          this.dispatch(setHeartbeat(true))
          this.pollInterval = 15000
          this.start()
        })
        .catch(err => {
          this.failCount += 1
          this.dispatch(setHeartbeat(false))
          clearTimeout(this.timeout)
          this.pollInterval = 5000
          if (this.failCount * this.pollInterval < this.failTimeout) {
            this.start()
          }
        })
    }, this.pollInterval)
  }
}

export default Heartbeat
