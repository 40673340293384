// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //interpolation help text
  content["Provides linear interpolation in the QOS series based on the actual samples collected by the probe."] =
  "Provides linear interpolation in the QOS series based on the actual samples collected by the probe.";

  //Aggregation Interval help text
  content["Automatic sets the aggregation interval as: \n1 minute for a time frame <= 1 hour \n5 minutes for a time frame <= 1 day \n30 minutes for a time frame <= 1 week \n2 hours for a time frame over a week"] =
  "Automatic sets the aggregation interval as: \n1 minute for a time frame <= 1 hour \n5 minutes for a time frame <= 1 day \n30 minutes for a time frame <= 1 week \n2 hours for a time frame over a week";

  //Import
  content["Open/Import"] =
  "Open/Import";

  //export
  content["Export"] =
  "Export";

  //save
  content["Save"] =
  "Save";

  //save as
  content["Save As"] =
  "Save As";

  //clear
  content["New"] =
  "New";

  // END OF LOCALIZATION

export default content;
