/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  REQUEST_OPEN_ALARMS,
  FETCH_OPEN_ALARMS,
  FETCH_OPEN_ALARMS_SUCCESS,
  FETCH_OPEN_ALARMS_FAILURE, 
  POLL_OPEN_ALARMS_START,
  POLL_OPEN_ALARMS_STOP,
  REQUEST_ACTIVE_DEVICES,
  FETCH_ACTIVE_DEVICES,
  FETCH_ACTIVE_DEVICES_SUCCESS,
  FETCH_ACTIVE_DEVICES_FAILURE, 
  POLL_ACTIVE_DEVICES_START,
  POLL_ACTIVE_DEVICES_STOP,
  REQUEST_ROLE_DEVICES,
  FETCH_ROLE_DEVICES,
  FETCH_ROLE_DEVICES_SUCCESS,
  FETCH_ROLE_DEVICES_FAILURE, 
  POLL_ROLE_DEVICES_START,
  POLL_ROLE_DEVICES_STOP,
  REQUEST_TOP_TECHNOLOGIES,
  FETCH_TOP_TECHNOLOGIES,
  FETCH_TOP_TECHNOLOGIES_SUCCESS,
  FETCH_TOP_TECHNOLOGIES_FAILURE, 
  POLL_TOP_TECHNOLOGIES_START,
  POLL_TOP_TECHNOLOGIES_STOP,
  REQUEST_TOP_GROUPS,
  FETCH_TOP_GROUPS,
  FETCH_TOP_GROUPS_SUCCESS,
  FETCH_TOP_GROUPS_FAILURE, 
  POLL_TOP_GROUPS_START,
  POLL_TOP_GROUPS_STOP,
  REQUEST_TOP_DEVICES,
  FETCH_TOP_DEVICES,
  FETCH_TOP_DEVICES_SUCCESS,
  FETCH_TOP_DEVICES_FAILURE, 
  POLL_TOP_DEVICES_START,
  POLL_TOP_DEVICES_STOP,
  HOME_SCREEN_COMPONENTS_CHANGE
} from './actionTypes'

export function requestOpenAlarms() {
  return {
    type: REQUEST_OPEN_ALARMS
  }
}

export function fetchOpenAlarmsSuccess(json) {
  return {
    type: FETCH_OPEN_ALARMS_SUCCESS,
    data: json
  }
}

export function fetchOpenAlarmsFailure(error) {
  return {
    type: FETCH_OPEN_ALARMS_FAILURE,
    errorMessage: error.message
  }
}

export function fetchOpenAlarmspoll(csId,groupIds,probeId) {
  return dispatch => {
    dispatch(requestOpenAlarms())
    dispatch(pollOpenAlarmsStart(csId,groupIds,probeId))
    return {
      type:FETCH_OPEN_ALARMS,
      
    }
  }
}

export function pollOpenAlarmsStart(csId,groupIds,probeId) {
  // console.log("Start polling OpenAlarms")
  return {
    type:POLL_OPEN_ALARMS_START,
    csId:csId,
    groupIds:groupIds,
    probeId:probeId
  }
}
export function pollOpenAlarmsStop() {
  // console.log("Stop polling OpenAlarms")
  return {
    type:POLL_OPEN_ALARMS_STOP,
    
  }
} 

//-----------Active Devices -------------------------------------------------

export function requestActiveDevices() {
  return {
    type: REQUEST_ACTIVE_DEVICES
  }
}

export function fetchActiveDevicesSuccess(json) {
  return {
    type: FETCH_ACTIVE_DEVICES_SUCCESS,
    data: json
  }
}

export function fetchActiveDevicesFailure(error) {
  return {
    type: FETCH_ACTIVE_DEVICES_FAILURE,
    errorMessage: error.message
  }
}

export function fetchActiveDevicespoll() {
  return dispatch => {
    dispatch(requestActiveDevices())
    dispatch(pollActiveDevicesStart())
    return {
      type:FETCH_ACTIVE_DEVICES,
      
    }
  }
}

export function pollActiveDevicesStart() {
  // console.log("Start polling ActiveDevices")
  return {
    type:POLL_ACTIVE_DEVICES_START,
    
  }
}
export function pollActiveDevicesStop() {
  // console.log("Stop polling ActiveDevices")
  return {
    type:POLL_ACTIVE_DEVICES_STOP,
    
  }
} 


//-----------Role Devices -------------------------------------------------

export function requestRoleDevices() {
  return {
    type: REQUEST_ROLE_DEVICES
  }
}

export function fetchRoleDevicesSuccess(json) {
  return {
    type: FETCH_ROLE_DEVICES_SUCCESS,
    data: json
  }
}

export function fetchRoleDevicesFailure(error) {
  return {
    type: FETCH_ROLE_DEVICES_FAILURE,
    errorMessage: error.message
  }
}

export function fetchRoleDevicespoll(groupIds,probeId) {
  return dispatch => {
    dispatch(requestRoleDevices())
    dispatch(pollRoleDevicesStart(groupIds,probeId))
    return {
      type:FETCH_ROLE_DEVICES,
      
    }
  }
}

export function pollRoleDevicesStart(groupIds,probeId) {
  // console.log("Start polling RoleDevices")
  return {
    type:POLL_ROLE_DEVICES_START,
    groupIds:groupIds,
    probeId:probeId
  }
}
export function pollRoleDevicesStop() {
  // console.log("Stop polling RoleDevices")
  return {
    type:POLL_ROLE_DEVICES_STOP,
    
  }
} 

//-----------TopTechnologies -------------------------------------------------

export function requestTopTechnologies() {
  return {
    type: REQUEST_TOP_TECHNOLOGIES
  }
}

export function fetchTopTechnologiesSuccess(json) {
  return {
    type: FETCH_TOP_TECHNOLOGIES_SUCCESS,
    data: json
  }
}

export function fetchTopTechnologiesFailure(error) {
  return {
    type: FETCH_TOP_TECHNOLOGIES_FAILURE,
    errorMessage: error.message
  }
}

export function fetchTopTechnologiespoll(csId,groupIds) {
  return dispatch => {
    dispatch(requestTopTechnologies())
    dispatch(pollTopTechnologiesStart(csId,groupIds))
    return {
      type:FETCH_TOP_TECHNOLOGIES,
      
    }
  }
}

export function pollTopTechnologiesStart(csId,groupIds) {
  // console.log("Start polling TopTechnologies")
  return {
    type:POLL_TOP_TECHNOLOGIES_START,
    csId:csId,
    groupIds:groupIds,
  }
}
export function pollTopTechnologiesStop() {
  // console.log("Stop polling TopTechnologies")
  return {
    type:POLL_TOP_TECHNOLOGIES_STOP,
    
  }
} 

//-----------TopGroups -------------------------------------------------

export function requestTopGroups() {
  return {
    type: REQUEST_TOP_GROUPS
  }
}

export function fetchTopGroupsSuccess(json) {
  return {
    type: FETCH_TOP_GROUPS_SUCCESS,
    data: json
  }
}

export function fetchTopGroupsFailure(error) {
  return {
    type: FETCH_TOP_GROUPS_FAILURE,
    errorMessage: error.message
  }
}

export function fetchTopGroupspoll(csId) {
  return dispatch => {
    dispatch(requestTopGroups())
    dispatch(pollTopGroupsStart(csId))
    return {
      type:FETCH_TOP_GROUPS,
      
    }
  }
}

export function pollTopGroupsStart(csId) {
  // console.log("Start polling TopGroups")
  return {
    type:POLL_TOP_GROUPS_START,
    csId:csId,
  }
}
export function pollTopGroupsStop() {
  // console.log("Stop polling TopGroups")
  return {
    type:POLL_TOP_GROUPS_STOP,
    
  }
} 
//-----------TopDevices -------------------------------------------------

export function requestTopDevices() {
  return {
    type: REQUEST_TOP_DEVICES
  }
}

export function fetchTopDevicesSuccess(json) {
  return {
    type: FETCH_TOP_DEVICES_SUCCESS,
    data: json
  }
}

export function fetchTopDevicesFailure(error) {
  return {
    type: FETCH_TOP_DEVICES_FAILURE,
    errorMessage: error.message
  }
}

export function fetchTopDevicespoll(groupIds,probeId) {
  return dispatch => {
    dispatch(requestTopDevices())
    dispatch(pollTopDevicesStart(groupIds,probeId))
    return {
      type:FETCH_TOP_DEVICES,
      
    }
  }
}

export function pollTopDevicesStart(groupIds,probeId) {
  // console.log("Start polling TopDevices")
  return {
    type:POLL_TOP_DEVICES_START,
    groupIds:groupIds,
    probeId:probeId
  }
}
export function pollTopDevicesStop() {
  // console.log("Stop polling TopDevices")
  return {
    type:POLL_TOP_DEVICES_STOP,
    
  }
} 

//----Home screen component

export function homeScreenComponentsChanged(updatedState) {
  return {
    type:HOME_SCREEN_COMPONENTS_CHANGE,
    currentState:updatedState
  }
} 