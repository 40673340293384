/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import { timeFormat } from "d3-time-format";
import {CircularProgress} from '@mineral/core';

import {
  LineChart,
  Line,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const dateFormat = timeFormat("%A, %B %d, %Y %I:%M %p");
const dateSmFormat = timeFormat("%Y-%m-%d");
const hoursFormat = timeFormat("%I:%M %p");
const randomColor = [
  "#2F8DFA",
  "#5C6BC0",
  "#AB47BC",
  "#e67e22",
  "#ef5350",
  "#26C6DA",
  "#66BB6A",
  "#D4E157",
  "#00897B",
  "#FFCA28",
  "#FFA726",
  "#FF7043",
  "#8D6E63",
  "#BDBDBD",
  "#78909C",
  "#0099BC",
  "#8E8CD8",
  "#00B294",
  "#10893E",
  "#567C73",
  "#7E735F",
  "#2D7D9A",
  "#767676",
  "#7A7574",
];
const randomColoFn = () => {
  let color =
    "#" +
    ("000000" + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
  return color;
};
const CustomTooltip = ({ payload, label }) => {
  if (payload.length === 0) {
    return null;
  }
  const date = dateFormat(label);
  const tooltipItem = payload.map((item, i) => {
    return (
      <div key={item.name + i} className="tool-tip-item">
        <p className="tool-tip-item">
          <span className="tooltip-name">
            <i style={{ backgroundColor: item.fill }} />
            {`${item.name}`}
          </span>
          <span className="tooltip-value">
            {item.value && `${item.value}${item.unit}`}
          </span>
        </p>
      </div>
    );
  });
  return (
    <div className="recharts-default-tooltip chart-custom-tooltip">
      <p className="tool-tip-label time">{date}</p>
      <div
        className={`tool-tip-item-wrapper ${
          payload.length > 15 ? "large" : "normal"
        }`}
      >
        {tooltipItem}
      </div>
    </div>
  );
};

class TimeserieChart extends Component {
  componentDidUpdate() {
    if (
      this.props.selectedMetricOrder.length > 0 &&
      this.props.selectedArrayChanged
    ) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }
  renderLines() {
    const { unit } = this.props;

    return this.props.values.map((value, i) => {
      return (
        <Line
          key={value + i}
          animationDuration={0}
          strokeWidth={2}
          unit={unit}
          dot={false}
          type="monotone"
          dataKey={value}
          connectNulls={false}
          stroke={randomColor[i] || randomColoFn()}
          fill={randomColor[i] || randomColoFn()}
        />
      );
    });
  }

  renderChart() {
    const height = this.props.values.length > 15 ? 400 : 200;

    const { group, data, yDomain, timerange } = this.props;

    return (
      <ResponsiveContainer width="100%" minHeight={height} height="100%">
        <LineChart
          data={data}
          syncId={group}
          margin={{ top: 5, right: 50, left: 0, bottom: 5 }}
        >
          <XAxis
            dataKey="epoch"
            scale="utcTime"
            tickFormatter={timerange <= 48 ? hoursFormat : dateSmFormat}
            tick={{ dy: 12 }}
            interval={"preserveStartEnd"}
          />
          <YAxis axisLine={true} tickLine={false} domain={yDomain} />
          {this.props.values.length > 30 ? null : (
            <Tooltip content={<CustomTooltip />} />
          )}
          <CartesianGrid horizontal={true} vertical={false} />
          {this.renderLines()}
          {this.props.values.length > 1 ? <Legend /> : null}
        </LineChart>
      </ResponsiveContainer>
    );
  }

  renderEmptyChart() {
    return (
      <div className="empty">
        No data collected for the time period specified
      </div>
    );
  }

  render() {
    const { data, name, avgVal, maxVal, minVal, unit, isFetching } = this.props;

    if (isFetching) {
      return <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{color: '#3272D9'}} /></div>;
    }

    let average = `${avgVal.toFixed(2)} ${unit}`;
    let maximum = `${maxVal.toFixed(2)} ${unit}`;
    let minimum = `${minVal.toFixed(2)} ${unit}`;

    if (data.length === 0) {
      average = "N/A";
      maximum = "N/A";
      minimum = "N/A";
    }

    return (
      <div className="timeserie-wrapper">
        <div className="title" title={name}>
          {name}
        </div>
        {data.length !== 0 ? this.renderChart() : this.renderEmptyChart()}
        <div className="stats">
          <div>
            <span className="stats-label">avg</span>
            <span className="stats-value">{average}</span>
          </div>
          <div>
            <span className="stats-label">min</span>
            <span className="stats-value">{minimum}</span>
          </div>
          <div>
            <span className="stats-label">max</span>
            <span className="stats-value">{maximum}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default TimeserieChart;
